export const arrToObj = (arr, keyName) => {
  if (Array.isArray(arr) && arr.length) {
    return Object.fromEntries(arr.map((item) => [item[keyName], item]));
  }
  return {};
};

export const getLanguage = (type) => {
  switch (type) {
    case 'MySQL':
      return 'mysql';
    case 'PostgreSQL':
      return 'pgsql';
    case 'Redis':
      return 'redis';
    default:
      return 'sql';
  }
};
