import Vue from 'vue';
import VueI18n from 'vue-i18n';
import iEn from 'view-design/dist/locale/en-US';
import iZhCN from 'view-design/dist/locale/zh-CN';
import antdZhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import antdEn from 'ant-design-vue/lib/locale-provider/en_US';

Vue.use(VueI18n);

// Prevent non-existence of VUE_APP_I18N_LOCALE
let locale = localStorage.getItem('lang') || navigator.language || navigator.userLanguage || 'zh-CN';
if (['zh', 'zh-TW', 'zh-HK'].includes(locale)) {
  locale = 'zh-CN';
}
if (!['zh-CN', 'en-US'].includes(locale)) {
  locale = 'en-US';
}
export default new VueI18n({
  locale,
  fallbackLocale: 'zh-CN',
  messages: {
    'zh-CN': Object.assign(require('./locales/zh'), iZhCN, antdZhCN),
    'en-US': Object.assign(require('./locales/en'), iEn, antdEn)
  }
});
