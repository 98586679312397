export const dsApi = {
  connectDs: '/clouddm/console/api/v1/datasource/connectds',
  addDs: '/clouddm/console/api/v1/datasource/addds',
  getDsList: '/rdp/console/api/v1/datasource/listbycondition',
  getDmDsList: '/clouddm/console/api/v1/datasource/listbycondition',
  getDsListForManage: '/clouddm/console/api/v1/datasource/listdsformanage',
  listDsForManage: '/clouddm/console/api/v1/datasource/listdsformanage',
  getDsConfig: '/clouddm/console/api/v1/datasource/querydsconfig',
  updateDsConfig: '/clouddm/console/api/v1/datasource/upsertdsconfig',
  getDs: '/clouddm/console/api/v1/datasource/queryds',
  updateDsHosts: '/clouddm/console/api/v1/datasource/updatedshosts',
  updateDsAccount: '/clouddm/console/api/v1/datasource/updatedsaccount',
  deleteDs: '/clouddm/console/api/v1/datasource/deleteds',
  updateDsDesc: '/clouddm/console/api/v1/datasource/updatedsdesc',
  modifyUserDsAuth: '/rdp/console/api/v1/dataauth/modifyuserdsauth',
  listUserDsAuth: '/rdp/console/api/v1/dataauth/listuserdsauth',
  listMyAllDsAuth: '/rdp/console/api/v1/dataauth/listmyalldsauth',
  listUserAllDsAuth: '/rdp/console/api/v1/dataauth/listuseralldsauth',
  listAllDsAuthKind: '/rdp/console/api/v1/dataauth/listalldsauthkind',
  updateDsBindingAndEnv: '/clouddm/console/api/v1/datasource/updatedsbindingandenv',
  schemaCheckDdl: '/rdp/console/api/v1/dataauth/check_permissions',
  listDsConnectionOption: '/clouddm/console/api/v1/constant/listdsconnectionoption',
  dsSettingDef: '/clouddm/console/api/v1/datasource/dsSettingsDef',
  enableDsQuery: '/clouddm/console/api/v1/datasource/enableDsQuery',
  disableDsQuery: '/clouddm/console/api/v1/datasource/disableDsQuery',
  listSpec: '/clouddm/console/api/v1/datasource/listSpec',
  bindEnvParam: '/clouddm/console/api/v1/datasource/bindEnvParam',
  unbindEnvParam: '/clouddm/console/api/v1/datasource/unbindEnvParam',
  listEnvParamForSec: '/clouddm/console/api/v1/datasource/listEnvParamForSec',
  addDesktopDs: '/clouddm/console/api/v1/desktop/datasource/addDs',
  testDesktopDs: '/clouddm/console/api/v1/desktop/datasource/testDs',
  testEnableQuery: '/clouddm/console/api/v1/datasource/testEnableQuery',
  testConnect: '/clouddm/console/api/v1/datasource/testConnect'
};
