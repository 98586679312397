import { render, staticRenderFns } from "./CleanData.vue?vue&type=template&id=61f00db4&scoped=true"
import script from "./CleanData.vue?vue&type=script&lang=js"
export * from "./CleanData.vue?vue&type=script&lang=js"
import style0 from "./CleanData.vue?vue&type=style&index=0&id=61f00db4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f00db4",
  null
  
)

export default component.exports