import {
  UPDATE_CLUSTER_LIST,
  UPDATE_DEPLOY_ENV_LIST_MAP, UPDATE_DS_SETTING,
  UPDATE_DS_TYPE_LIST, UPDATE_GLOBAL_SETTING,
  UPDATE_USERINFO,
  UPDATE_PRODUCT_CLUSTER,
  UPDATE_SELECT_PRODUCT_CLUSTER, UPDATE_DM_GLOBAL_SETTING, UPDATE_CC_GLOBAL_SETTING,
  UPDATE_MY_AUTH, UPDATE_DEPLOY_SITE, UPDATE_SELECT_DM_PRODUCT_CLUSTER,
  UPDATE_MY_CATALOG, UPDATE_RULE_SETTING,
  UPDATE_PUBLIC_KEY
} from '@/store/mutationTypes';
import request from '@/services/http/request';
import { api } from '@/services/http/api';
import { listProductCluster } from '@/services/cc/api/productCluster';
import { ccGlobalConfig } from '@/services/cc/api/constant';
import { listMyAuthCategoryForMenu, getCurrUserConfigs, getPrimaryAccountPwdValidateExpr } from '@/services/cc/api/rdpUser';
import i18n from '@/i18n';
import { createWebSocket, hasWebSocketInstance } from '@/services/socket';

export default {
  async getRuleSetting({ commit }) {
    const res = await request({ url: api.ruleSettingDef });
    if (res.success) {
      commit(UPDATE_RULE_SETTING, { ruleSetting: res.data });
    }
  },
  async getUserInfo({
    commit
  }) {
    const publicKeyRes = await request({ url: api.getPublicKey });
    if (publicKeyRes.success) {
      commit(UPDATE_PUBLIC_KEY, publicKeyRes.data);
    }
    const userInfoRes = await request({ url: api.getUserInfo });
    if (userInfoRes.success) {
      const userConfigRes = await getCurrUserConfigs();
      if (userConfigRes.data.success) {
        userInfoRes.data.userConfig = userConfigRes.data.data;
      }
      commit(UPDATE_USERINFO, userInfoRes.data);
      const userAuthRes = await request({ url: api.listMyAuth });
      if (userAuthRes.success) {
        commit(UPDATE_MY_AUTH, userAuthRes.data);
      }
      const catRes = await listMyAuthCategoryForMenu();
      if (catRes.data.success) {
        commit(UPDATE_MY_CATALOG, catRes.data.data);
      }
    }

    const globalSettingRes = await request({ url: api.getGlobalSettings });
    let dmGlobalSettingRes;
    let ccGlobalSettingRes;
    if (globalSettingRes.success) {
      commit(UPDATE_DEPLOY_SITE, globalSettingRes.data.deploySite);

      commit(UPDATE_GLOBAL_SETTING, globalSettingRes.data);

      const { PRODUCT_CLOUD_DM, PRODUCT_CLOUD_CANAL } = globalSettingRes.data.features;

      let icon_url = '/rdp.ico';
      let title = 'ClouGence RDP';
      if (PRODUCT_CLOUD_DM && !PRODUCT_CLOUD_CANAL) {
        icon_url = '/dm.ico';
        title = 'CloudDM';
      }
      if (!PRODUCT_CLOUD_DM && PRODUCT_CLOUD_CANAL) {
        icon_url = '/cc.ico';
        title = 'CloudCanal';
      }
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = icon_url;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title = title;

      // if have product cluster list, dont need to apply it.
      // if (globalSettingRes.data.features.PRODUCT_CLOUD_CANAL) {
      //   ccGlobalSettingRes = await ccGlobalConfig();
      //   if (ccGlobalSettingRes.data.success) {
      //     commit(UPDATE_CC_GLOBAL_SETTING, ccGlobalSettingRes.data.data);
      //   }
      // }
      // if (PRODUCT_CLOUD_DM) {
      //   console.log(1112222);
      //   dmGlobalSettingRes = await request({ url: api.dmGlobalSettings });
      //   if (dmGlobalSettingRes.success) {
      //     commit(UPDATE_DM_GLOBAL_SETTING, dmGlobalSettingRes.data);
      //   }
      //   const dsSettingRes = await request({ url: api.dsSettingDef });
      //
      //   if (dsSettingRes.success) {
      //     commit(UPDATE_DS_SETTING, dsSettingRes.data);
      //   }
      // }
    }
  },
  async getCcGlobalConfig({
    commit
  }) {
    const ccGlobalSettingRes = await ccGlobalConfig();
    if (ccGlobalSettingRes.data.success) {
      commit(UPDATE_CC_GLOBAL_SETTING, ccGlobalSettingRes.data.data);
    }
  },
  async getDmGlobalConfig({
    commit
  }) {
    const dmGlobalSettingRes = await request({ url: api.dmGlobalSettings });
    if (dmGlobalSettingRes.success) {
      const dmSetting = dmGlobalSettingRes.data;

      if (dmSetting.personal) {
        i18n.locale = 'zh-CN';
      }

      const dsSettingRes = await request({ url: api.dsSettingDef });

      if (dsSettingRes.success) {
        dmSetting.dsSettingDef = dsSettingRes.data;
        commit(UPDATE_DS_SETTING, dsSettingRes.data);
      }
      commit(UPDATE_DM_GLOBAL_SETTING, dmSetting);
    }
  },
  async getDeployEnvList({
    commit
  }) {
    const res = await request({ url: api.listCloudOrIdcNames });
    if (res.success) {
      commit(UPDATE_DEPLOY_ENV_LIST_MAP, res.data);
    }
  },
  async getRegionList({
    commit
  }) {
    // const res = await request({
    //   url: api.getRegionList,
    //   data: { deployEnvType: CLUSTER_ENV.ALIBABA_CLOUD_HOSTED }
    // });
    // const res2 = await request({
    //   url: api.getRegionList,
    //   data: { deployEnvType: CLUSTER_ENV.SELF_MAINTENANCE }
    // });
    //
    // if (res.success && res2.success) {
    //   const data = {
    //     aliyun: res.data,
    //     self: res2.data
    //   };
    //   commit(UPDATE_REGION_LIST_MAP, data);
    // }
  },
  async getClusterList({
    commit
  }, deployEnvType) {
    const res = await request({
      url: api.getAllClusterList,
      data: { deployEnvType }
    });
    if (res.success) {
      commit(UPDATE_CLUSTER_LIST, res.data);
    }
  },
  async getDsTypeList({
    commit
  }, deployEnvType) {
    const res = await request({
      url: api.getDsTypeList,
      data: { deployEnvType }
    });

    if (res.success) {
      commit(UPDATE_DS_TYPE_LIST, res.data);
    }
  },
  async getProductList({ commit, state }) {
    const res = await listProductCluster();
    const ccList = [];
    const dmList = [];
    if (res.data.success) {
      if (res.data.data && res.data.data[0]) {
        const promises = res.data.data.map(async (item) => {
          if (item.product === 'CloudDM') {
            try {
              const responseB = await request({ url: api.dmGlobalSettings, headers: { 'X-Product-Code': item.clusterCode } });
              let dmSetting = {};
              if (responseB.success) {
                dmSetting = responseB.data;
                const dsSettingRes = await request({ url: api.dsSettingDef, headers: { 'X-Product-Code': item.clusterCode } });
                if (dsSettingRes.success) {
                  dmSetting.dsSettingDef = dsSettingRes.data;
                }
                // commit(UPDATE_DM_GLOBAL_SETTING, dmSetting);
              }
              item.config = dmSetting;
              item.status = 'ONLINE';
              return item;
            } catch (e) {
              item.status = 'OFFLINE';
              return item;
            }
          } else if (item.product === 'CloudCanal') {
            try {
              const responseB = await ccGlobalConfig(null, { customeHeaders: { 'X-Product-Code': item.clusterCode } });
              item.config = responseB.data.data;
              item.status = 'ONLINE';
              return item;
            } catch (e) {
              item.status = 'OFFLINE';
              return item;
            }
          } else {
            return item;
          }
        });
        const updateProductCluster = await Promise.allSettled(promises);
        const finalProduct = [];
        updateProductCluster.forEach((product) => {
          if (product.status === 'fulfilled') {
            finalProduct.push(product.value);
          }
        });
        commit(UPDATE_PRODUCT_CLUSTER, finalProduct);
        res.data.data.forEach((cluster) => {
          if (cluster.product === 'CloudCanal') {
            ccList.push(cluster);
          } else if (cluster.product === 'CloudDM') {
            dmList.push(cluster);
          }
        });
        if (ccList.length > 0) {
          if (localStorage.getItem('selectCcProductCluster')) {
            commit(UPDATE_SELECT_PRODUCT_CLUSTER, localStorage.getItem('selectCcProductCluster'));
          } else {
            commit(UPDATE_SELECT_PRODUCT_CLUSTER, ccList[0].clusterCode);
          }
        }
        if (dmList.length > 0) {
          let selectedDm;
          if (localStorage.getItem('selectDmProductCluster')) {
            dmList.forEach((dm) => {
              if (dm.clusterCode === localStorage.getItem('selectDmProductCluster')) {
                selectedDm = dm;
              }
            });
            if (selectedDm) {
              commit(UPDATE_SELECT_DM_PRODUCT_CLUSTER, localStorage.getItem('selectDmProductCluster'));
              if (selectedDm.config) {
                commit(UPDATE_DS_SETTING, selectedDm.config.dsSettingDef);
              }
            } else {
              commit(UPDATE_SELECT_DM_PRODUCT_CLUSTER, dmList[0].clusterCode);
              if (dmList[0].config) {
                commit(UPDATE_DS_SETTING, dmList[0].config.dsSettingDef);
              }
            }
          } else {
            commit(UPDATE_SELECT_DM_PRODUCT_CLUSTER, dmList[0].clusterCode);
            if (dmList[0].config) {
              commit(UPDATE_DS_SETTING, dmList[0].config.dsSettingDef);
            }
          }
        }
      }
    }

    const initWebsocket = () => {
      if ('WebSocket' in window) {
        const protocol = document.location.protocol;
        let wsPrefix = 'ws';
        if (protocol === 'https:') {
          wsPrefix = 'wss';
        }
        // const host = document.location.host;
        let host = '';
        if (process.env.NODE_ENV === 'development') {
          host = state.dmGlobalSetting.personal ? 'localhost:33228' : 'localhost:8222';
        } else {
          if (dmList.length > 0) {
            if (dmList[0].apiAddr) {
              host = dmList[0].apiAddr.split('//')[1];
              console.log(host);
            }
          } else {
            host = document.location.host;
          }
        }

        if (state.globalSetting.features && state.globalSetting.features.PRODUCT_CLOUD_DM) {
          createWebSocket(`${wsPrefix}://${host}/clouddm/console/api/v1/ws/channel`);
        }
      }
    };

    if (!hasWebSocketInstance()) {
      initWebsocket();
    }
  }
};

export const ACTIONS_TYPE = {
  GET_USER_INFO: 'getUserInfo',
  GET_DEPLOY_ENV_LIST: 'getDeployEnvList',
  GET_REGION_LIST: 'getRegionList',
  GET_CLUSTER_LIST: 'getClusterList',
  GET_DS_TYPE_LIST: 'getDsTypeList',
  GET_DS_LIST: 'getDsList',
  GET_PRODUCT_CLUSTER: 'getProductList'
};
