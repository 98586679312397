export const queryApi = {
  createSession: '/clouddm/console/api/v1/query/createsession',
  cancelQuery: '/clouddm/console/api/v1/query/cancel',
  closesSession: '/clouddm/console/api/v1/query/closesession',
  commit: '/clouddm/console/api/v1/query/commit',
  executeQuery: '/clouddm/console/api/v1/query/execute',
  getAutoCommit: '/clouddm/console/api/v1/query/getautocommit',
  rollBack: '/clouddm/console/api/v1/query/rollback',
  analysisSplit: '/clouddm/console/api/v1/query/analysissplit',
  changeSchema: '/clouddm/console/api/v1/query/changeschema',
  setAutocommit: '/clouddm/console/api/v1/query/setautocommit',
  setTx: '/clouddm/console/api/v1/query/set_tx',
  fetchDsStatusConf: '/clouddm/console/api/v1/query/fetchDsStatusConf'
};
