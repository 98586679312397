<template>
  <div class="table-list-container">
    <div class="table-list-resize"/>
    <Select v-model="currentTab.leafType" @on-change="handleChangeTab" size="small"
              class="table-leaf-type-select">
      <Option v-for="tab in getLeafGroup(currentTab.node.INSTANCE.attr.dsType)"
                       :key="tab.type" :value="tab.type">
        {{ tab.i18n }}
      </Option>
    </Select>
    <div class="table-list-content">
      <div class="search-header">
        <Input v-model="currentTab[currentTab.leafType].searchKey" style="margin-right: 6px;" search
                 @on-change="handleSearch" size="small" allowClear>
        </Input>
        <cc-svg-icon style="margin-left: 2px" name="refresh" @click.native="handleRefreshTree" :size="16"></cc-svg-icon>
      </div>
      <div class="table-query-item-container" @contextmenu.prevent.stop="onContextmenu"
           :class="!['TABLE', 'VIEW', 'PROC', 'FUNC'].includes(currentTab.leafType) ? 'no-indent' : ''">
        <loading :active.sync="tableListLoading" :is-full-page="false"></loading>
        <c-tree
          :emptyText="$t('zan-wu-shu-ju')"
          class="table-list-tree"
          ref="tableTree"
          key-field="key"
          :load="handleExpandLoadNode"
          :expanded-keys="currentTab.expandedKeys"
          :render="renderNode"
          :nodeIndent="20"
          :renderNodeAmount="200"
          @click="handleSelectTable"
          @node-dblclick="handleDblClick"
          @expand="handleSetExpandedKeys"
          @node-right-click="handleNodeRightClick"
        >
          <template #empty>
            <div style="margin-bottom: 10px;font-weight: bold" v-if="!tableListLoading">{{ $t('zan-wu-shu-ju') }}
            </div>
            <a-button @click="handleRefreshTree" type="primary" v-if="!tableListLoading">{{ $t('shua-xin') }}
            </a-button>
          </template>
        </c-tree>
      </div>
    </div>
    <Modal :title="menuModal.title" v-model="menuModal.show" :mask-closable="false"
             :closable="false" :keyboard="false">
      <div style="margin-bottom: 5px;font-weight: bold;">
        {{ menuModal.content }}
      </div>
      <Collapse :bordered="false" size="small" style="margin: 5px 0;"
                  v-model="menuModal.collapseKey" class="advanced-setting">
        <Panel name="options">
          {{$t('gao-ji-pei-zhi')}}
          <div slot="content" style="padding: 5px">
            <div v-for="setting in advancedSetting" :key="setting.value">
              <Checkbox :value="menuModal.options[setting.value]"
                          @on-change="handleMenuOptionChange(setting.value, $event)">
                {{ setting.label }}
              </Checkbox>
            </div>
          </div>
        </Panel>
      </Collapse>
      <Input v-if="menuModal.showNameInput"
                :value="menuModal.name"
               :placeholder="$t('qing-shu-ru-xin-de-ming-zi')"
               @on-change="handleMenuNameChange"
               allow-clear/>
      <div style="margin: 5px 0;font-weight: bold;" v-if="menuModal.sql">{{ $t('sql-yu-ju') }}:</div>
      <read-only-editor :text="menuModal.sql" v-if="menuModal.sql"/>
      <div slot="footer">
        <Button type="primary" @click="handleRightClickMenu(actionType)" v-if="!menuModal.sql"
                  :disabled="!menuModal.name">{{ $t('sheng-cheng-sql-yu-ju') }}
        </Button>
        <Button v-if="menuModal.permission && menuModal.sql"
                  :type="menuModal.danger ? 'error' : 'primary'"
                  @click="handleDoAction" :loading="doActionLoading">{{ $t('li-ji-zhi-hang') }}
        </Button>
        <Button v-if="!menuModal.permission && menuModal.sql">{{ $t('ti-jiao-gong-dan') }}</Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
    <Modal :title="sqlModal.title" v-model="sqlModal.show" :mask-closable="false"
             :closable="false" :keyboard="false" :width="800">
      <div class="content">
        <div class="header" style="margin-bottom: 10px;" v-if="currentDdlList.length">
          <RadioGroup v-model="sqlModal.type" @on-change="handleStruct">
            <Radio label="Request" v-if="currentDdlList.includes('Request')">{{ $t('huo-qu-ddl-yu-ju') }}</Radio>
            <Radio label="Convert" v-if="currentDdlList.includes('Convert')">{{ $t('sheng-cheng-ddl-yu-ju') }}</Radio>
          </RadioGroup>
          <Select v-if="currentTargetDsList.length && sqlModal.type === 'Convert'"
                    v-model="sqlModal.dsType"
                    @on-change="handleStruct"
                    style="width: 200px;"
          >
            <Option v-for="ds in currentTargetDsList" :value="ds" :key="ds">{{ ds }}
            </Option>
          </Select>
        </div>
        <Collapse :bordered="false" size="small" style="margin: 5px 0;"
                    v-model="menuModal.collapseKey"
                    v-if="sqlModal.type === 'Convert'" class="advanced-setting">
          <Panel name="options">
            {{ $t('gao-ji-pei-zhi') }}
            <div slot="content" style="padding: 5px;">
              <div v-for="setting in structAdvancedSetting" :key="setting.value">
                <Checkbox :value="menuModal.options[setting.value]"
                          @on-change="handleMenuOptionChange(setting.value, $event)">
                  {{ setting.label }}
                </Checkbox>
              </div>
            </div>
          </Panel>
        </Collapse>
        <div style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;overflow: auto;max-height: 500px;">
          <loading :active.sync="sqlModal.loading" :is-full-page="false"></loading>
          <pre>{{ sqlModal.sql }}</pre>
        </div>
      </div>
      <div slot="footer">
        <Button @click="copyText(sqlModal.sql)" type="primary">{{ $t('fu-zhi-sql') }}</Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
    <Modal v-model="triggerModal.show" :title="triggerModal.title" :mask-closable="false"
             :keyboard="false" :width="800">
      <div class="trigger-modal">
        <Form :form="triggerModal.options" :label-width="80"
                v-show="!triggerModal.sql">
          <FormItem :label="$t('chu-fa-ming')">
            <Input v-model="triggerModal.options.name" size="small"/>
          </FormItem>
          <FormItem :label="$t('chu-fa-shi-jian')">
            <RadioGroup v-model="triggerModal.options.triggerTime" size="small">
              <Radio label="before">{{ $t('cao-zuo-zhi-qian') }}</Radio>
              <Radio label="after">{{ $t('cao-zuo-zhi-hou') }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem :label="$t('cao-zuo-lei-xing')">
            <RadioGroup v-model="triggerModal.options.triggerEvent"
                           v-if="isMySQL(currentTab.dsType)">
              <Radio label="delete">{{ $t('shan-chu') }}</Radio>
              <Radio label="update">{{ $t('geng-xin') }}</Radio>
              <Radio label="insert">{{ $t('cha-ru') }}</Radio>
            </RadioGroup>
            <CheckboxGroup v-else v-model="triggerModal.options.triggerEventArr"
                              @on-change="handleTriggerColumnsChange">
              <Checkbox label="delete">{{ $t('shan-chu') }}</Checkbox>
              <Checkbox label="insert">{{ $t('cha-ru') }}</Checkbox>
              <Checkbox label="update">{{ $t('geng-xin') }}</Checkbox>
              <Checkbox label="truncate" v-if="isPG(currentTab.dsType)">{{ $t('qing-kong') }}</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <FormItem :label="$t('geng-xin-de-lie')"
                       v-if="!isMySQL(currentTab.dsType) && triggerModal.options.triggerEventArr.includes('update')">
            <Select v-model="triggerModal.options.triggerColumns" multiple size="small" transfer>
              <Option v-for="column in triggerModal.columnList" :key="column.name" :value="column.name">
                {{ column.name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('chu-fa-biao-ming')">
            <Input v-model="triggerModal.options.triggerTable" size="small" disabled/>
          </FormItem>
          <FormItem :label="$t('chu-fwa-qi-ti')">
            <div class="trigger-monaco-editor" ref="triggerMonacoEditor">
            </div>
          </FormItem>
          <Collapse :bordered="false" size="small" style="margin: 5px 0;"
            v-model="triggerModal.collapseKey" class="advanced-setting">
            <Panel name="advanced">
              {{$t('gao-ji-pei-zhi')}}
              <template #content>
                <Form v-if="isMySQL(currentTab.dsType)" :label-width="100">
                  <FormItem :label="$t('chu-fa-qi-shun-xu')">
                    <RadioGroup v-model="triggerModal.options.features.order">
                      <Radio label="follows">FOLLOWS</Radio>
                      <Radio label="precedes">PRECEDES</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem :label="$t('gen-sui-chu-fa-qi-ming-cheng')" v-if="triggerModal.options.features.order">
                    <Input v-model="triggerModal.options.features.otherTriggerName" size="small"/>
                  </FormItem>
                </Form>
                <Form v-if="isOracle(currentTab.dsType)" :label-width="80">
                  <FormItem :label="$t('xin-zhi-bie-ming')">
                    <Input v-model="triggerModal.options.features.newAlias" size="small"/>
                  </FormItem>
                  <FormItem :label="$t('jiu-zhi-bie-ming')">
                    <Input v-model="triggerModal.options.features.oldAlias" size="small"/>
                  </FormItem>
                  <FormItem :label="$t('chu-fa-li-du')">
                    <RadioGroup v-model="triggerModal.options.features.triggerGranularity">
                      <Radio label="row">{{ $t('hang-ji') }}</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem :label="$t('chu-fa-tiao-jian')">
                    <Input v-model="triggerModal.options.features.condition" size="small"/>
                  </FormItem>
                </Form>
                <Form v-if="isPG(currentTab.dsType)" :label-width="80">
                  <FormItem :label="$t('yue-shu-chu-fa-qi')" v-if="triggerModal.options.triggerTime === 'after'">
                    <Checkbox v-model="triggerModal.options.features.constraint"/>
                  </FormItem>
                  <FormItem :label="$t('yue-shu-yin-yong-biao')" v-if="triggerModal.options.features.constraint">
                    <Input v-model="triggerModal.options.features.referencedTableName" size="small"/>
                  </FormItem>
                  <FormItem :label="$t('chu-fa-shi-ji')" v-if="triggerModal.options.features.triggerTiming">
                    <Select v-model="triggerModal.options.features.triggerTiming" size="small" transfer>
                      <Option value="DEFERRABLE">DEFERRABLE</Option>
                      <Option value="NOT DEFERRABLE">NOT DEFERRABLE</Option>
                      <Option value="INITIALLY IMMEDIATE">INITIALLY IMMEDIATE</Option>
                      <Option value="INITIALLY DEFERRED">INITIALLY DEFERRED</Option>
                      <Option value="DEFERRABLE INITIALLY DEFERRED">DEFERRABLE INITIALLY DEFERRED</Option>
                      <Option value="DEFERRABLE INITIALLY IMMEDIATE">DEFERRABLE INITIALLY IMMEDIATE</Option>
                      <Option value="NOT DEFERRABLE INITIALLY IMMEDIATE">NOT DEFERRABLE INITIALLY IMMEDIATE</Option>
                    </Select>
                  </FormItem>
                  <FormItem :label="$t('xin-zhi-bie-ming')">
                    <Input v-model="triggerModal.options.features.newAlias" size="small"/>
                  </FormItem>
                  <FormItem :label="$t('jiu-zhi-bie-ming')">
                    <Input v-model="triggerModal.options.features.oldAlias" size="small"/>
                  </FormItem>
                  <FormItem :label="$t('chu-fa-li-du')">
                    <RadioGroup v-model="triggerModal.options.features.triggerGranularity">
                      <Radio label="row">{{ $t('hang-ji') }}</Radio>
                      <Radio label="statement">{{ $t('yu-ju-ji') }}</Radio>
                    </RadioGroup>
                  </FormItem>
                  <FormItem :label="$t('chu-fa-tiao-jian')">
                    <Input v-model="triggerModal.options.features.condition" size="small"/>
                  </FormItem>
                </Form>
              </template>
            </Panel>
          </Collapse>
        </Form>
        <div v-show="triggerModal.sql"
             style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;overflow: auto;max-height: 500px;">
          <loading :active.sync="sqlModal.loading" :is-full-page="false"></loading>
          <pre>{{ triggerModal.sql }}</pre>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" @click="handleRightClickMenu(actionType)" v-if="!triggerModal.sql"
                  :disabled="!triggerModal.options.name">{{ $t('sheng-cheng-sql-yu-ju') }}
        </Button>
        <Button v-if="triggerModal.sql" @click="handlePreCreateTrigger">
          {{ $t('fan-hui-xiu-gai') }}
        </Button>
        <Button v-if="triggerModal.permission && triggerModal.sql"
                  :type="triggerModal.danger ? 'error' : 'primary'"
                  @click="handleDoAction" :loading="doActionLoading" style="margin-right: 10px;">
          {{ $t('li-ji-zhi-hang') }}
        </Button>
        <Button v-if="!triggerModal.permission && triggerModal.sql">{{ $t('ti-jiao-gong-dan') }}</Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
    <Modal v-model="viewModal.show" :title="viewModal.title" :mask-closable="false"
             :closable="false" :keyboard="false" :width="800">
      <div class="view-modal">
        <Form :form="viewModal.options"
                v-show="!viewModal.sql" :label-width="80">
          <FormItem :label="$t('shi-tu-ming')">
            <Input v-model="viewModal.options.name" size="small"/>
          </FormItem>
          <FormItem :label="$t('shi-tu-ti')">
            <div class="view-monaco-editor" ref="viewMonacoEditor">
            </div>
          </FormItem>
          <Collapse style="margin-bottom: 10px;">
            <Panel name="advanced">
              {{$t('gao-ji-pei-zhi')}}
              <template #content>
                <Form v-if="isMySQL(currentTab.dsType)" :label-width="100">
                  <FormItem :label="$t('jian-cha-fang-shi')">
                    <Select v-model="viewModal.options.features.checkOption" size="small" transfer>
                      <Option value="CASCADED">{{ $t('ji-lian') }}</Option>
                      <Option value="LOCAL">{{ $t('ben-di') }}</Option>
                    </Select>
                  </FormItem>
                </Form>
                <Form v-if="isOracle(currentTab.dsType)" :label-width="100">
                  <FormItem :label="$t('qiang-zhi-chuang-jian')">
                    <Checkbox v-model="viewModal.options.features.force"/>
                  </FormItem>
                </Form>
                <Form v-if="isPG(currentTab.dsType)" :label-width="100">
                  <FormItem :label="$t('lin-shi-shi-tu')">
                    <Checkbox v-model="viewModal.options.features.temp"/>
                  </FormItem>
                  <FormItem label="security_barrier">
                    <Checkbox v-model="viewModal.options.features.security_barrier" />
                  </FormItem>
                  <FormItem :label="$t('jian-cha-fang-shi')">
                    <Select v-model="viewModal.options.features.checkOption" size="small">
                      <Option value="CASCADED">{{ $t('ji-lian') }}</Option>
                      <Option value="LOCAL">{{ $t('ben-di') }}</Option>
                    </Select>
                  </FormItem>
                </Form>
              </template>
            </Panel>
          </Collapse>
        </Form>
        <div v-show="viewModal.sql"
             style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;overflow: auto;max-height: 500px;">
          <loading :active.sync="viewModal.loading" :is-full-page="false"></loading>
          <pre>{{ viewModal.sql }}</pre>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" @click="handleRightClickMenu(actionType)" v-if="!viewModal.sql"
                  :disabled="!viewModal.options.name">{{ $t('sheng-cheng-sql-yu-ju') }}
        </Button>
        <Button v-if="viewModal.sql" @click="handlePreCreateTrigger">
          {{ $t('fan-hui-xiu-gai') }}
        </Button>
        <Button v-if="viewModal.permission && viewModal.sql"
                  :type="viewModal.danger ? 'error' : 'primary'"
                  @click="handleDoAction" :loading="doActionLoading" style="margin-right: 10px;">
          {{ $t('li-ji-zhi-hang') }}
        </Button>
        <Button v-if="!viewModal.permission && viewModal.sql">{{ $t('ti-jiao-gong-dan') }}</Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
    <Modal v-model="functionModal.show" :title="functionModal.title" :mask-closable="false"
             :closable="false" :keyboard="false" :width="800">
      <div class="function-modal">
        <Form :form="functionModal.options" v-show="!functionModal.sql" :label-width="80">
          <FormItem :label="$t('han-shu-ming')">
            <Input v-model="functionModal.options.name" size="small"/>
          </FormItem>
          <FormItem :label="$t('can-shu')">
            <div class="param-container">
              <div class="left">
                <div class="op">
                  <div class="add" @click="handleAddFunctionParam">
                    <Icon type="md-add" />
                  </div>
                  <div class="remove" @click="handleRemoveFunctionParam">
                    <Icon type="md-remove" />
                  </div>
                </div>
                <div class="list">
                  <div v-for="(param, index) in functionModal.options.params"
                       :key="param.key" :class="`param ${index === functionModal.selectedIndex ? 'active' : ''}`"
                       @click="handleClickFunctionParam(index)"
                  >
                    {{param.name}}
                  </div>
                </div>
              </div>
              <div class="right">
                <div v-if="functionModal.selectedIndex > -1" style="padding: 10px;">
                  <create-table-item
                    node-type="params"
                    :current-schema="component"
                    :selected-index="functionModal.selectedIndex"
                    :selected-node="functionModal.selectedNode"
                    :form-data="functionModal.options"
                    v-for="component in functionModal.selectedSchema"
                    :key="component.field"
                    :tab="currentTab"
                    type="param"
                  />
                </div>
              </div>
            </div>
          </FormItem>
          <FormItem :label="$t('fan-hui-can-shu')">
            <Select v-model="functionModal.options.returnParams.paramType" @on-change="handleFunctionReturnParamTypeChange" size="small">
              <Option v-for="columnType in columnList" :value="columnType.value" :key="columnType.value">{{columnType.label}}</Option>
            </Select>
          </FormItem>
          <FormItem v-for="item in functionModal.returnParamsForm" :label="item.titleI18N" :key="item.field">
            <Input v-model="functionModal.options.returnParams[item.field]" size="small"/>
          </FormItem>
          <FormItem :label="$t('que-ding-xing')" v-if="isMySQL(currentTab.dsType)">
            <Checkbox v-model="functionModal.options.features.deterministic"></Checkbox>
          </FormItem>
          <FormItem :label="$t('sql-shu-xing')" v-if="isMySQL(currentTab.dsType)">
            <Select v-model="functionModal.options.features.sqlDataAccess" size="small">
              <Option value="no sql">NO SQL</Option>
              <Option value="modify sql data">MODIFY SQL DATA</Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('han-shu-ti')">
            <div class="function-monaco-editor" ref="functionMonacoEditor" style="padding-top: 10px;">
            </div>
          </FormItem>
        </Form>
        <div v-show="functionModal.sql"
             style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;overflow: auto;max-height: 500px;">
          <loading :active.sync="functionModal.loading" :is-full-page="false"></loading>
          <pre>{{ functionModal.sql }}</pre>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" @click="handleRightClickMenu(actionType)" v-if="!functionModal.sql"
                  :disabled="!functionModal.options.name">{{ $t('sheng-cheng-sql-yu-ju') }}
        </Button>
        <Button v-if="functionModal.sql" @click="handlePreCreateTrigger">
          {{ $t('fan-hui-xiu-gai') }}
        </Button>
        <Button v-if="functionModal.permission && functionModal.sql"
                  :type="functionModal.danger ? 'error' : 'primary'"
                  @click="handleDoAction" :loading="doActionLoading" style="margin-right: 10px;">
          {{ $t('li-ji-zhi-hang') }}
        </Button>
        <Button v-if="!functionModal.permission && functionModal.sql">{{ $t('ti-jiao-gong-dan') }}</Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
    <Modal v-model="procedureModal.show" :title="procedureModal.title" :mask-closable="false"
             :closable="false" :keyboard="false" :width="800">
      <div class="procedure-modal">
        <Form :form="procedureModal.options" :label-width="100"
                v-show="!procedureModal.sql">
          <FormItem :label="$t('cun-chu-guo-cheng-ming')">
            <Input v-model="procedureModal.options.name" size="small"/>
          </FormItem>
          <FormItem :label="$t('can-shu')">
            <div class="param-container">
              <div class="left">
                <div class="op">
                  <div class="add" @click="handleAddProcedureParam">
                    <Icon type="md-add" />
                  </div>
                  <div class="remove" @click="handleRemoveProcedureParam">
                    <Icon type="md-remove" />
                  </div>
                </div>
                <div class="list">
                  <div v-for="(param, index) in procedureModal.options.params"
                       :key="param.key" :class="`param ${index === procedureModal.selectedIndex ? 'active' : ''}`"
                       @click="handleClickProcedureParam(index)"
                  >
                    {{param.name}}
                  </div>
                </div>
              </div>
              <div class="right">
                <div v-if="procedureModal.selectedIndex > -1" style="padding: 10px;">
                  <create-table-item
                    node-type="params"
                    :current-schema="component"
                    :selected-index="procedureModal.selectedIndex"
                    :selected-node="procedureModal.selectedNode"
                    :form-data="procedureModal.options"
                    v-for="component in procedureModal.selectedSchema"
                    :key="component.field"
                    :tab="currentTab"
                    type="param"
                  />
                </div>
              </div>
            </div>
          </FormItem>
          <FormItem :label="$t('cun-chu-guo-cheng-ti')">
            <div class="procedure-monaco-editor" ref="procedureMonacoEditor" style="padding-top: 10px;">
            </div>
          </FormItem>
        </Form>
        <div v-show="procedureModal.sql"
             style="width: 100%;border: 1px solid #ccc;padding: 3px 10px;overflow: auto;max-height: 500px;">
          <loading :active.sync="procedureModal.loading" :is-full-page="false"></loading>
          <pre>{{ procedureModal.sql }}</pre>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" @click="handleRightClickMenu(actionType)" v-if="!procedureModal.sql"
                  :disabled="!procedureModal.options.name">{{ $t('sheng-cheng-sql-yu-ju') }}
        </Button>
        <Button v-if="procedureModal.sql" @click="handlePreCreateTrigger">
          {{ $t('fan-hui-xiu-gai') }}
        </Button>
        <Button v-if="procedureModal.permission && procedureModal.sql"
                  :type="procedureModal.danger ? 'error' : 'primary'"
                  @click="handleDoAction" :loading="doActionLoading" style="margin-right: 10px;">
          {{ $t('li-ji-zhi-hang') }}
        </Button>
        <Button v-if="!procedureModal.permission && procedureModal.sql">{{ $t('ti-jiao-gong-dan') }}</Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
    <a-modal :title="genDataModal.title" v-model="genDataModal.show" :width="1000"
             v-if="genDataModal.show"
             :mask-closable="false" :keyboard="false" @cancel="handleCloseModal">
      <div class="generate-modal">
        <div class="advanced" v-show="genDataModal.step === 1">
          <div class="title">{{ $t('gao-ji-xuan-xiang') }}</div>
          <div class="options">
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.producer" size="small"
                       :addon-before="$t('sheng-chan-bing-fa-du')"/>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('zao-shu-ju-zhong-sheng-cheng-sui-ji-shu-ju-de-shu-liang') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.writer" size="small"
                       :addon-before="$t('xie-ru-bing-fa-du')"/>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('zao-shu-ju-zhong-jiang-shu-ju-xie-ru-shu-ju-ku-xian-cheng-de-shu-liang') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-checkbox v-model="genDataModal.globalConfigs.ignoreErrors">{{ $t('yu-dao-cuo-wu-shi-ji-xu') }}
              </a-checkbox>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('sheng-cheng-shu-ju-shi-ru-yu-dao-duplicate-key-deng-cuo-wu-hu-lve-bing-ji-xu-sheng-cheng-shu-ju') }}
                </template>
              </a-tooltip>
            </div>
            <!--            <div class="option">-->
            <!--              <a-checkbox v-model="genDataModal.globalConfigs.transaction">开启事务</a-checkbox>-->
            <!--              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">-->
            <!--                <a-icon type="question-circle"/>-->
            <!--                <template slot="title">-->
            <!--                  如果数据库支持事务，勾选此选项将开启事务-->
            <!--                </template>-->
            <!--              </a-tooltip>-->
            <!--            </div>-->
          </div>
          <div class="options" style="margin-top: 5px;"
               v-if="actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT">
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.time" :addon-before="$t('yun-hang-shi-jian')"
                       :addon-after="$t('miao')"
                       size="small"></a-input>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('zeng-liang-cao-zuo-yun-hang-shi-jian-dan-wei-miao') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.insertRatio" addon-before="INSERT"
                       size="small" addon-after="%"></a-input>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('cha-ru-cao-zuo-de-bi-shuai') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.updateRatio" addon-before="UPDATE"
                       size="small" addon-after="%"></a-input>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('geng-xin-cao-zuo-de-bi-shuai') }}
                </template>
              </a-tooltip>
            </div>
            <div class="option">
              <a-input v-model="genDataModal.globalConfigs.deleteRatio" addon-before="DELETE"
                       size="small" addon-after="%"></a-input>
              <a-tooltip placement="bottom" style="margin-left: 3px;margin-right: 5px;">
                <a-icon type="question-circle"/>
                <template slot="title">
                  {{ $t('shan-chu-cao-zuo-de-bi-shuai') }}
                </template>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div class="content" v-show="genDataModal.step === 1">
          <div class="title">{{ $t('biao-she-zhi') }}</div>
          <div class="table-setting">
            <div class="left">
              <div class="search">
                <a-input v-model="genDataModal.searchKey" size="small"
                         @change="handleGenDataFilter(genDataModal.searchKey)">
                  <a-icon slot="prefix" type="search"/>
                </a-input>
              </div>
              <c-tree
                :emptyText="$t('zan-wu-shu-ju')"
                checkable
                cascade
                class="datasource-tree"
                ref="genDataTree"
                key-field="key"
                :load="handleGenDataExpandLoadNode"
                :expanded-keys="genDataModal.expandedKeys"
                :render="renderGenDataNode"
                :nodeIndent="20"
                :auto-load="false"
                @click="handleGenDataSelectTable"
                @select="handleGenDataSelectTable"
                @expand="handleGenDataSetExpandedKeys"
                @check="handleGenDataCheck"
              >
                <template #empty>
                  <div style="margin-bottom: 10px;font-weight: bold">{{ $t('zan-wu-shu-ju') }}
                  </div>
                  <a-button @click="handleRefreshTree" type="primary">{{ $t('shua-xin') }}
                  </a-button>
                </template>
              </c-tree>
            </div>
            <div class="right">
              <div v-if="genDataModal.selectedNode">
                <div class="select-title"
                     v-if="genDataModal.selectedNode.configType === 'tableConfig'">
                  <a-breadcrumb separator=">">
                    <a-breadcrumb-item><b>{{ $t('dang-qian-biao') }}</b> {{ genDataModal.selectedNode.title }}</a-breadcrumb-item>
                  </a-breadcrumb>
                </div>
                <div class="select-title"
                     v-if="genDataModal.selectedNode.configType === 'columnConfig'">
                  <a-breadcrumb separator=">">
                    <a-breadcrumb-item href=""><span @click="handleGoTableConfig"><b>{{ $t('dang-qian-biao') }}</b> {{ genDataModal.selectedNode.tableName }}</span></a-breadcrumb-item>
                    <a-breadcrumb-item>
                      <b> {{ $t('dang-qian-lie') }}</b>
                        {{ genDataModal.selectedNode.title }}
                    </a-breadcrumb-item>
                  </a-breadcrumb>
                </div>
                <create-table-item v-for="component in genDataModal.selectedSchema"
                                   :current-schema="component"
                                   :key="component.field"
                                   :tab="currentTab"
                                   :form-data="genDataModal.tableConfigs"
                                   :node-type="genDataModal.selectedNode.configType"
                                   :selected-node="genDataModal.selectedNode"
                />
                <div style="display: flex;margin-top: 5px;" v-if="genDataModal.selectedNode.configType === 'tableConfig'">
                  <div style="width: 100px;line-height: 24px">{{ $t('pei-zhi-suan-fa') }}</div>
                  <div style="flex: 1;">
                    <a-table class="column-config-table" bordered
                             size="small" :columns="configColumns" :data-source="getConfigData" :pagination="false">
                      <div slot="genType" slot-scope="text, record">
                        <a @click="handleShowColumnConfigDetail(record)">{{getGenDetail(record)}}</a>
                      </div>
                    </a-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="preview" v-if="genDataModal.step === 2">
          <div class="left">
            <div v-for="config in genDataModal.selectedTaleConfigs"
                 :style="`background: ${selectedTableConfig.name === config.name ? '#d4e7fc' : '#fff'};padding: 3px 5px;cursor:pointer;font-weight:500`"
                 :key="config.name" @click="handleSelectTableConfig(config)">
              {{ config.name }}
            </div>
          </div>
          <div class="right">
            <c-c-read-only-table :cell-data="cellData" :column-list="columnList"
                                 :selected-config="selectedTableConfig" :width="652"/>
            <p class="tips"><span>*</span>{{ $t('biao-ge-nei-wei-shi-li-shu-ju') }}</p>
          </div>
        </div>
        <div class="result" v-if="genDataModal.step === 3">
          <div class="header">
            <div class="status">
              <b>{{ $t('ren-wu-zhuang-tai') }}： &nbsp;&nbsp;&nbsp;&nbsp;</b><span :style="`color:${generateJobStatusColor[genDataModal.status]};font-size:14px`">{{generateJobStatus[genDataModal.status]}}</span>
            </div>
            <div class="info-container">
              <div class="info">
                <div class="line">
                  <div><b>{{ $t('zong-cheng-gong-hang') }}： &nbsp;&nbsp;&nbsp;&nbsp;</b>{{ genDataModal.successTotal }}</div>
                  <div><b>{{ $t('zong-shi-bai-hang') }}： &nbsp;&nbsp;&nbsp;&nbsp;</b>{{ genDataModal.failedTotal }}</div>
                  <div><b>{{ $t('ping-jun-xie-ru') }}： &nbsp;&nbsp;&nbsp;&nbsp;</b>{{ genDataModal.writeAvgTime }}</div>
                </div>
                <div class="line">
                  <div><b>{{ $t('cheng-gong-cha-ru-hang') }}： </b>{{ genDataModal.successInsertTotal }}</div>
                  <div><b>{{ $t('cheng-gong-shan-chu-hang') }}： </b>{{ genDataModal.successDeleteTotal }}</div>
                  <div><b>{{ $t('cheng-gong-xiu-gai-hang') }}： </b>{{ genDataModal.successUpdateTotal }}</div>
                </div>
                <div class="line">
                  <div><b>{{ $t('shi-bai-cha-ru-hang') }}： </b>{{ genDataModal.failedInsertTotal }}</div>
                  <div><b>{{ $t('shi-bai-shan-chu-hang') }}： </b>{{ genDataModal.failedDeleteTotal }}</div>
                  <div><b>{{ $t('shi-bai-xiu-gai-hang') }}： </b>{{ genDataModal.failedUpdateTotal }}</div>
                </div>
                <div class="line">
                  <div><b>{{ $t('ri-zhi-wen-jian') }}： </b>{{ genDataModal.logFile }}</div>
                </div>
                <div class="line" v-if="!isDesktop">
                  <div><b>{{ $t('ri-zhi-wen-jian-suo-zai-fu-wu-qi') }}： </b>{{ genDataModal.logHost }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="logs">
            <div v-if="!genDataModal.logArr.length">{{ $t('huo-qu-shu-ju-ri-zhi-zhong') }}</div>
            <div v-else id="log-list">
              <div class="log" v-for="log in genDataModal.logArr" :key="log.id">{{log.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <Button style="margin-right: 10px;" v-if="genDataModal.step === 3" @click="handleShowLog(-25)" :disabled="(parseInt(genDataModal.endRow) <= 25) || genDataModal.logLoading || genDataModal.logNextLoading">{{ $t('shang-yi-ye') }}</Button>
        <Button style="margin-right: 10px;" v-if="genDataModal.step === 3" @click="handleShowLog(25)" :disabled="genDataModal.logLoading || genDataModal.logPreLoading">{{ $t('xia-yi-ye') }}</Button>
        <Button style="margin-right: 10px;" v-if="genDataModal.step === 3" @click="handleShowLog(0)" :disabled="genDataModal.logPreLoading || genDataModal.logNextLoading">{{ $t('shua-xin') }}</Button>
        <Button v-if="genDataModal.step === 2"
                  @click="handlePreStep" style="margin-right: 10px;">{{ $t('shang-yi-bu') }}
        </Button>
        <Button type="primary" v-if="genDataModal.step === 1" @click="handlePreview"
                  :loading="genDataModal.loading" style="margin-right: 10px;">{{ $t('yu-lan') }}
        </Button>
        <Button type="primary" v-if="genDataModal.step === 2" style="margin-right: 10px;"
                  @click="handleGenData" :loading="genDataModal.loading">
          {{ this.genDataModal.title }}
        </Button>
        <Button type="error" v-if="genDataModal.step === 3 && showTaskPauseBtn"
                  style="margin-right: 10px;" :loading="genDataModal.loading"
                  @click="handleAsyncEventAction('pause')">
          {{ $t('zan-ting') }}
        </Button>
        <Button type="primary"
          v-if="genDataModal.step === 3 && showTaskResumeBtn"
          @click="handleAsyncEventAction('resume')" style="margin-right: 10px;"
          :loading="genDataModal.loading">
          {{ $t('hui-fu') }}
        </Button>
        <Button type="error" @click="handleCloseFaker"
                  v-if="genDataModal.step === 3 && showTaskCancelBtn" style="margin-right: 10px;"
                  :loading="genDataModal.loading">
          {{ $t('zhong-zhi') }}
        </Button>
        <Button @click="handleCloseModal" v-if="genDataModal.step === 3"
                  style="margin-right: 10px;">
          {{genDataModal.status === 'COMPLETE' ? this.$t('guan-bi'): this.$t('zui-xiao-hua')}}
        </Button>
        <Button @click="handleCloseModal" v-if="genDataModal.step !== 3">
          {{ $t('guan-bi') }}
        </Button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import CTree from '@/components/ctree/ctree.umd';
import Loading from 'vue-loading-overlay';
import { mapGetters, mapState } from 'vuex';
import deepClone from 'lodash.clonedeep';
import copyMixin from '@/mixins/copyMixin';
import { ACTION_TYPE, TAB_TYPE } from '@/const';
import datasourceMixin from '@/mixins/datasourceMixin';
import { isMySQL, isRedis } from '@/const/dataSource';
import browseMixin from '@/mixins/browseMixin';
import {
  FAKER_TASK_STATUS, isOracle, isPG, TABLE_RIGHT_CLICK_MENU_ITEM
} from '@/utils';
import utilMixin from '@/mixins/utilMixin';
import { clearAllPending } from '@/services/http/cancelRequest';
import ReadOnlyEditor from '@/components/editor/ReadOnlyEditor';
import CreateTableItem from '@/components/modal/CreateTableItem';
import CCReadOnlyTable from '@/components/widgets/CCReadOnlyTable';
import * as monaco from 'monaco-editor';
import { Modal } from 'ant-design-vue';
import i18n from '@/i18n';
import { nanoid } from 'nanoid';

const BG_COLOR = {
  Insert: 'rgb(236, 255, 220)',
  Delete: 'rgb(250, 128, 114)',
  Update: 'yellow'
};

const EMPTY_PROCEDURE_DATA = {
  collapseKey: '',
  show: false,
  permission: false,
  sql: '',
  actionData: {},
  selectedSchema: {},
  selectedIndex: -1,
  options: {
    delimited: true,
    name: '',
    sql: '',
    params: [],
    features: {
      deterministic: false,
      sqlDataAccess: null,
      otherOptions: ''
    }
  }
};
const EMPTY_FUNCTION_DATA = {
  collapseKey: '',
  show: false,
  permission: false,
  sql: '',
  actionData: {},
  selectedSchema: [],
  selectedIndex: -1,
  returnParamsForm: [],
  options: {
    delimited: true,
    name: '',
    sql: '',
    features: {
      deterministic: false,
      sqlDataAccess: null,
      otherOptions: ''
    },
    params: [],
    returnParams: {
      name: '返回参数',
      columnType: 'INT',
      scale: 1,
      length: 2
    }
  }
};
const EMPTY_VIEW_DATA = {
  collapseKey: '',
  show: false,
  permission: false,
  sql: '',
  title: '新建视图',
  actionData: {},
  options: {
    delimited: true,
    name: '',
    sql: '',
    features: {
      checkOption: null,
      force: false,
      temp: false,
      security_barrier: false
    }
  }
};
const EMPTY_TRIGGER_DATA = {
  collapseKey: '',
  columnList: [],
  show: false,
  permission: false,
  sql: '',
  title: '',
  actionData: {},
  columns: [],
  options: {
    delimited: true,
    name: '',
    sql: '',
    triggerTime: 'BEFORE',
    triggerEvent: '',
    triggerEventArr: [],
    triggerTable: '',
    triggerColumns: [],
    features: {
      order: '',
      otherTriggerName: '',
      newAlias: '',
      oldAlias: '',
      condition: '',
      triggerGranularity: '',
      constraint: false,
      referencedTableName: '',
      triggerTiming: ''
    }
  }
};

const EMPTY_GEN_DATA = {
  loading: false,
  resume: false,
  sessionId: '',
  dataSourceId: '',
  tableConfigColumns: {},
  refresh: true,
  timer: null,
  selectedSchema: [],
  selectedIndex: -1,
  searchKey: '',
  tableConfigs: [],
  selectedTaleConfigs: [],
  treeData: [],
  show: false,
  step: 1,
  previewLoading: false,
  failedDeleteTotal: 0,
  failedInsertTotal: 0,
  failedTotal: 0,
  failedUpdateTotal: 0,
  successDeleteTotal: 0,
  successInsertTotal: 0,
  successTotal: 0,
  successUpdateTotal: 0,
  writeAvgTime: '0毫秒',
  endRow: 0,
  logArr: [],
  logLoading: false,
  logPreLoading: false,
  logNextLoading: false,
  status: '',
  logFile: '',
  logHost: '',
  globalConfigs: {
    producer: 2,
    writer: 4,
    ignoreErrors: true,
    transaction: false,
    insertRatio: '80',
    updateRatio: '10',
    deleteRatio: '10',
    time: '300'
  },
  selectedNode: null,
  expandedKeys: [],
  checkedKeys: []
};
const EMPTY_SQL_DATA = {
  loading: false,
  dsType: '',
  type: 'Request',
  title: i18n.t('huo-qu-ddl-yu-ju'),
  show: false,
  sql: '',
  permission: false,
  danger: false,
  collapseKey: '',
  options: {
    delimited: false,
    usingExists: false,
    cascade: false,
    restrict: false,
    purge: false,
    truncateUseDelete: false
  }
};
const EMPTY_MENU_DATA = {
  actionData: {},
  show: false,
  title: '',
  content: '',
  name: '',
  preName: '',
  showNameInput: false,
  sql: '',
  permission: false,
  danger: false,
  collapseKey: '',
  options: {
    delimited: false,
    usingExists: false,
    cascade: false,
    restrict: false,
    purge: false,
    truncateUseDelete: false
  }
};

export default {
  name: 'TableList',
  mixins: [copyMixin, datasourceMixin, browseMixin, utilMixin],
  components: {
    CCReadOnlyTable,
    CreateTableItem,
    ReadOnlyEditor,
    // GenDataModal,
    Loading,
    CTree
  },
  props: {
    rdbTableDetail: Function,
    getNodeData: Function,
    handleAddTab: Function,
    tableListLoading: Boolean,
    currentTab: {
      type: Object,
      default: () => {
      }
    },
    listLeaf: Function,
    handleQueryTable: Function,
    listDbTables: Function,
    handleShowStruct: Function,
    handleNewDbQuery: Function,
    handleShowData: Function
  },
  data() {
    return {
      procedureModal: deepClone(EMPTY_PROCEDURE_DATA),
      functionModal: deepClone(EMPTY_FUNCTION_DATA),
      viewModal: deepClone(EMPTY_VIEW_DATA),
      triggerModal: deepClone(EMPTY_TRIGGER_DATA),
      genDataModal: deepClone(EMPTY_GEN_DATA),
      sqlModal: deepClone(EMPTY_SQL_DATA),
      menuModal: deepClone(EMPTY_MENU_DATA),
      FAKER_TASK_STATUS,
      monacoEditor: null,
      defaultOpts: {
        value: '', // 编辑器的值
        language: 'mysql',
        fontSize: 14,
        fontWeight: 'bold',
        theme: 'vs', // 编辑器主题：vs, hc-black, or vs-dark，更多选择详见官网
        minimap: {
          enabled: false
        },
        automaticLayout: true,
        lineNumbers: 'off',
        autoIndent: true // 自动缩进
      },
      functionModalReturnParamsColumns: [
        {
          title: '数据类型',
          scopedSlots: { customRender: 'columnType' }
        },
        {
          title: '长度',
          scopedSlots: { customRender: 'length' }
        },
        {
          title: '小数点',
          scopedSlots: { customRender: 'scale' }
        }
      ],
      functionModalParamsColumns: [
        {
          title: '名称',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '数据类型',
          scopedSlots: { customRender: 'columnType' }
        },
        {
          title: '长度',
          scopedSlots: { customRender: 'length' }
        },
        {
          title: '小数点',
          scopedSlots: { customRender: 'scale' }
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      procedureModalParamsColumns: [
        {
          title: '名称',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '模式',
          scopedSlots: { customRender: 'mode' }
        },
        {
          title: '数据类型',
          scopedSlots: { customRender: 'columnType' }
        },
        {
          title: '长度',
          scopedSlots: { customRender: 'length' }
        },
        {
          title: '小数点',
          scopedSlots: { customRender: 'scale' }
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      currentDdlList: [],
      currentTargetDsList: [],
      TABLE_RIGHT_CLICK_MENU_ITEM,
      selectedTableConfig: {},
      schemaDef: {},
      scrollY: 0,
      top: 0,
      selectedNode: null,
      advancedSetting: [
        {
          value: 'delimited',
          label: this.$t('shi-yong-xian-ding-fu-bao-guo-shu-ju-ku-dui-xiang-ming')
        }, {
          value: 'usingExists',
          label: this.$t('shi-yong-if-exists-zi-ju')
        }, {
          value: 'cascade',
          label: this.$t('shi-yong-cascade-zi-ju-jin-hang-qiang-zhi-shan-chu')
        }, {
          value: 'restrict',
          label: this.$t('shi-yong-restrict-zi-ju-zai-xian-zhi-tiao-jian-xia-shan-chu')
        }, {
          value: 'purge',
          label: this.$t('shi-yong-purge-zi-ju-jin-hang-zi-yuan-hui-shou')
        }, {
          value: 'truncateUseDelete',
          label: this.$t('shi-yong-delete-yu-ju-ti-dai-truncate-yu-ju')
        }
      ],
      structAdvancedSetting: [
        {
          value: 'delimited',
          label: this.$t('shi-yong-xian-ding-fu-bao-guo-shu-ju-ku-dui-xiang-ming')
        }
      ],
      showTableLoading: false,
      columnList: [],
      previewData: {},
      cellData: [],
      actionType: '',
      menuList: [],
      tableListItemLength: 0,
      doActionLoading: false,
      timeoutMs: 10000,
      timeoutS: 10,
      refreshLoading: false,
      configColumns: [
        {
          title: this.$t('lie-ming'),
          dataIndex: 'name'
        }, {
          title: this.$t('sheng-cheng-qi'),
          dataIndex: 'seedType'
        }, {
          title: this.$t('sheng-cheng-fang-shi'),
          dataIndex: 'genType',
          scopedSlots: { customRender: 'genType' }
        }
      ],
      generateJobStatus: {
        INIT: this.$t('chu-shi-hua'),
        RUNNING: this.$t('yun-hang-zhong'),
        PAUSE: this.$t('yi-zan-ting'),
        COMPLETE: this.$t('yi-wan-cheng'),
        WAITING_RESUME: this.$t('hui-fu-zhong'),
        WAITING_PAUSE: this.$t('zan-ting-zhong')
      },
      generateJobStatusColor: {
        INIT: '#ccc',
        RUNNING: '#0BB9F8',
        PAUSE: '#FF6E0D',
        COMPLETE: '#52c41a',
        WAITING_RESUME: '#FF6E0D',
        WAITING_PAUSE: '#FF6E0D'
      }
    };
  },
  mounted() {
    const currentLeaf = this.currentTab[this.currentTab.leafType];
    if (currentLeaf.treeData) {
      this.handleSetData(currentLeaf.treeData);
    }

    const tableListTreeList = $('.table-list-tree');
    if (tableListTreeList && tableListTreeList.length) {
      tableListTreeList[0].addEventListener('scroll', this.handleSetScrollTop, true);
    }

    window.$bus.on('showFakerModal', (task) => this.showFakerDetail(task));
  },
  beforeDestroy() {
    const tableListTreeList = $('.table-list-tree');
    if (tableListTreeList && tableListTreeList.length) {
      tableListTreeList[0].removeEventListener('scroll', this.handleSetScrollTop, true);
    }
    window.$bus.off('showFakerModal', this.showFakerDetail);
  },
  computed: {
    ...mapState(['globalDsSetting']),
    ...mapGetters([
      'isDesktop',
      'getLeafGroup',
      'getMenus',
      'getBrowserMenus',
      'getQuickQuery',
      'genQualifierText',
      'targetDsList',
      'ddlList'
    ]),
    showTaskCancelBtn() {
      const {
        INIT, RUNNING, PAUSE, WAITING_RESUME, WAITING_PAUSE
      } = FAKER_TASK_STATUS;
      return [INIT, RUNNING, PAUSE, WAITING_RESUME, WAITING_PAUSE].includes(this.genDataModal.status);
    },
    showTaskPauseBtn() {
      const { RUNNING, WAITING_PAUSE } = FAKER_TASK_STATUS;
      return [RUNNING, WAITING_PAUSE].includes(this.genDataModal.status);
    },
    showTaskRetryBtn() {
      const { COMPLETE } = FAKER_TASK_STATUS;
      return [COMPLETE].includes(this.genDataModal.status);
    },
    showTaskResumeBtn() {
      const { PAUSE, WAITING_RESUME } = FAKER_TASK_STATUS;
      return [PAUSE, WAITING_RESUME].includes(this.genDataModal.status);
    },
    getConfigData() {
      let config = [];
      this.genDataModal.tableConfigs.forEach((table) => {
        if (table.key === this.genDataModal.selectedNode.key) {
          config = table.columnConfigs;
        }
      });
      return config;
    },
    getGenDetail() {
      return (record) => {
        let detail = '';
        if (record.genType) {
          detail += record.genType;
        } else {
          detail += this.$t('sui-ji-sheng-cheng');
        }
        if (record.max || record.min) {
          detail += `(${this.$t('qu-jian')}[${record.min}-${record.max}])`;
        }
        if (record.minLength || record.maxLength) {
          detail += `(${this.$t('chang-du-qu-jian')}[${record.minLength}-${record.maxLength}])`;
        }
        return detail;
      };
    }
  },
  methods: {
    handleFunctionReturnParamTypeChange(e) {
      this.columnList.forEach((option) => {
        if (option.value === e) {
          this.functionModal.returnParamsForm = option.children;
          option.children.forEach((child) => {
            this.functionModal.options.returnParams[child.field] = child.defaultVal;
          });
        }
      });
    },
    isPG,
    isOracle,
    isMySQL,
    async handleAsyncEventAction(actionType) {
      try {
        let res;
        let msg;
        this.genDataModal.loading = true;
        switch (actionType) {
          case 'pause':
            res = await this.$services.fakerPause({
              data: {
                dataSourceId: this.currentTab.node.INSTANCE.id,
                toolSessionId: this.genDataModal.sessionId
              }
            });
            msg = this.$t('zan-ting-cheng-gong');
            break;
          case 'resume':
            res = await this.$services.fakerResume({
              data: {
                dataSourceId: this.currentTab.node.INSTANCE.id,
                toolSessionId: this.genDataModal.sessionId
              }
            });
            msg = this.$t('hui-fu-cheng-gong');
            break;
          default:
            break;
        }

        this.genDataModal.loading = false;
        if (res.success) {
          this.$message.success(msg);
          switch (actionType) {
            case 'pause':
              await this.handleShowLog();
              break;
            case 'resume':
              await this.handleShowLog();
              break;
            default:
              break;
          }
        }
      } catch (e) {
        this.genDataModal.loading = false;
      }
    },
    async showFakerDetail(task) {
      this.genDataModal.step = 3;
      this.genDataModal.show = true;
      const res = await this.$services.fakerFetchUiConfig({
        data: {
          toolSessionId: task.bizId
        }
      });

      if (res.success) {
        this.genDataModal = { ...this.genDataModal, ...res.data };
        this.genDataModal.preTableConfigs = deepClone(res.data.tableConfigs);
        this.genDataModal.sessionId = task.bizId;
        this.genDataModal.dataSourceId = res.data.levels[1];
        this.genDataModal.resume = true;
        this.actionType = res.data.type === 'FULL' ? TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER : TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT;
        this.genDataModal.title = this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER ? this.$t('sheng-cheng-shu-ju') : this.$t('ya-li-ce-shi');
        this.genDataModal.endRow = 0;
        await this.handleShowLog();
      }
    },
    handleTriggerColumnsChange(value) {
      if (!value.includes('UPDATE')) {
        this.triggerModal.options.triggerColumns = [];
      }
    },
    handleAddFunctionParam() {
      const param = {
        name: 'param_name',
        key: nanoid()
      };
      if (this.functionModal.selectedSchema) {
        this.functionModal.selectedSchema.forEach((schema) => {
          param[schema.field] = schema.defaultVal;
          if (schema.options) {
            schema.options.forEach((option) => {
              if (option.value === schema.defaultVal) {
                if (option.children && option.children.length) {
                  schema.children = [option];
                  option.children.forEach((child) => {
                    param[child.field] = child.defaultVal;
                  });
                }
              }
            });
          }
        });
      }
      this.functionModal.options.params.unshift(param);
      this.functionModal.selectedIndex = this.functionModal.options.params.length - 1;
      this.functionModal.selectedNode = this.functionModal.options.params[this.functionModal.selectedIndex];
    },
    handleClickFunctionParam(index) {
      this.functionModal.selectedIndex = index;
      this.functionModal.selectedNode = this.functionModal.options.params[index];
    },
    handleRemoveFunctionParam() {
      if (this.functionModal.options.params.length) {
        this.functionModal.options.params.splice(this.functionModal.selectedIndex, 1);
        this.functionModal.selectedIndex = -1;
        this.functionModal.selectedNode = {};
      }
    },
    handleAddProcedureParam() {
      const param = {
        name: 'param_name',
        key: nanoid()
      };
      if (this.procedureModal.selectedSchema) {
        this.procedureModal.selectedSchema.forEach((schema) => {
          param[schema.field] = schema.defaultVal;
          if (schema.options) {
            schema.options.forEach((option) => {
              if (option.value === schema.defaultVal) {
                if (option.children && option.children.length) {
                  schema.children = [option];
                  option.children.forEach((child) => {
                    param[child.field] = child.defaultVal;
                  });
                }
              }
            });
          }
        });
      }
      this.procedureModal.options.params.unshift(param);
      this.procedureModal.selectedIndex = this.procedureModal.options.params.length - 1;
      this.procedureModal.selectedNode = this.procedureModal.options.params[this.procedureModal.selectedIndex];
    },
    handleClickProcedureParam(index) {
      this.procedureModal.selectedIndex = index;
      this.procedureModal.selectedNode = this.procedureModal.options.params[index];
    },
    handleRemoveProcedureParam() {
      if (this.procedureModal.options.params.length) {
        this.procedureModal.options.params.splice(this.procedureModal.selectedIndex, 1);
        this.procedureModal.selectedIndex = -1;
        this.procedureModal.selectedNode = {};
      }
    },
    async handleStruct() {
      this.sqlModal.loading = true;
      const {
        node,
        selectedTable: table,
        leafType
      } = this.currentTab;
      if (this.sqlModal.type === 'Request') {
        const res = await this.$services.requestScript({
          data: {
            levels: this.browseGenLevelsData(node),
            targetType: leafType,
            targetName: table.title
          }
        });

        if (res.success) {
          this.sqlModal.sql = res.data.sql;
        }
      } else if (this.sqlModal.type === 'Convert') {
        const res = await this.$services.convertDDL({
          data: {
            levels: this.browseGenLevelsData(node),
            leafType,
            sourceTableName: table.title,
            targetDsType: this.sqlModal.dsType,
            options: this.sqlModal.options
          }
        });

        if (res.success) {
          this.sqlModal.sql = res.data.sql;
        }
      }

      this.sqlModal.loading = false;
    },
    handleSelectTableConfig(tableConfig) {
      this.selectedTableConfig = tableConfig;
      const cellData = [];
      const currentTableData = this.previewData[tableConfig.name];
      if (currentTableData && currentTableData.length) {
        this.columnList = this.genDataModal.tableConfigColumns[tableConfig.name];
        this.columnList.forEach((column, columnIndex) => {
          currentTableData.forEach((row, rowIndex) => {
            const cell = {
              r: rowIndex,
              c: columnIndex,
              v: {
                ct: {
                  fa: '@',
                  t: 's'
                },
                v: row.type === 'Delete' ? row.oldValue[column] : row.newValue[column],
                m: row.type === 'Delete' ? row.oldValue[column] : row.newValue[column],
                bg: row.type === 'Update' ? row.oldValue[column] !== row.newValue[column] ? BG_COLOR[row.type] : '#fff' : BG_COLOR[row.type]
              }
            };
            if (row.type === 'Update') {
              cell.v.ps = {
                value: row.oldValue[column]
              };
            }
            cellData.push(cell);
          });
        });
      }

      this.cellData = cellData;
    },
    handleShowColumnConfigDetail(record) {
      this.$refs.genDataTree.setSelected(record.key, true);
    },
    handleGoTableConfig() {
      console.log(11223344, this.genDataModal.selectedNode.key);
      const currentKey = this.genDataModal.selectedNode.key;
      const lastIndex = currentKey.lastIndexOf('.');
      const tableKey = currentKey.substring(0, lastIndex);

      console.log('tableKey', tableKey);
      this.$refs.genDataTree.setSelected(tableKey, true);
    },
    handlePreStep() {
      this.genDataModal.refresh = true;
      if (this.genDataModal.step === 2) {
        this.genDataModal.step -= 1;
      }
      if (this.genDataModal.step === 3) {
        if (this.genDataModal.status !== 'COMPLETE') {
          Modal.confirm({
            title: this.$t('ti-shi'),
            content: this.$t('dian-ji-shang-yi-bu-hui-guan-bi-thisgendatamodaltitle-que-ding-yao-jin-hang-shang-yi-bu-cao-zuo-ma', [this.genDataModal.title]),
            onOk: () => {
              this.genDataModal.step -= 2;
              this.clearLogInterVal();
              this.handleCloseFaker();
              if (this.genDataModal.resume) {
                this.handleRightClickMenu(this.actionType);
              }
            }
          });
        } else {
          this.genDataModal.step -= 2;
        }
      }
    },
    highlightLog(log) {
      if (log.includes('[ERROR]')) {
        return this.highlightStrict(log, '[ERROR]', 'color: red', 'b');
      }
      if (log.includes('[TRACE]')) {
        return this.highlightStrict(log, '[TRACE]', 'color: gray', 'b');
      }
      if (log.includes('[DEBUG]')) {
        return this.highlightStrict(log, '[DEBUG]', 'color: gray', 'b');
      }
      if (log.includes('[INFO]')) {
        return this.highlightStrict(log, '[INFO]', 'color: green', 'b');
      }
      if (log.includes('[WARN]')) {
        return this.highlightStrict(log, '[WARN]', 'color: yellow', 'b');
      }

      return log;
    },
    async handlePreview() {
      const { node } = this.currentTab;
      const checkedKeys = this.$refs.genDataTree.getCheckedKeys();
      console.log('checkedKeys', checkedKeys);
      const tableConfigs = [];
      const tableConfigColumns = {};
      this.genDataModal.tableConfigs.forEach((tableConfig) => {
        const selectedTableConfig = deepClone(tableConfig);
        tableConfigColumns[selectedTableConfig.name] = [];
        selectedTableConfig.columnConfigs = [];
        delete selectedTableConfig.key;
        if (tableConfig.columnConfigs && tableConfig.columnConfigs.length) {
          tableConfig.columnConfigs.forEach((columnConfig) => {
            if (checkedKeys.includes(columnConfig.key)) {
              const selectedColumnConfig = {
                seedConfig: {}
              };
              Object.keys(columnConfig).forEach((key) => {
                if (['name', 'seedType', 'ignoreColsInsert', 'ignoreColsUpdate', 'ignoreColsUpdateWhere', 'ignoreColsDeleteWhere'].includes(key)) {
                  selectedColumnConfig[key] = columnConfig[key];
                } else {
                  if (key !== 'key') {
                    selectedColumnConfig.seedConfig[key] = columnConfig[key];
                  }
                }
              });
              delete selectedColumnConfig.key;
              selectedTableConfig.columnConfigs.push(selectedColumnConfig);
              tableConfigColumns[selectedTableConfig.name].push(selectedColumnConfig.name);
            }
          });
        }
        if (this.genDataModal.resume && checkedKeys.includes(tableConfig.key)) {
          this.genDataModal.preTableConfigs.forEach((preTableConfig) => {
            if (preTableConfig.name === tableConfig.name) {
              selectedTableConfig.columnConfigs = preTableConfig.columnConfigs;
              tableConfigColumns[selectedTableConfig.name] = [];
              preTableConfig.columnConfigs.forEach((preColumn) => {
                tableConfigColumns[selectedTableConfig.name].push(preColumn.name);
              });
            }
          });
        }

        if (selectedTableConfig.columnConfigs.length) {
          tableConfigs.push(selectedTableConfig);
        }
      });
      this.genDataModal.tableConfigColumns = tableConfigColumns;

      try {
        this.genDataModal.loading = true;
        const res = await this.$services.fakerPreview({
          data: {
            levels: this.browseGenLevelsData(node),
            ...this.genDataModal.globalConfigs,
            tableConfigs,
            type: this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT ? 'INCREMENT' : 'FULL'
          }
        });
        this.genDataModal.loading = false;

        this.genDataModal.selectedTaleConfigs = tableConfigs;

        if (res.success) {
          this.genDataModal.step++;
          if (res.data) {
            if (res.data.data) {
              this.previewData = res.data.data;
              const cellData = [];
              const currentTableConfig = tableConfigs[0];
              this.selectedTableConfig = currentTableConfig;
              const currentTableData = this.previewData[currentTableConfig.name];
              if (currentTableData && currentTableData.length) {
                this.columnList = tableConfigColumns[currentTableConfig.name];
                this.columnList.forEach((column, columnIndex) => {
                  currentTableData.forEach((row, rowIndex) => {
                    const cell = {
                      r: rowIndex,
                      c: columnIndex,
                      v: {
                        ct: {
                          fa: '@',
                          t: 's'
                        },
                        v: row.type === 'Delete' ? row.oldValue[column] : row.newValue[column],
                        m: row.type === 'Delete' ? row.oldValue[column] : row.newValue[column],
                        bg: row.type === 'Update' ? row.oldValue[column] !== row.newValue[column] ? BG_COLOR[row.type] : '#fff' : BG_COLOR[row.type]
                      }
                    };
                    if (row.type === 'Update') {
                      cell.v.ps = {
                        value: row.oldValue[column]
                      };
                    }
                    cellData.push(cell);
                  });
                });

                this.cellData = cellData;
              }
            }
          }
        }
      } catch (e) {
        this.genDataModal.loading = false;
      }
    },
    async handleGenData() {
      try {
        this.genDataModal.loading = true;
        const res = await this.$services.fakerExecute({
          data: {
            levels: this.browseGenLevelsData(this.currentTab.node),
            ...this.genDataModal.globalConfigs,
            tableConfigs: this.genDataModal.selectedTaleConfigs,
            type: this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT ? 'INCREMENT' : 'FULL'
          }
        });
        this.genDataModal.loading = false;
        if (res.success) {
          this.genDataModal.sessionId = res.data;
          this.genDataModal.step++;
          await this.handleShowLog();
        }
      } catch (e) {
        this.genDataModal.loading = false;
      }
    },
    async handleObtainLog(loop = true) {
      this.refreshLoading = true;
      this.clearLogInterVal();
      const obtainLog = async () => {
        const res = await this.$services.fakerTailLog({
          data: {
            dataSourceId: this.genDataModal.dataSourceId,
            toolSessionId: this.genDataModal.sessionId,
            startLine: this.genDataModal.startLine
          }
        });

        if (res.success) {
          const {
            status,
            endLine,
            failedDeleteTotal,
            failedInsertTotal,
            failedTotal,
            failedUpdateTotal,
            successDeleteTotal,
            successInsertTotal,
            successTotal,
            successUpdateTotal,
            writeAvgTime,
            logArr,
            logFile,
            logHost
          } = res.data;
          this.genDataModal.status = status;
          if (status !== FAKER_TASK_STATUS.COMPLETE) {
            this.genDataModal.failedTotal = failedTotal;
            this.genDataModal.failedInsertTotal = failedInsertTotal;
            this.genDataModal.failedDeleteTotal = failedDeleteTotal;
            this.genDataModal.failedUpdateTotal = failedUpdateTotal;
            this.genDataModal.successTotal = successTotal;
            this.genDataModal.successInsertTotal = successInsertTotal;
            this.genDataModal.successDeleteTotal = successDeleteTotal;
            this.genDataModal.successUpdateTotal = successUpdateTotal;
            this.genDataModal.writeAvgTime = writeAvgTime;
            this.genDataModal.logFile = logFile;
            this.genDataModal.logHost = logHost;
          }
          if (logArr && logArr.length) {
            this.genDataModal.logArr = logArr.reverse().concat(this.genDataModal.logArr);
          }
          // if (status === FAKER_TASK_STATUS.COMPLETE || status === FAKER_TASK_STATUS.PAUSE) {
          //   this.clearLogInterVal();
          // }
        }
        this.refreshLoading = false;
      };
      await obtainLog();
      // if (loop || this.genDataModal.refresh) {
      //   this.genDataModal.timer = window.setInterval(obtainLog, this.timeoutMs);
      // }
    },
    async getFakerDef() {
      const { selectedTable, node } = this.currentTab;
      try {
        this.genDataModal.loading = true;
        const defRes = await this.$services.fakerDef({
          data: {
            levels: this.browseGenLevelsData(node),
            table: selectedTable.title,
            type: this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT ? 'INCREMENT' : 'FULL'
          }
        });
        this.genDataModal.loading = false;
        if (defRes.success) {
          this.schemaDef = defRes.data;
        }
      } catch (e) {
        this.genDataModal.loading = false;
        console.log(e);
      }
    },
    async getInitTableFaker(node, params = {}, resolve, reject) {
      console.log('init faker');
      const {
        checked, selected, expand, preTableConfig
      } = params;
      try {
        this.genDataModal.loading = true;
        const initRes = await this.$services.fakerInit({
          data: {
            levels: this.browseGenLevelsData(this.currentTab.node),
            table: node.title,
            type: this.actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT ? 'INCREMENT' : 'FULL'
          }
        });
        await this.$refs.genDataTree.setChecked(node.key, false);
        this.genDataModal.loading = false;
        if (initRes.success) {
          const children = [];
          initRes.data.columns.forEach((column) => {
            const {
              seedType, name, support, ignoreAct
            } = column;
            let preChecked = true;
            if (preTableConfig) {
              const preColIndex = preTableConfig.columnConfigs.findIndex((col) => col.name === name);
              if (preColIndex === -1) {
                preChecked = false;
              }
            }

            children.push({
              ignoreAct,
              title: name,
              seedType,
              name,
              disabled: !support,
              tableName: node.title,
              isLeaf: true,
              checked: preChecked && support,
              configType: 'columnConfig',
              key: `${node.key}.\`${name}\``
            });
          });

          node._loaded = true;
          if (resolve) {
            if (selected) {
              await this.$refs.genDataTree.setSelected(node.key, true);
            }
            if (expand) {
              await this.$refs.genDataTree.setExpand(node.key, true);
            }
            if (checked) {
              console.log('checked', node.key);
              await this.$refs.genDataTree.setChecked(node.key, true);
            }
            resolve(children);
          }
        } else {
          if (reject) {
            console.log('reject1');
            reject();
          }
        }
      } catch (e) {
        this.genDataModal.loading = false;
        if (reject) {
          console.log('reject2');
          reject(e);
        }
      }
    },
    async handleGenDataCheck(node) {
      console.log('check');
      if (!node.resume) {
        this.$refs.genDataTree.setSelected(node.key, true); // 设置选中状态
        await this.$refs.genDataTree.setExpand(node.key, true);
        setTimeout(() => {
          this.$refs.genDataTree.setChecked(node.key, true);
        });
      }
    },
    async handleGenDataSetCheckedKeys(keys, checked = true) {
      console.log('handleGenDataSetCheckedKeys', keys);
      this.genDataModal.checkedKeys = keys;
      await this.$refs.genDataTree.setCheckedKeys(keys, checked);
    },
    async handleGenDataGetCheckedKeys() {
      console.log('handleGenDataGetCheckedKeys');
      const checkedKeys = await this.$refs.genDataTree.getCheckedKeys();
      return checkedKeys;
    },
    handleSetScrollTop(e) {
      this.scrollY = e.target.scrollTop;
    },
    handleEleScroll(top) {
      const eleList = $('.table-list-tree .ctree-tree__scroll-area');
      if (eleList && eleList.length) {
        eleList[0].scrollTo({ top });
      }
    },
    async handleSearch() {
      await this.handleFilter(this.currentTab[this.currentTab.leafType].searchKey);
      if (this.currentTab[this.currentTab.leafType].treeData && this.currentTab[this.currentTab.leafType].treeData.length) {
        this.$refs.tableTree.scrollTo(this.currentTab[this.currentTab.leafType].treeData[0].key);
      }
    },
    handleDblClick(node) {
      console.log('dbl click');
      let sql = '';
      const {
        quickQuery,
        quickQueryColumn,
        quickQueryMap
      } = this.getQuickQuery(this.currentTab.dsType);
      const { selectedTable, leafGroup } = this.currentTab;
      const needQualifier = node.nodeType === 'TABLE' || node.nodeType === 'VIEW' || node.nodeType === 'COLUMN';
      if (quickQuery && leafGroup.findIndex((leaf) => leaf.type === node.nodeType) > -1) {
        sql = quickQuery;
        sql = sql.replace('%%TABLE%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, selectedTable.title) : selectedTable.title);
        sql = sql.replace('%%SCHEMA%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, this.currentTab.node.SCHEMA.name) : this.currentTab.node.SCHEMA.name);
      }

      if (quickQueryColumn && node.nodeType === 'COLUMN') {
        sql = quickQueryColumn;
        sql = sql.replace('%%TABLE%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, selectedTable.title) : selectedTable.title);
        sql = sql.replace('%%COLUMN%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, node.title) : node.title);
      }

      if (quickQueryMap) {
        if (quickQueryMap[node.nodeType]) {
          sql = quickQueryMap[node.nodeType];
          sql = sql.replace('%%TABLE%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, selectedTable.title) : selectedTable.title);
          sql = sql.replace('%%SCHEMA%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, this.currentTab.node.SCHEMA.name) : this.currentTab.node.SCHEMA.name);
          sql = sql.replace('%%COLUMN%%', needQualifier ? this.genQualifierText(this.currentTab.dsType, node.title) : node.title);
          sql = sql.replace('%%PARAM%%', (node.objAttr && node.objAttr.rdb_param) || '');
        }
      }

      this.handleQueryTable(sql);
    },
    handleRefreshTree() {
      this.scrollY = 0;
      this.currentTab.expandedKeys = [];
      this.listLeaf();
    },
    handleFocus() {
      if (this.selectedNode) {
        this.handleScrollTo(this.selectedNode.key);
      }
    },
    handleScrollTo(key) {
      this.$refs.tableTree.scrollTo(key);
    },
    handleGenDataScrollTo(key) {
      this.$refs.genDataTree.scrollTo(key);
    },
    isExpandedKey(node) {
      return this.currentTab.expandedKeys.includes(node.key);
    },
    isGenDataExpandedKey(key) {
      return this.genDataModal.expandedKeys.includes(key);
    },
    handleSetExpandedKeys(node) {
      const { key } = node;
      if (this.isExpandedKey(node)) {
        this.currentTab.expandedKeys = this.currentTab.expandedKeys.filter((k) => k !== key);
      } else {
        this.currentTab.expandedKeys.push(key);
      }
    },
    handleGenDataSetExpandedKeys(node) {
      const { key } = node;
      if (this.isGenDataExpandedKey(key)) {
        this.genDataModal.expandedKeys = this.genDataModal.expandedKeys.filter((k) => k !== key);
      } else {
        this.genDataModal.expandedKeys.push(key);
      }
    },
    handleNodeClick(node) {
      this.handleSetSelected(node);
    },
    async handleExpandLoadNode(node, resolve) {
      await this.getNodeData(node, {}, resolve);
    },
    async handleGenDataExpandLoadNode(node, resolve, reject) {
      if (node && node.title) {
        let preTableConfig = null;
        if (node.resume) {
          preTableConfig = this.genDataModal.preTableConfigs.find((tableConfig) => tableConfig.name === node.title);
        }
        await this.getInitTableFaker(node, {
          checked: false, selected: true, expand: false, preTableConfig
        },
        (children) => {
          this.genDataModal.tableConfigs.forEach((tableConfig) => {
            if (tableConfig.name === node.title) {
              children.forEach((child) => {
                const columnConfig = {
                  name: child.title,
                  seedType: child.seedType,
                  key: child.key
                };
                this.schemaDef.uiPanels.columnConfig.children.forEach((child2) => {
                  console.log('child2', child2);
                  if (child2.type === 'Options') {
                    child2.options.forEach((option) => {
                      if (option.value === child.seedType) {
                        option.children.forEach((child3) => {
                          columnConfig[child3.field] = child3.defaultVal;
                        });
                      }
                    });
                  } else {
                    columnConfig[child2.field] = child2.defaultVal;
                    if (child2.type === 'Check') {
                      if (columnConfig[child2.field] === 'true') {
                        columnConfig[child2.field] = true;
                      }
                      if (columnConfig[child2.field] === 'false') {
                        columnConfig[child2.field] = false;
                      }
                    }
                  }
                });
                if (child.ignoreAct && child.ignoreAct.length) {
                  child.ignoreAct.forEach((ignoreConfig) => {
                    columnConfig[ignoreConfig] = true;
                  });
                }
                tableConfig.columnConfigs.push(columnConfig);
              });
            }
          });
          console.log('children', children);
          resolve(children);
        }, reject);
        node.expand = false;
        console.log('node', node);
      } else {
        resolve();
      }
    },
    handleFilter(searchKey) {
      if (this.$refs.tableTree) {
        this.$refs.tableTree.filter(searchKey);
      }
    },
    handleGenDataFilter(searchKey) {
      if (this.$refs.genDataTree) {
        this.$refs.genDataTree.filter(searchKey);
      }
    },
    async handleSetData(data) {
      if (this.$refs.tableTree) {
        this.top = this.scrollY;
        await this.$refs.tableTree.setData(data);
        await this.handleSearch();
        this.$nextTick(() => {
          this.handleEleScroll(this.top);
        });
      }
    },
    handleGenDataSetData(data) {
      if (this.$refs.genDataTree) {
        this.$refs.genDataTree.setData(data);
      }
    },
    handleSetSelected(node, selected = true) {
      if (this.$refs.tableTree) {
        this.selectedNode = node;
        this.currentTab.selectedTable = {
          title: this.getRootNode(node).title
        };
        this.$refs.tableTree.setSelected(node.key, selected);
      }
    },
    async handleGenDataSetSelected(node, selected = true) {
      if (this.$refs.genDataTree) {
        this.genDataModal.selectedNode = node;
        this.genDataModal.selectedSchema = this.schemaDef.uiPanels[node.configType].children;
        let selectedData = {};
        if (node.configType === 'tableConfig') {
          this.genDataModal.tableConfigs.forEach((tableConfig) => {
            if (tableConfig.name === node.title) {
              selectedData = tableConfig;
            }
          });
        } else {
          this.genDataModal.tableConfigs.forEach((tableConfig) => {
            if (tableConfig.name === node.tableName) {
              tableConfig.columnConfigs.forEach((columnConfig) => {
                if (columnConfig.name === node.title) {
                  selectedData = columnConfig;
                }
              });
            }
          });
        }
        const generateOptionSchema = (item) => {
          if (item.type === 'Options' && selectedData) {
            const optionData = selectedData[item.field];
            item.options.forEach((option) => {
              if (option.value === optionData) {
                if (option.children && option.children.length) {
                  item.children = option.children;
                  option.children.forEach((child) => {
                    if (!(child.field in selectedData)) {
                      selectedData[child.field] = child.defaultVal;
                    }
                    generateOptionSchema(child);
                  });
                } else {
                  item.children = [];
                }
              }
            });
          }
          if (!(item.field in selectedData)) {
            selectedData[item.field] = item.defaultVal;
          }
        };
        this.genDataModal.selectedSchema.forEach((item) => {
          generateOptionSchema(item);
        });
        await this.$refs.genDataTree.setSelected(node.key, selected);
      }
    },
    getRootNode(node) {
      let rootNode = deepClone(node);
      if (rootNode.leafKey !== rootNode.key) {
        if (rootNode._parent) {
          if (rootNode._parent.key === rootNode.leafKey) {
            rootNode = rootNode._parent;
          } else {
            if (rootNode._parent._parent) {
              if (rootNode._parent._parent.key === rootNode.leafKey) {
                rootNode = rootNode._parent._parent;
              }
            }
          }
        }
      }
      return rootNode;
    },
    renderNode(h, node) {
      const {
        title, icon, tips, children, nodeType
      } = node;
      return (
        <div class={['node']} key={title}>
          {icon && <cc-svg-icon name={icon}/>}
          <div style={{ marginLeft: '3px' }}
               domPropsInnerHTML={this.highlight(title, this.currentTab[this.currentTab.leafType].searchKey)}>
          </div>
          {children && children.length > 0 && nodeType.includes('GROUP')
            && <div style="font-weight: bold;color: #bbb;">[{children.length}]</div>}
          {tips && <div style={{ marginLeft: '3px', color: '#ccc' }}>{tips}</div>}
        </div>
      );
    },
    renderGenDataNode(h, node) {
      const {
        title, icon, tips, children, nodeType
      } = node;
      return (
        <div class={['node']} key={title}>
          {icon && <cc-svg-icon name={icon}/>}
          <div style={{ marginLeft: '3px' }}
               domPropsInnerHTML={this.highlight(title, this.genDataModal.searchKey)}>
          </div>
          {children && children.length > 0 && nodeType.includes('GROUP')
            && <div style="font-weight: bold;color: #bbb;">[{children.length}]</div>}
          {tips && <div style={{ marginLeft: '3px', color: '#ccc' }}>{tips}</div>}
        </div>
      );
    },
    isRedis,
    handleSetExpandAll(expand = true) {
      this.$refs.tableTree.setExpandAll(expand);
    },
    handleSelectTable(table) {
      console.log('click');
      this.handleSetSelected(table);
    },
    handleGenDataSelectTable(table) {
      this.handleGenDataSetSelected(table);
    },
    handleNodeRightClick(table) {
      this.handleSetSelected(table);
    },
    handleChangeTab(leafType) {
      this.currentTab.leafType = leafType;
      this.listLeaf();
    },
    onContextmenu(event) {
      const node = this.$refs.tableTree.getSelectedNode();
      const isNotNode = event.target.classList && event.target.classList.length && event.target.classList[0] === 'ctree-tree__block-area';
      const items = [];
      const menuList = this.getBrowserMenus(this.currentTab.dsType, isNotNode || !node ? this.currentTab.leafType : node.nodeType);
      if (menuList && menuList.length) {
        if (isNotNode || !node) {
          menuList.forEach((menu) => {
            if (menu.menuId !== 'MENU_SEPARATOR' && !menu.needTarget) {
              items.push({
                label: menu.i18n,
                icon: `iconfont icon-svg-${menu.menuId}`,
                onClick: () => this.handleRightClickMenu(menu.menuId)
              });
            }
          });
        } else {
          menuList.forEach((menu, menuIndex) => {
            if (menu.menuId !== 'MENU_SEPARATOR') {
              items.push({
                label: menu.i18n,
                icon: `iconfont icon-svg-${menu.menuId}`,
                divided: menuList[menuIndex + 1] && menuList[menuIndex + 1].menuId === 'MENU_SEPARATOR',
                onClick: () => this.handleRightClickMenu(menu.menuId)
              });
            }
          });
        }
      }
      this.$contextmenu({
        items,
        event,
        customClass: 'custom-class',
        zIndex: 3,
        minWidth: 100
      });
    },
    clearLogInterVal() {
      if (this.genDataModal.timer) {
        window.clearInterval(this.genDataModal.timer);
        this.genDataModal.timer = null;
      }
    },
    async handleCloseFaker() {
      try {
        this.genDataModal.loading = true;
        this.clearLogInterVal();
        const res = await this.$services.fakerClose({
          data: {
            dataSourceId: this.currentTab.node.INSTANCE.id,
            toolSessionId: this.genDataModal.sessionId
          }
        });
        this.genDataModal.loading = false;
        if (res.success) {
          this.handleShowLog();
        }
      } catch (e) {
        this.genDataModal.loading = false;
      }
    },
    async handleShowLog(step = 0) {
      if (step === 25) {
        this.genDataModal.logNextLoading = true;
      } else if (step === -25) {
        this.genDataModal.logPreLoading = true;
      } else {
        this.genDataModal.logLoading = true;
      }

      const startLine = this.genDataModal.startLine + step < 0 || step === 0 ? 0 : this.genDataModal.startLine + step;
      this.genDataModal.startLine = startLine;

      const res = await this.$services.fakerTailLog({
        data: {
          dataSourceId: this.genDataModal.dataSourceId,
          toolSessionId: this.genDataModal.sessionId,
          startLine
        }
      });

      if (step === 25) {
        this.genDataModal.logNextLoading = false;
      } else if (step === -25) {
        this.genDataModal.logPreLoading = false;
      } else {
        this.genDataModal.logLoading = false;
      }

      if (res.success) {
        const {
          status,
          endLine,
          failedDeleteTotal,
          failedInsertTotal,
          failedTotal,
          failedUpdateTotal,
          successDeleteTotal,
          successInsertTotal,
          successTotal,
          successUpdateTotal,
          writeAvgTime,
          logArr,
          logFile,
          logHost
        } = res.data;
        this.genDataModal.status = status;
        this.genDataModal.endRow = endLine;
        this.genDataModal.failedTotal = failedTotal;
        this.genDataModal.failedInsertTotal = failedInsertTotal;
        this.genDataModal.failedDeleteTotal = failedDeleteTotal;
        this.genDataModal.failedUpdateTotal = failedUpdateTotal;
        this.genDataModal.successTotal = successTotal;
        this.genDataModal.successInsertTotal = successInsertTotal;
        this.genDataModal.successDeleteTotal = successDeleteTotal;
        this.genDataModal.successUpdateTotal = successUpdateTotal;
        this.genDataModal.writeAvgTime = writeAvgTime;
        this.genDataModal.logFile = logFile;
        this.genDataModal.logHost = logHost;
        if (logArr) {
          this.genDataModal.logArr = logArr.map((log) => ({
            text: log,
            id: nanoid()
          }));
        } else {
          this.genDataModal.logArr = [];
        }
        setTimeout(() => {
          const ele = document.getElementById('log-list');
          if (ele) {
            console.log(ele);
            ele.scrollTop = ele.scrollHeight;
          }
        }, 0);
      }
    },
    async handleCloseModal() {
      this.genDataModal = deepClone(EMPTY_GEN_DATA);
      this.sqlModal = deepClone(EMPTY_SQL_DATA);
      this.menuModal = deepClone(EMPTY_MENU_DATA);

      this.triggerModal = deepClone(EMPTY_TRIGGER_DATA);
      this.viewModal = deepClone(EMPTY_VIEW_DATA);
      this.functionModal = deepClone(EMPTY_FUNCTION_DATA);
      this.procedureModal = deepClone(EMPTY_PROCEDURE_DATA);

      if (this.monacoEditor) {
        this.monacoEditor.dispose();
      }
      this.defaultOpts.value = '';
      this.currentDdlList = [];
      this.doActionLoading = false;
      clearAllPending();
    },
    handleMenuNameChange(e) {
      if (e.target.value !== this.menuModal.name) {
        this.menuModal.sql = '';
        this.menuModal.permission = false;
        this.menuModal.danger = false;
        this.menuModal.actionData = {};
      }
      this.menuModal.name = e.target.value;
    },
    handleMenuOptionChange(key, e) {
      if (this.menuModal.show) {
        if (e !== this.menuModal.options[key]) {
          this.menuModal.sql = '';
          this.menuModal.permission = false;
          this.menuModal.danger = false;
          this.menuModal.actionData = {};
        }
        this.menuModal.options[key] = e;
      }

      if (this.sqlModal.show) {
        if (e !== this.sqlModal.options[key]) {
          this.sqlModal.sql = '';
          this.sqlModal.permission = false;
          this.sqlModal.danger = false;
        }
        this.sqlModal.options[key] = e;
        this.handleStruct();
      }
    },
    async handleRightClickMenu(actionType) {
      console.log(actionType);
      this.actionType = actionType;
      const {
        node,
        selectedTable: table,
        leafType
      } = this.currentTab;
      const data = {
        node: this.currentTab.node,
        actionType,
        callback: null,
        other: {
          targetType: this.currentTab.leafType,
          targetName: table ? table.title : '',
          targetNewName: this.menuModal.name,
          options: this.menuModal.options
        }
      };

      let res = {};

      switch (actionType) {
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_COPY_NAME:
          this.copyText(this.selectedNode.title);
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER_INCREMENT:
          this.genDataModal.title = actionType === TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_FAKER ? this.$t('sheng-cheng-shu-ju') : this.$t('ya-li-ce-shi');
          // this.genDataModal.treeData = this.currentTab[this.currentTab.leafType].treeData;
          this.$nextTick(async () => {
            await this.getFakerDef();
            const treeData = [];
            const tableConfigs = [];
            let curNode = null;
            if (!this.genDataModal.show) {
              this.genDataModal.show = true;
            }
            if (!this.genDataModal.dataSourceId) {
              this.genDataModal.dataSourceId = this.currentTab.node.INSTANCE.id;
            }
            for await (const leaf of this.currentTab.TABLE.treeData) {
              const item = deepClone(leaf);
              item.configType = 'tableConfig';
              item.children = [];
              const tableConfig = {
                name: item.title,
                key: item.key,
                columnConfigs: []
              };
              this.schemaDef.uiPanels.tableConfig.children.forEach((child) => {
                tableConfig[child.field] = child.defaultVal;
              });

              if (this.selectedNode && leaf.key === this.selectedNode.key && !this.genDataModal.resume) {
                curNode = item;
                try {
                  await this.getInitTableFaker(item, {
                    checked: false,
                    selected: false,
                    expand: false
                  }, (children) => {
                    item.children = children;
                    children.forEach((child) => {
                      const columnConfig = {
                        name: child.title,
                        seedType: child.seedType,
                        key: child.key
                      };
                      this.schemaDef.uiPanels.columnConfig.children.forEach((child2) => {
                        if (child2.type === 'Options') {
                          child2.options.forEach((option) => {
                            if (option.value === child.seedType) {
                              option.children.forEach((child3) => {
                                columnConfig[child3.field] = child3.defaultVal;
                              });
                            }
                          });
                        } else {
                          columnConfig[child2.field] = child2.defaultVal;
                          if (child2.type === 'Check') {
                            if (columnConfig[child2.field] === 'true') {
                              columnConfig[child2.field] = true;
                            }
                            if (columnConfig[child2.field] === 'false') {
                              columnConfig[child2.field] = false;
                            }
                          }
                        }
                      });

                      if (child.ignoreAct && child.ignoreAct.length) {
                        child.ignoreAct.forEach((ignoreConfig) => {
                          columnConfig[ignoreConfig] = true;
                        });
                      }
                      tableConfig.columnConfigs.push(columnConfig);
                    });
                  });
                } catch (e) {
                  console.log(e);
                }
              }
              tableConfigs.push(tableConfig);
              if (this.genDataModal.resume && this.genDataModal.preTableConfigs.findIndex((preTableConfig) => preTableConfig.name === tableConfig.name) > -1) {
                item.resume = true;
                tableConfig.resume = true;
              }
              treeData.push(item);
            }

            this.genDataModal.treeData = treeData;
            await this.$refs.genDataTree.setData(treeData);

            if (this.genDataModal.resume) {
              this.genDataModal.preTableConfigs.forEach((preTableConfig) => {
                tableConfigs.forEach((tableConfig) => {
                  if (preTableConfig.name === tableConfig.name) {
                    this.$refs.genDataTree.setChecked(tableConfig.key, true);
                  }
                });
              });
            }

            this.genDataModal.tableConfigs = tableConfigs;

            if (curNode && curNode._loaded && !this.genDataModal.resume) {
              await this.$refs.genDataTree.scrollTo(curNode.key);
              await this.$refs.genDataTree.setExpand(curNode.key, true);
              await this.$refs.genDataTree.setChecked(curNode.key, true);
              await this.handleGenDataSetSelected(curNode, true);
            }
          });
          break;
        default:
          break;
      }

      switch (actionType) {
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_KEY_REFRESH:
          this.listLeaf();
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_COLUMN_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_INDEX_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PARTITION_REFRESH:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PRIMARY_REFRESH:
          this.rdbTableDetail(this.currentTab.selectedTable.title, {
            expand: false, selected: true, type: actionType, selectedNode: this.selectedNode
          });
          break;
        default:
          break;
      }

      switch (actionType) {
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_DATA:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_DATA:
          this.handleAddTab(TAB_TYPE.DATA, node, { table });
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_ALTER:
          this.handleAddTab(TAB_TYPE.STRUCT, node, { table, editorType: ACTION_TYPE.EDIT_TABLE });
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_CREATE:
          this.handleAddTab(TAB_TYPE.STRUCT, node, { editorType: ACTION_TYPE.CREATE_TABLE });
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_DROP:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_DROP:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_DROP:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PROCEDURE_DROP:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_FUNCTION_DROP:
          data.callback = (permission, danger, sql, genActionData) => {
            this.menuModal.actionData = genActionData;
            this.menuModal.permission = permission;
            this.menuModal.danger = danger;
            this.menuModal.show = true;
            this.menuModal.title = this.$t('que-ren-shan-chu');
            this.menuModal.name = table.title;
            this.menuModal.preName = table.title;
            this.menuModal.content = `${this.$t('que-ding-yao-shan-chu')}${this.currentTab.popTip}.\`${table.title}\`？`;
            this.menuModal.sql = sql;
          };
          await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_TRUNCATE:
          data.callback = (permission, danger, sql, genActionData) => {
            this.menuModal.actionData = genActionData;
            this.menuModal.permission = permission;
            this.menuModal.danger = danger;
            this.menuModal.show = true;
            this.menuModal.name = table.title;
            this.menuModal.preName = table.title;
            this.menuModal.title = this.$t('qing-kong');
            this.menuModal.content = `${this.$t('que-ding-yao-qing-kong')}${this.currentTab.popTip}.\`${table.title}\`？`;
            this.menuModal.sql = sql;
          };
          await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_RENAME:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_RENAME:
          if (!this.menuModal.name) {
            this.menuModal.show = true;
            this.menuModal.title = this.$t('zhong-ming-ming');
            this.menuModal.showNameInput = true;
            this.menuModal.name = table.title;
            this.menuModal.preName = table.title;
            this.menuModal.content = this.$t('zhong-ming-ming-thiscurrenttabpoptiptabletitle-wei', [this.currentTab.popTip, table.title]);
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.menuModal.permission = permission;
              this.menuModal.danger = danger;
              this.menuModal.sql = sql;
              this.menuModal.actionData = genActionData;
            };
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_REQUEST:
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_REQUEST:
          res = await this.$services.requestScript({
            data: {
              levels: this.browseGenLevelsData(node),
              targetType: leafType,
              targetName: table.title
            }
          });

          if (res.success) {
            this.sqlModal.show = true;
            this.sqlModal.title = this.$t('huo-qu-ddl-yu-ju-tabletitle', [table.title]);
            this.sqlModal.sql = res.data.sql;
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_GENERATE:
          res = await this.$services.generateScript({
            data: {
              levels: this.browseGenLevelsData(node),
              targetType: leafType,
              targetName: table.title
            }
          });

          if (res.success) {
            this.sqlModal.show = true;
            this.sqlModal.title = this.$t('sheng-cheng-ddl-yu-ju-tabletitle', [table.title]);
            this.sqlModal.sql = res.data.sql;
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_GET_DDL:
          this.currentTargetDsList = this.targetDsList(this.currentTab.dsType) || [];
          if (this.currentTargetDsList && this.currentTargetDsList.length) {
            this.sqlModal.dsType = this.currentTargetDsList[0];
            this.currentDdlList = this.ddlList(this.currentTab.dsType) || [];
            if (this.currentDdlList.length) {
              this.sqlModal.type = this.currentDdlList[0];
              this.sqlModal.show = true;
              await this.handleStruct();
            }
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_CREATE:
          this.triggerModal.title = this.$t('xin-jian-chu-fa-qi');
          if (!this.triggerModal.options.name) {
            this.triggerModal.show = true;
            this.triggerModal.options.triggerTable = table.title;
            const columnRes = await this.$services.rdbTableDetail({
              data: {
                levels: this.browseGenLevelsData(this.currentTab.node),
                targetName: table.title,
                targetType: 'TABLE'
              }
            });

            if (columnRes.success) {
              if (columnRes.data && columnRes.data.group) {
                columnRes.data.group.forEach((item) => {
                  if (item.type === 'RDB_COLUMN_GROUP') {
                    this.triggerModal.columnList = item.items;
                  }
                });
              }
            }
            this.$nextTick(() => {
              this.defaultOpts.value = isPG(this.currentTab.dsType) ? 'execute function\n' : 'begin\n \n end';
              this.monacoEditor = monaco.editor.create(this.$refs.triggerMonacoEditor, this.defaultOpts);
            });
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.triggerModal.permission = permission;
              this.triggerModal.danger = danger;
              this.triggerModal.sql = sql;
              this.triggerModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.triggerModal.options,
              triggerEvent: isMySQL(this.currentTab.dsType) ? this.triggerModal.options.triggerEvent ? [this.triggerModal.options.triggerEvent] : [] : this.triggerModal.options.triggerEventArr,
              sql: this.monacoEditor.getValue()
            };
            data.other.targetType = 'TRIGGER';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_ALTER:
          this.triggerModal.title = this.$t('xiu-gai-chu-fa-qi');
          if (!this.triggerModal.options.name) {
            res = await this.$services.loadObject({
              data: {
                levels: this.browseGenLevelsData(node),
                targetType: leafType,
                targetName: table.title
              }
            });

            if (res.success) {
              this.triggerModal.show = true;
              this.triggerModal.options = {
                ...res.data
              };
              if (isMySQL(this.currentTab.dsType)) {
                this.triggerModal.options.triggerEvent = res.data.triggerEvent.join(',');
              } else {
                this.triggerModal.options.triggerEventArr = res.data.triggerEvent;
              }
              const columnRes = await this.$services.rdbTableDetail({
                data: {
                  levels: this.browseGenLevelsData(this.currentTab.node),
                  targetName: res.data.triggerTable,
                  targetType: 'TABLE'
                }
              });

              if (columnRes.success) {
                if (columnRes.data && columnRes.data.group) {
                  columnRes.data.group.forEach((item) => {
                    if (item.type === 'RDB_COLUMN_GROUP') {
                      this.triggerModal.columnList = item.items;
                    }
                  });
                }
              }
              this.$nextTick(() => {
                this.monacoEditor = monaco.editor.create(this.$refs.triggerMonacoEditor, this.defaultOpts);
                this.monacoEditor.setValue(res.data.sql);
              });
            }
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.triggerModal.permission = permission;
              this.triggerModal.danger = danger;
              this.triggerModal.sql = sql;
              this.triggerModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.triggerModal.options,
              triggerEvent: isMySQL(this.currentTab.dsType)
                ? this.triggerModal.options.triggerEvent ? [this.triggerModal.options.triggerEvent] : [] : this.triggerModal.options.triggerEventArr,
              sql: this.monacoEditor.getValue()
            };
            data.other.targetType = 'TRIGGER';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_CREATE:
          this.viewModal.title = '新建视图';
          if (!this.viewModal.options.name) {
            this.viewModal.show = true;
            this.$nextTick(() => {
              this.monacoEditor = monaco.editor.create(this.$refs.viewMonacoEditor, this.defaultOpts);
            });
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.viewModal.permission = permission;
              this.viewModal.danger = danger;
              this.viewModal.sql = sql;
              this.viewModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.viewModal.options,
              sql: this.monacoEditor.getValue()
            };
            data.other.targetType = 'VIEW';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_ALTER:
          this.viewModal.title = '修改视图';
          if (!this.viewModal.options.name) {
            res = await this.$services.loadObject({
              data: {
                levels: this.browseGenLevelsData(node),
                targetType: leafType,
                targetName: table.title
              }
            });

            if (res.success) {
              this.viewModal.show = true;
              this.viewModal.options = {
                ...res.data
              };
              this.$nextTick(() => {
                this.monacoEditor = monaco.editor.create(this.$refs.viewMonacoEditor, this.defaultOpts);
                this.monacoEditor.setValue(res.data.sql);
              });
            }
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.viewModal.permission = permission;
              this.viewModal.danger = danger;
              this.viewModal.sql = sql;
              this.viewModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.viewModal.options,
              sql: this.monacoEditor.getValue()
            };
            data.other.targetType = 'VIEW';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PROCEDURE_CREATE:
          this.procedureModal.title = '新建存储过程';
          if (!this.procedureModal.options.name) {
            this.procedureModal.show = true;
            const typeRes = await this.$services.fetchParamOptions({
              data: {
                levels: this.browseGenLevelsData(node),
                type: 'Proc'
              }
            });
            if (typeRes.success) {
              this.procedureModal.selectedSchema = typeRes.data.uiPanels.params.children;
            }
            this.$nextTick(() => {
              this.defaultOpts.value = 'begin\n \n end';
              this.monacoEditor = monaco.editor.create(this.$refs.procedureMonacoEditor, this.defaultOpts);
            });
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.procedureModal.permission = permission;
              this.procedureModal.danger = danger;
              this.procedureModal.sql = sql;
              this.procedureModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.procedureModal.options,
              params: this.procedureModal.options.params.filter((param) => param.name),
              sql: this.monacoEditor.getValue()
            };
            data.other.targetType = 'PROC';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PROCEDURE_ALTER:
          this.procedureModal.title = '修改存储过程';
          if (!this.procedureModal.options.name) {
            const typeRes = await this.$services.fetchParamOptions({
              data: {
                levels: this.browseGenLevelsData(node),
                type: 'Proc'
              }
            });
            if (typeRes.success) {
              this.procedureModal.selectedSchema = typeRes.data.uiPanels.params.children;
            }
            res = await this.$services.loadObject({
              data: {
                levels: this.browseGenLevelsData(node),
                targetType: leafType,
                targetName: table.title
              }
            });

            if (res.success) {
              this.procedureModal.show = true;
              res.data.params.forEach((param) => {
                param.key = nanoid();
              });
              this.procedureModal.options = {
                ...res.data
              };
              this.$nextTick(() => {
                this.monacoEditor = monaco.editor.create(this.$refs.procedureMonacoEditor, this.defaultOpts);
                this.monacoEditor.setValue(res.data.sql);
              });
            }
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.procedureModal.permission = permission;
              this.procedureModal.danger = danger;
              this.procedureModal.sql = sql;
              this.procedureModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.procedureModal.options,
              sql: this.monacoEditor.getValue()
            };
            data.other.targetType = 'PROC';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_FUNCTION_CREATE:
          this.functionModal.title = '新建函数';
          if (!this.functionModal.options.name) {
            this.functionModal.show = true;
            const typeRes = await this.$services.fetchParamOptions({
              data: {
                levels: this.browseGenLevelsData(node),
                type: 'Func'
              }
            });
            if (typeRes.success) {
              this.functionModal.selectedSchema = typeRes.data.uiPanels.params.children;
            }
            const columnRes = await this.$services.fetchParamOptions({
              data: {
                levels: this.browseGenLevelsData(node),
                type: 'PARAM'
              }
            });
            if (columnRes.success) {
              this.columnList = columnRes.data.uiPanels.params.children[0].options;
              this.functionModal.options.returnParams = {
                name: '返回参数',
                paramType: columnRes.data.uiPanels.params.children[0].defaultVal
              };
              columnRes.data.uiPanels.params.children[0].options.forEach((option) => {
                if (option.value === columnRes.data.uiPanels.params.children[0].defaultVal) {
                  this.functionModal.returnParamsForm = option.children;
                  option.children.forEach((child) => {
                    this.functionModal.options.returnParams[child.field] = child.defaultVal;
                  });
                }
              });
            }
            this.$nextTick(() => {
              this.defaultOpts.value = 'begin\n \n end';
              this.monacoEditor = monaco.editor.create(this.$refs.functionMonacoEditor, this.defaultOpts);
            });
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.functionModal.permission = permission;
              this.functionModal.danger = danger;
              this.functionModal.sql = sql;
              this.functionModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.functionModal.options,
              params: [this.functionModal.options.returnParams, ...this.functionModal.options.params],
              sql: this.monacoEditor.getValue()
            };
            data.other.targetType = 'FUNC';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_FUNCTION_ALTER:
          this.functionModal.title = '修改函数';
          if (!this.functionModal.options.name) {
            const typeRes = await this.$services.fetchParamOptions({
              data: {
                levels: this.browseGenLevelsData(node),
                type: 'Func'
              }
            });
            if (typeRes.success) {
              this.functionModal.selectedSchema = typeRes.data.uiPanels.params.children;
            }
            const columnRes = await this.$services.fetchParamOptions({
              data: {
                levels: this.browseGenLevelsData(node),
                type: 'PARAM'
              }
            });
            if (columnRes.success) {
              this.columnList = columnRes.data.uiPanels.params.children[0].options;
              this.functionModal.options.returnParams = {
                name: '返回参数',
                paramType: columnRes.data.uiPanels.params.children[0].defaultVal
              };
            }
            res = await this.$services.loadObject({
              data: {
                levels: this.browseGenLevelsData(node),
                targetType: leafType,
                targetName: table.title
              }
            });

            if (res.success) {
              this.functionModal.show = true;
              const returnParams = res.data.params[0];
              columnRes.data.uiPanels.params.children[0].options.forEach((option) => {
                if (option.value === columnRes.data.uiPanels.params.children[0].defaultVal) {
                  this.functionModal.returnParamsForm = option.children;
                }
              });
              res.data.params.splice(0, 1);
              res.data.params.forEach((param) => {
                param.key = nanoid();
              });
              this.functionModal.options = {
                ...res.data,
                returnParams,
                params: res.data.params
              };
              this.$nextTick(() => {
                this.monacoEditor = monaco.editor.create(this.$refs.functionMonacoEditor, this.defaultOpts);
                this.monacoEditor.setValue(res.data.sql);
              });
            }
          } else {
            data.callback = (permission, danger, sql, genActionData) => {
              this.functionModal.permission = permission;
              this.functionModal.danger = danger;
              this.functionModal.sql = sql;
              this.functionModal.actionData = genActionData;
            };
            data.other.options = {
              ...this.functionModal.options,
              sql: this.monacoEditor.getValue()
            };
            data.other.targetType = 'FUNC';
            await this.browseGenAction(data.actionType, this.browseGenLevelsData(data.node), data.callback, data.other);
          }
          break;
        default:
          break;
      }
    },
    handlePreCreateTrigger() {
      this.triggerModal.sql = '';
      this.viewModal.sql = '';
      this.procedureModal.sql = '';
      this.functionModal.sql = '';
    },
    async handleDoAction() {
      const callback = () => {
        switch (this.actionType) {
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_DROP:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_DROP:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_DROP:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_FUNCTION_DROP:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PROCEDURE_DROP:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TABLE_RENAME:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_ALTER:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_ALTER:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_FUNCTION_ALTER:
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PROCEDURE_ALTER:
            this.listLeaf();
            break;
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_CREATE:
            this.handleChangeTab('TRIGGER');
            break;
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_CREATE:
            this.handleChangeTab('VIEW');
            break;
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_FUNCTION_CREATE:
            this.handleChangeTab('FUNC');
            break;
          case TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PROCEDURE_CREATE:
            this.handleChangeTab('PROC');
            break;
          default:
            break;
        }
        this.handleCloseModal();
        this.doActionLoading = false;
        this.$message.success(this.$t('cao-zuo-cheng-gong'));
      };

      let actionData = this.menuModal.actionData;
      if ([TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_CREATE, TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_TRIGGER_ALTER].includes(this.actionType)) {
        actionData = this.triggerModal.actionData;
      }
      if ([TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_CREATE, TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_VIEW_ALTER].includes(this.actionType)) {
        actionData = this.viewModal.actionData;
      }
      if ([TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_FUNCTION_CREATE, TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_FUNCTION_ALTER].includes(this.actionType)) {
        actionData = this.functionModal.actionData;
      }
      if ([TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PROCEDURE_CREATE, TABLE_RIGHT_CLICK_MENU_ITEM.MENU_BROWSE_PROCEDURE_ALTER].includes(this.actionType)) {
        actionData = this.procedureModal.actionData;
      }
      const callbackFail = () => {
        this.doActionLoading = false;
      };
      this.doActionLoading = true;
      await this.browseDoAction(actionData, callback, callbackFail);
    }
  }
};
</script>

<style scoped lang="less">
.table-list-resize {
  height: 100%;
  width: 6px;
  background: rgba(0, 0, 0, 0);
  //background: red;
  position: absolute;
  right: -3px;
  cursor: col-resize;
  z-index: 9;
}

/deep/ .table-leaf-type-select .ant-select-selection {
  border-top: none;
  border-left: none;
  border-right: none;
}

/deep/ .ctree-tree-node__title {
  padding-left: 0;
  margin-left: 0;
}

/deep/ .node {
  display: flex;
  align-items: center;
}

/deep/ .no-indent {
  .ctree-tree-node__square {
    display: none;
  }

  .node {
    padding-left: 5px;
  }
}

/deep/ .ant-collapse-header {
  padding: 5px 10px 5px 34px !important;
}

/deep/ .ant-collapse-item {
  border: none;
}

/deep/ .ant-collapse-content-box {
  padding: 8px 10px;
}

/deep/ .highlight {
  background: orange !important;
  border-radius: 2px;
}

.generate-modal {
  height: 500px;
  display: flex;
  flex-direction: column;

  .advanced {
    margin-bottom: 10px;

    .title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .options {
      display: flex;

      .option {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }
  }

  .content {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .table-setting {
      display: flex;
      width: 100%;
      flex: 1;
      min-height: 0;
      border: 1px solid #ccc;

      .left {
        width: 300px;
        //min-height: 400px;
        //max-height: 400px;
        border-right: 1px solid #ccc;
        display: flex;
        flex-direction: column;

        .search {
          /deep/ .ant-input {
            border: none;
            border-bottom: 1px solid #ccc;
          }
        }

        .datasource-tree {
          flex: 1;
          min-height: 0;
        }
      }

      .right {
        flex: 1;
        overflow: auto;
        padding: 10px;
        min-height: 200px;
        max-height: 400px;

        .select-title {
          margin-bottom: 16px;
        }

        .column-config-table {
          margin-top: 16px;
        }
      }
    }
  }

  .preview {
    display: flex;
    height: 100%;

    .left {
      width: 300px;
      border: 1px solid #ccc;
      border-right: none;
      overflow: scroll;
    }

    .right {
      flex: 1;
      min-width: 0;
      border: 1px solid #ccc;
      position: relative;

      .tips {
        position: absolute;
        bottom: 0;
        left: 5px;
        color: red;
      }
    }
  }

  .result {
    display: flex;
    flex-direction: column;
    height: 100%;

    .refresh-container {
      position: absolute;
      right: 0;
      top: 0;
      .result-refresh-btn {
        margin-left: 8px;
      }
    }

    .header {
      margin-bottom: 5px;
      position: relative;

      .status {
        line-height: 22px;
      }

      .info-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .info {
        flex: 1;
        margin-bottom: 5px;
        margin-right: 80px;

        .line {
          display: flex;

          div {
            flex: 1;
            line-height: 22px;
          }
        }
      }
    }

    .logs {
      flex: 1;
      min-height: 0;
      border: 1px solid #ccc;
      padding: 3px;

      #log-list {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;

        .log {
          display: flex;
          width: 100%;
        }
      }
    }
  }
}

.trigger-modal,
.view-modal,
.procedure-modal,
.function-modal {
  /deep/ .ivu-form-item {
    margin-bottom: 10px;
  }

  .advanced-setting {
    marigin-left: 10px;
  }

  .param-container {
    border: 1px solid #ddd;
    max-height: 150px;
    min-height: 150px;
    display: flex;

    .left {
      width: 200px;
      border-right: 1px solid #ddd;
      display: flex;
      flex-direction: column;

      .op {
        display: flex;
        border-bottom: 1px solid #ddd;
      }

      .add,
      .remove {
        flex: 1;
        min-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.add {
          border-right: 1px solid #ddd;
        }
      }

      .list {
        flex: 1;
        overflow: auto;

        .param {
          height: 20px;
          line-height: 20px;
          padding: 0 10px;
          border-bottom: 1px solid #ddd;

          &.active {
            background: #ccc;
          }
        }
      }
    }

    .right {
      flex: 1;
      overflow: auto;
    }
  }

  padding-right: 10px;
  max-height: 500px;
  overflow: auto;
}

.trigger-monaco-editor,
.view-monaco-editor,
.procedure-monaco-editor,
.function-monaco-editor {
  height: 250px;
  width: 100%;
  border: 1px solid #ccc;
}

/deep/ .ivu-collapse {
  background: #fff;
}

/deep/ .ivu-collapse-header {
  background: #f7f7f7;
}

/deep/ .ivu-collapse-content {
  padding: 10px;
}
</style>
