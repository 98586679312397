<template>
  <div class="user-center" style="padding: 16px;">
    <Breadcrumb style="margin-bottom: 14px;">
      <BreadcrumbItem>{{ $t('zhang-hu-xin-xi') }}</BreadcrumbItem>
    </Breadcrumb>
    <div>
      <div class="user-center-wrapper" style="padding-top: 0">
        <p><span class="user-center-label">{{ $t('you-xiang') }}</span><span class="user-center-value">{{ userInfo.email }}</span>
          <pd-button size="small" @click="handleUpdateUserInfo('email')">{{ $t('geng-huan') }}</pd-button>
        </p>
        <p v-if="isAsia"><span class="user-center-label">{{ $t('shou-ji-hao') }}</span><span class="user-center-value">{{ userInfo.phone }}</span>
          <pd-button size="small" @click="handleUpdateUserInfo('phone')">{{ $t('geng-huan') }}</pd-button>
        </p>
        <p><span class="user-center-label">{{ $t('deng-lu-mi-ma') }}</span><span class="user-center-value">*******</span>
          <pd-button size="small" @click="handleShowPassword">{{ $t('geng-huan') }}</pd-button>
        </p>
        <p v-if="userInfo.accountType === 'SUB_ACCOUNT'"><span class="user-center-label">{{ $t('zhang-hao') }}</span><span>{{ userInfo.loginAccount }}</span></p>
        <p v-if="userInfo.accountType === 'SUB_ACCOUNT'"><span class="user-center-label">{{ $t('suo-shu-zhu-zhang-hao') }}</span><span>{{ userInfo.pusername }}({{userInfo.pemail}})</span></p>
        <p><span class="user-center-label">{{ $t('gong-si-ming-cheng') }}</span><span>{{ userInfo.organization }}</span></p>
      </div>
    </div>
    <verify-code-modal
      :visible="showEditUserInfo"
      :title="$t('xiu-gai-updateuserinfotypephone-shou-ji-hao-you-xiang', [updateUserInfoType===`phone`?$t('shou-ji-hao'):$t('you-xiang')])"
      :verify-code-type="updateUserInfoType==='phone'?isVerifyPhone?'VERIFY_OLD_ACCOUNT':'UPDATE_USER_PHONE':isVerifyEmail?'VERIFY_OLD_ACCOUNT':'UPDATE_USER_EMAIL'"
      :handle-close-modal="handleCancelEdit"
      :handle-confirm-callback="handleConfirmUpdateUserInfo"
      :has-next-step="isVerifyPhone || isVerifyEmail"
      :new-phone="newPhone"
      :phone-number="updateUserInfo.phone"
      :email="updateUserInfo.email"
      :new-email="newEmail"
      ref="clear-position-modal"
      :width="580"
    >
      <div slot="content">
        <Form label-position="right" :label-width="60">
          <FormItem :label="$t('shou-ji-hao-0')" v-if="updateUserInfoType==='phone' && !isVerifyPhone">
            <Input v-model="updateUserInfo.phone"/>
          </FormItem>
          <FormItem :label="updateUserInfoType===`phone`?$t('yuan-shou-ji-hao'):$t('shou-ji-hao-0')" prop="phone" v-if="(isVerifyPhone || updateUserInfoType!=='phone') && isAsia">
            <Input v-model="userInfo.phone" disabled/>
          </FormItem>
          <FormItem  v-if="(isVerifyEmail || updateUserInfoType==='email') && !isAsia && isVerifyEmail" :label="$t('xiu-gai-you-xiang')">
            <Input v-model="userInfo.email" disabled/>
          </FormItem>
          <FormItem :label="$t('you-xiang-0')" v-if="updateUserInfoType==='email' && !isVerifyEmail">
            <Input v-model="updateUserInfo.email"/>
          </FormItem>
        </Form>
      </div>
    </verify-code-modal>
    <verify-code-modal
      :visible="showFetchAKSK"
      :title="$t('huo-qu-aksk')"
      verify-code-type="FETCH_USER_AK_SK"
      :handle-close-modal="handleCancelEdit"
      :handle-confirm-callback="handleConfirmFetchAKSK"
      ref="clear-position-modal"
      :width="580"
    >
      <div slot="content">
        <h3 style="margin-bottom: 20px">{{ $t('wei-bao-zheng-an-quan-xing-qing-shu-ru-yan-zheng-ma-lai-huo-qu-aksk') }}</h3>
      </div>
    </verify-code-modal>
    <password-confirm-modal
      :visible="showEditPassword"
      :title="$t('chong-zhi-mi-ma')"
      :handle-close-modal="handleCancelEdit"
      :handle-confirm-callback="handleConfirmEditPassword"
      ref="clear-position-modal"
      :width="420"
      :err-msg="errMsg"
    >
      <FormItem slot="content" :label="$t('xin-mi-ma')">
        <Poptip trigger="focus" placement="right-start">
          <Input  style="width: 308px" v-model="newPassword" type="password" password :placeholder="$t('qing-shu-ru-xin-mi-ma')"></Input>
          <div slot="content">
            <p>{{passwordRule.tips}}</p>
          </div>
        </Poptip>
      </FormItem>
    </password-confirm-modal>
    <password-confirm-modal
      :visible="showEditEmail"
      :title="$t('xiu-gai-you-xiang')"
      :handle-close-modal="handleCancelEdit"
      :handle-confirm-callback="handleConfirmEditEmail"
      ref="clear-position-modal"
      :width="420"
      :err-msg="errMsg"
      :label="$t('mi-ma')"
      :placeholder="$t('xu-yan-zheng-cao-zuo-ren-shen-fen')"
    >
      <FormItem slot="content" :label="$t('xin-you-xiang')">
        <Input  style="width: 308px" v-model="newEmailToReset" :placeholder="$t('qing-shu-ru-xin-you-xiang')"></Input>
      </FormItem>
    </password-confirm-modal>
    <password-confirm-modal
      :visible="showEditPhone"
      :title="$t('xiu-gai-shou-ji-hao')"
      :handle-close-modal="handleCancelEdit"
      :handle-confirm-callback="handleConfirmEditPhone"
      ref="clear-position-modal"
      :width="420"
      :err-msg="errMsg"
      :label="$t('mi-ma')"
      :placeholder="$t('xu-yan-zheng-cao-zuo-ren-shen-fen')"
    >
      <FormItem slot="content" :label="$t('xin-shou-ji-hao')">
        <Input  style="width: 308px" v-model="newPhoneToReset" :placeholder="$t('qing-shu-ru-xin-shou-ji-hao')"></Input>
      </FormItem>
    </password-confirm-modal>
    <Modal
      v-model="showAKSK"
      title="AK/SK"
      width="600px"
      footer-hide
    >
      <div>
        <h3 style="margin-bottom: 20px">{{ $t('wei-bao-zheng-nin-de-zhang-hao-an-quan-qing-wu-bi-bao-guan-hao-nin-de-aksk') }}</h3>
        <Form label-position="right" :label-width="80">
          <FormItem label="accessKey：">
            <p>{{akskInfo.accessKey}}</p>
          </FormItem>
          <FormItem label="secretKey：">
            <p>{{akskInfo.secretKey}}</p>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCopy(JSON.stringify(akskInfo))">{{ $t('fu-zhi') }}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('guan-bi') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { sendCodeInLoginState } from '@/services/cc/api/rdpVerify';
import {
  getCurrUserConfigs, updateUserPhone, updateUserEmail, checkVerifyCode, queryUserAkSk, getSubAccountPwdValidateExpr, getPrimaryAccountPwdValidateExpr
} from '@/services/cc/api/rdpUser';
import { logout } from '@/services/cc/api/auth';
import {
  listAllConfigs,
  updateDingDingConfigs
} from '@/services/cc/api/systemConfig';
import { verifyIm, verifyMail } from '@/services/cc/api/rdpAlert';
import fecha from 'fecha';
import { applyStsToken, invalidStsToken } from '@/services/cc/api/stToken';
import { formatHour } from '@/components/util';
import VerifyCodeModal from '@/components/modal/VerifyCodeModal';
import PasswordConfirmModal from '@/components/modal/PasswordConfirmModal';
import { mapGetters, mapState } from 'vuex';
import { encryptMixin } from '@/mixins/encryptMixin';
import Mapping from '../util';

export default {
  components: { VerifyCodeModal, PasswordConfirmModal },
  mixins: [encryptMixin],
  data() {
    return {
      updateUserInfoType: '',
      loading: false,
      editEmail: false,
      isVerifyPhone: false,
      isVerifyEmail: false,
      newPhone: false,
      newEmail: false,
      newEmailToReset: '',
      newPhoneToReset: '',
      showEditUserInfo: false,
      showFetchAKSK: false,
      showAKSK: false,
      akskInfo: {},
      newPassword: '',
      passwordRule: {
        expr: '',
        tips: ''
      },
      errMsg: '',
      formatHour,
      updateUserInfo: {
        phone: '',
        email: ''
      },
      resourceData: {
        fullCheckCount: 0,
        fullTransferCount: 0,
        incrementDuration: 0,
        structTransferCount: 0
      },
      quotaData: {
        workerCount: 0,
        workerCountUsed: 0,
        dataJobCount: 0,
        dataSourceCount: 0,
        dataJobCountUsed: 0,
        dataSourceCountUsed: 0
      },
      applyCode: '',
      ifEdit: true,
      showTest: false,
      connection: false,
      showEditPassword: false,
      showEditEmail: false,
      showEditPhone: false,
      showSmtp: false,
      pwLength: false,
      pwContain: false,
      pwFormat: false,
      pwConfirm: false,
      verifyCode: '',
      password: '',
      passwordAgain: '',
      sendcodeDisabled: true,
      sendCodeAgainTime: 60,
      systemForm: {
        EMAIL_HOST_KEY: '',
        EMAIL_PORT_KEY: '465',
        EMAIL_USERNAME_KEY: '',
        EMAIL_PASSWORD_KEY: '',
        DINGDING_URL_TOKEN_KEY: '',
        EMAIL_FROM_KEY: ''
      },
      alarmSetting: {},
      setList: [],
      licenseUrl: {},
      aliyunAk: '',
      aliyunSk: '',
      emailList: [],
      emailSuffix: ['qq.com', 'sina.com', '163.com', 'sohu.com', '126.com'],
      smtpList: {
        'qq.com': 'smtp.qq.com',
        'sina.com': 'smtp.sina.com.cn',
        '163.com': 'smtp.163.com',
        'sohu.com': 'smtp.sohu.com',
        '126.com': 'smtp.126.com'
      },
      smtpPort: {
        'qq.com': '465',
        'sina.com': '25',
        '163.com': '465',
        'sohu.com': '110',
        '126.com': '25'
      },
      configKeyMap: {
        EMAIL_HOST_KEY: 'spring.mail.host',
        EMAIL_PORT_KEY: 'spring.mail.port',
        EMAIL_USERNAME_KEY: 'spring.mail.username',
        EMAIL_PASSWORD_KEY: 'spring.mail.password',
        EMAIL_FROM_KEY: 'spring.mail.properties.from',
        DINGDING_URL_TOKEN_KEY: 'console.config.alert.dingtalk.alerturl'
      },
      editPasswordRule: {
        password: [
          { required: true, message: 'The name cannot be empty', trigger: 'blur' }
        ],
        passwordAgain: [
          { required: true, message: 'The name cannot be empty', trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: 'The verifyCode cannot be empty', trigger: 'blur' }
        ]
      },
      setMetaColumn: [
        {
          title: this.$t('tao-can-ming-cheng'),
          key: 'licenseSetMeta'
        },
        {
          title: this.$t('nei-rong'),
          slot: 'licenseContent'
        },
        {
          title: this.$t('mu-lu-jia-ge'),
          width: 120,
          render: (h, params) => h('div', {}, this.$t('thisgetlicensepriceparamsrowlicensemetas-yuan', [this.getLicensePrice(params.row.licenseMetas)]))
        }
      ],
      guotaColumn: [
        {
          title: this.$t('xian-zhi-xiang-mu'),
          key: 'description',
          minWidth: 160
        },
        {
          title: this.$t('yi-yong-shu-liang'),
          key: 'used',
          minWidth: 80
        },
        {
          title: this.$t('zong-shu'),
          key: 'quota',
          minWidth: 80
        }
      ],
      userConfigList: [],
      userConfigs: {}
    };
  },
  created() {
    if (this.userInfo.accountType === 'PRIMARY_ACCOUNT') {
      getPrimaryAccountPwdValidateExpr().then((res) => {
        if (res.data.success) {
          console.log('res', res);
          this.passwordRule.tips = res.data.data.tips;
          this.passwordRule.expr = res.data.data.expr;
        }
      });
    } else {
      getSubAccountPwdValidateExpr().then((res) => {
        if (res.data.success) {
          console.log('res', res);
          this.passwordRule.tips = res.data.data.tips;
          this.passwordRule.expr = res.data.data.expr;
        }
      });
    }
  },
  mounted() {
    // this.listAllConfigs();
    console.log('test', this.userInfo);
    let subAccountPwdVerifyTips = '';
    let subAccountPwdVerifyExpr = '';
    this.userInfo.userConfig.forEach((config) => {
      if (config.configName === 'subAccountPwdVerifyTips') {
        subAccountPwdVerifyTips = config.configValue;
      } else if (config.configName === 'subAccountPwdVerifyExpr') {
        subAccountPwdVerifyExpr = config.configValue;
      }
    });
    if (subAccountPwdVerifyExpr && subAccountPwdVerifyTips) {
      this.passwordRule.tips = subAccountPwdVerifyTips;
      this.passwordRule.expr = subAccountPwdVerifyExpr;
    }
  },
  computed: {
    ...mapGetters(['isAsia', 'verifyType']),
    ...mapState(['userInfo', 'globalSetting']),
    getCreateTime() {
      if (this.userInfo.gmtCreate) {
        return fecha.format(new Date(this.userInfo.gmtCreate), 'YYYY-MM-DD HH:mm:ss');
      }
      return '';
    },
    getUpdateTime() {
      if (this.userInfo.gmtModified) {
        return fecha.format(new Date(this.userInfo.gmtModified), 'YYYY-MM-DD HH:mm:ss');
      }
      return '';
    }
  },
  methods: {
    async handleSubmitUserConfig() {
      await this.$refs.userConfigParamsEdit.showUserConfigModal();
    },
    async handleRefreshFollowedList() {
      await this.$refs.followedJobList.getFollowedJobs();
    },
    async getUserConfig() {
      const res = await getCurrUserConfigs();
      if (res.data.success) {
        this.userConfigList = res.data.data;
        this.userConfigList.forEach((item) => {
          this.userConfigs[item.configName] = item.configValue;
        });
      }
    },
    handleSearch() {
      this.getUserInfo();
    },
    getUserInfo() {

    },
    listAllConfigs() {
      this.loading = true;
      listAllConfigs().then((res) => {
        this.loading = false;
        if (res.data.code === '1') {
          this.alarmSetting.emailAddress = res.data.data.emailAddress;
          this.alarmSetting.phoneNumber = res.data.data.phoneNumber;
          res.data.data.systemConfigVOList.map((item) => {
            if (item.configName === 'spring.mail.host') {
              this.systemForm.EMAIL_HOST_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.port') {
              this.systemForm.EMAIL_PORT_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.username') {
              this.systemForm.EMAIL_USERNAME_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.password') {
              // this.systemForm.EMAIL_PASSWORD_KEY = item.configValue;
            }
            if (item.configName === 'console.config.alert.dingtalk.alerturl') {
              this.systemForm.DINGDING_URL_TOKEN_KEY = item.configValue;
            }
            if (item.configName === 'spring.mail.properties.from') {
              this.systemForm.EMAIL_FROM_KEY = item.configValue;
            }
            if (this.systemForm.EMAIL_FROM_KEY) {
              this.systemForm.EMAIL_USERNAME_KEY = this.systemForm.EMAIL_FROM_KEY;
            } else if (this.systemForm.EMAIL_USERNAME_KEY) {
              this.systemForm.EMAIL_FROM_KEY = this.systemForm.EMAIL_USERNAME_KEY;
            }
            return null;
          });
          this.handleShowStmp();
        }
      });
    },
    handleShowPassword() {
      if (this.globalSetting.deployMode === 'CLOUD_PRIVATE') {
        this.showEditPassword = true;
      } else {
        this.$router.push({ path: '/reset' });
      }
      // window.location.reload();
    },
    handleUpdateUserInfo(type) {
      if (this.globalSetting.deployMode === 'CLOUD_PRIVATE') {
        if (type === 'email') {
          this.showEditEmail = true;
        } else if (type === 'phone') {
          this.showEditPhone = true;
        }
      } else {
        this.updateUserInfoType = type;
        this.showEditUserInfo = true;
        this.updateUserInfo.phone = this.userInfo.phone;
        this.updateUserInfo.email = this.userInfo.email;
        if (type === 'phone') {
          this.isVerifyPhone = true;
        }
        if (type === 'email' && !this.isAsia) {
          this.isVerifyEmail = true;
        }
      }
    },
    handleCancelEdit() {
      this.verifyCode = '';
      this.password = '';
      this.passwordAgain = '';
      this.newPassword = '';
      // this.ifEdit = false;
      this.newEmailToReset = '';
      this.newPhoneToReset = '';
      this.errMsg = '';
      this.showEditPassword = false;
      this.editEmail = false;
      this.showEditUserInfo = false;
      this.showFetchAKSK = false;
      this.showAKSK = false;
      this.isVerifyPhone = false;
      this.isVerifyEmail = false;
      this.showEditEmail = false;
      this.showEditPhone = false;
    },
    handleShowFetchAKSK() {
      this.showFetchAKSK = true;
    },
    handleConfirmFetchAKSK(verifyCode) {
      queryUserAkSk({
        verifyCode,
        verifyType: this.verifyType
      }).then((res) => {
        if (res.data.code === '1') {
          this.showFetchAKSK = false;
          this.akskInfo = res.data.data;
          this.showAKSK = true;
        }
      });
    },
    handleConfirmEditPassword(originPassword) {
      const regExp = new RegExp(this.passwordRule.expr);
      if (regExp.test(this.newPassword)) {
        this.$services.resetPwdWithOriginPwd({
          data: {
            originPassword: this.passwordEncrypt(originPassword),
            newPassword: this.passwordEncrypt(this.newPassword)
          },
          modal: false
        }).then((res) => {
          if (res.success) {
            this.showEditPassword = false;
            this.newPassword = '';
            this.$router.push({ path: '/login' });
          } else {
            this.errMsg = res.msg;
          }
        });
      } else {
        this.errMsg = this.passwordRule.tips;
      }
    },
    handleConfirmEditEmail(originPassword) {
      this.$services.updateUserEmailWithPwd({
        data: {
          password: this.passwordEncrypt(originPassword),
          email: this.newEmailToReset
        },
        modal: false
      }).then((res) => {
        if (res.success) {
          this.showEditEmail = false;
          this.newEmailToReset = '';
          this.getUserInfo();
        } else {
          this.errMsg = res.msg;
        }
      });
    },
    handleConfirmEditPhone(originPassword) {
      this.$services.updateUserPhoneWithPwd({
        data: {
          password: this.passwordEncrypt(originPassword),
          phone: this.newPhoneToReset
        },
        modal: false
      }).then((res) => {
        if (res.success) {
          this.showEditPhone = false;
          this.newPhoneToReset = '';
          this.getUserInfo();
        } else {
          this.errMsg = res.msg;
        }
      });
    },
    handleConfirmUpdateUserInfo(verifyCode) {
      if (this.isVerifyPhone || this.isVerifyEmail) {
        const data = {
          verifyCode,
          verifyType: this.verifyType
        };
        checkVerifyCode(data).then((res) => {
          if (res.data.code === '1') {
            this.isVerifyPhone = false;
            this.isVerifyEmail = false;
            this.userInfo.verifyCode = '';
            // this.$refs.verifyCount.counting = false;
            this.newPhone = true;
            this.newEmail = true;
          }
        });
      } else {
        const postFunc = this.updateUserInfoType === 'phone' ? updateUserPhone : updateUserEmail;
        postFunc({
          phone: this.updateUserInfo.phone,
          email: this.updateUserInfo.email,
          verifyCode,
          phoneAreaCode: 'CHINA',
          verifyType: this.verifyType
        }).then((res) => {
          if (res.data.code === '1') {
            this.$Message.success(this.$t('xiu-gai-cheng-gong'));
            this.showEditUserInfo = false;
            setTimeout(() => {
              this.getUserInfo();
            }, 500);
          }
        });
      }
    },
    handleVerify() {
      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      const that = this;

      this.sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(that.sendCodeAgain);
          that.sendcodeDisabled = true;
        }
      }, 1000);

      sendCodeInLoginState({
        verifyType: 'SMS_VERIFY_CODE',
        verifyCodeType: 'RESET_PASSWORD'
      }).then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('fa-song-cheng-gong'));
        } else {
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(this.sendCodeAgain);
          this.$Modal.error({
            title: 'ERROR',
            content: `${res.data.msg}`
          });
        }
      }).catch((res) => {
        this.sendcodeDisabled = true;
        this.sendCodeAgainTime = 60;
        clearInterval(this.sendCodeAgain);
        this.$Modal.error({
          title: 'ERROR',
          content: `${res.data.msg}`
        });
      });
    },
    handleCheckPasswordAgain() {
      this.pwConfirm = Boolean(this.password && this.password === this.passwordAgain);
    },
    updateDingDingConfigs() {
      const list = [];

      for (const key in this.systemForm) {
        if (key !== 'EMAIL_PASSWORD_KEY') {
          list.push({
            configName: this.configKeyMap[key],
            configValue: this.systemForm[key]
          });
        } else if (this.systemForm[key]) {
          list.push({
            configName: this.configKeyMap[key],
            configValue: this.systemForm[key]
          });
        }
      }
      list.push({
        configName: this.configKeyMap.EMAIL_FROM_KEY,
        configValue: this.systemForm.EMAIL_USERNAME_KEY
      });
      updateDingDingConfigs(list).then((res) => {
        if (res.data.code === '1') {
          this.listAllConfigs();
          this.$Message.success(this.$t('xiu-gai-cheng-gong'));
          this.editEmail = false;
        }
        // this.ifEdit = false;
      });
    },
    handleFillEmail(value) {
      this.emailList = [];
      if (value.indexOf('@') < 0) {
        this.emailSuffix.map((item) => {
          this.emailList.push(`${value}@${item}`);
          return null;
        });
      }
      this.handleShowStmp();
    },
    handleShowStmp() {
      if (this.systemForm.EMAIL_USERNAME_KEY) {
        const list = this.systemForm.EMAIL_USERNAME_KEY.split('@');

        if (list.length > 1) {
          if (this.emailSuffix.indexOf(list[1]) < 0) {
            this.showSmtp = true;
          } else {
            this.showSmtp = false;
            this.systemForm.EMAIL_HOST_KEY = this.smtpList[list[1]];
            this.systemForm.EMAIL_PORT_KEY = this.smtpPort[list[1]];
          }
        } else {
          this.showSmtp = false;
        }
      } else {
        this.showSmtp = false;
      }
    },
    handleVerifyEmail() {
      verifyMail().then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('yan-zheng-you-xiang-fu-wu-qi-cheng-gong'));
        }
      });
    },
    handleVerifyIm() {
      verifyIm().then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('yan-zheng-im-gao-jing-cheng-gong'));
        }
      });
    },
    handleShowEdit() {
      this.ifEdit = true;
    },
    handleApplyStToken() {
      invalidStsToken().then((res) => {
        if (res.data.code === '1') {
          applyStsToken({
            userAk: this.aliyunAk,
            userSk: this.aliyunSk
          }).then((res1) => {
            if (res1.data.code === '1') {
              this.$Message.success(this.$t('cao-zuo-cheng-gong'));
              this.aliyunAk = '';
              this.aliyunSk = '';
            }
          });
        }
      });
    },
    handleCleanStToken() {
      invalidStsToken().then((res) => {
        if (res.data.code === '1') {
          this.$Message.success(this.$t('cao-zuo-cheng-gong'));
        }
      });
    },
    handleCheckPassword() {
      this.pwLength = this.password.length >= 8 && this.password.length <= 32;
      this.pwContain = this.password.indexOf(this.userInfo.phone) === -1;

      const pattern = /(?=.*[0-9])(?=.*[a-zA-Z])/;

      this.pwFormat = pattern.test(this.password);
    },
    handleGoLicenseSet(url) {
      window.open(url);
    },
    getLicenseType(key) {
      const value = key.substring(14, key.length - 2);
      const list = value.split(', ');
      const map = {};

      list.map((item) => {
        const kv = item.split('=');

        map[kv[0]] = kv[1];
        return null;
      });
      return Mapping.licenseTypeDefault[map.licenseType];
    },
    getLicenseCount(key) {
      const value = key.substring(14, key.length - 2);
      const list = value.split(', ');
      const map = {};

      list.map((item) => {
        const kv = item.split('=');

        map[kv[0]] = kv[1];
        return null;
      });
      if (map.licenseType === 'INCREMENT_SYNC_DURATION') {
        return this.$t('paramsrowamount-24-tian', [map.amount / 24]);
      }
      return this.$t('mapamount-ci', [map.amount]);
    },
    getLicensePrice(data) {
      let totalPrice = 0;

      Object.keys(data).map((key) => {
        const value = key.substring(14, key.length - 2);
        const list = value.split(', ');
        const map = {};

        list.map((item) => {
          const kv = item.split('=');

          map[kv[0]] = kv[1];
          return null;
        });
        totalPrice = map.price * data[key];
        return null;
      });
      return totalPrice;
    },
    handleEditEmail() {
      this.editEmail = true;
    },
    handleCopy(value) {
      const aux = document.createElement('input');

      aux.setAttribute('value', value);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);

      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    }
  }
};
</script>
<style lang="less">
.user-center-title-container {
  font-size: 12px;
  padding-left: 8px;
  color: #333333;
  font-family: PingFangSC-Semibold,serif;
  font-weight: 500;
  border-left: 3px solid #535C70;
  margin-bottom: 6px;
}

.user-center-wrapper {
  margin-top: 16px;
  padding: 20px 40px;
  background-color: #ffffff;
  border: 1px solid #DADADA;

  & > p {
    line-height: 36px;
    font-size: 16px;
    padding: 12px 0;
    border-bottom: 1px solid #DADADA;

    .user-center-label {
      font-family: PingFangSC-Semibold,serif;
      font-weight: 500;
      display: inline-block;
      width: 104px;
    }

    .user-center-value {
      margin-right: 10px;
      display: inline-block;
    }
  }

  .ivu-table td, .ivu-table th {
    height: 40px;
  }
}

.user-center-wrapper-sp {
  background-color: #ECECEC;
  margin-bottom: 20px;

  .ivu-input {
    height: 40px;
    line-height: 40px;
  }

  .user-center-wrapper-sp-btn {
    background-color: #FFA30E;
    color: #ffffff;
    margin-top: 16px;
    background-image: none;
    border: none;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Semibold,serif;
    font-weight: 500;

    &:hover {
      background-color: #FFA30E !important;
      background-image: none;
    }
  }
}

/*.ivu-form-item:last-child{*/
/*    margin-bottom: 0;*/
/*}*/
.system-setting-title {
  font-family: PingFangSC-Semibold,serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.user-center {
  position: relative;
  padding: 16px;

  .ivu-tabs-nav .ivu-tabs-tab-active {
    color: #0BB9F8;
    font-family: PingFangSC-Semibold,serif;
  }

  .ivu-table th {
    background-color: #F5F5F5;
  }
}

.password-check {
  li {
    font-size: 12px;
    line-height: 25px;
    color: #808695;

    i {
      margin-right: 5px;
      font-size: 14px;
    }

    .ivu-icon-ios-close-circle-outline {
      color: #ed4014;
    }

    .ivu-icon-ios-checkmark-circle-outline {
      color: #19be6b;
    }
  }
}

.resource-basic {
  height: 132px;
  width: 100%;
  /*padding: 20px 60px;*/
  padding-top: 32px;
  /*border-radius: 4px;*/
  background-color: #ffffff;
  position: relative;
  box-shadow: 1px 1px 5px 0 rgba(197, 197, 197, 0.5);

  .resource-content {
    width: 200px;
    margin: 0 auto;
    /*padding-left: 96px;*/

    .license-set-url {
      cursor: pointer;
      font-family: PingFangSC-Medium,serif;
      font-weight: 500;
      margin-bottom: 30px;

      &:hover {
        color: #0BB9F8;
      }

      &:active {
        color: #0087C7;
      }
    }
  }

  .resource-basic-title {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .resource-basic-count {
    font-size: 24px;
    font-family: PingFangSC-Semibold,serif;
    font-weight: 500;
    /*margin-bottom: 16px;*/
  }

  .resource-logo {
    /*position: absolute;*/
    font-size: 32px;
    /*left: 20px;*/
    /*top:37px;*/
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
    margin-right: 16px;

    .iconfont {
      font-size: 32px;
    }

    .iconxingzhuang {
      color: #FFAC25;
    }

    .iconquanliangqianyisvg {
      color: #66A2FF;
    }

    .iconqianyi {
      color: #67CD51;
    }

    .iconjiaoyan {
      color: #838AFF;
    }
  }
}

.resource-suggest {
  background-color: #fff2de;
  height: 580px;
  overflow: hidden;

  .iconfont {
    color: #F1DAB6;
    font-size: 180px;
    right: -50px;
    bottom: -40px;
  }
}

.quota-basic {
  height: 125px;
  background-color: #DEF3FC;
  border-radius: 4px;
  padding: 20px 26px;

  .quota-basic-title {
    font-size: 16px;
    margin-bottom: 7px;
  }

  .quota-use-count {
    font-size: 36px;
    font-family: PingFangSC-Semibold,serif;
    margin-right: 10px;
  }

  .quota-total-count {
    font-size: 24px;
    font-family: PingFangSC-Semibold,serif;
    margin-top: 20px;
  }
}

.user-account-title {
  font-size: 16px;
  font-family: PingFangSC-Semibold,serif;
  margin-bottom: 10px;
  border: 1px solid #DADADA;
  background-color: #EEEEEE;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-quota-count {
  font-family: PingFangSC-Medium,serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
}

.user-quota-title {
  color: #888888;
  text-align: center;
}

.license-set-detail-item {
  height: 40px;
  line-height: 40px;

  span {
    width: 100px;
    display: inline-block;
  }
}
</style>
