<template>
  <div class="job-detail-state-container">
    <div>
      <div class="job-detail-state-progress">
        <p class="job-detail-state-progress-title"><span
          class="status-point"></span>{{ $t('zeng-liang-tong-bu-1') }}
          <a @click="handleShowLog(task.dataTaskId)">{{ $t('cha-kan-ri-zhi') }}</a>
        </p>
        <div class="job-detail-state-count">
          <div class="circle-background"></div>
          <i-circle :stroke-width="5" :stroke-color="getCurrentStateColor('INCREMENT')" :percent="0"
                    :size="200">
            <div class="incre-inner"
                 :style="`font-size:32px;background-color:${getStepColor};box-shadow: 0 0 10px 0 ${getStepColor};`">
              {{ MappingUtil.taskStatus[taskList['INCREMENT'].dataTaskStatus] }}
            </div>
          </i-circle>
        </div>
      </div>
      <div class="job-detail-state-detail">
        <p class="job-detail-state-progress-title"><span
          class="status-point"></span>{{ $t('dang-qian-wei-dian') }}</p>
        <div class="job-detail-position">
          <div v-if="posContent">
            <div v-for="(pos, index) in posContent" :key="index">
              <div v-if="pos.type === 'LABEL'">
                <div>
                  <span class="basic-info-label">{{ pos.label }}:</span>
                  <span class="basic-info-value">
                  {{ pos.value }}
                </span>
                </div>
              </div>
              <div v-if="pos.type === 'FORM'" style="margin-top: 20px">
                <pd-button ghost type="primary" size="small"
                           @click="handleShowPosForm(pos)">{{ pos.label }}
                </pd-button>
              </div>
            </div>
          </div>
          <div v-if="!posContent">
            <div v-for="conf in posConf" :key="conf.key">
              <div v-if="conf.key === 'positionTimestamp'">
                <span class="basic-info-label">{{ conf.desc }}:</span>
                <span class="basic-info-value">
                            {{
                    fecha.format(new Date(taskPosition[conf.key]), 'YYYY-MM-DD HH:mm:ss')
                  }}({{ taskPosition[conf.key] }})
                          </span>
              </div>
              <div v-else>
                <span class="basic-info-label">{{ conf.desc }}:</span>
                <span class="basic-info-value">
                            {{ taskPosition[conf.key] }}
                          </span>
              </div>
            </div>
            <div v-if="taskList['INCREMENT'].dataTaskStatus!=='INIT'">{{$t('ju-li-shang-ci-geng-xin') }}<span
              class="basic-info-value" :style="getDelayColor(taskPosition.delayMillis)">
                                  {{ formatSeconds(taskPosition.delayMillis / 1000) }}</span></div>
            <div class="job-detail-position-groups" v-if="posTypeList.length && posConf.length && !noResetPosition.includes(jobData.sourceDsVO.dataSourceType)">
              <pd-button ghost type="primary" size="small"
                         @click="handleShowConfirm">{{ $t('hui-su-wei-dian') }}
              </pd-button>
<!--              <pd-button style="margin-left: 6px" type="error" size="small" ghost-->
<!--                         @click="handleClearBinlog">{{ $t('qing-chu-wei-dian') }}-->
<!--              </pd-button>-->
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="job-detail-metric">
        <p class="job-detail-state-progress-title"><span
          class="status-point"></span>{{ $t('qian-yi-su-shuai') }}
          <a @click="handleMoreMonitor(taskList['INCREMENT'].dataTaskId,'INCREMENT')">{{
              $t('geng-duo-zhi-biao')
            }}</a></p>
        <div style="margin-top:20px">
          <div class="panel">
            <div class="panel-header">
              <div style="float: right">
              </div>

              {{ incrementMetric.title }}
            </div>
            <div class="panel-body">
              <div style="height: 200px">
                <Metric ref="metric2" :model="incrementMetric" :time="time"
                        :filters="filters" :resourceType="resourceType"
                        :dataJobId="jobId.toString()"
                        :selected="incrementMetric.selected"
                        :dataTaskId="taskList['INCREMENT'].dataTaskId.toString()"
                        :filterOthers="filterOthers"></Metric>
              </div>
            </div>
          </div>
        </div>
      </div>
    <PosModal :show-pos-modal="showPosModal" :pos="currentPos" :handle-cancel="handleCancel" :handle-confirm-pos="handleConfirmPos" :width="800">
      <PosForm :form="currentPos.ops" :form-data="formDataForPos" :label-width="80" :text-area-rows="10"></PosForm>
    </PosModal>
    </div>
</template>
<script>
import fecha from 'fecha';
import MappingUtil from '@/views/util';
import { increDataTaskPosOps, updateIncrePos } from '@/services/cc/api/job';
import { showPositionConf } from '@/services/cc/api/constant';
import PosModal from '@/components/function/dataJob/jobDetail/modal/PosModal';
import PosForm from '@/components/function/dataJob/jobDetail/PosForm';
import { Modal } from 'view-design';
import { isPG } from '@/utils';
import Metric from '../../monitor/metric/metric';
import { formatSeconds } from '../../../util';
import DataSourceGroup from '../../../../views/dataSourceGroup';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Metric, PosModal, PosForm },
  props: {
    getCurrentStateColor: Function,
    getStepColor: String,
    taskPosition: Object,
    taskList: Object,
    getDelayColor: Function,
    handleShowConfirm: Function,
    handleClearBinlog: Function,
    handleMoreMonitor: Function,
    incrementMetric: Object,
    filters: Object,
    resourceType: String,
    filterOthers: Object,
    time: Object,
    jobId: Number,
    jobData: Object,
    handleShowLog: Function,
    task: Object,
    posTypeList: Array
  },
  data() {
    return {
      posConf: {},
      MappingUtil,
      fecha,
      formatSeconds,
      DataSourceGroup,
      positionConf: {},
      posContent: [],
      currentPos: {},
      showPosModal: false,
      formDataForPos: {

      },
      noResetPosition: ['PostgreSQL', 'SQLServer']
    };
  },
  created() {
    showPositionConf({
      dataJobId: this.jobId
    })
      .then((res) => {
        if (res.data.data[this.jobData.sourceDsVO.dataSourceType]) {
          this.posConf = res.data.data[this.jobData.sourceDsVO.dataSourceType];
        }
      });
    this.getIncrePos();
  },
  methods: {
    getIncrePos() {
      this.posContent = [];
      increDataTaskPosOps({
        taskId: this.task.dataTaskId,
        srcDsType: this.jobData.sourceDsVO.dataSourceType
      }).then((res) => {
        console.log(res);
        if (res.data.code === '1') {
          this.posContent = res.data.data;
        }
      });
    },
    isPG,
    handleRender() {
      const that = this;
      this.getIncrePos();

      setTimeout(() => {
        if (that.$refs.metric2) {
          that.$refs.metric2.$emit('render');
        }
      }, 100);
    },
    handleShowPosForm(pos) {
      this.currentPos = pos;
      if (this.taskList.INCREMENT && pos.dataTaskStatusNeed.includes(this.taskList.INCREMENT.dataTaskStatus)) {
        if (pos.type === 'FORM') {
          pos.ops.forEach((op) => {
            this.formDataForPos[op.targetParameter] = op.preFillValue;
          });
          pos.hiddenParams.forEach((param) => {
            this.formDataForPos[param.targetParameter] = param.value;
          });
        }
        this.showPosModal = true;
      } else {
        Modal.info({
          title: this.$t('ti-shi'),
          content: pos.dataTaskStatusNeedComment
        });
      }
    },
    updateIncrePos() {
      console.log('lalal');
      // this.updateIncrePos((res) => {
      //   console.log('res', res);
      // });
      updateIncrePos(this.formDataForPos).then((res) => {
        if (res.data.success) {
          window.location.reload();
        }
        this.handleCancel();
      });
    },
    handleCancel() {
      this.showPosModal = false;
    },
    handleConfirmPos() {
      const action = this.currentPos.targetAction;
      this.$Modal.confirm({
        title: this.$t('xiu-gai-wei-dian-que-ren'),
        content: this.$t('que-ren-yao-xiu-gai-gai-ren-wu-de-wei-dian-xin-xi-ma-gai-cao-zuo-cun-zai-yi-ding-feng-xian-qing-jin-shen-cao-zuo'),
        onOk: () => {
          this.showPosModal = false;
          this[action]();
        }
      });
    }
  }
};
</script>
