<template>
  <div class="operators">
    <async-run-modal
      :visible="showAsyncRunModal"
      :onRun="handleRunAsync"
      v-if="showAsyncRunModal"
      :handle-close-modal="handleHideAsyncRunModal"
      :title="$t('yi-bu-zhi-hang')" type="console"
      :data-source-type="tab.dsType"/>
    <div class="operator-character">
      <div class="left">
        <Button
          type="primary"
          size="small"
          :disabled="tab.running || !socket.connected || !tab.connected"
          :loading="tab.running"
          @click="onRun('run')"
        >
          <cc-iconfont v-if="!tab.running" name="run" :color="tab.running?'#999':'#ffffff'"
                       :size="12"></cc-iconfont>
          {{ $t('zhi-hang') }}
        </Button>
        <Button @click="handleStop" style="margin-left: 10px" :disabled="tab.stopping || !socket.connected || !tab.connected"
                :loading="tab.stopping" size="small">
          <cc-iconfont v-if="!tab.stopping" name="stop" :color="tab.running?'#555555':'#999'"
                       :size="12"></cc-iconfont>
          {{ $t('zhong-duan-zhi-hang') }}
        </Button>
        <Button @click="formatSql" style="margin-left: 10px" size="small">{{ $t('ge-shi-hua') }}</Button>
      </div>
      <div class="right" v-if="tab.connected">
        <Checkbox v-model="tab.readOnly" @on-change="handleReadOnly" v-if="tab.support.readOnly.conf === 'Allow'">{{ $t('zhi-du') }}</Checkbox>
        <div class="transaction" v-if="tab.support.autoCommit.conf === 'Allow'">
          <div class="auto-commit-select">
            <Tooltip placement="bottom">
              <div slot="content">
                <p>{{ $t('shou-dong-kai-qi-shi-wu-xu-shou-dong-ti-jiao-shi-wu') }}</p>
                <p>{{ $t('zi-dong-guan-bi-shi-wu-zhi-hang-de-sql-zi-dong-ti-jiao') }}</p>
                <p>{{ $t('shi-yong-mo-ren-de-shi-wu-ge-li-ji-bie') }}</p>
              </div>
              <div class="pointer" style="margin-right: 5px;">{{ $t('shi-wu') }}</div>
            </Tooltip>
            <RadioGroup v-model="tab.autoCommit" @on-change="handleChangeTx">
              <Radio label="true">{{ $t('zi-dong') }}</Radio>
              <Radio label="false">{{ $t('shou-dong') }}</Radio>
            </RadioGroup>
          </div>
          <Button v-if="tab.autoCommit === 'false'" @click="handleCommit" style="margin-right: 8px" size="small">
            {{ $t('ti-jiao') }}
          </Button>
          <Button v-if="tab.autoCommit === 'false'" @click="handleRollback" style="margin-right: 10px" size="small">
            {{ $t('hui-gun') }}
          </Button>
        </div>
        <div class="isolations" v-if="tab.autoCommit === 'false' && tab.support.isolation.conf === 'Allow' && isolations(tab.dsType).length">
          <Select v-model="tab.isolation" @on-change="handleSetIsolation" size="small">
            <Option v-for="isolation in isolations(tab.dsType)" :value="isolation.key" :key="isolation.key">{{isolation.i18n}}</Option>
          </Select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import AsyncRunModal from '@/components/modal/AsyncRunModal';
import { isRedis } from '@/const/dataSource';
import browseMixin from '@/mixins/browseMixin';
import {
  hasSchema, supportTransaction, WS_REQ_QUERY_TYPE, WS_TYPE
} from '../../../utils/index';

export default {
  name: 'Operators',
  components: { AsyncRunModal },
  mixins: [browseMixin],
  props: {
    handleReadOnly: Function,
    onRun: Function,
    tab: Object,
    storeQueryTabs: Function,
    formatSql: Function,
    handleStop: Function,
    handleRollback: Function,
    handleCommit: Function,
    handleSetIsolation: Function,
    handleSetTx: Function
  },
  data() {
    return {
      showAsyncRunModal: false,
      runTypes: {
        runAsync: { key: 'runAsync', label: this.$t('yi-bu-zhi-hang') }
        // ticket: { key: 'ticket', label: '提交工单' }
      },
      hasSchema,
      supportTransaction,
      sqlExecType: []
    };
  },
  computed: {
    ...mapGetters([
      'isDesktop', 'supportRdbTx', 'isolations'
    ]),
    ...mapState(['userInfo', 'globalSetting', 'socket'])
  },
  watch: {
    'tab.sessionId': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleSetInitTx();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    isRedis,
    handleChangeTx(e) {
      if (this.tab.sessionId) {
        this.handleSetTx(e);
      }
    },
    handleSetInitTx() {
      if (this.tab.support.autoCommit.conf === 'Allow' && this.tab.sessionId) {
        this.handleSetTx(this.tab.autoCommit);
      }
    },
    handleRunAsync(asyncForm) {
      this.onRun('runAsync', asyncForm);
    },
    async handleJps() {
      window.jpc({
        request: 'saveFile({"file": "clouddm.csv"})',
        onSuccess: async (response) => {
          if (response) {
            await this.handleRunAsync({ exportToFile: response });
          }
        },
        onFailure(errorCode, errorMessage) { console.log(errorCode, errorMessage); }
      });
    },
    handleReadOnlyChange(e) {
      console.log(e.target.checked);
    },
    handleShowAsyncRunModal() {
      if (this.isDesktop) {
        this.handleJps();
      } else {
        this.showAsyncRunModal = true;
      }
    },
    handleHideAsyncRunModal() {
      this.showAsyncRunModal = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>
<style lang="less" scoped>
.operators {
  padding: 8px;
  border-bottom: 1px solid #C7C7C7;
  position: relative;

  .operator-character {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    .left {

    }

    .right {
      display: flex;
      .transaction {
        display: flex;
        .auto-commit-select {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
