export const ticketApi = {
  requestUserDsAuth: '/clouddm/console/api/v1/ticket/requestuserdsauth',
  listTickets: '/clouddm/console/api/v1/ticket/listbasic',
  createTicket: '/clouddm/console/api/v1/ticket/create',
  deleteTicket: '/clouddm/console/api/v1/ticket/delete',
  updateApproKey: '/clouddm/console/api/v1/ticket/appro/updatekey',
  listApproTemplates: '/clouddm/console/api/v1/ticket/appro/listtemplates',
  refreshApproTemplates: '/clouddm/console/api/v1/ticket/appro/refreshtemplates',
  queryTicketDetail: '/clouddm/console/api/v1/ticket/querydetail',
  cancelTicket: '/clouddm/console/api/v1/ticket/cancel',
  confirmTicket: '/clouddm/console/api/v1/ticket/confirm',
  retryTicket: '/clouddm/console/api/v1/ticket/retry',
  closeTicket: '/clouddm/console/api/v1/ticket/close',
  ticketType: '/clouddm/console/api/v1/ticket/appro/ticketype',
  approvalTicket: '/clouddm/console/api/v1/ticket/approval',
  ticketType2: '/clouddm/console/api/v1/ticket/ticketype',
  listTemplates: '/clouddm/console/api/v1/ticket/listtemplates',
  listDsInsLevels: '/clouddm/console/api/v1/ticket/listDsInsLevels',
  listDbLevels: '/clouddm/console/api/v1/ticket/listDbLevels',
  refreshTemplates: '/clouddm/console/api/v1/ticket/refreshtemplates'
};
