<template>
  <div class="ticket-container">
    <div class="table-list-layout">
      <div class="table-list">
        <div class="header">
          <Breadcrumb>
            <BreadcrumbItem>{{ $t('gong-dan') }}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Tabs v-model="ticketListType" @on-click="listTickets">
          <TabPane name="WAIT_SELF_PROCESS" :label="$t('dai-ban')"/>
          <TabPane name="SELF_CREATE" :label="$t('wo-fa-qi-de')"/>
          <TabPane name="ALL" :label="$t('quan-bu')"/>
        </Tabs>
        <div class="content">
          <div class="option">
            <div class="left">
              <DatePicker v-model="searchKey.daterange" format="yyyy-MM-dd HH:mm:ss"
                          type="datetimerange" :placeholder="$t('qing-xuan-ze-shi-jian')"
                          style="width: 300px;margin-right: 4px;" clearable/>
              <Select style="width:120px;margin-right: 4px" v-model="searchKey.ticketStatus" clearable>
                <Option v-for="(status,key) in ticketStatusList" :value="key" :key="key">
                  {{ status }}
                </Option>
              </Select>
              <Input :placeholder="$t('qing-shu-ru-gong-dan-biao-ti-guan-jian-zi-cha-xun')"
                     v-model="searchKey.ticketTitleName"
                     style="width: 280px;margin-right: 4px;" clearable/>
              <Button type="primary" class="ticket-search-btn" @click="listTickets">
                {{ $t('cha-xun') }}
              </Button>
            </div>
            <div class="right">
              <Button to="/ticket_create" style="margin-right: 10px;" type="primary" ghost
                      icon="md-add" v-if="myAuth.includes('RDP_WORKER_ORDER_REQUEST')">
                {{ $t('ti-jiao-gong-dan') }}
              </Button>
              <Button type="default" @click="listTickets">{{ $t('shua-xin') }}</Button>
            </div>
          </div>
          <div class="table">
            <Table size="small" :columns="ticketColumns" :data="ticketData" border :loading="loading">
              <div slot="ticketStatus" slot-scope="{ row }">
                <div class="ticket-status"
                     :style="`color:${TICKET_STATUS_COLOR[row.ticketStatus]}`">
                  <div class="type" :style="styleVar(row.ticketStatus)"/>
                  <div class="content">{{ TICKET_STATUS[row.ticketStatus] }}</div>
                </div>
              </div>
              <div slot="targetInfo" slot-scope="{ row }">
                  <span v-if="row.dataSourceInstName">
                    <cc-data-source-icon color="#4DBAEE" :size="18" :type="row.dataSourceType"
                                                 :instanceType="row.deployEnvType"></cc-data-source-icon>
                    {{ row.targetInfo }}
                  </span>
                  <span v-else>
                    {{ $t('shi-li-yi-shan-chu') }}
                  </span>
              </div>
              <div slot="action" slot-scope="{ row }">
                <router-link :to="`/ticket/${row.id}`">{{ $t('xiang-qing') }}</router-link>
              </div>
            </Table>
          </div>
        </div>
      </div>
      <div class="footer">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange"
              show-sizer
              v-model="pageNum"
              :page-size="pageSize" @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { TICKET_STATUS, TICKET_STATUS_COLOR, TICKET_WAIT_STATUS } from '@/const';

export default {
  name: 'Ticket',
  data() {
    return {
      loading: false,
      ticketData: [],
      ticketListType: 'WAIT_SELF_PROCESS',
      searchKey: {
        daterange: [],
        ticketStatus: '',
        type: '',
        ticketTitleName: ''
      },
      ticketColumns: [
        {
          title: this.$t('zhuang-tai'),
          slot: 'ticketStatus',
          width: 100
        }, {
          title: this.$t('gong-dan-hao'),
          key: 'bizId',
          width: 150
        }, {
          title: this.$t('biao-ti'),
          key: 'ticketTitle'
        }, {
          title: this.$t('shi-li-ku'),
          slot: 'targetInfo'
        }, {
          title: this.$t('shen-qing-ren'),
          key: 'userName',
          width: 150
        }, {
          title: this.$t('ti-jiao-shi-jian'),
          key: 'gmtCreate',
          width: 170
        }, {
          title: this.$t('jie-shu-shi-jian'),
          key: 'finishTime',
          width: 170
        },
        {
          title: this.$t('cao-zuo'),
          width: 66,
          fixed: 'right',
          slot: 'action'
        }
      ],
      pageSize: 20,
      pageNum: 1,
      total: 0
    };
  },
  mounted() {
    this.listTickets();
  },
  computed: {
    ticketStatusList() {
      return this.ticketListType === 'WAIT_SELF_PROCESS' ? TICKET_WAIT_STATUS : TICKET_STATUS;
    },
    styleVar() {
      return (ticketStatus) => ({
        '--status-color': TICKET_STATUS_COLOR[ticketStatus]
      });
    },
    TICKET_STATUS() {
      return TICKET_STATUS;
    },
    TICKET_STATUS_COLOR() {
      return TICKET_STATUS_COLOR;
    },
    ...mapState(['userInfo', 'myCatLog', 'myAuth'])
  },
  methods: {
    handlePageChange(pageNum) {
      this.pageNum = pageNum;
      this.listTickets();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.handlePageChange(1);
    },
    async listTickets() {
      this.loading = true;
      const res = await this.$services.listTickets({
        data: {
          ticketId: null,
          userName: '',
          startTimeMs: new Date(this.searchKey.daterange[0]).getTime(),
          endTimeMs: new Date(this.searchKey.daterange[1]).getTime(),
          ticketTitleName: this.searchKey.ticketTitleName,
          ticketStatus: this.searchKey.ticketStatus,
          ticketListType: this.ticketListType,
          page: {
            pageSize: this.pageSize,
            pageNum: this.pageNum
          }
        }
      });
      this.loading = false;
      if (res.success) {
        this.ticketData = res.data.records;
        this.total = res.data.total;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.ticket-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
</style>
