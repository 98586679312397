<template>
  <div>
    <div class="job-detail-container">
      <Breadcrumb>
        <BreadcrumbItem to="/data/job/list">{{ $t('tong-bu-ren-wu') }}</BreadcrumbItem>
        <BreadcrumbItem>{{ $t('ren-wu-xiang-qing') }}</BreadcrumbItem>
      </Breadcrumb>
      <div class="refresh-btns">
        <Checkbox @on-change="handleInterval">{{ $t('10-miao-ding-shi-shua-xin') }}</Checkbox>
        <pd-button @click="handleRefresh" :loading="refreshLoading">{{ $t('shua-xin') }}</pd-button>
      </div>
      <div class="job-detail-content">
        <div class="job-header">
                    <span class="job-header-db">
                        <i class="iconfont iconshujuku"></i>
                    </span>
          <div class="job-header-name">
            <p>
              <span class="job-header-name-main">{{ jobData.dataJobName }}</span>
              <svg class="icon job-info-status job-info-status-running" aria-hidden="true">
                <use :xlink:href="'#'+getStatusIcon(jobData)"></use>
              </svg>
              <span
                :style="`font-family:PingFangSC-Semibold;color:${getFontStepColor}`">{{
                  getStatusName(jobData)
                }}</span>
            </p>
            <span class="job-header-name-desc">{{
                jobData.dataJobDesc ? jobData.dataJobDesc : $t('zan-wu-miao-shu')
              }}<i
                style="margin-left: 4px" class="iconfont iconbianji" @click="handleEditJobDesc"></i></span>
          </div>
          <div class="task-detail-step" v-if="ifShowStep">
            <DataJobStep :currentStep="currentStep" :showStep="showStep" :jobData="jobData"
                         :currentStatus="currentStatus"></DataJobStep>
          </div>
          <div class="job-header-buttons">
            <Dropdown style="margin-right: 8px" v-if="myAuth.includes('CC_DATAJOB_MANAGE')">
              <pd-button type="default">
                {{ $t('gong-neng-lie-biao') }}
                <Icon style="font-size: 15px" type="md-apps" />
              </pd-button>
              <DropdownMenu slot="list">
                <DropdownItem
                  v-if="showReplayBtn()">
                  <div class="dropdown-content" @click="handleReplayInDetail">{{ $t('zhong-pao') }}</div>
                </DropdownItem>
                <DropdownItem
                  v-if="!showReplayBtn()" disabled>
                  <div class="dropdown-content">{{ $t('zhong-pao') }}</div>
                </DropdownItem>
                <DropdownItem>
                  <div class="dropdown-content" @click="handleParamsEdit">
                    {{ $t('xiu-gai-ren-wu-can-shu') }}
                  </div>
                </DropdownItem>
                <DropdownItem :disabled="disabledEditJob">
                  <div class="dropdown-content" v-if="!disabledEditJob" @click="handleEditJob">
                    {{ $t('xiu-gai-ding-yue') }}
                  </div>
                  <Tooltip v-else :content="$t('zhi-you-zhi-chi-de-shu-ju-yuan-lei-xing-bing-qie-zai-zeng-liang-tong-bu-jie-duan-de-zhu-ren-wu-ke-yi-jin-hang-ding-yue-xiu-gai')" transfer placement="right">
                    <p class="dropdown-content">{{ $t('xiu-gai-ding-yue') }}</p>
                  </Tooltip>
                </DropdownItem>
                <DropdownItem>
                  <div class="dropdown-content" @click="handleShowJobOptions">{{ $t('xiu-gai-pei-zhi') }}</div>
                </DropdownItem>
                <DropdownItem>
                  <div class="dropdown-content" @click="handleAlarmConfig">{{ $t('gao-jing-pei-zhi') }}</div>
                </DropdownItem>
                <DropdownItem v-if="jobData.havePackage">
                  <div class="dropdown-content" @click="handleShowPackageList">{{ $t('dai-ma-bao-guan-li-0') }}</div>
                </DropdownItem>
                <DropdownItem
                  v-if="jobData.childJobs&&jobData.childJobs.length>0">
                  <div class="dropdown-content" @click="handleGoTaskGroup">{{ $t('cha-kan-guan-lian-ren-wu') }}</div>
                </DropdownItem>
                <DropdownItem
                  v-if="!jobData.childJobs||jobData.childJobs.length===0" disabled>
                  <div class="dropdown-content">{{ $t('cha-kan-guan-lian-ren-wu') }}</div>
                </DropdownItem>
                <DropdownItem
                  v-if="!DataSourceGroup.noDb.includes(jobData.sourceDsVO.dataSourceType) || isMQ(jobData.sourceDsVO.dataSourceType)">
                  <div class="dropdown-content" @click="handleCreateSimilarJob">{{ $t('chuang-jian-xiang-si-ren-wu') }}</div>
                </DropdownItem>
                <DropdownItem>
                  <div class="dropdown-content" @click="handleShowRestartList">{{ $t('zhong-qi-li-shi-ji-lu') }}</div>
                </DropdownItem>
                <DropdownItem v-if="canCheck">
                  <div class="dropdown-content" @click="handleAddCheckJob">{{ $t('chuang-jian-shu-ju-jiao-yan-ren-wu') }}</div>
                </DropdownItem>
                <DropdownItem v-if="!canCheck" disabled>
                  <div class="dropdown-content">{{ $t('chuang-jian-shu-ju-jiao-yan-ren-wu') }}</div>
                </DropdownItem>
                <DropdownItem v-if="canRevise">
                  <div class="dropdown-content" @click="handleAddReviseJob">{{ $t('chuang-jian-shu-ju-ding-zheng-ren-wu') }}</div>
                </DropdownItem>
                <DropdownItem v-if="!canRevise" disabled>
                  <div class="dropdown-content">{{ $t('chuang-jian-shu-ju-ding-zheng-ren-wu') }}</div>
                </DropdownItem>
                <DropdownItem>
                  <div class="dropdown-content" @click="handleRebuildIndex">{{ $t('geng-xin-yuan-shu-ju-suo-yin') }}</div>
                </DropdownItem>
                <DropdownItem>
                  <div class="dropdown-content" @click="handlePDeleteJob">{{ $t('shan-chu') }}</div>
                </DropdownItem>
                <DropdownItem
                  v-if="showManuelMergeBtn">
                  <div class="dropdown-content" type="primary" @click="manualMergeEditJob(jobData)" v-permission="'dataJob'">{{ $t('shou-dong-he-bing') }}
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <pd-button v-if="!disableBtn('startfsm')" style="margin-right: 5px" type="primary"
                       @click="handleStartFsm"
                       :disabled="disableBtn('startfsm')">
              {{ starting ? $t('qi-dong-zhong') : $t('qi-dong') }}
            </pd-button>
            <pd-button v-if="disableBtn('startfsm')" style="margin-right: 5px" type="primary"
                       @click="handleStartJob"
                       :disabled="disableBtn('start')">
              {{ starting ? $t('qi-dong-zhong') : $t('qi-dong') }}
            </pd-button>
            <pd-button style="margin-right: 5px" type="warning" @click="handleRestartJob"
                       :disabled="disableBtn('restart')">
              {{ restarting ? $t('chong-qi-chong') : $t('chong-qi') }}
            </pd-button>
            <pd-button style="margin-right: 5px" type="error" @click="handleStopJob"
                       :disabled="disableBtn('stop')"
            >
              {{ stopping ? $t('ting-zhi-zhong') : $t('ting-zhi') }}
            </pd-button>
          </div>
        </div>
      </div>
      <div class="job-body">
        <DataConfigInfo :jobData="jobData" :taskList="taskList"
                        :handleShowMapping="handleShowMapping" :handleShowPackageList="handleShowPackageList"
                        :getDataSourceDetail="getDataSourceDetail" :activePackage="activePackage"
                        :getDataSinkDetail="getDataSinkDetail" :sourceDetail="sourceDetail"
                        :sinkDetail="sinkDetail" :queryData="queryData"></DataConfigInfo>
        <div class="job-detail-state">
          <div class="worker-ip-btn"
               v-if="currentState==='FULL'&&taskList['FULL']&&taskList['FULL'].workerIp&&taskList['FULL'].clusterId">
            <i style="margin-right: 6px;font-size: 14px" class="iconfont iconmachine"></i><a
            @click="handleGoWorker(taskList['FULL'])">{{
              taskList['FULL'].workerIp
            }}</a>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyWorkerIp(taskList['FULL'].workerIp)"/>
          </div>
          <div class="worker-ip-btn"
               v-if="currentState==='REVISE'&&taskList['REVISE']&&taskList['REVISE'].workerIp&&taskList['REVISE'].clusterId">
            <i style="margin-right: 6px;font-size: 14px" class="iconfont iconmachine"></i><a
            style="color: #333333"
            @click="handleGoWorker(taskList['REVISE'])">{{
              taskList['REVISE'].workerIp
            }}</a>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyWorkerIp(taskList['REVISE'].workerIp)"/>
          </div>
          <div class="worker-ip-btn"
               v-if="currentState==='INCREMENT'&&taskList['INCREMENT']&&taskList['INCREMENT'].workerIp&&taskList['INCREMENT'].clusterId">
            <i style="margin-right: 6px;font-size: 14px" class="iconfont iconmachine"></i><a
            style="color: #333333"
            @click="handleGoWorker(taskList['INCREMENT'])">{{
              taskList['INCREMENT'].workerIp
            }}</a>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyWorkerIp(taskList['INCREMENT'].workerIp)"/>
          </div>
          <div class="worker-ip-btn"
               v-if="(currentState==='CHECK'||currentState==='COMPLETE')&&taskList['CHECK']&&taskList['CHECK'].workerIp&&taskList['CHECK'].clusterId">
            <i style="margin-right: 6px;font-size: 14px" class="iconfont iconmachine"></i><a
            style="color: #333333"
            @click="handleGoWorker(taskList['CHECK'])">{{
              taskList['CHECK'].workerIp
            }}</a>
            <Icon class="copy-icon" type="ios-photos-outline"
                  @click="handleCopyWorkerIp(taskList['CHECK'].workerIp)"/>
          </div>
          <Tabs type="card" v-model="currentState" @on-click="handleTabChange">
            <TabPane :label="buildStructLabel" name="BUILD_STRUCT" v-if="taskList['BUILD_STRUCT']">
              <StructBuild :jobData="jobData" :getCurrentStateColor="getCurrentStateColor"
                           :taskList="taskList" :task="taskList['BUILD_STRUCT']"
                           :showStructData="showStructData" :structData="structData"
                           :handleStructPageChange="handleStructPageChange"
                           :handleShowLog="handleShowLog"></StructBuild>
            </TabPane>
            <TabPane :label="fullLabel" name="FULL" v-if="taskList['FULL']">
              <Full :getCurrentStateColor="getCurrentStateColor" :fullProgress="fullProgress"
                    :handleFilterFullTable="handleFilterFullTable" :task="taskList['FULL']"
                    :showFullTransData="showFullTransData" :getProgressStatus="getProgressStatus"
                    :taskList="taskList"
                    :fullTotal="fullTotal"
                    :full-page="fullPage"
                    :handleShowLog="handleShowLog"
                    :fullSize="fullSize" :handleFullPageChange="handleFullPageChange"
                    :handleMoreMonitor="handleMoreMonitor" :resourceType="resourceType"
                    :filters="filters" :filterOthers="filterOthers" :metric="metric" :time="time"
                    :jobId="jobId"
                    :filterJobStatus="filterJobStatus"
                    :filterTableName="filterTableName" ref="fullDetail"
              ></Full>
            </TabPane>
            <TabPane :label="increLabel" name="INCREMENT" v-if="taskList['INCREMENT']">
              <Increment :getCurrentStateColor="getCurrentStateColor" :getStepColor="getStepColor"
                         :taskPosition="taskPosition" :taskList="taskList"
                         :getDelayColor="getDelayColor" :handleShowConfirm="handleShowConfirm"
                         :handleClearBinlog="handleClearBinlog" :handleShowLog="handleShowLog"
                         :task="taskList['INCREMENT']"
                         :handleMoreMonitor="handleMoreMonitor" :incrementMetric="incrementMetric"
                         :filters="filters" :posTypeList="posTypeList"
                         :resourceType="resourceType" :jobData="jobData"
                         :filterOthers="filterOthers" :time="time" :jobId="jobId"
                         ref="incrementDetail"
              ></Increment>
            </TabPane>
            <TabPane :label="checkLabel" name="CHECK" v-if="taskList['CHECK']">
              <Check :getCurrentStateColor="getCurrentStateColor" :checkProgress="checkProgress"
                     :showCheckData="showCheckData" :getProgressStatus="getProgressStatus"
                     :checkTotal="checkTotal" :checkSize="checkSize"
                     :handleCheckPageChange="handleCheckPageChange"
                     :handleCheckSortChange="handleCheckSortChange"
                     :handleCheckSearch="handleCheckSearch"
                     :taskList="taskList" :resourceType="resourceType"
                     :filters="filters" :filterOthers="filterOthers" :metric="checkMetric"
                     :time="time" :jobId="jobId"
                     :filterJobStatus="filterJobStatus" :handleShowLog="handleShowLog"
                     :task="taskList['CHECK']" :handleMoreMonitor="handleMoreMonitor"
                     :filterTableName="filterTableName" ref="checkDetail"
              ></Check>
            </TabPane>
            <TabPane :label="reverseLabel" name="REVISE" v-if="taskList['REVISE']">
              <Revise :getCurrentStateColor="getCurrentStateColor" :reviseProgress="reviseProgress"
                      :handleFilterFullTable="handleFilterFullTable"
                      :showFullTransData="showFullTransData" :getProgressStatus="getProgressStatus"
                      :taskList="taskList"
                      :fullTotal="fullTotal"
                      :fullSize="fullSize" :handleFullPageChange="handleFullPageChange"
                      :handleMoreMonitor="handleMoreMonitor" :resourceType="resourceType"
                      :filters="filters" :filterOthers="filterOthers" :metric="reviseMetricConfig" :time="time"
                      :jobId="jobId"
                      :filterJobStatus="filterJobStatus" :handleShowLog="handleShowLog"
                      :task="taskList['REVISE']"
                      :filterTableName="filterTableName" ref="reviseDetail"
              ></Revise>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
    <DataJobDetailModal :showMapping="showMapping" :jobData="jobData" :getAddedData="getAddedData"
                        :selectedIndex="selectedIndex" :mappingDef="mappingDef"
                        :updateSourceSchema="updateSourceSchema"
                        :sourceSchema="sourceSchema" :getMappingCount="getMappingCount"
                        :showTableMapping="showTableMapping" :sourceTable="sourceTable"
                        :showResetPosition="showResetPosition" :positionLoading="positionLoading"
                        :handleResetPosition="handleResetPosition"
                        :handleChangeBinlogCancel="handleChangeBinlogCancel"
                        :showPartition="showPartition" :partition="partition"
                        :showWhereCondition="showWhereCondition"
                        :showEditJobDesc="showEditJobDesc" :selectedJob="selectedJob"
                        :handleConfirmEdit="handleConfirmEdit" :showConfirm="showConfirm"
                        :handleCancelDelete="handleCancelDelete" :showLog="showLog"
                        :updateLogFileName="updateLogFileName"
                        :handleShowWhereCondition="handleShowWhereCondition"
                        :handleSelectLog="handleSelectLog" :logLoading="logLoading"
                        :logPreLoading="logPreLoading" :logNextLoading="logNextLoading"
                        :handleShowLog="handleShowLog" :trueColumnData="trueColumnData"
                        :logData="logData" :showEditJob="showEditJob" :checkReviseOpts="checkReviseOpts"
                        :handleCancelEdit="handleCancelEdit" :loopRunTypes="loopRunTypes"
                        :currentWhereCondition="currentWhereCondition" :taskPosition="taskPosition"
                        :handleChangeTable="handleChangeTable" :mqSchema="mqSchema"
                        :mqSchemaTemplate="mqSchemaTemplate" :showCreateCheck="showCreateCheck"
                        :selectedLog="selectedLog" :handlePageChange="handlePageChange"
                        :queryJobSchema="queryJobSchema" :posTypeList="posTypeList"
                        :updatePositionInfo="updatePositionInfo" :targetSchema="targetSchema"
                        :handleConfirmCreateCheck="handleConfirmCreateCheck"
    ></DataJobDetailModal>
    <restart-list-modal :visible="showRestartListModal" :handle-close-modal="handleCloseRestartListModal" :restart-list="restartList"/>
    <alarm-rule-modal :visible="showEditAlarmRule" :handle-close-modal="handleCloseAlarmRuleModal"/>
    <second-confirm-modal
      :visible="showClearPosition"
      :title="$t('qing-chu-wei-dian')"
      :event="SECOND_CONFIRM_EVENT_LIST.DELETE_POSITION"
      ref="second-confirm-modal"
      :handle-confirm="() => handleResetPosition('', true)"
      :handle-close="handleChangeBinlogCancel"
    >
      <template>
        <Alert>
          {{ $t('qing-shu-ru-dong-zuo-ming-cheng') }}<span class="warn-font">{{SECOND_CONFIRM_EVENT_LIST.DELETE_POSITION}}</span>{{ $t('yi-wan-cheng-qing-chu') }}{{ jobData.dataJobName }}
          {{ $t('ren-wu-de-wei-dian-de-cao-zuo-bing-she-zhi-wei-dang-qian-shi-jian') }}<span class="warn-font">{{ positionInfo.binlogTime && fecha.format(new Date(positionInfo.binlogTime), 'YYYY-MM-DD HH:mm:ss') }} </span>{{ $t('ju-hao') }}
        </Alert>
      </template>
    </second-confirm-modal>
    <second-confirm-modal
      :title="$t('shan-chu-ren-wu')"
      :event="SECOND_CONFIRM_EVENT_LIST.DELETE_JOB"
      :text="$t('yi-wan-cheng-shan-chu-selectedjobrowdatajobname-ren-wu-de-cao-zuo', [selectedJobRow.dataJobName])"
      :visible="showConfirm"
      ref="second-confirm-modal"
      :handle-confirm="handleConfirmDelete"
      :handle-close="handleCancelDelete"
    />
    <Modal
      v-model="showPackage"
      :title="$t('dai-ma-bao-guan-li-0')"
      footer-hide
      width="1200"
      :closable="false"
    >
      <div class="pkg-manager">
        <div>
          <Button type="primary" style="margin-bottom: 10px;" @click="handleUpload">{{ $t('shang-chuan-xin-bao') }}</Button>
          <Table stripe border size="small" :columns="packageColumns" :data="packageData" height="400" :loading="activePkgLoading">
            <template slot-scope="{ row }" slot="desc">
              <div>
                {{row.description}}
                <Icon class="edit-icon" type="md-create" @click="handleShowUpdatePkg(row)"/>
              </div>
            </template>
            <template slot-scope="{ row }" slot="status">
              <div>
                <div v-if="row.active">{{ $t('yi-ji-huo') }}
                  <Icon style="color: rgb(82, 196, 26);font-size: 18px" type="md-star" />
                </div>
                <div v-if="!row.active">{{ $t('wei-ji-huo') }}</div>
              </div>
            </template>
            <template slot-scope="{ row }" slot="action">
              <div>
                <Poptip  v-if="!row.active" transfer trigger="hover">
                  <a style="margin-right: 6px" @click="activePackages(row)">{{ $t('ji-huo') }}</a>
                  <div slot="content">
                    {{ $t('ji-huo-hou-qing-shou-dong-zhong-qi-ren-wu-fou-ze-dai-ma-bao-jiang-bu-hui-sheng-xiao') }}
                  </div>
                </Poptip>
                <a style="margin-right: 6px" @click="downloadPackage(row)">{{ $t('xia-zai') }}</a>
                <a v-if="!row.active" @click="handleDeletePackage(row)">{{ $t('shan-chu') }}</a>
              </div>
            </template>
          </Table>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button @click="handleCancel">{{ $t('guan-bi') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUploadPkg"
      :title="$t('shang-chuan-xin-bao')"
      footer-hide
    >
      <div>
        <Form label-position="right" :label-width="60">
          <FormItem :label="$t('shang-chuan-cha-jian')">
        <span class="upload-btn" v-if="!customPkgFile">
                  <input style="margin-left: 10px" @change="handleFileChange" type = "file" name = "uploadfile" id = "uploadfile" />
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-LocalUpload"></use>
                  </svg>
                  {{ $t('dian-ji-zhe-li-shang-chuan-wen-jian') }}
          </span>
            <span v-if="customPkgFile" class="file-name-span">
            {{customPkgFile.name}}
            ({{(customPkgFile.size/1024).toFixed(1)}}KB)
            <Icon type="md-close" @click="handleRemovePkg"/>
          </span>
          </FormItem>
          <FormItem :label="$t('miao-shu')">
            <Input style="width: 280px" v-model="addedPackage.pkgDescription"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpload">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelUpload">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUpdatePkg"
      :title="$t('xiu-gai-miao-shu')"
      footer-hide
    >
      <div>
        <Form label-position="right">
          <FormItem :label="$t('miao-shu')">
            <Input style="width: 380px" v-model="pkgDescription"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpdate">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelUpdate">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUpdatePkgProgress"
      :title="$t('shang-chuan-xin-bao-jin-du')"
      footer-hide
    >
      <div>
        <Progress :percent="currentPercentage" :stroke-width="20" status="active"
                  text-inside/>
        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">{{ $t('shang-chuan-zhong') }}</p>
      </div>
    </Modal>
    <Modal v-model="showDeletePkg" :title="$t('shan-chu-zi-ding-yi-dai-ma-bao')" footer-hide>
      <div style="padding: 20px">
        <Progress :percent="currentPercentage" :stroke-width="20"
                  :status="currentDeletetStatus==='SUCCESS'?'success':(currentPkgDeleteStatus==='FAILED'?'wrong':'active')"
                  text-inside/>
        <p style="padding-left:10px;text-align: center;margin-top: 16px;font-weight:500">
          {{
            currentPkgDeleteStatus === 'SUCCESS' ? $t('shan-chu-cheng-gong') : currentPkgDeleteStatus === 'FAILED' ? $t('shan-chu-shi-bai') : $t('shan-chu-zi-ding-yi-dai-ma-bao-zhong')
          }}</p>
      </div>
    </Modal>
    <Modal v-model="showLog"
           :title="$t('ren-wu-ri-zhi')"
           width="1200px"
           class-name="show-log-container"
           :closable="false"
    >
      <Tabs type="card" v-model="logFileName" @on-click="handleSelectLog" :animated="false">
        <TabPane :label="log.fileName" :name="log.fileName" v-for="(log) in logData"
                 :key="log.fileName">
          <div class="log-content" >
            <p class="log-content-desc"><span>{{ $t('miao-shu-0') }}</span><span
              class="point-content">{{ selectedLog.desc }}</span></p>
            <p class="log-content-desc"><span>{{ $t('lu-jing') }}</span><span
              class="point-content">{{ selectedLog.path }}</span></p>
            <div class="detail" v-if="selectedLog.content">
              <pre>{{selectedLog.content}}</pre>
<!--              <div v-for="(line, index) in selectedLog.content.split('\n')" :key="line+index" style="display: flex;">-->
<!--                <div :style="`text-align: right;font-weight: bold;width: ${log.endRow.length * 9}px`"-->
<!--                     v-text="parseInt(log.endRow) > 200 ? parseInt(log.endRow) - (200 - index) : index + 1"-->
<!--                >-->
<!--                </div>-->
<!--                <div style="width: 10px;"></div>-->
<!--                <pre style="flex: 1">{{line}}</pre>-->
<!--              </div>-->
            </div>
            <p class="log-content-desc" v-else>
              {{ $t('mei-you-ri-zhi') }}
            </p>
          </div>
        </TabPane>
      </Tabs>
      <div slot="footer" class="modal-footer">
        <pd-button @click="handleShowLog(null, -200, parseInt(selectedLog.endRow))" style="margin-right: 5px;" :disabled="(parseInt(selectedLog.endRow) <= 200) || logLoading || logNextLoading" :loading="logPreLoading">{{ $t('shang-yi-ye') }}
        </pd-button>
        <pd-button @click="handleShowLog(null, 200, parseInt(selectedLog.endRow))" style="margin-right: 5px;" :loading="logNextLoading" :disabled="logLoading || logPreLoading">{{ $t('xia-yi-ye') }}
        </pd-button>
        <pd-button @click="handleShowLog(null, 0, parseInt(selectedLog.endRow))" :loading="logLoading" :disabled="logPreLoading || logNextLoading">{{ $t('shua-xin') }}
        </pd-button>
        <pd-button style="margin-left: 5px" type="default"
                   @click="handleCancelDelete">{{ $t('guan-bi') }}
        </pd-button>
      </div>
    </Modal>
    <Modal v-model="showJobOptions" :title="$t('xiu-gai-pei-zhi')" width="500" footer-hide>
      <div>
        <PosForm :form="jobOptionsForm" :form-data="jobOptions" :label-width="160"></PosForm>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpdateJobOptions">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {
  activeFsm,
  deleteJob,
  queryJobById,
  queryJobSchemaById,
  resetPosTocurrent,
  restartJob,
  startJob,
  stopJob,
  tailTaskMainLog,
  updateJob,
  updateIncrePos,
  listPackages,
  deletePackages,
  activePackages,
  updatePkgInfo, getRestartList,
  preFirstCheck,
  canAddReviseTask,
  addrevisetask, manualMergeEditJob,
  queryJobOptions,
  updateJobOptions,
  addCheckChildJob
} from '@/services/cc/api/job';
import { downloadPackage } from '@/services/cc/api/download';
import {
  schemaLessValueFormatTemplate,
  dsPosTypes,
  supportedsourcedstype, supportedtargetdstype, rebuildIndex, transformJobType, dsCheckReviseOpts,
  listLoopRunTypes
} from '@/services/cc/api/constant';
import {
  updateAlertConfigs
} from '@/services/cc/api/alert';
import { batchListColumns } from '@/services/cc/api/datasource';
import { queryById } from '@/services/cc/api/rdpDs';
import { queryTaskDetailMonitorConfig } from '@/services/cc/api/monitor';
import { uploadPackage } from '@/services/cc/api/upload';
import { Config } from '@/components/function/monitor/config';
import { queryConsoleJob } from '@/services/cc/api/consoleJob';
import _ from 'lodash';
import { sendCodeInLoginState } from '@/services/cc/api/rdpVerify';
import fecha from 'fecha';
import Time from '@/components/function/monitor/utils/time';
import DataJobDetailMix from '@/components/function/mixin/dataJobDetailMixin';
import DataJobDetailModal from '@/components/function/dataJob/jobDetail/modal/DataJobDetailModal';
import '@/components/function/dataJob/jobDetail/jobDetail.less';
import DataJobStep from '@/components/function/dataJob/jobDetail/DataJobStep';
import DataConfigInfo from '@/components/function/dataJob/jobDetail/DataConfigInfo';
import StructBuild from '@/components/function/dataJob/jobDetail/StructBuild';
import Full from '@/components/function/dataJob/jobDetail/Full';
import Increment from '@/components/function/dataJob/jobDetail/Increment';
import Check from '@/components/function/dataJob/jobDetail/Check';
import Revise from '@/components/function/dataJob/jobDetail/Revise';
import RestartListModal from '@/views/data/modal/RestartListModal';
import {
  isKafka,
  isMongoDB, isMQ, isMySQL, isOracle, isPG, isSQLServer, isES
} from '@/utils';
import AlarmRuleModal from '@/views/data/modal/AlarmRuleModal';
import { Modal } from 'view-design';
import MyMixin from '@/components/function/mixin/dataJobListMixin';
import SecondConfirmModal from '@/components/modal/SecondConfirmModal';
import { SECOND_CONFIRM_EVENT_LIST } from '@/const';
import PosForm from '@/components/function/dataJob/jobDetail/PosForm';
import { parseCron } from '@/components/util';
import { mapState } from 'vuex';
import { orderBy, sortBy } from 'lodash/collection';
import DataSourceGroup from '../dataSourceGroup';
import { handleMqSchemaTool } from '../viewUtil';
import MappingUtil from '../util';
import store from '../../store/index';

export default {
  name: 'JobDetail',
  mixins: [DataJobDetailMix, MyMixin],
  components: {
    SecondConfirmModal,
    AlarmRuleModal,
    RestartListModal,
    DataJobDetailModal,
    DataJobStep,
    DataConfigInfo,
    StructBuild,
    Full,
    Increment,
    Check,
    Revise,
    PosForm
  },
  created() {
    this.jobId = Number(this.$route.params.id);
    this.parentDataJobId = this.$route.query.parentDataJobId;
    this.queryData('init');
  },
  mounted() {
    const totalHeight = window.innerHeight;

    // eslint-disable-next-line no-undef
    $('.job-body')
      .css('height', `${totalHeight - 200}px`);
  },
  beforeDestroy() {
    clearInterval(this.myInterval);
    clearInterval(this.sendCodeAgain);
    clearInterval(this.startingInterval);
    clearInterval(this.restartingInterval);
    clearInterval(this.stoppingInterval);
    clearInterval(this.queryDeletePkgInterval);
    clearInterval(this.getPercantage);
  },
  computed: {
    ...mapState(['myAuth']),
    SECOND_CONFIRM_EVENT_LIST() {
      return SECOND_CONFIRM_EVENT_LIST;
    },
    specificDataSource() {
      const { sourceType, sinkType } = this.taskInfo;
      return (isSQLServer(sourceType) && isMySQL(sinkType));
    },
    getFontStepColor() {
      const type = MappingUtil.taskSateToTaskType[this.jobData.dataTaskState];

      if (type) {
        if (!this.getDataTask(this.jobData.dataTasks)[type]) {
          return '#CCCCCC';
        }
        if (this.getDataTask(this.jobData.dataTasks)[type].dataTaskStatus === 'STOP') {
          return '#FFA30E';
        }
        if (this.getDataTask(this.jobData.dataTasks)[type].healthLevel === 'Health') {
          return '#52C41A';
        }
        return '#FF1815';
      }
      if (this.jobData.dataTaskState === 'COMPLETE') {
        return '#808695';
      }
      return '#52C41A';
    },
    getStepColor() {
      if (this.jobData.currentStatus === 'COMPLETE') {
        return '#9EE6AA';
      }
      if (this.jobData.currentStatus === 'STOP') {
        return '#FFBE56';
      }
      if (this.jobData.currentStatus === 'ABNORMAL') {
        return '#FF7674';
      }
      return '#6ED0FF';
    },
    disabledEditJob() {
      let disabled = false;
      disabled = Boolean(this.parentDataJobId) || (((this.jobData.dataTaskState !== 'INCRE' && this.jobData.dataTaskState !== 'CATCH_UP' && ((this.jobData.dataTaskState !== 'FULL' && this.jobData.dataTaskState !== 'CHECK') || (this.jobData.currentStatus !== 'STOP' && this.jobData.currentStatus !== 'WAIT_STOP'))
          || this.DataSourceGroup.noEditTarget.includes(this.jobData.targetDsVO.dataSourceType) || this.DataSourceGroup.noEditSource.includes(this.jobData.sourceDsVO.dataSourceType))));
      return disabled;
    },
    showManuelMergeBtn() {
      return this.jobData.parentJobId > 0 && this.jobData.dataJobEdit && this.jobData.dataTaskState === 'INCRE';
    }
  },
  data() {
    return {
      activePkgLoading: false,
      alarmLevels: [],
      restartList: [],
      showRestartListModal: false,
      parentDataJobId: '',
      positionLoading: false,
      mqSchemaTemplate: '',
      mqSchema: {},
      fecha,
      DataSourceGroup,
      posTypeList: [],
      schemaLessValueFormat: 'CLOUDCANAL_JSON_FOR_MQ',
      currentPkgDeleteStatus: '',
      verifyCode: '',
      showClearPosition: false,
      showDeletePkg: false,
      currentDeletetStatus: '',
      sendcodeDisabled: true,
      showPackage: false,
      sendCodeAgainTime: 69,
      showEditJob: false,
      logFileName: '',
      logLoading: false,
      logPreLoading: false,
      logNextLoading: false,
      showLog: false,
      showConfirm: false,
      selectedIndex: 0,
      ifShowStep: false,
      showUploadPkg: false,
      showUpdatePkgProgress: false,
      currentPercentage: 0,
      showUpdatePkg: false,
      customPkgFile: null,
      pkgDescription: '',
      selectedPkg: '',
      sourceTable: {},
      sourceDetail: {},
      sinkDetail: {},
      currentState: '',
      queryJobSchema: {},
      resourceType: 'DATA_JOB',
      store,
      sendCodeAgain: '',
      startingInterval: '',
      restartingInterval: '',
      stoppingInterval: '',
      sinkDbSchemaRelation: {},
      sourceDbSchemaRelation: {},
      selectedJob: {},
      showEditJobDesc: false,
      showWhereCondition: false,
      currentWhereCondition: '',
      tableFilter: {},
      MappingUtil,
      showEditAlarmRule: false,
      showJobOptions: false,
      indicators: [],
      trueColumnData: {},
      showPartition: false,
      activePackage: {},
      logData: [],
      selectedLog: {},
      partition: { partition: [] },
      sourceSchema: [],
      targetSchema: [],
      mappingDef: [],
      addRule: {
        indicators: [{
          indicator: '',
          value: '',
          symbol: ''
        }],
        methods: ['sms', 'dingding'],
        sendAdmin: false
      },
      taskExceptionMethods: [],
      taskExceptionSendAdmin: false,
      taskExceptionExpress: '',
      exceptionConfig: {},
      delayAlertOn: false,
      transTime: 0,
      jobInfoColumn: [
        {
          title: this.$t('ren-wu-xin-xi'),
          slot: 'jobInfo'
        }
      ],
      jobInfoData: [
        {}
      ],
      dataSourceInfoColumn: [
        {
          title: this.$t('yuan-shu-ju-yuan-xin-xi'),
          slot: 'dataSourceInfo'
        }
      ],
      dataSourceInfoData: [{}],
      targetInfoColumn: [
        {
          title: this.$t('mu-biao-shu-ju-yuan-xin-xi'),
          slot: 'targetInfo'
        }
      ],
      targetInfoData: [{}],
      showTableMapping: {},
      showFullTableList: [],
      filterTableName: '',
      filterJobStatus: ['running', 'wait', 'finish'],
      jobPanelCount: {
        BUILD_STRUCT: '1',
        FULL: '2',
        INCRE: '3',
        CATCH_UP: '3',
        CHECK: '4'
      },
      jobStateMapping: {
        BUILD_STRUCT: 'BUILD_STRUCT',
        FULL: 'FULL',
        INCRE: 'INCREMENT',
        CATCH_UP: 'INCREMENT',
        CHECK: 'CHECK',
        REVISE: 'REVISE',
        COMPLETE: 'COMPLETE'
      },
      starting: false,
      restarting: false,
      stopping: false,
      showResetPosition: false,
      hasInterval: false,
      hasFullInterval: false,
      canRevise: false,
      canCheck: false,
      showCreateCheck: false,
      positionInfo: {
        binlogTime: new Date(),
        gtid: ''
      },
      refreshLoading: false,
      showEditBinlogTime: false,
      showDataJobDetailInterval: {},
      jobId: 0,
      mappingPageSize: 4,
      taskMap: {},
      taskList: {},
      showMapping: false,
      showJobDetail: '-1',
      currentStep: 0,
      currentStatus: 'process',
      showStep: 1,
      fullSize: 5,
      fullPage: 1,
      fullTotal: 0,
      checkTotal: 0,
      filters: {
        instance: 'all',
        job: 'all'
      },
      filterOthers: {
        filterInstances: [],
        dbOnInstance: {},
        database: this.$t('quan-bu')
      },
      panelType: 'job',
      metrics: [],
      metric: {},
      incrementMetric: {},
      checkMetric: {},
      reviseMetricConfig: {},
      time: new Time(),
      configFile: Config.mysql,
      fullProgress: {
        trans: 0,
        transPercent: 0
      },
      checkProgress: {
        checked: 6000,
        checkedPercent: 20
      },
      reviseProgress: {
      },
      structProgress: {
        progressPercent: 50,
        builded: 10,
        total: 20,
        currentSchema: 'db2',
        currentTable: 'table3'
      },
      taskPosition: {},
      jobData: {
        sourceDsVO: {},
        targetDsVO: {},
        gmtCreated: '2020-02-24T06:16:34.000+0000'
      },
      schemaData: null,
      fullTransData: [],
      showFullTransData: [],
      checkColumns: [
        {
          title: this.$t('shu-ju-ku'),
          key: 'schema',
          width: 150
        },
        {
          title: this.$t('biao-ming'),
          key: 'table',
          width: 200
        },
        {
          title: this.$t('diu-shi-hang-shu'),
          key: 'loss',
          width: 150
        },
        {
          title: this.$t('bu-yi-zhi-hang-shu'),
          key: 'diff',
          width: 150
        },
        {
          title: this.$t('jin-du'),
          slot: 'checkProgress'
        }
      ],
      checkData: [],
      showCheckData: [],
      checkPage: 1,
      checkSize: 10,
      structData: [],
      showStructData: [],
      structPage: 1,
      commonSelectColumns: {},
      fullMetricConfig: {},
      incrementMetricConfig: {},
      addedPackage: {},
      jobOptions: {},
      jobOptionsForm: [],
      buildStructLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, this.$t('jie-gou-qian-yi')),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('BUILD_STRUCT')
          }
        })
      ]),
      fullLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, this.$t('quan-liang-qian-yi-0')),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('FULL')
          }
        })
      ]),
      increLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, this.$t('zeng-liang-tong-bu-1')),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('INCREMENT')
          }
        })
      ]),
      checkLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, this.$t('shu-ju-xiao-yan-0')),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('CHECK')
          }
        })]),
      reverseLabel: (h) => h('div', [
        h('span', {
          style: {
            marginLeft: '18px'
          }
        }, this.$t('shu-ju-ding-zheng-0')),
        h('span', {
          style: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            marginLeft: '8px',
            display: 'inline-block',
            background: this.getTabColor('REVISE')
          }
        })
      ]),
      packageColumns: [
        {
          title: this.$t('bao-ming-0'),
          key: 'fileName'
        },
        {
          title: this.$t('miao-shu'),
          slot: 'desc'
        },
        {
          title: this.$t('bao-da-xiao-mb'),
          key: 'fileSizeMb',
          maxWidth: 120
        },
        {
          title: this.$t('chuang-jian-shi-jian'),
          key: 'gmtCreate',
          width: 180
        },
        {
          title: this.$t('shang-chuan-yong-hu'),
          key: 'uploadUserName',
          width: 100
        },
        {
          title: this.$t('zhuang-tai'),
          slot: 'status',
          width: 120
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'action',
          width: 160
        }
      ],
      packageData: [],
      loopRunTypes: [],
      checkReviseOpts: []
    };
  },
  methods: {
    isKafka,
    isMQ,
    async canAddReviseTask() {
      const res = await canAddReviseTask({
        jobId: this.jobData.dataJobId
      });
      if (res.data.success) {
        if (res.data.data) {
          this.canRevise = true;
        }
      }
    },
    handleAddCheckJob() {
      this.showCreateCheck = true;
    },
    handleConfirmCreateCheck(data) {
      console.log('checkconfig', data);
      addCheckChildJob({
        jobId: this.jobId,
        checkPeriod: data.checkMode === 'checkPeriod',
        checkPeriodCronExpr: parseCron(data.checkPeriodDate),
        dataReviseType: data.dataReviseType
      }).then((res) => {
        if (res.data.success) {
          this.showCreateCheck = false;
          this.$Message.success(this.$t('chuang-jian-shu-ju-jiao-yan-ren-wu-cheng-gong'));
        }
      });
    },
    handleAddReviseJob() {
      addrevisetask({
        jobId: this.jobData.dataJobId
      }).then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('chuang-jian-shu-ju-ding-zheng-ren-wu-cheng-gong'));
        }
      });
    },
    showReplayBtn() {
      return ((this.jobData.dataJobType === 'CHECK' || this.jobData.dataJobType === 'REVISE') && (this.jobData.currentStatus === 'STOP' || this.jobData.dataTaskState === 'COMPLETE')
        || ((this.getDataTask(this.jobData.dataTasks).FULL
          && (this.jobData.dataTaskState === 'COMPLETE'
            || this.jobData.currentStatus === 'STOP')))
        && this.getDataTask(this.jobData.dataTasks).INCREMENT && this.getDataTask(this.jobData.dataTasks).INCREMENT.workerIp);
    },
    handleReplayInDetail() {
      this.handleReplay({
        dataJobId: this.jobData.dataJobId,
        dataJobName: this.jobData.dataJobName
      });
    },
    handleCloseAlarmRuleModal() {
      this.showEditAlarmRule = false;
    },
    getAddedData(type, sourceDb, sourceTable) {
      const sourceType = this.jobData.sourceDsVO.dataSourceType;
      const data = [];

      if (type === 'TABLE') {
        if (isPG(sourceType)) {
          sourceDb.schemas[0].tables.forEach((item) => {
            if (item.targetAutoCreate) {
              data.push(item);
            }
          });
        } else if (isOracle(sourceType)) {
          sourceDb.tableSpaces[0].tables.forEach((item) => {
            if (item.targetAutoCreate) {
              data.push(item);
            }
          });
        } else if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
          sourceDb.collections.forEach((item) => {
            if (item.targetAutoCreate) {
              data.push(item);
            }
          });
        } else {
          sourceDb.tables.forEach((item) => {
            if (item.targetAutoCreate) {
              data.push(item);
            }
          });
        }
      }
      if (type === 'COLUMN') {
        if (sourceTable.columns) {
          sourceTable.columns.map((column) => {
            if (column.targetAutoCreate) {
              data.push(column);
            }
            return null;
          });
        }
      }
      return data;
    },
    handleCloseRestartListModal() {
      this.showRestartListModal = false;
    },
    handleTabChange(name) {
      if (name === 'BUILD_STRUCT') {
        this.initSchema();
      }
      this.logFileName = '';
      this.selectedLog = {};
    },
    handleRefresh() {
      console.log('Refreshing');
      this.refreshLoading = true;
      this.queryData('refresh');
      if (this.$refs.incrementDetail) {
        this.$refs.incrementDetail.handleRender();
      }
      if (this.$refs.fullDetail) {
        this.$refs.fullDetail.handleRender();
      }
      if (this.$refs.checkDetail) {
        this.$refs.checkDetail.handleRender();
      }
      if (this.$refs.reviseDetail) {
        this.$refs.reviseDetail.handleRender();
      }
    },
    queryData(type) {
      queryJobById({ jobId: this.jobId })
        .then((res) => {
          try {
            this.refreshLoading = false;
            this.jobData = res.data.data;
            if (this.jobData.targetDsVO.dbSchemaRelation) {
              this.sinkDbSchemaRelation = JSON.parse(this.jobData.targetDsVO.dbSchemaRelation.configValue);
            }
            if (this.jobData.sourceDsVO.dbSchemaRelation) {
              this.sourceDbSchemaRelation = JSON.parse(this.jobData.sourceDsVO.dbSchemaRelation.configValue);
            }
            if (this.jobData.state === 'INIT') {
              this.currentStep = 0;
            }
            if (this.jobData.dataTaskState === 'COMPLETE') {
              this.jobData.currentStatus = 'COMPLETE';
            } else {
              this.jobData.dataTasks.map((task) => {
                if ((this.jobData.dataTaskState === 'CATCH_UP' || this.jobData.dataTaskState === 'INCRE') && task.dataTaskType === 'INCREMENT') {
                  this.jobData.currentStatus = task.dataTaskStatus;
                } else if (task.dataTaskType === this.jobData.dataTaskState) {
                  this.jobData.currentStatus = task.dataTaskStatus;
                }
                return null;
              });
            }
            this.showJobDetail = this.jobPanelCount[this.jobData.dataTaskState];
            if (type !== 'refresh') {
              let state = this.jobStateMapping[this.jobData.dataTaskState];

              if (state === 'COMPLETE') {
                const lastState = this.jobData.dataTasks[this.jobData.dataTasks.length - 1].dataTaskType;

                if (lastState === 'CATCH_UP') {
                  this.currentState = 'INCREMENT';
                } else {
                  this.currentState = lastState;
                }
              } else {
                if (!state && this.jobData.dataTasks && this.jobData.dataTasks.length) {
                  state = this.jobData.dataTasks[0].dataTaskType;
                }

                this.currentState = state;
              }
            }
            this.jobData.dataTasks.map((item) => {
              this.taskList[item.dataTaskType] = item;
              if (item.dataTaskType === 'FULL') {
                this.fullProgress = JSON.parse(item.taskPosition);
                if (this.fullProgress.transTableDetail) {
                  const transTableDetail = JSON.parse(this.fullProgress.transTableDetail);

                  this.fullTransData = [];
                  Object.keys(transTableDetail)
                    .map((table) => {
                      if (DataSourceGroup.hasSchema.includes(this.jobData.sourceDsVO.dataSourceType)) {
                        this.fullTransData.push({
                          dbName: transTableDetail[table].dbName,
                          schema: transTableDetail[table].schema,
                          table: transTableDetail[table].tableName,
                          trans: transTableDetail[table].handledRecordCount,
                          transPercent: transTableDetail[table].percentage
                        });
                      } else {
                        this.fullTransData.push({
                          schema: transTableDetail[table].schema || transTableDetail[table].dbName,
                          table: transTableDetail[table].tableName,
                          trans: transTableDetail[table].handledRecordCount,
                          transPercent: transTableDetail[table].percentage
                        });
                      }
                      return null;
                    });
                  this.showFullTableList = _.cloneDeep(this.fullTransData);
                  this.showFullTransData = this.showFullTableList.slice((this.fullPage - 1) * this.fullSize, this.fullPage * this.fullSize);
                  this.fullTotal = this.showFullTableList.length;
                }
              } else if (item.dataTaskType === 'INCREMENT') {
                this.taskPosition = JSON.parse(item.taskPosition);
                this.delayAlertOn = item.delayAlertOn;
              } else if (item.dataTaskType === 'CHECK') {
                this.checkProgress = JSON.parse(item.taskPosition);
                if (this.checkProgress.checkTableDetail) {
                  const checkTableDetail = JSON.parse(this.checkProgress.checkTableDetail);

                  const checkData = [];
                  Object.keys(checkTableDetail)
                    .map((table) => {
                      checkData.push({
                        schema: checkTableDetail[table].schema,
                        table: checkTableDetail[table].tableName,
                        loss: checkTableDetail[table].loss,
                        diff: checkTableDetail[table].diff,
                        handledRecordCount: checkTableDetail[table].handledRecordCount,
                        checkPercent: checkTableDetail[table].percentage
                      });
                      return null;
                    });

                  this.checkData = checkData;
                  this.rawCheckData = _.cloneDeep(checkData);
                  this.checkTotal = this.checkData.length;
                  this.showCheckData = this.checkData.slice((this.checkPage - 1) * this.checkSize, this.checkPage * this.checkSize);
                }
              } else if (item.dataTaskType === 'REVISE') {
                this.reviseProgress = JSON.parse(item.taskPosition);
                if (this.reviseProgress.revisePosDetail) {
                  this.reviseProgress.revisePosDetailObj = JSON.parse(this.reviseProgress.revisePosDetail);
                }
              } else if (item.dataTaskType === 'BUILD_STRUCT') {
                this.initSchema();
              }
              if (this.taskList.BUILD_STRUCT && this.taskList.FULL && this.taskList.INCREMENT) {
                this.showStep = 1;
                if (this.jobData.dataTaskState === 'BUILD_STRUCT') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'FULL') {
                  this.currentStep = 2;
                } else if (this.jobData.dataTaskState === 'INCRE' || this.jobData.dataTaskState === 'CATCH_UP') {
                  this.currentStep = 3;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 5;
                }
              } else if (!this.taskList.BUILD_STRUCT && this.taskList.FULL && this.taskList.INCREMENT) {
                this.showStep = 4;
                if (this.jobData.dataTaskState === 'FULL') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'INCRE' || this.jobData.dataTaskState === 'CATCH_UP') {
                  this.currentStep = 2;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 4;
                }
              } else if (!this.taskList.BUILD_STRUCT && this.taskList.FULL && !this.taskList.INCREMENT) {
                this.showStep = 3;
                if (this.jobData.dataTaskState === 'FULL') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 3;
                }
              } else if (this.taskList.BUILD_STRUCT && this.taskList.FULL && !this.taskList.INCREMENT) {
                this.showStep = 2;
                if (this.jobData.dataTaskState === 'BUILD_STRUCT') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'FULL') {
                  this.currentStep = 2;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 4;
                }
              } else if (this.taskList.BUILD_STRUCT && !this.taskList.FULL && this.taskList.INCREMENT) {
                this.showStep = 6;
                if (this.jobData.dataTaskState === 'BUILD_STRUCT') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'INCRE' || this.jobData.dataTaskState === 'CATCH_UP') {
                  this.currentStep = 2;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 4;
                }
              } else if (!this.taskList.BUILD_STRUCT && !this.taskList.FULL && this.taskList.INCREMENT) {
                this.showStep = 5;
                if (this.jobData.dataTaskState === 'INCRE' || this.jobData.dataTaskState === 'CATCH_UP') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 3;
                }
              } else if (!this.taskList.BUILD_STRUCT && !this.taskList.FULL && !this.taskList.INCREMENT && this.taskList.CHECK) {
                this.showStep = 7;
                if (this.jobData.dataTaskState === 'CHECK') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 2;
                }
              } else if (this.taskList.BUILD_STRUCT && !this.taskList.FULL && !this.taskList.INCREMENT && !this.taskList.CHECK) {
                this.showStep = 8;
                if (this.jobData.dataTaskState === 'STRUCT_MIGRATION') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 2;
                }
              } else if (this.taskList.REVISE) {
                this.showStep = 9;
                if (this.jobData.dataTaskState === 'REVISE') {
                  this.currentStep = 1;
                } else if (this.jobData.dataTaskState === 'COMPLETE') {
                  this.currentStep = 2;
                }
              }

              this.jobData.dataTasks.map((task) => {
                this.taskMap[task.dataJobType] = task;
                return null;
              });
              return null;
            });
            if (this.currentState === 'BUILD_STRUCT') {
              this.initSchema();
            }

            if (this.taskList.FULL) {
              queryTaskDetailMonitorConfig({
                dataJobId: this.jobId,
                dataTaskId: this.taskList.FULL.dataTaskId
              })
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.fullMetricConfig = res1.data.data;

                    this.formatFullMetric(this.fullMetricConfig);
                  }
                });
            }
            if (this.taskList.REVISE) {
              queryTaskDetailMonitorConfig({
                dataJobId: this.jobId,
                dataTaskId: this.taskList.REVISE.dataTaskId
              })
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.reviseMetricConfig = res1.data.data;

                    this.formatReviseMetric(this.reviseMetricConfig);
                  }
                });
            }
            if (this.taskList.INCREMENT) {
              queryTaskDetailMonitorConfig({
                dataJobId: this.jobId,
                dataTaskId: this.taskList.INCREMENT.dataTaskId
              })
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.incrementMetric = res1.data.data;

                    this.formatIncrMetric(this.incrementMetric);
                  }
                });
            }
            if (this.taskList.CHECK) {
              queryTaskDetailMonitorConfig({
                dataJobId: this.jobId,
                dataTaskId: this.taskList.CHECK.dataTaskId
              })
                .then((res1) => {
                  if (res1.data.code === '1') {
                    this.checkMetric = res1.data.data;
                    this.formatCheckMetric(this.checkMetric);
                  }
                });
            }
            if (this.jobData.havePackage) {
              this.listPackages();
            }
            console.log(1, (this.jobData.dataJobType === 'SYNC' || this.jobData.dataJobType === 'MIGRATION') && (!this.jobData.parentJobId || this.jobData.parentJobId === 0));
            if ((this.jobData.dataJobType === 'SYNC' || this.jobData.dataJobType === 'MIGRATION') && (!this.jobData.parentJobId || this.jobData.parentJobId === 0)) {
              transformJobType({
                sourceType: this.jobData.sourceDsVO.dataSourceType,
                targetType: this.jobData.targetDsVO.dataSourceType
              }).then((res1) => {
                if (res1.data.success) {
                  console.log('res1.data.data.CHECK', res1.data.data.optionType.CHECK);
                  if (res1.data.data.optionType.CHECK) {
                    this.canCheck = true;
                    dsCheckReviseOpts({
                      srcDsType: this.jobData.sourceDsVO.dataSourceType,
                      dstDsType: this.jobData.targetDsVO.dataSourceType
                    }).then((res2) => {
                      if (res2.data.success) {
                        this.checkReviseOpts = res2.data.data;
                      }
                    });
                    listLoopRunTypes().then((res2) => {
                      if (res2.data.code === '1') {
                        this.loopRunTypes = res2.data.data;
                      }
                    });
                  }
                }
              });
            }
            if (type === 'init') {
              const that = this;

              setTimeout(() => {
                that.init();
                // that.ifShowStep = true;
              }, 1000);
            }

            if (type === 'refresh' && this.$refs.fullDetail) {
              const { theFilterJobStatus, theFilterTableName } = this.$refs.fullDetail;
              this.handleFilterFullTable(theFilterJobStatus, theFilterTableName);
            }

            this.canAddReviseTask();
          } catch (e) {
            console.log('e', e);
          }
          this.dsPosTypes();
        })
        .catch((e) => {
          console.log(e);
          this.refreshLoading = false;
        });
    },
    formatFullMetric(data) {
      data.config[0].options.queries.map((query) => {
        if (query.metricNamePattern.indexOf('sum(') > -1) {
          let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
          const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

          a = a.replace('\'', '');

          query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.FULL.dataTaskId}"}) by${b}`;
        } else if (query.metricNamePattern.indexOf('increase') > -1) {
          let a = query.metricNamePattern.split('[')[0].split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
        } else {
          let a = query.metricNamePattern.split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = a;
          query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
        }
        return null;
      });
    },
    formatReviseMetric(data) {
      data.config[0].options.queries.map((query) => {
        if (query.metricNamePattern.indexOf('sum(') > -1) {
          let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
          const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

          a = a.replace('\'', '');

          query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.REVISE.dataTaskId}"}) by${b}`;
        } else if (query.metricNamePattern.indexOf('increase') > -1) {
          let a = query.metricNamePattern.split('[')[0].split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
        } else {
          let a = query.metricNamePattern.split('{')[0];
          a = a.replace('\'', '');
          query.metricNamePattern = a;
          query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
        }
        return null;
      });
    },
    formatCheckMetric(data) {
      data.config[0].options.queries.map((query) => {
        if (query.metricNamePattern.indexOf('sum(') > -1) {
          let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
          const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

          a = a.replace('\'', '');

          query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.REVISE.dataTaskId}"}) by${b}`;
        } else if (query.metricNamePattern.indexOf('increase') > -1) {
          let a = query.metricNamePattern.split('[')[0].split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
        } else {
          let a = query.metricNamePattern.split('{')[0];
          a = a.replace('\'', '');
          query.metricNamePattern = a;
          query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
        }
        return null;
      });
    },
    formatIncrMetric(data) {
      data.config[0].options.queries.map((query) => {
        if (query.metricNamePattern.indexOf('sum(') > -1) {
          let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
          const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.INCREMENT.dataTaskId}"}) by${b}`;
        } else if (query.metricNamePattern.indexOf('increase') > -1) {
          let a = query.metricNamePattern.split('[')[0].split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
        } else {
          let a = query.metricNamePattern.split('{')[0];

          a = a.replace('\'', '');
          query.metricNamePattern = a;
          query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
        }
        return null;
      });
    },
    handleParamsEdit() {
      this.$router.push({
        path: `/data/job/${this.jobId}/params`
      });
    },
    handleMoreMonitor(id, type) {
      if (type === 'FULL') {
        this.$router.push({
          path: `/monitor/job/full/graph/${this.jobId}/${id}`
        });
      } else {
        this.$router.push({
          path: `/monitor/job/graph/${this.jobId}/${id}`
        });
      }
    },
    handleShowMapping() {
      this.$Spin.show();

      queryJobSchemaById({
        jobId: this.jobId,
        srcDsType: this.jobData.sourceDsVO.dataSourceType,
        dstDsType: this.jobData.targetDsVO.dataSourceType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.schemaData = res.data.data;
            this.schemaLessValueFormat = res.data.data.dstSchemaLessFormat || res.data.data.srcSchemaLessFormat;

            this.sourceSchema = JSON.parse(res.data.data.sourceSchema);
            this.mappingDef = JSON.parse(res.data.data.mappingConfig);
            if (res.data.data.targetSchema) {
              this.targetSchema = JSON.parse(res.data.data.targetSchema);
            }
            if (this.schemaLessValueFormat && (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType) || DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType))) {
              schemaLessValueFormatTemplate({
                schemaLessValueFormat: this.schemaLessValueFormat
              })
                .then((res1) => {
                  this.mqSchemaTemplate = res1.data.data;
                });
            }

            this.getConfigMapping();

            let db = this.sourceSchema[0];

            if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              this.sourceTable = db.schemas[0].tables[0];
            } else if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              this.sourceTable = db.tableSpaces[0].tables[0];
            } else if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
              db = this.targetSchema[0];
              this.sourceTable = db.tables[0];
            } else if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
              this.sourceTable = db.collections[0];
            } else {
              this.sourceTable = db.tables[0];
            }

            this.getColumnData(db, this.sourceTable);
            this.showMapping = true;
          }
          this.$Spin.hide();
        })
        .catch((e) => {
          console.error(e);
          this.$Spin.hide();
        });
    },
    getConfigMapping() {
      const sourceType = this.jobData.sourceDsVO.dataSourceType;
      this.structData = [];
      this.sourceSchema.forEach((db) => {
        this.tableFilter[db.db] = '';
        if (isMongoDB(sourceType)) {
          this.showTableMapping[db.db] = {
            list: [],
            page: 1,
            size: 4,
            total: db.collections.length
          };
          this.showTableMapping[db.db].list = db.collections.slice(0, this.mappingPageSize);
        } else if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          this.showTableMapping[db.db] = {
            list: [],
            page: 1,
            size: 4,
            total: db.schemas[0].tables.length
          };
          this.showTableMapping[db.db].list = db.schemas[0].tables.slice(0, this.mappingPageSize);
        } else if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          this.showTableMapping[db.db] = {
            list: [],
            page: 1,
            size: 4,
            total: db.tableSpaces[0].tables.length
          };
          this.showTableMapping[db.db].list = db.tableSpaces[0].tables.slice(0, this.mappingPageSize);
        } else if (DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) === -1) {
          this.showTableMapping[db.db] = {
            list: [],
            page: 1,
            size: 4,
            total: db.tables.length
          };
          this.showTableMapping[db.db].list = db.tables.slice(0, this.mappingPageSize);
        }
        if (DataSourceGroup.mq.indexOf(this.jobData.sourceDsVO.dataSourceType) === -1) {
          if (this.getAddedData('TABLE', db).length > 0) {
            this.getAddedData('TABLE', db)
              .map((table) => {
                if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
                  this.structData.push({
                    schema: db.db,
                    table: table.collection
                  });
                } else {
                  this.structData.push({
                    schema: db.db,
                    table: table.table
                  });
                }
                return null;
              });
          } else {
            this.getAddedData('TABLE', db)
              .map((table) => {
                if (this.getAddedData('Column', db, table).length > 0) {
                  if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
                    this.structData.push({
                      schema: db.db,
                      table: table.collection
                    });
                  } else {
                    this.structData.push({
                      schema: db.db,
                      table: table.table
                    });
                  }
                }
                return null;
              });
          }
          this.showStructData = this.structData.slice((this.structPage - 1) * 10, this.structPage * 10);
        }
        this.handleMqSchema(db);
      });
      if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
        const tables = [];
        let dbName = '';
        this.mappingDef.forEach((mapping) => {
          if (mapping.method === 'TOPIC_TOPIC' || mapping.method === 'TOPIC_TABLE' || mapping.method === 'TOPIC_INDEX') {
            Object.keys(mapping.serializeMapping).forEach((key) => {
              const table = {
                topic: JSON.parse(key).value,
                table: JSON.parse(key).value
              };
              tables.push(table);
            });
          }

          if (mapping.method === 'ANY_DB') {
            if (mapping.serializeMapping[JSON.stringify({ value: 'ANY_DB' })]) {
              dbName = JSON.parse(mapping.serializeMapping[JSON.stringify({ value: 'ANY_DB' })]).value;
            }
          }

          if (mapping.method === 'ANY_SCHEMA') {
            const data = JSON.parse(mapping.serializeMapping[JSON.stringify({ value: 'ANY_SCHEMA' })]);
            dbName = `${data.parent.value}.${data.value}`;
          }
        });
        if (isMQ(this.jobData.sourceDsVO.dataSourceType)) {
          dbName = this.targetSchema[0].db;
        }
        const db = {
          db: dbName,
          tables
        };
        this.targetSchema = [db];

        this.showTableMapping[db.db] = {
          list: [],
          page: 1,
          size: 4,
          total: db.tables.length
        };

        this.showTableMapping[db.db].list = db.tables.slice(0, this.mappingPageSize);
      }
    },
    handleShowDetail() {
      this.handleRender();
    },
    initSchema() {
      queryJobSchemaById({
        jobId: this.jobId,
        srcDsType: this.jobData.sourceDsVO.dataSourceType,
        dstDsType: this.jobData.targetDsVO.dataSourceType
      })
        .then((response) => {
          if (response.data.code === '1') {
            this.schemaLessValueFormat = response.data.data.dstSchemaLessFormat || response.data.data.srcSchemaLessFormat;
            this.sourceSchema = JSON.parse(response.data.data.sourceSchema);
            this.mappingDef = JSON.parse(response.data.data.mappingConfig);
            if (response.data.data.targetSchema) {
              this.targetSchema = JSON.parse(response.data.data.targetSchema);
            }
            if (this.schemaLessValueFormat && (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType) || DataSourceGroup.mq.includes(this.jobData.targetDsVO.dataSourceType))) {
              schemaLessValueFormatTemplate({
                schemaLessValueFormat: this.schemaLessValueFormat
              })
                .then((res1) => {
                  this.mqSchemaTemplate = res1.data.data;
                });
            }

            this.structData = [];
            this.getConfigMapping();
          }
        });
    },
    handleRestartJob() {
      this.$Modal.confirm({
        title: this.$t('zhong-qi-ren-wu-que-ren'),
        content: this.$t('qing-que-ren-shi-fou-yao-zhong-qi-gai-ren-wu-zhong-qi-zhong-jiang-chu-xian-ren-wu-zhong-duan'),
        onOk: () => {
          restartJob({
            jobId: this.jobId,
            dataJobName: this.jobData.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.restarting = true;
                const that = this;

                setTimeout(() => {
                  that.restarting = false;
                  that.handleRefresh();
                }, 1000);
              }
            });
        }
      });
    },
    handleStopJob() {
      this.$Modal.confirm({
        title: this.$t('ting-zhi-ren-wu-que-ren'),
        content: this.$t('qing-que-ren-shi-fou-yao-ting-zhi-gai-ren-wu-ting-zhi-jiang-shi-ren-wu-zhong-duan'),
        onOk: () => {
          stopJob({
            jobId: this.jobId,
            dataJobName: this.jobData.dataJobName
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.stopping = true;
                const that = this;
                setTimeout(() => {
                  that.handleRefresh();
                  that.stopping = false;
                }, 1000);
              }
            });
        }
      });
    },
    handleStartJob() {
      startJob({
        jobId: this.jobId,
        dataJobName: this.jobData.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.starting = true;
            const that = this;

            setTimeout(() => {
              that.handleRefresh();
              that.starting = false;
            }, 1000);
          }
        });
    },
    handleStartFsm() {
      activeFsm({
        jobId: this.jobId,
        dataJobName: this.jobData.dataJobName
      })
        .then((res) => {
          if (res.data.code === '1') {
            const that = this;

            setTimeout(() => {
              that.queryData();
            }, 2000);
            this.$Message.success(this.$t('qi-dong-cheng-gong'));
          }
        });
    },
    init() {
      try {
        const metrics1 = this.incrementMetric;
        const metrics2 = this.fullMetricConfig;
        const metrics3 = this.checkMetric;
        const metrics4 = this.reviseMetricConfig;

        this.metric = {};
        this.incrementMetric = {};
        this.metrics = metrics1;
        if (this.taskList.INCREMENT) {
          if (metrics1.config) {
            metrics1.queries = metrics1.config[0].options.queries;
            this.formatIncrMetric(metrics1);
            this.incrementMetric = metrics1;
          }
        }
        if (this.taskList.FULL) {
          if (metrics2.config) {
            metrics2.queries = metrics2.config[0].options.queries;
            this.formatFullMetric(metrics2);
            this.metric = metrics2;
          }
        }

        if (this.taskList.REVISE) {
          if (metrics4.config) {
            metrics4.queries = metrics4.config[0].options.queries;
            this.formatReviseMetric(metrics4);
            this.reviseMetric = metrics4;
          }
        }
        if (this.taskList.CHECK) {
          if (metrics3.config) {
            metrics3.queries = metrics3.config[0].options.queries;
            metrics3.config[0].options.queries.map((query) => {
              if (query.metricNamePattern.indexOf('sum(') > -1) {
                let a = _.cloneDeep(query.metricNamePattern.split('{')[0]);
                const b = _.cloneDeep(query.metricNamePattern.split('by')[1]);

                a = a.replace('\'', '');

                query.metricNamePattern = `${a}{job_id="${this.jobId}",task_id="${this.taskList.CHECK.dataTaskId}"}) by${b}`;
              } else if (query.metricNamePattern.indexOf('increase') > -1) {
                let a = query.metricNamePattern.split('[')[0].split('{')[0];

                a = a.replace('\'', '');
                query.metricNamePattern = `${a}'{'job_id="{0}",task_id="{1}"'}'[${query.metricNamePattern.split('[')[1]}`;
              } else {
                let a = query.metricNamePattern.split('{')[0];

                a = a.replace('\'', '');
                query.metricNamePattern = a;
                query.metricNamePattern += '\'{\'job_id="{0}",task_id="{1}"\'}\'';
              }
              return null;
            });
            this.checkMetric = metrics3;
          }
        }

        this.handleRefresh();
      } catch (e) {
        console.log('e', e);
      }
    },
    handleRender() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric1) {
          that.$refs.metric1.$emit('render');
        } else {
          _.forEach(that.$refs.metrics, (m) => {
            m.$emit('render');
          });
        }
        if (that.$refs.metric2) {
          that.$refs.metric2.$emit('render');
        }
        if (that.$refs.metric3) {
          that.$refs.metric3.$emit('render');
        }
      }, 100);
    },
    handleRenderFull() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric1) {
          that.$refs.metric1.$emit('render');
        } else {
          _.forEach(that.$refs.metrics, (m) => {
            m.$emit('render');
          });
        }
      }, 100);
    },
    handleRenderIncrement() {
      const that = this;

      setTimeout(() => {
        if (that.$refs.metric2) {
          that.$refs.metric2.$emit('render');
        }
      }, 100);
    },
    handleFullPageChange(page) {
      this.fullPage = page;
      this.showFullTransData = this.showFullTableList.slice((this.fullPage - 1) * this.fullSize, this.fullPage * this.fullSize);
    },
    handleClearBinlog() {
      if (this.jobData.currentStatus === 'RUNNING') {
        this.$Modal.warning({
          title: this.$t('qing-chu-wei-dian-ti-xing'),
          content: this.$t('dang-qian-ren-wu-huan-zai-yun-hang-zhong-qing-xian-ting-zhi-ren-wu-zai-jin-hang-wei-dian-qing-chu')
        });
      } else {
        this.positionInfo.binlogTime = new Date();
        this.showClearPosition = true;
      }
    },
    handleChangeBinlogCancel() {
      this.showEditBinlogTime = false;
      this.showResetPosition = false;
      this.showClearPosition = false;
      this.showEditAlarmRule = false;
      this.showMapping = false;
    },
    handleGoFsm() {
      this.$router.push({ path: `/system/fsm/${this.jobData.fsmId}/${this.jobId}` });
    },
    handleGoWorker(task) {
      this.$router.push({ path: `/ccsystem/resource/${task.clusterId}?ip=${task.workerIp}&task=${task.dataTaskId}` });
    },
    handleShowConfirm() {
      if (this.jobData.currentStatus === 'RUNNING') {
        this.$Modal.warning({
          title: this.$t('chong-zhi-wei-dian-ti-xing'),
          content: this.$t('dang-qian-ren-wu-huan-zai-yun-hang-zhong-qing-xian-ting-zhi-ren-wu-zai-jin-hang-wei-dian-hui-su')
        });
      } else {
        this.showEditBinlogTime = false;
        this.showResetPosition = true;
      }
    },
    handleResetPosition(verifyCode, clearPosition, posType) {
      if (this.jobData.currentStatus === 'RUNNING') {
        this.$Modal.warning({
          title: this.$t('hui-su-wei-dian-ti-xing'),
          content: this.$t('dang-qian-ren-wu-huan-zai-yun-hang-zhong-qing-xian-ting-zhi-ren-wu-zai-jin-hang-wei-dian-hui-su')
        });
        this.showClearPosition = false;
        this.showResetPosition = false;
        this.positionInfo.binlogTime = new Date(this.taskPosition.positionTimestamp);
      } else {
        this.positionLoading = true;
        this.showEditBinlogTime = false;
        if (clearPosition) {
          resetPosTocurrent({
            taskId: this.taskList.INCREMENT.dataTaskId,
            posTypes: this.posTypeList,
            verifyCode
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.$Message.success(this.$t('hui-su-wei-dian-cheng-gong'));
                this.queryData();
                this.init();
                const that = this;

                setTimeout(() => {
                  that.handleRender();
                  that.showJobDetail = that.jobPanelCount[that.jobData.dataTaskState];
                }, 200);

                this.positionLoading = false;
                this.showClearPosition = false;
                this.showResetPosition = false;
                this.$refs['clear-position-modal'].handleEmptyVerifyCodeModalData();
              }
            });
        } else {
          updateIncrePos({
            taskId: this.taskList.INCREMENT.dataTaskId,
            posType,
            positionTimestamp: this.positionInfo.binlogTime.getTime(),
            journalFile: this.positionInfo.journalFile,
            filePosition: this.positionInfo.filePosition,
            gtidPosition: this.positionInfo.gtid,
            lsn: this.positionInfo.lsn,
            scn: this.positionInfo.scn,
            dataId: this.positionInfo.dataId,
            serverId: this.positionInfo.serverId,
            transactionId: this.positionInfo.transactionId
          }).then((res) => {
            if (res.data.code === '1') {
              this.$Message.success(this.$t('hui-su-wei-dian-cheng-gong'));
              this.queryData();
              this.init();
              const that = this;

              setTimeout(() => {
                that.handleRender();
                that.showJobDetail = that.jobPanelCount[that.jobData.dataTaskState];
              }, 200);

              this.positionLoading = false;
              this.showClearPosition = false;
              this.showResetPosition = false;
            }
          });
        }
      }
    },
    handleInterval() {
      const that = this;

      if (this.hasInterval) {
        this.hasInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasInterval = true;
        this.myInterval = setInterval(() => {
          that.queryData('refresh');
          that.handleRender();
          // that.getTransTime();
        }, 10000);
      }
    },
    handleFullInterval() {
      const that = this;

      if (this.hasFullInterval) {
        this.hasFullInterval = false;
        clearInterval(this.myInterval);
      } else {
        this.hasFullInterval = true;
        this.myInterval = setInterval(() => {
          that.handleRenderFull();
        }, 10000);
      }
    },
    handleGoDataSource(id) {
      this.$router.push({ path: `/data/dataSource/${id}` });
    },
    getMappingCount(type, sourceDb) {
      let count = 0;

      this.mappingDef.forEach((item) => {
        if (item.method === type) {
          if (type === 'TABLE_TABLE') {
            if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              sourceDb.schemas[0].tables.map((table) => {
                const mapping = {
                  parent: {
                    value: sourceDb.schemas[0].schema,
                    parent: {
                      value: sourceDb.db
                    }
                  },
                  value: table.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  count++;
                }
                return null;
              });
            } else if (DataSourceGroup.oracle.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
              sourceDb.tableSpaces[0].tables.map((table) => {
                const mapping = {
                  parent: {
                    value: sourceDb.tableSpaces[0].tableSpace,
                    parent: {
                      value: sourceDb.db
                    }
                  },
                  value: table.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  count++;
                }
                return null;
              });
            } else if (isMongoDB(this.jobData.sourceDsVO.dataSourceType)) {
              sourceDb.collections.map((table) => {
                const mapping = {
                  parent: sourceDb.db,
                  value: table.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  count++;
                }
                return null;
              });
            } else {
              sourceDb.tables.map((table) => {
                const mapping = {
                  parent: sourceDb.db,
                  value: table.table
                };

                if (item.serializeMapping[JSON.stringify(mapping)]) {
                  count++;
                }
                return null;
              });
            }
          }
        } else if (type === 'TABLE_TABLE_WITH_SCHEMA' && item.method === 'TABLE_TABLE') {
          if (sourceDb.tableSpaces) {
            sourceDb.tableSpaces[0].tables.forEach((table) => {
              const mapping = {
                parent: {
                  value: sourceDb.tableSpaces[0].tableSpace,
                  parent: {
                    value: sourceDb.db
                  }
                },
                value: table.table
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                count++;
              }
            });
          } else {
            sourceDb.schemas[0].tables.forEach((table) => {
              const mapping = {
                parent: {
                  value: sourceDb.schemas[0].schema,
                  parent: {
                    value: sourceDb.db
                  }
                },
                value: table.table
              };

              if (item.serializeMapping[JSON.stringify(mapping)]) {
                count++;
              }
            });
          }
        }
      });
      return count;
    },
    handleAlarmConfig() {
      this.showEditAlarmRule = true;
    },
    handleShowJobOptions() {
      queryJobOptions({ jobId: this.jobId }).then((res) => {
        if (res.data.success) {
          this.showJobOptions = true;
          this.jobOptionsForm = res.data.data;
          this.jobOptionsForm.forEach((op) => {
            if (op.type === 'SWITCH') {
              if (op.checked) {
                this.jobOptions[op.targetParameter] = true;
              }
            }
            if (op.preFillValue) {
              this.jobOptions[op.targetParameter] = op.preFillValue;
            }
          });
        }
      });
    },
    handleConfirmUpdateJobOptions() {
      updateJobOptions({
        jobId: this.jobId,
        options: this.jobOptions
      }).then((res) => {
        if (res.data.success) {
          this.queryData();
          this.showJobOptions = false;
        }
      });
    },
    handleFilterFullTable(data1, data2) {
      this.filterJobStatus = data1;
      this.showFullTableList = [];
      this.filterTableName = data2;
      this.fullTransData.map((item) => {
        if (this.filterTableName) {
          if (item.table.indexOf(this.filterTableName) > -1) {
            if (this.filterJobStatus.length < 3) {
              this.filterJobStatus.map((status) => {
                if (status === 'running') {
                  if (item.transPercent < 100 && item.transPercent > 0) {
                    this.showFullTableList.push(item);
                  }
                } else if (status === 'wait') {
                  if (item.transPercent === 0) {
                    this.showFullTableList.push(item);
                  }
                } else if (status === 'finish') {
                  if (item.transPercent === 100) {
                    this.showFullTableList.push(item);
                  }
                }
                return null;
              });
            } else {
              this.showFullTableList.push(item);
            }
          }
        } else if (this.filterJobStatus.length < 3) {
          this.filterJobStatus.map((status) => {
            if (status === 'running') {
              if (item.transPercent < 100 && item.transPercent > 0) {
                this.showFullTableList.push(item);
              }
            } else if (status === 'wait') {
              if (item.transPercent === 0) {
                this.showFullTableList.push(item);
              }
            } else if (status === 'finish') {
              if (item.transPercent === 100) {
                this.showFullTableList.push(item);
              }
            }
            return null;
          });
        } else {
          this.showFullTableList.push(item);
        }
        return null;
      });
      this.fullPage = 1;
      this.showFullTransData = this.showFullTableList.slice((this.fullPage - 1) * this.fullSize, this.fullPage * this.fullSize);
      this.fullTotal = this.showFullTableList.length;
    },
    handleShowWhereCondition(tableInfo, table, db) {
      if (tableInfo.type === 'SQL_WHERE' || tableInfo.type === 'SQL_WHERE_ADV') {
        let content = 'select ';

        table.columns.map((column, index) => {
          if (index < table.columns.length - 1) {
            content += `${column.column},`;
          } else {
            content += column.column;
          }
          return null;
        });

        content += ` from ${db.db}.${table.table} where ${tableInfo.expression};`;
        this.currentWhereCondition = content;
        this.showWhereCondition = true;
      }
    },
    handlePartition(targetDbTableConfig, db, table) {
      this.partition = targetDbTableConfig[db].tableConfig[table];
      this.showPartition = true;
    },
    getCheckExpression() {
      const weekMap = {
        1: this.$t('ri'),
        2: this.$t('yi'),
        3: this.$t('er'),
        4: this.$t('san'),
        5: this.$t('si'),
        6: this.$t('wu'),
        7: this.$t('liu')
      };
      const expressionArr = this.taskList.CHECK.checkPeriodCronExpr.split(' ');

      if (expressionArr[5] === '?') {
        if (expressionArr[2] === '*') {
          const hourStr = expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1];
          return this.$t('mei-xiao-shi-hourstr-fen-zhi-hang-yi-ci', [hourStr]);
        }
        const dayStr = expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1];
        return this.$t('mei-tian-expressionarr2daystr-zhi-hang-yi-ci', [expressionArr[2], dayStr]);
      }
      const weekStr = expressionArr[1] < 10 ? `0${expressionArr[1]}` : expressionArr[1];
      return this.$t('mei-zhou-weekmapexpressionarr5expressionarr2weekstr-zhi-hang-yi-ci', [weekMap[expressionArr[5]], expressionArr[2], weekStr]);
    },
    handleEditJobDesc() {
      this.selectedJob = _.cloneDeep(this.jobData);
      this.showEditJobDesc = true;
    },
    handleConfirmEdit() {
      this.showEditJobDesc = false;
      updateJob({
        jobId: this.jobId,
        dataJobDesc: this.selectedJob.dataJobDesc
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.queryData();
          }
        });
    },
    handleCheckSortChange(e) {
      const { key, order } = e;
      this.checkData = orderBy(this.checkData, [key], [order]);
      this.handleCheckPageChange(1);
    },
    handleCheckSearch(searchKey, key) {
      this.checkData = this.rawCheckData.filter((data) => data[key].includes(searchKey));
      this.checkTotal = this.checkData.length;
      this.handleCheckPageChange(1);
    },
    handleCheckPageChange(page) {
      this.checkPage = page;
      this.showCheckData = this.checkData.slice((this.checkPage - 1) * this.checkSize, this.checkPage * this.checkSize);
    },
    sameItem(sink, source) {
      let hasSame = false;

      if (this.jobData.targetCaseSensitive === 'false') {
        if (sink.toUpperCase() === source.toUpperCase()) {
          hasSame = true;
        }
      } else if (this.jobData.commonRule === 'UpperCase') {
        if (sink.toUpperCase() === source.toUpperCase()) {
          hasSame = true;
        }
      } else if (this.jobData.commonRule === 'LowerCase') {
        if (sink.toLowerCase() === source.toLowerCase()) {
          hasSame = true;
        }
      } else if (sink === source) {
        hasSame = true;
      }
      return hasSame;
    },
    handleStructPageChange(page) {
      this.structPage = page;
      this.showStructData = this.structData.slice((this.structPage - 1) * 10, this.structPage * 10);
    },
    getStatusName(row) {
      const type = MappingUtil.taskSateToTaskType[row.dataTaskState];

      if (type) {
        if (!this.getDataTask(row.dataTasks)[type]) {
          return this.$t('chu-shi-hua');
        }
        if (this.getDataTask(row.dataTasks)[type].dataTaskStatus === 'STOP') {
          return this.$t('yi-ting-zhi');
        }
        if (this.getDataTask(row.dataTasks)[type].healthLevel === 'Health') {
          return this.$t('zheng-chang');
        }
        return this.$t('yi-chang');
      }
      if (row.dataTaskState === 'COMPLETE') {
        return this.$t('yi-wan-cheng');
      }
      return this.$t('zheng-chang');
    },
    getStatusIcon(row) {
      const type = MappingUtil.taskSateToTaskType[row.dataTaskState];

      if (type) {
        if (!this.getDataTask(row.dataTasks)[type]) {
          return 'icon-chongqi';
        }
        if (this.getDataTask(row.dataTasks)[type].dataTaskStatus === 'STOP') {
          return 'icon-zanting';
        }
        if (this.getDataTask(row.dataTasks)[type].healthLevel === 'Health') {
          return 'icon-zhengchang';
        }
        return 'icon-yichang';
      }
      if (row.dataTaskState === 'COMPLETE') {
        return 'icon-wancheng';
      }
      return 'icon-zhengchang';
    },
    getDataTask(taskList) {
      const dataTasks = {};

      if (taskList) {
        taskList.map((item) => {
          dataTasks[item.dataTaskType] = item;
          return null;
        });
      }
      return dataTasks;
    },
    getCurrentStateColor(state) {
      const status = this.taskList[state].dataTaskStatus;

      switch (status) {
        case 'STOP':
          return '#FFBE56';
        case 'ABNORMAL':
          return '#FF7674';
        case 'RUNNING':
          return '#52C41A';
        default:
          return '#52C41A';
      }
    },
    handleCopyWorkerIp(data) {
      const aux = document.createElement('input');

      aux.setAttribute('value', data);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);

      this.$Message.success(this.$t('fu-zhi-cheng-gong'));
    },
    getDataSourceDetail() {
      queryById({ dataSourceId: this.jobData.sourceDsVO.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceDetail = res.data.data;
          }
        });
    },
    getDataSinkDetail() {
      queryById({ dataSourceId: this.jobData.targetDsVO.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.sinkDetail = res.data.data;
          }
        });
    },
    handleChangeTable(sourceDb, sourceTable, index) {
      this.sourceTable = sourceTable;
      this.selectedIndex = index;
      this.handleMqSchema(sourceDb);
      this.getColumnData(sourceDb, sourceTable);
    },
    isTarget() {
      return DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType);
    },
    getColumnData(sourceDb, sourceTable) {
      if (sourceTable && sourceTable.columns && !(isMQ(this.jobData.sourceDsVO.dataSourceType))) {
        const dbTablesMap = {};
        dbTablesMap[sourceDb.db] = sourceTable;
        const tableMetas = [];
        tableMetas.push({
          dbName: sourceDb.db,
          tableName: sourceTable.table,
          schemaName: sourceDb.schemas ? sourceDb.schemas[0].schema : sourceDb.tableSpaces ? sourceDb.tableSpaces[0].tableSpace : ''
        });
        const dsVO = this.isTarget() ? this.jobData.targetDsVO : this.jobData.sourceDsVO;
        const hostType = this.isTarget() ? this.jobData.targetDsHostType : this.jobData.sourceDsHostType;
        batchListColumns({
          host: dsVO.host,
          privateHost: dsVO.privateHost,
          publicHost: dsVO.publicHost,
          hostType,
          type: dsVO.dataSourceType,
          dbName: sourceDb.db,
          dataSourceId: dsVO.id,
          referenceDataJobId: this.jobData.dataJobId,
          referenceDsEndPointType: this.isTarget() ? 'TARGET' : 'SOURCE',
          dbTablesMap,
          tableSchema: sourceDb.schemas ? sourceDb.schemas[0].schema : '',
          tableMetas
        })
          .then((res) => {
            if (res.data.code === '1') {
              this.trueColumnData = res.data.data.tableMetaDataMap;
            }
          });
      }
    },
    getTabColor(type) {
      if (this.taskList[type] && this.taskList[type].dataTaskStatus !== 'COMPLETE') {
        if (this.taskList[type].dataTaskStatus === 'RUNNING' && this.taskList[type].healthLevel === 'Unhealthy') {
          return '#FF1815';
        }
        return this.getCurrentStateColor(type);
      }
    },
    handleCancelDelete() {
      this.verifyCode = '';
      this.showEditAlarmRule = false;
      this.showLog = false;
      this.showConfirm = false;
      this.showWhereCondition = false;
    },
    handlePDeleteJob() {
      this.showConfirm = true;
    },
    handleShowLog(taskId, step = 0, endRow = 0) {
      let id = taskId;

      if (typeof taskId !== 'number') {
        id = this.taskList[this.currentState] ? this.taskList[this.currentState].dataTaskId : 0;
      }

      if (step === 200) {
        this.logNextLoading = true;
      } else if (step === -200) {
        this.logPreLoading = true;
      } else {
        this.logLoading = true;
      }

      tailTaskMainLog({
        taskId: id,
        dataJobId: this.jobId,
        endRow: endRow + step < 0 || step === 0 ? 0 : endRow + step
      })
        .then((res) => {
          if (step === 200) {
            this.logNextLoading = false;
          } else if (step === -200) {
            this.logPreLoading = false;
          } else {
            this.logLoading = false;
          }

          if (res.data.code === '1') {
            this.logData = res.data.data;
            if (this.selectedLog && this.selectedLog.fileName) {
              this.logData.map((item) => {
                if (item.fileName === this.selectedLog.fileName) {
                  this.selectedLog = item;
                }
                return null;
              });
            } else if (this.logData.length > 0) {
              this.selectedLog = this.logData[0];
              this.logFileName = this.selectedLog.fileName;
            }
            if (this.logData.length > 0) {
              this.showLog = true;
            } else {
              this.$Modal.info({
                title: this.$t('cha-kan-ri-zhi-ti-xing'),
                content: this.$t('gai-ren-wu-dang-qian-jie-duan-wei-kai-shi-huo-yi-wan-cheng-wu-fa-huo-qu-ri-zhi')
              });
            }
            const ele = document.getElementsByClassName('log-content');

            ele.scrollTop = ele.scrollHeight;
          }
        });
    },
    // handleShowLog () {
    //
    //     this.logLoading = true;
    //     let taskId = 0;
    //
    //     if (this.currentState && this.currentState !== 'INIT' && this.currentState !== 'COMPLETE') {
    //
    //         taskId = this.taskList[this.currentState].dataTaskId;
    //         tailTaskMainLog({
    //             taskId,
    //             dataJobId: this.jobId
    //         }).then((res) => {
    //
    //             this.logLoading = false;
    //             if (res.data.code === '1') {
    //
    //                 this.logData = res.data.data;
    //                 if (this.selectedLog && this.selectedLog.fileName) {
    //
    //                     this.logData.map((item) => {
    //
    //                         if (item.fileName === this.selectedLog.fileName) {
    //
    //                             this.selectedLog = item;
    //
    //                         }
    //
    //                     });
    //
    //                 } else if (this.logData.length > 0) {
    //
    //                     this.selectedLog = this.logData[0];
    //                     this.logFileName = this.selectedLog.fileName;
    //
    //                 }
    //                 if (this.logData.length > 0) {
    //
    //                     this.showLog = true;
    //
    //                 } else {
    //
    //                     this.$Modal.info({
    //                         title: '查看日志提醒',
    //                         content: '该任务当前阶段已完成，无法获取日志。'
    //                     });
    //
    //                 }
    //                 const ele = document.getElementsByClassName('log-content');
    //
    //                 ele.scrollTop = ele.scrollHeight;
    //
    //             }
    //
    //         });
    //
    //     } else if (this.currentState === 'INIT') {
    //
    //         this.$Modal.info({
    //             title: '查看日志提醒',
    //             content: '任务初始化中，无法获取日志。'
    //         });
    //
    //     } else if (this.currentState === 'COMPELETE') {
    //
    //         this.$Modal.info({
    //             title: '查看日志提醒',
    //             content: '任务已完成，无法获取日志。'
    //         });
    //
    //     } else if (!this.currentState) {
    //
    //         this.$Modal.info({
    //             title: '查看日志提醒',
    //             content: '任务正在初始化中，无法获取日志。'
    //         });
    //
    //     }
    //
    // },
    handleSelectLog(logName) {
      this.logData.forEach((item) => {
        if (item.fileName === logName) {
          this.selectedLog = item;
        }
      });
    },
    handleEditJob() {
      this.$Spin.show();

      queryJobSchemaById({
        jobId: this.jobId,
        srcDsType: this.jobData.sourceDsVO.dataSourceType,
        dstDsType: this.jobData.targetDsVO.dataSourceType
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceSchema = JSON.parse(res.data.data.sourceSchema);
            this.mappingDef = JSON.parse(res.data.data.mappingConfig);
            this.queryJobSchema = res.data.data;
            // if (this.queryJobSchema.schemaWhiteListLevel === 'DB') {
            //   this.$Modal.warning({
            //     title: '修改订阅提示',
            //     content: '该任务非白名单模式，不支持修改订阅。如需加库，请新建任务。'
            //   });
            //   this.$Spin.hide();
            //   return;
            // }

            let editType = 1;
            if ((this.jobData.dataTaskState === 'FULL' || this.jobData.dataTaskState === 'CHECK') && (this.jobData.currentStatus === 'STOP' || this.jobData.currentStatus === 'WAIT_STOP')) {
              editType = 2;
            }

            this.$router.push({
              path: '/data/job/edit/process',
              query: { jobId: this.jobId, type: editType }
            });
          }
          this.$Spin.hide();
        })
        .catch((e) => {
          console.log(e);
          this.$Spin.hide();
        });
    },
    handleCancelEdit() {
      this.showEditJob = false;
      this.showEditJobDesc = false;
      this.showCreateCheck = false;
    },
    updatePositionInfo(data, posType, type) {
      if (posType.includes('GTID_POS')) {
        this.positionInfo.gtid = data;
      } else if (posType.includes('TIMESTAMP_POS')) {
        if (type === 'serverId') {
          this.positionInfo.serverId = data;
        } else {
          this.positionInfo.binlogTime = data;
        }
      } else if (posType.includes('LOG_FILE_POS')) {
        if (type === 'file') {
          this.positionInfo.journalFile = data;
        } else if (type === 'position') {
          this.positionInfo.filePosition = data;
        } else if (type === 'serverId') {
          this.positionInfo.serverId = data;
        }
      } else if (posType.includes('LSN_POS')) {
        this.positionInfo.lsn = data;
        this.positionInfo.lsn = data;
      } else if (posType.includes('SCN_POS')) {
        this.positionInfo.scn = data;
      } else if (posType.includes('DATA_ID_POS')) {
        this.positionInfo.dataId = data;
      } else if (posType.includes('TRANSACTION_ID_POS')) {
        this.positionInfo.transactionId = data;
      }
    },
    handleEditRule() {
      this.showEditAlarmRule = false;
      if (this.addRule.indicators[0].value < 1) {
        this.$Modal.warning({
          title: this.$t('gao-jing-pei-zhi-shi-bai'),
          content: this.$t('yan-chi-shi-jian-zui-xiao-she-zhi-1-fen-zhong')
        });
      } else {
        const data1 = {};
        const data2 = {};
        const
          alertConfigVOList = [];

        data1.expression = this.addRule.indicators[0].indicator + this.addRule.indicators[0].symbol + this.addRule.indicators[0].value;
        data1.phone = this.addRule.methods.indexOf('phone') > -1;
        data1.email = this.addRule.methods.indexOf('email') > -1;
        data1.sms = this.addRule.methods.indexOf('sms') > -1;
        data1.dingding = this.addRule.methods.indexOf('dingding') > -1;
        data1.sendAdmin = this.addRule.sendAdmin;
        data1.id = this.selectedRule.id;
        alertConfigVOList.push(data1);
        data2.id = this.exceptionConfig.id;
        data2.sendAdmin = this.taskExceptionSendAdmin;
        data2.dingding = this.taskExceptionMethods.indexOf('dingding') > -1;
        data2.sms = this.taskExceptionMethods.indexOf('sms') > -1;
        data2.phone = this.taskExceptionMethods.indexOf('phone') > -1;
        data2.email = this.taskExceptionMethods.indexOf('email') > -1;
        data2.expression = this.taskExceptionExpress;
        alertConfigVOList.push(data2);
        updateAlertConfigs(alertConfigVOList)
          .then((res) => {
            if (res.data.code === '1') {
              this.$Message.success(this.$t('xiu-gai-gao-jing-pei-zhi-cheng-gong'));
            }
          });
      }
    },
    handlePageChange(sourceDb, index, page) {
      this.showTableMapping[sourceDb.db].page = page;
      if (DataSourceGroup.hasSchema.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
        if (DataSourceGroup.pg.indexOf(this.jobData.sourceDsVO.dataSourceType) > -1) {
          this.showTableMapping[sourceDb.db].list = this.sourceSchema[index].schemas[0].tables.slice((this.showTableMapping[sourceDb.db].page - 1) * this.showTableMapping[sourceDb.db].size,
            this.showTableMapping[sourceDb.db].page * this.showTableMapping[sourceDb.db].size);
        } else {
          this.showTableMapping[sourceDb.db].list = this.sourceSchema[index].tableSpaces[0].tables.slice((this.showTableMapping[sourceDb.db].page - 1) * this.showTableMapping[sourceDb.db].size,
            this.showTableMapping[sourceDb.db].page * this.showTableMapping[sourceDb.db].size);
        }
      } else if (DataSourceGroup.mq.includes(this.jobData.sourceDsVO.dataSourceType)) {
        this.showTableMapping[sourceDb.db].list = this.targetSchema[index].tables.slice((this.showTableMapping[sourceDb.db].page - 1) * this.showTableMapping[sourceDb.db].size,
          this.showTableMapping[sourceDb.db].page * this.showTableMapping[sourceDb.db].size);
      } else {
        this.showTableMapping[sourceDb.db].list = this.sourceSchema[index].tables.slice((this.showTableMapping[sourceDb.db].page - 1) * this.showTableMapping[sourceDb.db].size,
          this.showTableMapping[sourceDb.db].page * this.showTableMapping[sourceDb.db].size);
      }
      this.showTableMapping = { ...this.showTableMapping };
      this.selectedIndex = 0;
      this.sourceTable = this.showTableMapping[sourceDb.db].list[0];
    },
    updateLogFileName(name) {
      this.logFileName = name;
    },
    handleVerify(type) {
      this.sendcodeDisabled = false;
      this.sendCodeAgainTime = 60;
      const that = this;

      let verifyType = '';

      this.sendCodeAgain = setInterval(() => {
        if (that.sendCodeAgainTime > 0) {
          that.sendCodeAgainTime--;
        } else {
          clearInterval(that.sendCodeAgain);
          that.sendcodeDisabled = true;
        }
      }, 1000);

      if (type === 'delete') {
        verifyType = 'DELETE_JOB';
      } else {
        verifyType = 'DELETE_POSITION';
      }
      sendCodeInLoginState({
        verifyType: 'SMS_VERIFY_CODE',
        verifyCodeType: verifyType
      })
        .then((res) => {
          if (res.data.code !== '1') {
            this.sendcodeDisabled = true;
            this.sendCodeAgainTime = 60;
            clearInterval(this.sendCodeAgain);
          }
        })
        .catch(() => {
          this.sendcodeDisabled = true;
          this.sendCodeAgainTime = 60;
          clearInterval(this.sendCodeAgain);
        });
    },
    handleConfirmDelete(verifyCode) {
      deleteJob({
        jobId: this.jobData.dataJobId,
        verifyCode
      })
        .then((res) => {
          if (res.data.code === '1') {
            this.$router.push({ path: '/data/job/list' });
            this.handleCancelDelete();
          }
        });
    },
    updateSourceSchema() {
      this.sourceSchema = _.cloneDeep(JSON.parse(this.jobData.sourceSchema));
    },
    handleGoTaskGroup() {
      this.$router.push({ path: `/data/job/group/${this.jobId}` });
    },
    handleMqSchema(db) {
      if (!this.sourceTable || !this.sourceTable.table) {
        setTimeout(() => {
          if (this.showTableMapping[db.db]) {
            this.sourceTable = this.showTableMapping[db.db].list[0];
            this.currentIndex = 0;
            this.handleMqSchema(db);
          }
        }, 1000);
      }
      if (db.tables) {
        let currentTable = {};

        db.tables.map((table) => {
          if (table.table === this.sourceTable.table) {
            currentTable = table;
          }
          return null;
        });
        this.mqSchema[db.db] = this.mqSchemaTemplate;
        this.mqSchema[db.db] = handleMqSchemaTool(this.mqSchema[db.db], currentTable.columns, db.db, '', currentTable.table, 'detail');
      }
      if (db.schemas) {
        let currentTable = {};

        db.schemas[0].tables.map((table) => {
          if (table.table === this.sourceTable.table) {
            currentTable = table;
          }
          return null;
        });
        this.mqSchema[`${db.db}.${db.schemas[0].schema}`] = this.mqSchemaTemplate;
        this.mqSchema[`${db.db}.${db.schemas[0].schema}`] = handleMqSchemaTool(this.mqSchema[`${db.db}.${db.schemas[0].schema}`],
          currentTable.columns, db.db, db.schemas[0].schema, currentTable.table, 'detail');
      }

      if (db.tableSpaces) {
        console.log(db.tableSpaces);
        let currentTable = {};

        db.tableSpaces[0].tables.map((table) => {
          if (table.table === this.sourceTable.table) {
            currentTable = table;
          }
          return null;
        });
        this.mqSchema[`${db.db}.${db.tableSpaces[0].tableSpace}`] = this.mqSchemaTemplate;
        this.mqSchema[`${db.db}.${db.tableSpaces[0].tableSpace}`] = handleMqSchemaTool(this.mqSchema[`${db.db}.${db.tableSpaces[0].tableSpace}`],
          currentTable.columns, db.db, db.tableSpaces[0].tableSpace, currentTable.table, 'detail');
      }
      this.mqSchema = { ...this.mqSchema };
    },
    disableBtn(type) {
      if (type === 'start') {
        return !(this.jobData.currentStatus === 'STOP') || this.starting;
      }
      if (type === 'restart') {
        return !(this.jobData.currentStatus === 'RUNNING') || this.restarting || this.stopping;
      }
      if (type === 'stop') {
        return !((this.jobData.currentStatus === 'RUNNING' || this.jobData.currentStatus === 'WAIT_START')) || this.stopping || this.restarting;
      }
      if (type === 'startfsm') {
        return !(this.getDataTask(this.jobData.dataTasks).BUILD_STRUCT
          ? this.getDataTask(this.jobData.dataTasks).BUILD_STRUCT.dataTaskStatus === 'COMPLETE' && (!this.jobData.fsmActive && this.jobData.dataTaskState === 'INIT')
          : !this.jobData.fsmActive && this.jobData.dataTaskState === 'INIT');
      }
      return false;
    },
    dsPosTypes() {
      if (!this.posTypeList || !this.posTypeList.length) {
        dsPosTypes({ dsType: this.jobData.sourceDsVO.dataSourceType }).then((res) => {
          if (res.data.code === '1') {
            this.posTypeList = res.data.data;
          }
        });
      }
    },
    handleShowPackageList() {
      this.listPackages();
      this.showPackage = true;
    },
    handleCancel() {
      this.showPackage = false;
      this.showJobOptions = false;
    },
    handleCancelUpload() {
      this.showUploadPkg = false;
      this.handleRemovePkg();
    },
    handleCancelUpdate() {
      this.showUpdatePkg = false;
    },
    handleDeletePackage(row) {
      this.$Modal.confirm({
        title: this.$t('shan-chu-que-ren'),
        content: this.$t('p-que-ren-yao-shan-chu-rowdescription-zhe-ge-bao-ma-p', [row.description]),
        onOk: () => {
          this.$Spin.show();
          deletePackages({
            pkgId: row.id,
            dataJobId: this.jobId
          }).then((res) => {
            this.currentPercentage = 0;
            if (res.data.code === '1') {
              if (res.data.data) {
                this.showDeletePkg = true;
                const that = this;
                this.queryDeletePkgInterval = setInterval(() => {
                  queryConsoleJob({ consoleJobId: res.data.data })
                    .then((response) => {
                      if (res.data.code === '1') {
                        that.currentPkgDeleteStatus = response.data.data.taskState;
                        if (response.data.data.taskState === 'SUCCESS') {
                          clearInterval(that.queryDeletePkgInterval);
                          // that.sourceDisabled = false;
                          setTimeout(() => {
                            that.listPackages();
                          }, 500);
                        } else if (response.data.data.taskState === 'FAILED') {
                          clearInterval(that.queryDeletePkgInterval);
                          // that.sourceDisabled = false;
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                      // that.sourceDisabled = false;
                    });
                }, 2000);
                this.getPercantage = setInterval(() => {
                  if (that.currentPkgDeleteStatus === 'SUCCESS') {
                    that.currentPercentage = 100;
                    clearInterval(that.getPercantage);
                    setTimeout(() => {
                      that.showDeletePkg = false;
                    }, 500);
                  } else if (that.currentPkgDeleteStatus === 'FAILED') {
                    clearInterval(that.getPercantage);
                  } else if (that.currentPercentage < 90) {
                    that.currentPercentage += Math.floor(Math.random() * 3);
                  } else if (that.currentPercentage < 97 && that.currentPercentage >= 90) {
                    that.currentPercentage += Math.floor(Math.random() * 1.5);
                  } else if (that.currentPercentage >= 97) {
                    that.currentPercentage = 99;
                  }
                }, 200);
              }
              this.listPackages();
            }
          }).finally(() => {
            this.$Spin.hide();
          });
        },
        onCancel: () => {
          this.$Message.info('Clicked cancel');
        }
      });
    },
    listPackages() {
      listPackages({
        dataJobId: this.jobData.dataJobId
      }).then((res) => {
        if (res.data.code === '1') {
          this.packageData = res.data.data;
          this.activePackage = {};
          this.packageData.forEach((item) => {
            if (item.active) {
              this.activePackage = item;
            }
          });
        }
      });
    },
    downloadPackage(row) {
      this.$Spin.show();
      downloadPackage({
        pkgId: row.id,
        dataJobId: this.jobData.dataJobId
      }).then((res) => {
        const a = document.createElement('a');
        const binaryData = [];
        binaryData.push(res.data);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/jar' }));
        const filename = row.fileName;
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        this.$Spin.hide();
      });
    },
    handleUpload() {
      this.showUploadPkg = true;
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024 * 500) {
          this.$Modal.error({
            title: this.$t('wen-jian-guo-da'),
            content: this.$t('wen-jian-da-xiao-ying-bu-chao-guo-500m')
          });

          return false;
        }
        this.customPkgFile = file;
      }
    },
    handleConfirmUpload() {
      this.addedPackage.customPkgFile = this.customPkgFile;
      this.showUploadPkg = false;
      const data = {
        description: this.addedPackage.pkgDescription,
        dataJobId: this.jobId
      };
      this.showUpdatePkgProgress = true;
      this.currentPercentage = 0;
      this.getPercantage = setInterval(() => {
        if (this.currentPercentage < 90) {
          this.currentPercentage += Math.floor(Math.random() * 10);
        } else if (this.currentPercentage < 97 && this.currentPercentage >= 90) {
          this.currentPercentage += Math.floor(Math.random() * 3);
        } else if (this.currentPercentage >= 97) {
          this.currentPercentage = 99;
        }
      }, 200);
      const stringData = JSON.stringify(data); const
        formData = new FormData();

      if (this.addedPackage.customPkgFile) {
        formData.append('customPkgFile', this.addedPackage.customPkgFile);
      }
      formData.append('uploadPkgFO', new Blob([stringData], { type: 'application/json' }));
      uploadPackage(formData).then((res) => {
        if (res.data.code === '1') {
          this.currentPercentage = 100;
          clearInterval(this.getPercantage);
          setTimeout(() => {
            this.showUpdatePkgProgress = false;
            this.listPackages();
          }, 200);
        }
      }).finally(() => {
        this.handleRemovePkg();
        this.showUpdatePkgProgress = false;
      });
    },
    handleConfirmUpdate() {
      updatePkgInfo({
        dataJobId: this.jobId,
        pkgId: this.selectedPkg.id,
        description: this.pkgDescription
      }).then((res) => {
        if (res.data.code === '1') {
          this.listPackages();
          this.showUpdatePkg = false;
        }
      });
    },
    handleRemovePkg() {
      this.customPkgFile = null;
      this.addedPackage.pkgDescription = '';
    },
    activePackages(row) {
      this.activePkgLoading = true;
      activePackages({
        activePkgId: row.id,
        dataJobId: this.jobId
      }).then((res) => {
        if (res.data.code === '1') {
          this.activePkgLoading = false;
          this.listPackages();
        }
      });
    },
    handleShowUpdatePkg(row) {
      this.showUpdatePkg = true;
      this.pkgDescription = row.description;
      this.selectedPkg = row;
    },
    async handleRebuildIndex() {
      const res = await rebuildIndex({
        dataJobId: this.jobId
      });

      if (res.data.success) {
        this.$Message.success(this.$t('geng-xin-yuan-shu-ju-suo-yin-cheng-gong'));
      } else {
        this.$Message.error(this.$t('geng-xin-yuan-shu-ju-suo-yin-shi-bai'));
      }
    },
    handleShowRestartList() {
      const task = this.taskList[this.currentState];
      getRestartList({
        dataJobId: this.jobId,
        dataTaskId: task.dataTaskId
      }).then((res) => {
        if (res.data.success) {
          this.restartList = res.data.data;
        }
      });
      this.showRestartListModal = true;
    },
    async handleCreateSimilarJob(type = 'revise') {
      await preFirstCheck()
        .then((res2) => {
          if (res2.data.code === '1') {
            this.handleCreateSimilarJobFunc(type);
          }
        });
    },
    async handleCreateSimilarJobFunc(type) {
      const { sourceDsVO, targetDsVO } = this.jobData;
      const sourceRes = await supportedsourcedstype({ deployType: sourceDsVO.deployType });
      let unsupportedDsType = false;
      let msg = '';
      if (sourceRes.data.success) {
        if (sourceRes.data.data[sourceDsVO.dataSourceType]) {
          const sinkRes = await supportedtargetdstype({
            deployType: targetDsVO.deployType,
            sourceType: sourceDsVO.dataSourceType
          });

          if (sinkRes.data.success) {
            if (!sinkRes.data.data[targetDsVO.dataSourceType]) {
              unsupportedDsType = true;
              msg = this.$t('targetdsvodatasourcetype-bu-zhi-chi', [targetDsVO.dataSourceType]);
            }
          }
        } else {
          unsupportedDsType = true;
          msg = this.$t('sourcedsvodatasourcetype-bu-zhi-chi', [sourceDsVO.dataSourceType]);
        }
      }

      if (!unsupportedDsType) {
        if (!this.schemaData) {
          this.$Spin.show();

          queryJobSchemaById({
            jobId: this.jobId,
            srcDsType: this.jobData.sourceDsVO.dataSourceType,
            dstDsType: this.jobData.targetDsVO.dataSourceType
          })
            .then((res) => {
              if (res.data.code === '1') {
                this.schemaData = res.data.data;
                this.jobData.schemaData = res.data.data;
                if (type === 'revise') {
                  this.jobData.createReviseTask = true;
                  this.jobData.checkTaskId = this.taskList.CHECK.dataTaskId;
                }
                store.commit('getJobDataForSimilarJob', this.jobData);
                this.$router.push({ path: '/data/job/create/process' });
              }
            }).finally(() => {
              this.$Spin.hide();
            });
        } else {
          this.jobData.schemaData = this.schemaData;
          store.commit('getJobDataForSimilarJob', this.jobData);
          this.$router.push({ path: '/data/job/create/process' });
        }
      } else {
        Modal.error({
          title: this.$t('xu-ke-guo-qi'),
          content: msg
        });
      }
    },
    manualMergeEditJob(row) {
      this.$Modal.confirm({
        title: this.$t('shou-dong-he-bing-que-ren'),
        content: this.$t('que-ren-yao-shou-dong-he-bing-gai-zi-ren-wu-ma'),
        onOk: () => {
          manualMergeEditJob({
            dataJobId: row.dataJobId
          }).then((res) => {
            if (res.data.code === '1') {
              this.$Message.success(this.$t('shou-dong-he-bing-zhong'));
              setTimeout(() => {
                if (this.type === 'group') {
                  this.getJobData();
                } else {
                  this.getJobList();
                }
              }, 2000);
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="less">

.job-info-status {
  font-size: 14px;
}
.pkg-manager {
  .edit-icon {
    cursor: pointer;
    margin-left: 6px;
  }
}

.ivu-progress-normal .ivu-progress-bg {
  background: #9EE6AA;
}

.job-header-buttons .ivu-dropdown-item {
  padding: 0!important;
}
.dropdown-content {
  padding: 7px 16px;
}
</style>
