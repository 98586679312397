import {
  UPDATE_CLUSTER_LIST,
  UPDATE_DEPLOY_ENV_LIST_MAP,
  UPDATE_DS_SETTING,
  UPDATE_DS_TYPE_LIST,
  UPDATE_EDITOR_SET,
  UPDATE_GLOBAL_SETTING,
  UPDATE_REGION_LIST_MAP,
  UPDATE_USERINFO,
  UPDATE_PRODUCT_CLUSTER,
  UPDATE_SELECT_PRODUCT_CLUSTER,
  UPDATE_CC_GLOBAL_SETTING,
  UPDATE_DM_GLOBAL_SETTING,
  UPDATE_MY_CATALOG,
  UPDATE_MY_AUTH,
  UPDATE_DEPLOY_SITE,
  UPDATE_SELECT_DM_PRODUCT_CLUSTER,
  UPDATE_SOCKET_STATUS, UPDATE_RULE_SETTING, UPDATE_PUBLIC_KEY
} from '@/store/mutationTypes';
import i18n from '@/i18n';
import router from '@/router';

const URL_AUTH_MAPPING = {};

export default {
  [UPDATE_RULE_SETTING](state, data) {
    state.ruleSetting = data.ruleSetting;
  },
  [UPDATE_PUBLIC_KEY](state, publicKey) {
    state.publicKey = publicKey;
  },
  [UPDATE_USERINFO](state, userInfo) {
    state.userInfo = userInfo;
  },
  [UPDATE_CLUSTER_LIST](state, list) {
    const temp = {};
    let workersNum = 0;
    let runningWorkersNum = 0;
    list.forEach((cluster) => {
      workersNum += cluster.workerCount;
      runningWorkersNum += cluster.runningCount;
      temp[cluster.id] = {
        name: cluster.clusterName,
        desc: cluster.clusterDesc,
        value: cluster.id,
        ...cluster
      };
    });

    state.clusterListMap = temp;
    state.allClusterWorkers = workersNum;
    state.allClusterRunningWorkers = runningWorkersNum;
  },
  [UPDATE_DEPLOY_ENV_LIST_MAP](state, list) {
    const temp = {};
    list.forEach((env) => {
      temp[env.name] = {
        name: env.nameI18n,
        value: env.name
      };
    });

    state.deployEnvListMap = temp;
  },
  [UPDATE_REGION_LIST_MAP](state, list) {
    const temp = {};
    const temp2 = {};
    const temp3 = {};
    list.aliyun.forEach((region) => {
      const { regionName, regionNameI18n, regionKindI18n } = region;
      temp3[regionName] = regionNameI18n;
      if (temp[regionKindI18n]) {
        temp[regionKindI18n].children.push(region);
      } else {
        temp[regionKindI18n] = {
          name: regionKindI18n,
          children: [region]
        };
      }
    });
    list.self.forEach((region) => {
      const { regionKindI18n } = region;
      if (temp2[regionKindI18n]) {
        temp2[regionKindI18n].children.push(region);
      } else {
        temp2[regionKindI18n] = {
          name: regionKindI18n,
          children: [region]
        };
      }
    });
    state.aliyunRegionListMap = temp;
    state.selfRegionListMap = temp2;
    state.regionList = temp3;
  },
  [UPDATE_DS_TYPE_LIST](state, list) {
    state.dsTypeList = list;
  },
  [UPDATE_PRODUCT_CLUSTER](state, list) {
    state.productClusterList = list;
  },
  [UPDATE_SELECT_PRODUCT_CLUSTER](state, cluster) {
    console.log('UPDATE_SELECT_PRODUCT_CLUSTER', cluster);
    state.selectCcProductCluster = cluster;
  },
  [UPDATE_SELECT_DM_PRODUCT_CLUSTER](state, cluster) {
    console.log('UPDATE_SELECT_DM_PRODUCT_CLUSTER', cluster);
    state.selectDmProductCluster = cluster;
  },
  [UPDATE_MY_CATALOG](state, data) {
    state.myCatLog = data;
    const { myCatLog, globalSetting, userInfo } = state;
    const systemMenuItems = [];
    if (myCatLog.includes('CAT_RDP_USER')) {
      systemMenuItems.push({
        key: '/system/sub_account',
        href: '/#/system/sub_account',
        label: i18n.t('zi-zhang-hao-guan-li')
      });
    }
    if (myCatLog.includes('CAT_RDP_ROLE')) {
      systemMenuItems.push({
        key: '/system/role',
        href: '/#/system/role',
        label: i18n.t('jiao-se-guan-li')
      });
    }
    if (myCatLog.includes('CAT_RDP_ENV')) {
      systemMenuItems.push({
        key: '/system/env',
        href: '/#/system/env',
        label: i18n.t('huan-jing-guan-li')
      });
    }
    if (myCatLog.includes('CAT_RDP_OP_AUDIT')) {
      systemMenuItems.push({
        key: '/system/operation_log',
        href: '/#/system/operation_log',
        label: i18n.t('cao-zuo-shen-ji')
      });
    }
    if (myCatLog.includes('CAT_RDP_PRI_PREFERENCE_CONF')) {
      systemMenuItems.push({
        key: '/system/preference',
        href: '/#/system/preference',
        label: i18n.t('wo-de-pian-hao')
      });
    }

    if (myCatLog.includes('CAT_RDP_AUTH') && userInfo.accountType !== 'PRIMARY_ACCOUNT') {
      systemMenuItems.push({
        key: '/system/auth',
        href: '/#/system/auth',
        label: i18n.t('wo-de-quan-xian')
      });
    }

    if (myCatLog.includes('CAT_RDP_PRI_THIRD_PARTY_CONF')) {
      systemMenuItems.push({
        key: '/ccsystem/ticketTokenSetting',
        href: '/#/ccsystem/ticketTokenSetting',
        label: i18n.t('di-san-fang-pei-zhi')
      });
    }

    state.mySystemMenuItems = systemMenuItems;

    const ccSettingMenuItems = [];
    if (myCatLog.includes('CAT_CC_WORKER')) {
      ccSettingMenuItems.push({
        key: '/ccsystem/resource',
        href: '/#/ccsystem/resource',
        label: i18n.t('tong-bu-ji-qi')
      });
    }
    if (myCatLog.includes('CAT_CC_EX')) {
      ccSettingMenuItems.push({
        key: '/monitor/exception',
        href: '/#/monitor/exception',
        label: i18n.t('yi-chang-ri-zhi')
      });
    }
    if (myCatLog.includes('CAT_CC_ALERT_LOG')) {
      ccSettingMenuItems.push({
        key: '/alarm/log',
        href: '/#/alarm/log',
        label: i18n.t('gao-jing-ri-zhi')
      });
    }
    if (myCatLog.includes('CAT_CC_CONSOLE') && userInfo.maintainer) {
      ccSettingMenuItems.push({
        key: '/monitor/consolelist',
        href: '/#/monitor/consolelist',
        label: i18n.t('kong-zhi-tai-jian-kong')
      });
    }
    if (myCatLog.includes('CAT_CC_FSM')) {
      ccSettingMenuItems.push({
        key: '/ccsystem/fsm',
        href: '/#/ccsystem/fsm',
        label: i18n.t('zhuang-tai-ji-guan-li')
      });
    }
    if (myCatLog.includes('CAT_CC_CONSOLEJOB')) {
      ccSettingMenuItems.push({
        key: '/ccsystem/state/task',
        href: '/#/ccsystem/state/task',
        label: i18n.t('yi-bu-ren-wu-guan-li')
      });
    }
    if (myCatLog.includes('CAT_CC_OP_AUDIT')) {
      ccSettingMenuItems.push({
        key: '/ccsystem/operationLog',
        href: '/#/ccsystem/operationLog',
        label: i18n.t('cao-zuo-shen-ji')
      });
    }

    state.ccSettingMenuItems = ccSettingMenuItems;
  },
  updateMetaCenterSearchParam(state, params) {
    state.metaCenterSearchParams = params;
  },
  updateSelectedWorker(state, worker) {
    state.selectedWorker = worker;
  },
  updateSetPkAndIdFlags(state, flags) {
    state.setPkAndIdFlags = flags;
  },
  getGlobalConfig(state, config) {
    state.globalConfig = config;
  },
  getUserRole(state, role) {
    state.userRole = role;
  },
  getLicenseStatus(state, data) {
    state.licenseStatus = data;
  },
  getBlackUri(state, blackUri) {
    state.blackUri = blackUri;
  },
  getUrlLabels(state, list) {
    state.urlLabels = list;
  },
  updateSelectProductCluster(state, cluster) {
    state.selectCcProductCluster = cluster;
  },
  updateUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
  changeConnection(state, changeState) {
    if (changeState.type === 'source') {
      state.sourceConnection = changeState.ifConnection;
    } else {
      state.sinkConnection = changeState.ifConnection;
    }
  },
  getDataSourceDeployTypes(state, list) {
    state.dataSourceDeployTypes = list;
  },
  getSourceDataSourceTypes(state, list) {
    state.sourceDataSourceTypes = list;
  },
  getSinkDataSourceTypes(state, list) {
    state.sinkDataSourceTypes = list;
  },
  getSourceInstance(state, list) {
    state.sourceInstanceList = list;
  },
  getSinkInstance(state, list) {
    state.sinkInstanceList = list;
  },
  getSourceDbList(state, list) {
    state.sourceDbList = list;
  },
  getSinkDbList(state, list) {
    state.sinkDbList = list;
  },
  changeTest1(state, ifTest) {
    state.showTest1 = ifTest;
  },
  changeTest2(state, ifTest) {
    state.showTest2 = ifTest;
  },
  getTableDatas(state, data) {
    state.tableDatas[data.db][data.index][data.type] = data.data;
  },
  updateCleanDataData(state, data) {
    state.cleanDataData[`${data.table}|${data.db}`][data.index][data.type] = data.data;
  },
  getSelectedTables(state, data) {
    state.selectedTables = data;
  },
  getSelectedColumns(state, data) {
    state.selectedColumns = data;
  },
  updateSelectedTables(state, data) {
    state.selectedTables[data.db][data.index][data.type] = data.data;
  },
  updateTableFilter(state) {
    state.firstToTableFilter = true;
  },
  updateCleanData(state, firstToCleanData = true) {
    state.firstToCleanData = firstToCleanData;
  },
  getSinkTableList(state, data) {
    state.sinkTableList[data.db] = data.data;
  },
  getTableInfo(state, list) {
    state.tableInfo = list;
  },
  getSinkColumns(state, data) {
    state.sinkColumns[data.key] = data.data;
  },
  getCleanDataData(state, data) {
    state.cleanDataData[`${data.table}|${data.db}`] = data.data;
  },
  clearCleanData(state) {
    state.cleanDataData = {};
  },
  getCleanDataSinkTables(state, list) {
    state.cleanDataSinkTables = list;
  },
  updateCompareSelectedTables(state, obj) {
    state.compareSelectedTables = obj;
  },
  updateCompareSelectedDbs(state, obj) {
    state.compareSelectedDbs = obj;
  },
  getJobDataForSimilarJob(state, data) {
    state.jobData = data;
  },
  getDeploySite(state, data) {
    state.deploySite = data;
    if (data === 'asia_pacific') {
      state.docUrlPrefix = 'https://www.clougence.com';
    } else {
      state.docUrlPrefix = 'https://www.cloudcanalx.com/us';
    }
  },
  [UPDATE_DS_SETTING](state, dsSetting) {
    state.globalDsSetting = dsSetting;
  },
  [UPDATE_GLOBAL_SETTING](state, globalSetting) {
    if (globalSetting.enableProductCluster && state.userInfo.maintainer) {
      state.mySystemMenuItems.unshift({
        key: '/system/product_list',
        href: '/#/system/product_list',
        label: i18n.t('chan-pin-ji-qun-guan-li')
      });
    }
    state.globalSetting = globalSetting;
    let url = '';
    if (state.mySystemMenuItems.length) {
      url = state.mySystemMenuItems[0].key;
    }
    if (state.ccSettingMenuItems.length) {
      url = state.ccSettingMenuItems[0].key;
    }
    if (globalSetting.defaultProduct) {
      if (globalSetting.defaultProduct === 'CloudCanal' && state.myCatLog.includes('CAT_CC_DATAJOB')) {
        url = '/data/job/list';
      } else if (globalSetting.defaultProduct === 'CloudDM') {
        if (state.myCatLog.includes('CAT_DM_CONSOLE')) {
          url = '/sql';
        } else if (state.myCatLog.includes('CAT_RDP_WORKER_ORDER')) {
          url = '/ticket';
        } else if (state.myCatLog.includes('CAT_DM_SYS')) {
          url = '/dmdatasource';
        } else if (state.myCatLog.includes('CAT_RDP_DS')) {
          url = '/ccdatasource';
        }
      } else {
        if (state.myCatLog.includes('CAT_RDP_DS')) {
          url = '/ccdatasource';
        }
      }
      state.defaultRedirectUrl = url;

      if (window.location.hash === '#/') {
        router.push(url);
      }
    }
  },
  [UPDATE_CC_GLOBAL_SETTING](state, ccGlobalSetting) {
    state.ccGlobalSetting = ccGlobalSetting;
  },
  [UPDATE_DM_GLOBAL_SETTING](state, dmGlobalSetting = {}) {
    state.dmGlobalSetting = dmGlobalSetting;
  },
  [UPDATE_EDITOR_SET](state, data) {
    const { id, model, state: mState } = data;
    state.editorSet[id] = { model, state: mState };
  },
  [UPDATE_MY_AUTH](state, data) {
    state.myAuth = data;
  },
  [UPDATE_SOCKET_STATUS](state, socket) {
    console.log(socket);
    state.socket = socket;
  },
  [UPDATE_DEPLOY_SITE](state, data) {
    state.deploySite = data;
    if (data === 'asia_pacific') {
      state.docUrlPrefix = 'https://www.clougence.com';
    } else {
      state.docUrlPrefix = 'https://www.cloudcanalx.com/us';
    }
  }
};
