<template>
  <div style="height: 100%" class="result-container">
    <a-spin :spinning="tab.running"
            :tip="$t('ke-dian-ji-zhong-duan-zhi-hang-an-niu-zhong-duan-zhi-hang')"
            style="height: 100%;">
      <a-tabs :animated="false" size="small" hide-add type="editable-card"
              name="result" v-model="tab.result.active" :tabBarGutter="-1" @change="handleTabChange">
        <a-tab-pane
          key="message"
          name="message">
          <template slot="tab">
            <div style="user-select: none;height: 40px;padding: 0 16px;display: flex;align-items: center;">
              {{ $t('zhi-hang-xin-xi') }}
            </div>
          </template>
          <div :style="`height:${pageHeight}px`" class="result-info-container" id="result-info-container">
            <div v-for="(info,index) in tab.executeInfo" :key="index" class="result-info">
              <div class="info" v-if="info.resultType === 'QueryScript'">
                <div class="level" style="background: #bbb">{{ info.line }}</div>
                <div class="message">
                  <pre>{{info.script}}</pre>
                </div>
              </div>
              <div class="info" v-else>
                <div :class="`level ${info.level}`">{{ info.level }}</div>
                <div class="time">[{{ info.time }}]</div>
                <div class="message">
                  {{ info.message }}
                  <a href="#" v-if="info.message === $t('gui-ze-xiao-yan-shi-bai')"
                     @click="handleViewNoPassedRuleList(index)">{{ $t('cha-kan-xiang-qing') }}</a>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane
          key="async"
          name="async"
          v-if="tab.showAsyncListTab">
          <template slot="tab">
            <div
              style="user-select: none;height: 40px;padding: 0 16px;display: flex;align-items: center;">
              {{ $t('yi-bu-zhi-hang-guan-li-lie-biao') }}
            </div>
          </template>
          <div style="padding: 10px;">
            <AsyncJobList :biz-id="tab.sessionId" biz-type="QUERY_CONSOLE"
                          :set-async-job-detail="setAsyncJobDetail" v-if="asyncJobDetailId === -1"/>
            <AsyncJobDetail :export-job-id="asyncJobDetailId" v-else
                            :set-async-job-detail="setAsyncJobDetail" biz-type="QUERY_CONSOLE"
                            type="console"/>
          </div>
        </a-tab-pane>
        <a-tab-pane
          v-for="res in tab.result.list"
          :key="res.resultId">
          <template slot="tab">
            <a-dropdown :trigger="['contextmenu']">
              <div
                style="user-select: none;height: 40px;padding: 0 16px;display: flex;align-items: center;">
                <cc-iconfont
                  style="margin-right:4px"
                  name="success"
                  color="green"
                  :size="12"
                />
                {{ $t('jie-guo') }}{{ res.showIndex }}
                <a-icon type="close"
                        class="close-icon"
                        @click="handleEditTab('current', res.resultId)"/>
              </div>
              <a-menu slot="overlay">
                <a-menu-item @click="handleEditTab('other', res.resultId)">{{
                    $t('guan-bi-qi-ta')
                  }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </a-tab-pane>
      </a-tabs>
      <div v-if="!['message', 'async'].includes(tab.result.active)"
           style="display: flex;flex-direction: column;flex: 1;min-height: 0;">
        <div class="result-table-container">
          <div class="result-content" v-if="selectedTab">
            <div class="result-table">
              <vxe-grid
                :ref="`result_table_${tab.result.active}`"
                :data="selectedTab.showData"
                :height="pageHeight - 64"
                stripe
                resizable
                border
                highlight-hover-row
                keep-sourcess
                show-overflow
                size="mini"
                :export-config="exportConfig"
                :columns="selectedTab.columnListSeq"
                @cell-click="handleCellClick"
                @cell-dblclick="handleCellDbClick"
                :menu-config="tableMenu"
                @menu-click="contextMenuClickEvent"
                @cell-menu="handleRightClickCell"
                :scroll-y="{enabled: true, mode: 'wheel'}"
              >
                <template #toolbar_buttons>
                  <vxe-button @click="exportData">{{ $t('dao-chu') }}</vxe-button>
                </template>
                <template #header_title="{column}">
                  {{ column.title }}
                </template>
              </vxe-grid>
            </div>
          </div>
        </div>
        <div class="tip-footer">
          <Page :current="selectedTab.page" :page-size="50"
                :total="selectedTab.total"
                placement="top" show-total
                size="small" @on-change="changePage($event)"
          ></Page>
          <!--          <span>{{ $t('hao-shi') }}:{{selectedTab.receiveCost }}</span>-->
        </div>
      </div>
    </a-spin>
    <Modal v-model="showInsertSqlModal" @on-cancel="hideShowInsertSqlModal" title="Insert SQL"
           :width="1000" :mask-closable="false" transfer>
      <div class="insert-sql-modal">
        <div class="insert-sql-operation">
          <Input v-model="currentTableName" :placeholder="$t('qing-shu-ru-biao-ming')"
                 style="margin-right: 10px"/>
          <Button @click="generateInsertSql">{{ $t('sheng-cheng-insert-yu-ju') }}</Button>
        </div>
        <div class="insert-sql-content">
          <div v-for="(sql, index) in sqls" :key="index">
            {{ sql }}
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" ghost @click="copyText(sqls.join('\n'))">
          {{ $t('fu-zhi') }}
        </Button>
        <Button @click="hideShowInsertSqlModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal } from 'ant-design-vue';
import {
  mysqlInsert, pgInsert
} from '@/views/sql/components/typeGroup';
import { isMySQL, isPostgreSQL } from '@/const/dataSource';
import copyMixin from '@/mixins/copyMixin';
import { mapGetters } from 'vuex';
import AsyncJobList from '../../system/AsyncJobList/index';
import AsyncJobDetail from '../../system/AsyncJobList/asyncJobDetail';

export default {
  name: 'Result',
  mixins: [copyMixin],
  props: {
    handleRunAsync: Function,
    tab: Object
  },
  components: {
    AsyncJobDetail,
    AsyncJobList
  },
  data() {
    return {
      actionType: '',
      currentTableName: '',
      columnsList: [],
      searchText: '',
      sqls: [],
      showInsertSqlModal: false,
      rowIndex: 0,
      pageHeight: 0,
      tableMenu: {
        body: {
          options: [
            [
              {
                code: 'exportRowInsert',
                name: this.$t('dao-chu-dang-qian-hang-insert')
              }
              // {
              //   code: 'exportCellInsert',
              //   name: '导出单元格insert'
              // }
            ]
          ]
        }
      },
      toolbarConfig: {
        custom: true,
        slots: {
          buttons: 'toolbar_buttons'
        }
      },
      exportConfig: {},
      editorHeight: 250,
      asyncJobDetailId: -1
    };
  },
  computed: {
    ...mapGetters(['getQualifier']),
    selectedTab() {
      if (!['message', 'async'].includes(this.tab.result.active)) {
        let tab = {};
        for (let i = 0; i < this.tab.result.list.length; i++) {
          if (this.tab.result.list[i].resultId === this.tab.result.active) {
            tab = this.tab.result.list[i];
            break;
          }
        }
        return tab;
      } else {
        return {};
      }
    }
  },
  mounted() {
    window.$bus.on('setEditorHeight', (height) => {
      this.setEditorHeight(height);
    });
    this.pageHeight = window.innerHeight - 48 - 40 - 41 - 40 - 40 - 44 - this.editorHeight;
    window.onresize = () => {
      this.$nextTick(() => {
        this.pageHeight = window.innerHeight - 48 - 40 - 41 - 40 - 40 - 44 - this.editorHeight;
      });
    };
  },
  beforeDestroy() {
    window.$bus.off('setEditorHeight');
  },
  methods: {
    isPostgreSQL,
    isMySQL,
    handleTabChange(activeKey) {
      if (activeKey === 'message') {
        const ele = document.getElementById('result-info-container');
        if (ele) {
          ele.scrollTop = ele.scrollHeight;
        }
      }
    },
    setEditorHeight(height) {
      this.editorHeight = height;
      this.pageHeight = window.innerHeight - 180 - height;
    },
    handleViewNoPassedRuleList(index) {
      window.$bus.emit('showNoPassedRuleListModal', index);
    },
    handleEditTab(type, key) {
      const deleteIndex = this.tab.result.list.findIndex((tab) => tab.resultId === key);

      if (type === 'current') {
        this.tab.result.list.splice(deleteIndex, 1);
        const activeIndex = deleteIndex ? deleteIndex - 1 : 0;
        this.tab.result.active = this.tab.result.list.length ? this.tab.result.list[activeIndex].resultId : 'message';
      } else {
        this.tab.result.list = [this.tab.result.list[deleteIndex]];
        this.tab.result.active = key;
      }
    },
    setAsyncJobDetail(asyncJobDetail) {
      this.asyncJobDetailId = asyncJobDetail;
    },
    hideShowInsertSqlModal() {
      this.showInsertSqlModal = false;
      this.currentTableName = '';
    },
    handleRightClickCell(e) {
      this.handleCellClick(e);
    },
    contextMenuClickEvent(e) {
      const {
        menu,
        row,
        column,
        rowIndex
      } = e;
      this.handleCellClick(e);
      switch (menu.code) {
        case 'exportCell':
          if (row && column) {
            this.$refs[`result_table_${this.tab.result.active}`][0].exportData({
              data: [this.selectedRow]
            });
          }
          break;
        case 'exportRow':
          if (row && column) {
            this.$refs[`result_table_${this.tab.result.active}`][0].exportData({
              data: [this.selectedRow]
            });
          }
          break;
        case 'exportRowInsert':
          this.handleExport({
            key: 'currentInsert',
            rowIndex
          });
          break;
        default:
          break;
      }
    },
    async handleCellClick(e) {
      this.rowIndex = e.rowIndex;
      if (this.selectedCell) {
        this.selectedCell.style.backgroundColor = '';
      }
      if (this.selectedRowDom) {
        this.selectedRowDom.style.backgroundColor = '';
        this.selectedRowDom.children[0].style.backgroundColor = '';
        this.selectedRow = null;
        this.selectedRowDom = null;
      }
      e.cell.style.backgroundColor = '#C3DEFF';
      e.cell.parentNode.style.backgroundColor = e.$columnIndex === 0 ? '#C3DEFF' : '#E6F1FF';
      if (e.$columnIndex) {
        e.cell.parentNode.children[0].style.backgroundColor = '#E6F1FF';
      }

      this.selectedRowDom = e.cell.parentNode;
      console.log(e.cell.parentNode);
      this.selectedRow = e.row;
      this.selectedCell = e.cell;
    },
    handleCellDbClick(e) {
      console.log(e);
      const {
        row,
        column
      } = e;
      if (column.type === 'seq') {
        return;
      }
      window.$bus.emit('showCellDetailModal', {
        row,
        column
      });
    },
    generateRowInsert(row) {
      const { dsType } = this.tab;
      const {
        columnList,
        columnType,
        resource
      } = this.selectedTab;
      const tableName = this.currentTableName || resource || 'my_table';
      this.currentTableName = tableName;
      let keyStr = '';
      const qualifier = this.getQualifier(dsType);
      const {
        left,
        right
      } = qualifier;
      keyStr = `${left}${columnList.join(`${right}, ${left}`)}${right}`;
      let valueStr = '';
      columnList.forEach((key1, index) => {
        const value = row[key1];
        let insertType;
        if (this.tab.dataSourceType === 'MySQL') {
          insertType = mysqlInsert;
        } else {
          insertType = pgInsert;
        }
        if (index !== columnList.length - 1) {
          if (value === null) {
            valueStr += 'null, ';
          } else if (insertType.needQuote.indexOf(columnType[index]) > -1) {
            valueStr += `'${value}', `;
          } else if (insertType.noNeedQuote.indexOf(columnType[index]) > -1) {
            valueStr += `${value}, `;
          } else {
            valueStr += `'${value}', `;
          }
        } else {
          if (value === null) {
            valueStr += 'null';
          } else if (insertType.needQuote.indexOf(columnType[index]) > -1) {
            valueStr += `'${value}'`;
          } else if (insertType.noNeedQuote.indexOf(columnType[index]) > -1) {
            valueStr += `${value} `;
          } else {
            valueStr += `'${value}' `;
          }
        }
      });

      console.log(`INSERT INTO ${left}${tableName}${right} (${keyStr}) VALUES (${valueStr})`);

      return `INSERT INTO ${left}${tableName}${right} (${keyStr}) VALUES (${valueStr})`;
    },
    async handleExport({
      key,
      rowIndex
    }) {
      this.actionType = key;
      const {
        showData
      } = this.selectedTab;
      const sqls = [];
      let showInsertSqlModal = false;
      switch (key) {
        case 'all':
          const {
            instance,
            database,
            currentTable
          } = this.tab;
          const list = [];
          const columns = this.selectedTab.columnList;
          if (this.selectedTab && this.selectedTab.data) {
            this.selectedTab.data.forEach((item) => {
              const currentRow = {};
              for (let i = 0; i < columns.length; i++) {
                currentRow[columns[i]] = item[i].value;
                currentRow[`render_${columns[i]}`] = item[i].value;
              }
              list.push(currentRow);
            });

            this.$refs[`result_table_${this.tab.result.active}`].exportData({
              filename: `${instance}/${database}/${currentTable}`,
              type: 'csv',
              data: list,
              columnFilterMethod: ({ column }) => column.property
            });
          } else {
            Modal.info({
              title: this.$t('ti-shi'),
              content: this.$t('wu-shu-ju')
            });
          }
          break;
        case 'currentInsert':
          if (this.selectedRow) {
            showInsertSqlModal = true;
            let selectedRow = [];
            if (rowIndex) {
              selectedRow = showData[rowIndex];
            } else {
              selectedRow = showData[this.rowIndex];
            }
            sqls.push(this.generateRowInsert(selectedRow));
          } else {
            Modal.warning({
              content: this.$t('qing-zhi-shao-xuan-ze-yi-tiao-shu-ju')
            });
          }

          break;
        case 'allInsert':
          if (showData) {
            showInsertSqlModal = true;
            showData.forEach((row) => {
              sqls.push(this.generateRowInsert(row));
            });
          } else {
            Modal.info({
              title: this.$t('ti-shi'),
              content: this.$t('wu-shu-ju')
            });
          }
          break;
        default:
          break;
      }
      this.sqls = sqls;
      this.showInsertSqlModal = showInsertSqlModal;
    },
    generateInsertSql() {
      const {
        showData
      } = this.selectedTab;
      const sqls = [];
      if (this.actionType === 'currentInsert') {
        sqls.push(this.generateRowInsert(showData[this.rowIndex]));
      } else if (this.actionType === 'allInsert') {
        showData.forEach((row) => {
          sqls.push(this.generateRowInsert(row));
        });
      }
      this.sqls = sqls;
    },
    exportData() {
      const {
        instance,
        database,
        currentTable
      } = this.currentTab;
      this.$refs[`result_table_${this.tab.result.active}`][0].openExport({
        filename: `${instance}-${database}-${currentTable}`,
        types: ['csv']
      });
    },
    changePage(page) {
      this.selectedTab.page = page;
      this.selectedTab.showData = this.selectedTab.dataArr[page - 1];
    }
  }
};
</script>
<style lang="less" scoped>
.insert-sql-modal {
  display: flex;
  flex-direction: column;

  .insert-sql-operation {
    display: flex;
    margin-bottom: 10px;
  }

  .insert-sql-content {
    border: 1px solid rgba(218, 218, 218, 1);
    padding: 10px;
    max-height: 540px;
    overflow: auto;
  }
}

.tip-footer {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  color: #333333;
  background: #FFFFFF;
  z-index: 9;
}

.page-changer {
  //position: absolute;
  //right: 10px;
  //top: 0;
}

//.result-container .ant-tabs-bar {
//  margin: 0;
//  z-index: 9;
//}

.result-container {
  width: 100%-260px;
  overflow: hidden;

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-body {
    overflow: auto;
  }

  .ant-table .ant-table-thead {
    background: #F1F1F1;
  }

  .ivu-page-next, .ivu-page-prev {
    background: none;
  }

  .ivu-page-item {
    background: none;
  }

  .ivu-table-wrapper-with-border {
    border: none;
  }

  .result-table-operation {
    height: 30px;
    width: 100%;
  }

  .result-table-container {
    width: 100%;
    overflow: auto;
    padding: 10px 10px 0 10px;

    .result-content {
      display: flex;

      .result-table {
        width: calc(~"100%");

        .sql {
          height: 24px;
          line-height: 24px;
          border: 1px solid #ccc;
          margin-bottom: 3px;
          background: #eee;
          padding: 0 10px;
        }

        .search {
          height: 40px;
          line-height: 40px;
          width: 100%;
          background: #F3F3F3;
          padding-left: 10px;
        }
      }

      .result-op {
        padding-top: 11px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f0f0f0;
        width: 30px;
        border-left: 1px solid #C7C7C7;
      }
    }

  }

  .ant-table-body {
    .null-value {
      color: #999;
      font-style: italic;
    }

    .ant-table-tbody > tr > td {
      border-right: 1px solid #e8e8e8;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      padding: 4px 8px !important;
    }
  }

  .ant-table-thead > tr > th {
    border-right: 1px solid #e8e8e8;
  }

  .ant-table-row-cel {
    height: 27px !important;

    .ant-table-cell {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }

  .ivu-table-small .ivu-table-header thead tr th {
    height: 27px;
    line-height: 27px;
  }

  .result-info-container {
    padding: 8px;
    overflow: auto;

    .result-info {
      margin-bottom: 5px;
      font-weight: bold;

      .info {
        display: flex;

        .level {
          padding: 0 5px;
          border-radius: 3px;
          height: 20px;
          margin-right: 3px;
          color: #fff;

          &.Info {
            background: #19be6b;
          }

          &.Warn {
            background: #f90;
          }

          &.Error {
            background: #ed4014;
          }
        }

        .time {
          margin-right: 5px;
          color: #aaa;
        }

        .message {
          flex: 1;
        }
      }
    }
  }

  .vxe-table .vxe-table--header-wrapper {
    color: #333333;
  }

  .vxe-table--render-default {
    color: #333333;
  }

  .vxe-header--column {
    font-weight: 500;
  }

  .vxe-body--column {
    vertical-align: middle;
  }

  .vxe-input-tpl {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.seq-content,
.seq-header {
  background: #F5F5F5;
}

.close-icon {
  cursor: pointer;
  margin-left: 3px !important;
  margin-right: -5px !important;
  padding: 2px;

  &:hover {
    background: #eee;
    border-radius: 50%;
  }
}

/deep/ .ant-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
