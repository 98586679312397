export const fakerApi = {
  fakerDef: '/clouddm/console/api/v1/faker/fakerDef',
  fakerInit: '/clouddm/console/api/v1/faker/initFaker',
  fakerPreview: '/clouddm/console/api/v1/faker/preview',
  fakerExecute: '/clouddm/console/api/v1/faker/execute',
  fakerPause: '/clouddm/console/api/v1/faker/pause',
  fakerResume: '/clouddm/console/api/v1/faker/resume',
  fakerTailLog: '/clouddm/console/api/v1/faker/tailLog',
  fakerClose: '/clouddm/console/api/v1/faker/close',
  fakerFetchUiConfig: '/clouddm/console/api/v1/faker/fetchUiConfig'
};
