<template>
  <Table border size="small" :columns="paramsColumn" :data="userConfigMap"
         max-height="600" stripe>
    <template slot-scope="{ row }" slot="configValue">
      <p style="position: relative;padding-right: 15px;">
                                    <span v-if="isJSON(row.configValue)"
                                          style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;display: block">{{
                                        row.configValue
                                      }}
                                        <a style="position: absolute;right: 0;font-size: 14px"
                                           @click="handleShowJson(row)"><Icon
                                          type="md-arrow-dropdown"/></a>
                                    </span>
        <span v-if="!isJSON(row.configValue)">{{
            row.configValue ? row.configValue : '-'
          }}</span>
      </p>
    </template>
    <template slot-scope="{ row }" slot="paramName">
      <p style="position: relative">
                                    <span
                                      :style="`font-style: italic;font-size: 13px;font-weight: 500;color: ${row.needCreated ? '#ff6e0d' : ''}`">
                                      {{row.configName}}
                                      {{row.needCreated ? $t('dai-chuang-jian') : ''}}
                                    <Tooltip transfer :content="row.description" placement="right"
                                             style="position:absolute;right: 0;top: 0">
                                        <a><Icon style="font-size: 14px"
                                                 type="ios-information-circle"/></a>
                                    </Tooltip>
                                </span>
      </p>
    </template>
    <template slot-scope="{ row }" slot="currentValue">
      <div>
        <div v-if="!row.readOnly && canEdit">
          <div v-if="row.confValType === 'BOOLEAN'">
            <i-switch true-color="#52C41A" v-model="row.formatValue" @on-change="handleEditCurrent(row, row.formatValue)"></i-switch>
          </div>
          <div v-if="row.confValType !== 'BOOLEAN'">
                                          <span
                                            style="margin-right: 16px;display: inline-block;width: 60px;">{{
                                              row.currentCount
                                            }}</span><span>
                                    <Poptip v-model="row.visible" transfer
                                            @on-popper-show="handlePopShow(row)"
                                            placement="right" width="250">
<!--                                      <Icon type="md-create" />-->
                                      <i
                                        class="iconfont iconbianji"></i>
                                <div slot="content">
                                    <p style="font-size: 12px;margin-bottom: 10px">{{ $t('xiu-gai-can-shu-wei') }}:</p>
                                    <p style="margin-bottom: 10px"><Input size="small"
                                                                          style="width: 200px"
                                                                          v-model="currentValue"/></p>
                                    <p><pd-button style="margin-right: 5px" type="primary"
                                                  size="small"
                                                  @click="handleEditCurrent(row, currentValue)">{{ $t('que-ding') }}</pd-button></p>
                                </div>
                            </Poptip>
                                <Tooltip transfer style="margin-left: 5px" :content="$t('che-xiao')"
                                         placement="right"
                                         v-if="row.currentCount"><a style="font-size: 16px;"
                                                                    @click="handleCancelEdit(row)"><Icon
                                  type="md-undo"/></a></Tooltip></span>
          </div>
        </div>
        <div v-if="row.readOnly">
          {{ $t('zhi-du-can-shu') }}
        </div>
      </div>
    </template>
    <template slot-scope="{ row }" slot="action">
      <pd-button ghost type="primary" size="small" style="margin-right: 5px"
                 @click="handleShowEditParams(row,'serviceCoreParamsData')">{{ $t('xiu-gai') }}
      </pd-button>
    </template>
  </Table>
</template>
<script>
import UtilJson from '@/views/util';
import { pick } from '@/components/function/monitor/utils/colors';

export default {
  name: 'UserConfigTable',
  props: {
    userConfigMap: Array,
    handleShowJson: Function,
    isJSON: Function,
    canEdit: Boolean,
    handleShowEditParams: Function,
    handleEditCurrent: Function,
    showTagList: Array
  },
  data() {
    return {
      currentValue: '',
      paramsColumn: [
        {
          title: this.$t('can-shu-ming-cheng'),
          key: 'configName',
          slot: 'paramName',
          width: 300,
          sortable: true
        },
        {
          title: this.$t('can-shu-dang-qian-yun-hang-zhi'),
          key: 'configValue',
          width: 260,
          slot: 'configValue'
        },
        {
          title: this.$t('xiu-gai-hou-de-can-shu-zhi'),
          key: 'currentCount',
          slot: 'currentValue',
          width: 160,
          filters: [
            {
              label: this.$t('ke-xiu-gai-can-shu'),
              value: 1
            },
            {
              label: this.$t('zhi-du-can-shu'),
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return !row.readOnly;
            }
            if (value === 2) {
              return row.readOnly;
            }
          }
        },
        {
          title: this.$t('ke-xiu-gai-fan-wei'),
          key: 'valueRange'
        },
        {
          title: this.$t('biao-qian'),
          width: 160,
          filters: [],
          filterMethod(value, row) {
            return row.userConfigTagType === value;
          },
          filterMultiple: false,
          render: (h, params) => h('div', [
            h('Tooltip', {
              props: {
                content: UtilJson.tagInfo[params.row.userConfigTagType],
                placement: 'left-start',
                transfer: true
              }
            }, [
              h('pd-button', {
                props: {
                  type: 'warning',
                  size: 'small',
                  ghost: true
                },
                style: {
                  color: pick(this.showTagList.indexOf(params.row.userConfigTagType)),
                  borderColor: pick(this.showTagList.indexOf(params.row.userConfigTagType))
                }
              }, params.row.userConfigTagType)
            ])
          ])
        }
      ]
    };
  },
  methods: {
    handleCancelEdit(row) {
      this.userConfigMap.forEach((item) => {
        if (item.configName === row.configName) {
          item.currentCount = '';
        }
      });
      this.userConfigMap = [...this.userConfigMap];
    },
    handlePopShow(row) {
      this.currentValue = row.currentCount;
    }
  },
  watch: {
    userConfigMap: {
      handler(newVal) {
        const filters = [];
        newVal.forEach((userConfig) => {
          const currentTag = {
            label: userConfig.userConfigTagType,
            value: userConfig.userConfigTagType
          };
          const contains = filters.some((item) => item.label === userConfig.userConfigTagType);
          if (!contains) {
            filters.push(currentTag);
          }
        });
        this.paramsColumn[4].filters = filters;
      },
      deep: true
    }
  }
};
</script>
