<template>
  <div>
    <second-confirm-modal
      :title="$t('chong-zhi-wei-dian-que-ren')"
      :event="SECOND_CONFIRM_EVENT_LIST.RESET_POSITION"
      :text="$t('yi-wan-cheng-hui-su-selectedjobid-wei-dian-de-cao-zuo', [jobData.dataJobName])"
      :visible="showResetPositionConfirm"
      ref="second-confirm-modal"
      :handle-confirm="handleResetPositionConfirm"
      :handle-close="handleCancelModal"
    />
    <Modal
      v-model="showMapping"
      :title="$t('jobdatadatajobname-ren-wu-de-ku-biao-ying-she', [jobData.dataJobName])"
      width="1200px"
      footer-hide
      :closable="false"
    >
      <div>
        <div style="height: 560px;overflow: auto;" v-if="showMapping" class="show-mapping-modal">
          <ConfigMapping :sourceSchema="sourceSchema" :getTargetData="getTargetData"
                         :getAddedData="getAddedData" :trueColumnData="trueColumnData"
                         :selectedIndex="selectedIndex" :mappingDef="mappingDef"
                         :getMappingCount="getMappingCount"
                         :showTableMapping="showTableMapping" :sourceTable="sourceTable"
                         :handlePageChange="handlePageChange"
                         :handleChangeTable="handleChangeTable" :jobData="jobData"
                         :mqSchema="mqSchema" :handleShowWhereCondition="handleShowWhereCondition"
                         :targetSchema="targetSchema"></ConfigMapping>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleChangeBinlogCancel">{{ $t('guan-bi') }}
          </pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showResetPosition"
      :title="$t('hui-su-wei-dian')"
      footer-hide
      :closable="false"
    >
      <div>
        <Alert type="warning" style="margin-bottom: 20px">
          <div style="color: #333;line-height: 22px">
            <ul>
              <li style="list-style:none;">
                <span class="warn-point"></span>
                {{ $t('gai-cao-zuo') }}
                <span class="warn-font">{{ $t('bu-ke-ni') }}</span>
                {{ $t('qing-jin-shen-cao-zuo') }}
              </li>
              <li style="list-style:none;">
                <span class="warn-point"></span>
                {{ $t('hui-su-wei-dian-hou-jiang-zi-dong-zhong-qi-ren-wu') }}
              </li>
              <li style="list-style:none;">
                <span class="warn-point"></span>
                {{ $t('hui-su-wei-dian-de-shi-jian-ru-guo-da-yu-dang-qian-de-wei-dian-shi-jian-ke-neng-you') }}
                <strong>{{ $t('shu-ju-diu-shi-feng-xian-0') }}</strong>
              </li>
              <!--              <li style="list-style:none;">-->
              <!--                <span class="warn-point"></span>-->
              <!--                {{ $t('ru-xu-qing-kong-wei-dian-cong-dang-qian-shi-jian-kai-shi-xiao-fei-qing-shi-yong-qing-chu-wei-dian') }}-->
              <!--              </li>-->
            </ul>
          </div>
        </Alert>
        <p style="margin-bottom: 16px" v-if="posTypeList.length>1">
          <span style="margin-right: 10px">{{ $t('wei-dian-lei-xing') }}</span>
          <Select style="width: 200px;display: inline-block" v-model="posType" @on-change="handleChangePositionType">
            <Option v-for="type in posTypeList" :key="type" :value="type">{{MappingUtil.posType[type]||type}}</Option>
          </Select>
        </p>
        <div v-if="posType.includes('TIMESTAMP_POS')" style="margin-bottom:10px;">
          <span style="margin-right: 10px">{{ $t('xuan-ze-shi-jian-chuo') }}</span>
          <DatePicker :editable="false"
                      v-model="theBinlogTime"
                      @on-change="updatePositionInfo(theBinlogTime, posType)"
                      type="datetime" style="width: 200px">
          </DatePicker>
          <Poptip trigger="hover">
            <Icon type="ios-help-circle-outline" size="20" style="margin-left: 10px"></Icon>
            <div slot="content">
              {{ $t('qing-que-bao-tian-xie-de-shi-jian-dian-yi-hou-bao-han-yi-shang-zhi-ding-de-binlog-file') }}
            </div>
          </Poptip>
        </div>
        <div v-if="posType.includes('GTID_POS')">
          <span style="margin-right: 10px">{{ $t('tian-xie-gtid') }}</span>
          <Input v-model="gtid" style="width: 200px" @on-change="updatePositionInfo(gtid, posType)" />
        </div>
        <div v-if="posType.includes('LOG_FILE_POS')">
          <p style="margin-bottom: 16px">
            <span style="margin-right: 10px">{{ $t('tian-xie-wen-jian-ming-cheng') }}</span>
            <Input v-model="theLogFile" style="width: 200px" @on-change="updatePositionInfo(theLogFile, posType, 'file')" />
          </p>
          <p>
            <span style="margin-right: 10px">{{ $t('tian-xie-wen-jian-wei-dian') }}</span>
            <Input v-model="logFilePosition" style="width: 200px" @on-change="updatePositionInfo(logFilePosition, posType, 'position')" />
          </p>
        </div>
        <div v-if="posType.includes('LSN_POS')">
          <span style="margin-right: 10px">{{ $t('tian-xie-lsn') }}</span>
          <Input v-model="lsn" style="width: 200px" @on-change="updatePositionInfo(lsn, posType)" />
        </div>
        <div v-if="posType.includes('DATA_ID_POS')">
          <span style="margin-right: 10px">{{ $t('tian-xie-dataid') }}</span>
          <Input v-model="dataId" style="width: 200px" @on-change="updatePositionInfo(dataId, posType)" />
        </div>
        <div v-if="posType.includes('TRANSACTION_ID_POS')">
          <span style="margin-right: 10px">{{ $t('tian-xie-shi-wu-id') }}</span>
          <Input v-model="dataId" style="width: 200px" @on-change="updatePositionInfo(dataId, posType)" />
        </div>
        <div v-if="posType.includes('SCN_POS')">
          <span style="margin-right: 10px">{{ $t('tian-xie-scn') }}</span>
          <Input v-model="scn" style="width: 200px" @on-change="updatePositionInfo(scn, posType)" />
        </div>
        <div style="margin: 16px 0" v-if="jobData.sourceDsVO.dataSourceType === 'MySQL' && (posType.includes('LOG_FILE_POS') || posType.includes('TIMESTAMP_POS'))">
          <Checkbox v-model="showMoreSetting">{{ $t('gao-ji-she-zhi') }}</Checkbox>
        </div>
        <div v-if="showMoreSetting">
          <span style="margin-right: 10px">{{ $t('she-zhi-serverid') }}</span>
          <Input v-model="serverId" style="width: 200px" @on-change="updatePositionInfo(serverId, posType, 'serverId')" />
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" :loading="positionLoading" :disabled="!posType || (posType.includes('TIMESTAMP_POS')&&!theBinlogTime)"
                     @click="handleShowResetPositionConfirmModal">{{ $t('que-ding') }}
          </pd-button>
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleChangeBinlogCancel">{{ $t('qu-xiao') }}
          </pd-button>
        </div>
      </div>
    </Modal>
    <Modal v-model="showPartition" :title="$t('fen-qu-she-zhi')">
      <div style="word-break: break-all">
        <Form label-position="right" :label-width="80" v-if="partition.partition[0]">
          <FormItem :label="$t('fen-qu-jian')">
            {{ partition.partition[0].key }}
          </FormItem>
          <FormItem :label="$t('fen-qu-fang-fa')">
            {{ partition.partition[0].func }}
          </FormItem>
          <FormItem :label="$t('wen-jian-ge-shi')">
            {{ partition.fileFormat }}
          </FormItem>
          <FormItem :label="$t('ya-suo-fang-shi')">
            {{ partition.compressType }}
          </FormItem>
        </Form>
      </div>
    </Modal>
    <Modal footer-hide v-model="showWhereCondition" draggable :title="$t('where-tiao-jian')">
      <div>
        <div style="word-break: break-all">{{ currentWhereCondition }}</div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleCancelDelete">{{ $t('que-ren') }}</pd-button>
          <pd-button @click="handleCancelDelete">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showEditJobDesc"
      :title="$t('xiu-gai-ren-wu-miao-shu')"
      footer-hide
      :closable="false"
    >
      <div>
        <Form label-position="right" :label-width="100">
          <FormItem :label="$t('shi-li-id')">
            {{ selectedJob.dataJobName }}
          </FormItem>
          <FormItem :label="$t('miao-shu')">
            <Input type="textarea" v-model="selectedJob.dataJobDesc"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmEdit">{{$t('que-ren')}}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showCreateCheck"
      :title="$t('chuang-jian-shu-ju-jiao-yan-ren-wu')"
      footer-hide
      width="1000px"
      :closable="false"
    >
      <div>
        <Form :model="checkConfig" label-position="right" :label-width="120">
          <FormItem :label="$t('shi-fou-kai-qi-xiao-yan')" key="mode" prop="mode">
            <RadioGroup v-model="checkConfig.checkMode"
                        style="display: inline-block;vertical-align: middle">
              <Radio label="checkOnce">{{ $t('kai-qi-yi-ci-xing-xiao-yan') }}
              </Radio>
              <Radio v-if="jobData.dataJobType === 'SYNC'" label="checkPeriod">
                {{ $t('kai-qi-zhou-qi-xing-xiao-yan') }}
              </Radio>
            </RadioGroup>
            <div v-if="checkConfig.checkMode==='checkPeriod'" style="display: inline-block;margin-left: 10px;vertical-align:middle">
              <span style="margin-right: 5px">{{ $t('mei') }}</span>
              <Select v-model="checkConfig.checkPeriodDate.dayType" style="width: 100px;margin-right: 10px">
                <Option v-for="type in loopRunTypes" :value="type.loopRunType" :key="type.loopRunType">{{type.i18nName}}</Option>
              </Select>
              <Select v-model="checkConfig.checkPeriodDate.month" placeholder="select month"
                      v-if="checkConfig.checkPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
                <Option v-for="(month,index) of monthList" :key="index" :value="month">
                  {{month}}
                </Option>
              </Select>
              <Select v-model="checkConfig.checkPeriodDate.date" placeholder="select date"
                      v-if="checkConfig.checkPeriodDate.dayType==='MONTH'||checkConfig.checkPeriodDate.dayType==='YEAR'" style="width: 100px;margin-right: 10px">
                <Option v-for="(date,index) of dateList" :key="index" :value="date">
                  {{date}}
                </Option>
              </Select>
              <Select v-model="checkConfig.checkPeriodDate.week"
                      v-if="checkConfig.checkPeriodDate.dayType==='WEEK'" style="width: 100px;margin-right: 10px">
                <Option key="1" value="1">
                  {{ $t('ri-0') }}
                </Option>
                <Option key="2" value="2">
                  {{ $t('yi-1') }}
                </Option>
                <Option key="3" value="3">
                  {{ $t('er-0') }}
                </Option>
                <Option key="4" value="4">
                  {{ $t('san-0') }}
                </Option>
                <Option key="5" value="5">
                  {{ $t('si-0') }}
                </Option>
                <Option key="6" value="6">
                  {{ $t('wu-1') }}
                </Option>
                <Option key="7" value="7">
                  {{ $t('liu-0') }}
                </Option>
              </Select>
              <TimePicker v-if="checkConfig.checkPeriodDate.dayType!=='HOUR'"
                          v-model="checkConfig.checkPeriodDate.time" format="HH:mm"
                          placeholder="Select time" style="width: 112px"></TimePicker>
              <Select v-model="checkConfig.checkPeriodDate.hour"
                      v-if="checkConfig.checkPeriodDate.dayType==='HOUR'" style="width: 100px">
                <Option v-for="(minute,index) of minutes" :key="index" :value="minute">
                  {{ minute &lt; 10 ? '0' + minute : minute }}
                </Option>
              </Select>
              <span v-if="checkConfig.checkPeriodDate.dayType==='HOUR'" style="margin-right: 4px">{{ $t('fen') }}</span>
            </div>
          </FormItem>
          <div>
            <FormItem :label="$t('shu-ju-xiao-yan-mo-shi')" key="dataCheckType" prop="mode">
              <RadioGroup v-model="checkConfig.dataCheckType"
                          style="display: inline-block;vertical-align: middle">
                <Radio v-for="opt of checkReviseOpts" :label="opt.dataCheckType" :key="opt.dataCheckType">{{opt.i18nName}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem :label="$t('xiao-yan-ding-zheng-mo-shi')" key="dataReviseType" prop="mode">
              <RadioGroup v-model="checkConfig.dataReviseType"
                          style="display: inline-block;vertical-align: middle">
                <Radio v-for="type of selectedCheckType.reviseTypeVos" :label="type.dataReviseType" :key="type.dataReviseType">{{type.i18nName}}</Radio>
              </RadioGroup>
            </FormItem>
          </div>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmCreateCheck(checkConfig)">{{$t('que-ren')}}</pd-button>
          <pd-button @click="handleCancelEdit">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import fecha from 'fecha';
import MappingUtil from '@/views/util';
import { SECOND_CONFIRM_EVENT_LIST } from '@/const';
import SecondConfirmModal from '@/components/modal/SecondConfirmModal';
import ConfigMapping from '../ConfigMapping';
import DataJobDetailMixin from '../../../mixin/dataJobDetailMixin';

export default {
  mixins: [DataJobDetailMixin],
  components: {
    ConfigMapping,
    SecondConfirmModal
  },
  props: {
    showMapping: Boolean,
    jobData: Object,
    getAddedData: Function,
    selectedIndex: Number,
    mappingDef: Array,
    sourceSchema: Array,
    getMappingCount: Function,
    showTableMapping: Object,
    sourceTable: Object,
    showResetPosition: Boolean,
    binlogTime: Date,
    handleResetPosition: Function,
    handleChangeBinlogCancel: Function,
    showPartition: Boolean,
    partition: Object,
    showWhereCondition: Boolean,
    showEditJobDesc: Boolean,
    selectedJob: Object,
    handleConfirmEdit: Function,
    showConfirm: Boolean,
    handleCancelDelete: Function,
    showLog: Boolean,
    handleSelectLog: Function,
    logLoading: Boolean,
    logPreLoading: Boolean,
    logNextLoading: Boolean,
    handleShowLog: Function,
    logData: Array,
    showEditJob: Boolean,
    handleCancelEdit: Function,
    showClearPosition: Boolean,
    currentWhereCondition: String,
    taskPosition: Object,
    handleChangeTable: Function,
    updatePositionInfo: Function,
    selectedLog: Object,
    handlePageChange: Function,
    updateLogFileName: Function,
    updateSourceSchema: Function,
    mqSchema: Object,
    targetSchema: Array,
    handleShowWhereCondition: Function,
    positionLoading: Boolean,
    mqSchemaTemplate: String,
    queryJobSchema: Object,
    posTypeList: Array,
    trueColumnData: Object,
    showCreateCheck: Boolean,
    loopRunTypes: Array,
    checkReviseOpts: Array,
    handleConfirmCreateCheck: Function
  },
  data() {
    return {
      showResetPositionConfirm: false,
      fecha,
      MappingUtil,
      startTimeOptions: {
        disabledDate: (date) => date && date.valueOf() > new Date(this.taskPosition.positionTimestamp)
          || date.valueOf() < new Date(new Date(this.taskPosition.positionTimestamp).getTime() - 7 * 24 * 3600 * 1000)
      },
      verifyCode: '',
      logFileName: '',
      theBinlogTime: '',
      posType: '',
      gtid: '',
      theLogFile: '',
      logFilePosition: '',
      lsn: '',
      scn: '',
      dataId: '',
      serverId: null,
      showMoreSetting: false,
      checkConfig: {
        checkMode: 'checkOnce',
        checkPeriodDate: {},
        dataCheckType: ''
      },
      dateList: [],
      monthList: [],
      minutes: [],
      selectedCheckType: ''
    };
  },
  computed: {
    SECOND_CONFIRM_EVENT_LIST() {
      return SECOND_CONFIRM_EVENT_LIST;
    }
  },
  mounted() {
    for (let i = 0; i < 60; i++) {
      this.minutes.push(i);
    }
    for (let i = 1; i < 32; i++) {
      this.dateList.push(i);
    }
    for (let i = 1; i < 13; i++) {
      this.monthList.push(i);
    }
  },
  methods: {
    handleResetPositionConfirm() {
      this.handleCancelModal();
      this.handleResetPosition('', false, this.posType);
    },
    handleCancelModal() {
      this.showResetPositionConfirm = false;
    },
    handleShowResetPositionConfirmModal() {
      this.showResetPositionConfirm = true;
    },
    handleChangePositionType() {
      if (this.posType.includes('TIMESTAMP_POS')) {
        this.updatePositionInfo(this.theBinlogTime, this.posType);
      }
    }
  },
  watch: {
    taskPosition(val) {
      this.theBinlogTime = new Date(val.positionTimestamp);
    },
    posTypeList(val) {
      if (val && val.length) {
        this.posType = val[0];
      }
    },
    showCreateCheck(val) {
      if (val) {
        this.checkReviseOpts.forEach((opt) => {
          if (opt.defaultCheck) {
            this.selectedCheckType = opt;
            if (!this.checkConfig.dataCheckType) {
              this.checkConfig.dataCheckType = opt.dataCheckType;
            }
            opt.reviseTypeVos.forEach((type) => {
              if (type.defaultCheck) {
                if (!this.checkConfig.dataReviseType) {
                  this.checkConfig.dataReviseType = type.dataReviseType;
                }
              }
            });
          }
        });
      }
    }
  }
};
</script>
