<template>
  <div>
    <div @click="handleGetColumnByTable(table,index)"
         :class="`table-filter-nav-item ${index===selectedIndex?'table-filter-nav-item-selected':''}`"
         v-for="(table,index) in showTableList" :key="index">
      <div class="table-filter-nav-item-db">
        <i class="iconfont iconjiantou"></i>
        <img class="table-filter-nav-item-arrow" src="../../../../../assets/jiantou01.png" alt="arrow">
        <p style="margin-bottom: 2px">
          <i class="iconfont iconbiao"></i>{{ table.sourceDb }}<span
          v-if="DataSourceGroup.hasSchema.includes(sourceType)">.{{
            table.sourceSchema
          }}</span>.{{ table.sourceTable }}</p>
        <p style="word-break: break-all" :class="table.needAutoCreated?'db-to-create':''">
          <i
            :class="`iconfont ${sinkType === 'ElasticSearch'?'iconsuoyin':table.needAutoCreated?'icondaijianBIAO':'iconbiao'}`"></i>
          <span><span v-if="!DataSourceGroup.noDb.includes(sinkType) && (table.sinkDb || table.targetDb)">{{ table.sinkDb || table.targetDb }}.</span>
            <span v-if="DataSourceGroup.hasSchema.includes(sinkType)">{{ table.targetSchema }}.</span>{{
              table.sinkTable === 'cloudCanal_default' && table.customSinkTable ? table.customSinkTable : table.sinkTable || table.targetTable
            }}</span>
        </p>
        <div class="chosed-count" style="display: flex;justify-content: space-between;padding-right: 20px;">
          <div>
            {{ $t('yi-xuan-zhong') }}
            <span :class="getSelectedInfo(
              isMongoDB(sourceType)?table.sinkDb:table.sourceDb,
              isMongoDB(sourceType)?table.sinkTable:table.soucrceTable).selectedCount<
                      (selectedColumns[isMongoDB(sourceType)?table.sinkDb:table.sourceDb][isMongoDB(sourceType)?table.sinkTable:table.sourceTable]?
                      selectedColumns[isMongoDB(sourceType)?table.sinkDb:table.sourceDb][isMongoDB(sourceType)?table.sinkTable:table.sourceTable].length:0)?'warn-count':''"
                  style="text-align: center;display: inline-block;">
                    {{ getSelectedInfo(isMongoDB(sourceType)?table.sinkDb:table.sourceDb, isMongoDB(sourceType)?table.sinkTable:table.sourceTable).selectedCount }}</span>
            {{ $t('selectedcolumnsismongodbsourcetypetablesinkdbtablesourcedbismongodbsourcetypetablesinktabletablesourcetable-selectedcolumnsismongodbsourcetypetablesinkdbtablesourcedbismongodbsourcetypetablesinktabletablesourcetablelength-0-ge-lie', [selectedColumns[isMongoDB(sourceType)?table.sinkDb:table.sourceDb][isMongoDB(sourceType)?table.sinkTable:table.sourceTable] ? selectedColumns[isMongoDB(sourceType)?table.sinkDb:table.sourceDb][isMongoDB(sourceType)?table.sinkTable:table.sourceTable].length : 0]) }}
            </div>
          <Dropdown transfer trigger="click" v-if="!isMongoDB(sourceType)">
            <a class="dropdown-content" href="javascript:void(0)">
               {{ $t('cao-zuo') }}
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list" class="table-operation-dropdown">
              <DropdownItem v-if="DataSourceGroup.drds.includes(sinkType)">
                <a class="dropdown-content" @click="handleSetDrdsPartition(table)" style="color: #333333">{{ $t('fen-qu-jian') }}</a>
              </DropdownItem>
              <DropdownItem  v-if="supportVirtualColumn && !table.hasInJob" class="table-operation-dropdown">
                <a class="dropdown-content"
                  style="color: #333333"
                  @click="handleShowVirtualColumn(table)">
                  {{ $t('xu-ni-lie') }}</a>
              </DropdownItem>
              <DropdownItem v-if="supportSpecifiedKeys" class="table-operation-dropdown">
                <a class="dropdown-content"
                  style="color: #333333"
                  @click="handleChangePk(table)">
                  {{ $t('mu-biao-zhu-jian') }}</a>
              </DropdownItem>
              <DropdownItem v-if="sinkType==='ElasticSearch'" class="table-operation-dropdown">
                <a class="dropdown-content"
                  style="color: #333333;margin-right: 5px;"
                  @click="handleSetId(table)"
                >
                  {{$t('she-zhi-id-zhi')}} {{ setPkAndIdFlags[`${table.sourceDb}.${table.sourceTable}`] && setPkAndIdFlags[`${table.sourceDb}.${table.sourceTable}`].id ? $t('yi-she-zhi') : '' }}
                </a>
              </DropdownItem>
              <DropdownItem class="table-operation-dropdown">
                <a style="color: #333333"
                   :class="getWhereCondition(table)?'has-where-table dropdown-content':'dropdown-content'"
                   @click="handleFilter(table)">
                  {{ $t('shu-ju-guo-lv-tiao-jian') }}</a>
              </DropdownItem>
<!--              <DropdownItem v-if="(isMySQL(sourceType)||isPolar(sourceType)||isOracle(sourceType))&&isStarRocks(sinkType)">-->
<!--                <a style="color: #333333"-->
<!--                   class="color: #333333"-->
<!--                   @click="handleBucketKeys(table)">-->
<!--                  修改排序键和分桶键</a>-->
<!--              </DropdownItem>-->
              <DropdownItem v-if="isMySQL(sourceType) && isMySQL(sinkType)" class="table-operation-dropdown">
                <a style="color: #333333" class="dropdown-content"
                   @click="handleTargetFilter(table)">
                  {{ $t('dui-duan-geng-xin-tiao-jian') }}</a>
              </DropdownItem>
              <DropdownItem v-if="sinkType === 'StarRocks'" class="table-operation-dropdown">
                <a style="color: #333333" class="dropdown-content"
                   @click="handleSetSrPartition(table)">
                  {{ $t('she-zhi-fen-qu') }}</a>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataSourceGroup from '@/views/dataSourceGroup';
import {
  isMongoDB, isMySQL, isStarRocks, isPolar, isOracle
} from '@/utils';

export default {
  props: {
    taskInfo: Object,
    setPkAndIdFlags: Object,
    handleGetColumnByTable: Function,
    selectedIndex: Number,
    showTableList: Array,
    getSelectedInfo: Function,
    getWhereCondition: Function,
    handleShowVirtualColumn: Function,
    handleSetId: Function,
    handleFilter: Function,
    handleBucketKeys: Function,
    handleTargetFilter: Function,
    selectedColumns: Object,
    sourceType: String,
    sinkType: String,
    handleSetDrdsPartition: Function,
    uniqList: Object,
    handleConfirmChangePk: Function,
    handleChangePk: Function,
    theType: String,
    handleSetSrPartition: Function,
    supportVirtualColumn: Boolean,
    supportSpecifiedKeys: Boolean
  },
  data() {
    return {
      // selectedIndex: 0,
      showChangePk: false,
      selectedTable: {},
      DataSourceGroup
    };
  },
  methods: {
    isMongoDB,
    isMySQL,
    isStarRocks,
    isOracle,
    isPolar,
    handleClickTable(table, index) {
      this.handleGetColumnByTable(table, index);
      this.selectedIndex = index;
    }
  }
};
</script>
<style lang="less">
.operation {
  &:hover {
    color: #0BB9F8!important;
  }
  position: absolute;
  right: 100px;
  color: #333333;
}

.table-operation-dropdown .ivu-dropdown-item {
  padding: 0!important;
}

.dropdown-content {
  padding: 7px 16px;
  display: block;
}
</style>
