import ReconnectingWebSocket from 'reconnecting-websocket';
import { UPDATE_SOCKET_STATUS } from '@/store/mutationTypes';
import store from '@/store';
import { WS_TYPE } from '@/utils';
import i18n from '@/i18n';
import { EVENT_BUS_NAME_LIST } from '@/utils/eventBusName';

let rws = null;
let globalCallback = {
  open: null,
  message: null,
  error: null,
  close: null
};

const hasWebSocketInstance = () => !!rws;

const createWebSocket = (url) => {
  rws = new ReconnectingWebSocket(`${url}?locale=${i18n.locale}`, null, { debug: false, reconnectInterval: 3000 });
  rws.addEventListener('open', () => {
    if (!rws) {
      return;
    }

    if (rws.readyState === rws.OPEN) {
      store.commit(UPDATE_SOCKET_STATUS, { connected: true, msg: '连接已建立' });
      if (globalCallback.open) {
        globalCallback.open();
      }
    }
  });

  rws.addEventListener('message', (e) => {
    try {
      const data = JSON.parse(e.data);
      if (data && data.type === 'WS_SYS_STATUS' && data?.object?.serviceReady) {
        window.$bus.emit(EVENT_BUS_NAME_LIST.SOCKET_CONNECTION_OPEN);
      }

      if (data && data.type === WS_TYPE.WS_RES_ASYNC_EVENT) {
        window.$bus.emit(EVENT_BUS_NAME_LIST.WS_RES_ASYNC_EVENT, data);
      }
    } catch (error) {
      console.log(error);
    }
    if (globalCallback.message) {
      globalCallback.message(e.data);
    }
  });

  rws.addEventListener('error', (e) => {
    if (globalCallback.error) {
      globalCallback.error(e);
    }
  });

  rws.addEventListener('close', () => {
    store.commit(UPDATE_SOCKET_STATUS, { connected: false, msg: '连接已断开' });
    window.$bus.emit(EVENT_BUS_NAME_LIST.SOCKET_CONNECTION_CLOSE);
    // rws.close();
    if (globalCallback.close) {
      globalCallback.close();
    }
  });
};

// const webSocketOpen = () => {
//   console.log('ws open');
//   sendWebSocket({}, () => {});
// };

const webSocketSend = (data) => {
  rws.send(JSON.stringify(data));
};

// const webSocketOnMessage = (data) => {
//   globalCallback(data);
// };

const webSocketClose = () => {
  rws.close();
};

const sendWebSocket = (data, callback = {}) => {
  globalCallback = callback;
  switch (rws.readyState) {
    case rws.OPEN:
      console.log('OPEN');
      webSocketSend(data);
      break;
    case rws.CONNECTING:
      console.log('CONNECTING');
      setTimeout(() => {
        webSocketSend(data, callback);
      }, 1000);
      break;
    case rws.CLOSING:
      console.log('CLOSING');
      setTimeout(() => {
        webSocketSend(data, callback);
      });
      break;
    case rws.CLOSED:
      console.log('CLOSED');
      break;
    default:
      break;
  }
};

export {
  createWebSocket, webSocketClose, sendWebSocket, hasWebSocketInstance
};
