export const securityApi = {
  ruleList: '/clouddm/console/api/v1/security/rules/ruleList',
  ruleDetail: '/clouddm/console/api/v1/security/rules/ruleDetail',
  ruleSave: '/clouddm/console/api/v1/security/rules/ruleSave',
  ruleDelete: '/clouddm/console/api/v1/security/rules/ruleDelete',
  ruleExtract: '/clouddm/console/api/v1/security/rules/ruleExtract',
  ruleFormat: '/clouddm/console/api/v1/security/rules/ruleFormat',
  ruleSupportDs: '/clouddm/console/api/v1/security/rules/ruleSupportDs',
  ruleSettingDef: '/clouddm/console/api/v1/security/support/ruleSettingDef',
  specRuleDetail: '/clouddm/console/api/v1/security/rules/specRuleDetail',
  specFetchRange: '/clouddm/console/api/v1/security/range/specFetchRange',
  specSaveRange: '/clouddm/console/api/v1/security/range/specSaveRange',
  specDeleteRange: '/clouddm/console/api/v1/security/range/specDeleteRange',
  specList: '/clouddm/console/api/v1/security/rules/specList',
  specDetail: '/clouddm/console/api/v1/security/rules/specDetail',
  specUpdateInfo: '/clouddm/console/api/v1/security/rules/specUpdateInfo',
  specConfig: '/clouddm/console/api/v1/security/rules/specConfig',
  specDelete: '/clouddm/console/api/v1/security/rules/specDelete',
  specCreate: '/clouddm/console/api/v1/security/rules/specCreate',
  specSaveRule: '/clouddm/console/api/v1/security/rules/specSaveRule',
  rangeListEnv: '/clouddm/console/api/v1/security/range/listEnv',
  rangeListIns: '/clouddm/console/api/v1/security/range/listIns',
  rangeListLevels: '/clouddm/console/api/v1/security/range/listLevels',
  rangeListLeaf: '/clouddm/console/api/v1/security/range/listLeaf',
  rangeListColumn: '/clouddm/console/api/v1/security/range/listColumn'
};
