import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;

const pending = new Map();

const generateCancelReqKey = (type, config) => {
  const {
    url, method, params, data
  } = config;
  let obj = {};
  if (typeof data === 'string') {
    obj = JSON.parse(data);
  } else {
    obj = data;
  }
  return [url, method, qs.stringify(params), qs.stringify(obj)].join('&');
};

export const addPending = (config) => {
  const url = generateCancelReqKey('add', config);
  config.cancelToken = config.cancelToken || new CancelToken((c) => {
    if (!pending.has(url)) {
      pending.set(url, c);
    }
  });
};

export const cancelPending = (config) => {
  const url = generateCancelReqKey('remove', config);
  if (pending.has(url)) {
    console.log('cancel pending');
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
};

export const removePending = (config) => {
  const url = generateCancelReqKey('remove', config);
  if (pending.has(url)) {
    pending.delete(url);
  }
};

export const clearAllPending = () => {
  console.log(pending);
  for (const [url, cancel] of pending) {
    cancel(url);
  }
  pending.clear();
};
