<template>
  <div>
    <div  @click="handleShowUpdateLevel(row)">
          <span class="level-block" :style="`background: ${levelBackColor[row.jobLevel]}`">
      {{row.jobLevel}}
    </span>
      <Icon type="md-create" />
    </div>
    <Modal
      v-model="showUpdateLevel"
      :title="$t('xiu-gai-ren-wu-ji-bie')"
      footer-hide
    >
      <div>
        <p style="margin-bottom: 10px">{{$t('jiang')}}<span class="bold-name">{{selectedRow.dataJobName}}</span>{{$t('de-ren-wu-ji-bie-xiu-gai-wei')}}</p>
        <Select v-model="currentLevel">
          <Option value="P0">P0</Option>
          <Option value="P1">P1</Option>
          <Option value="P2">P2</Option>
          <Option value="P3">P3</Option>
          <Option value="P4">P4</Option>
        </Select>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="updateJobLevel">{{$t('que-ren')}}</pd-button>
          <pd-button style="margin-left: 5px" type="default"
                     @click="handleCancel">{{ $t('guan-bi') }}
          </pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { updateJobLevel } from '@/services/cc/api/job';

export default {
  name: 'JobLevel',
  props: {
    row: Object,
    nextStep: Function,
    beforeClick: Function
  },
  data() {
    return {
      showUpdateLevel: false,
      levelBackColor: {
        'P0': '#ed4014',
        'P1': '#ff9900',
        'P2': '#2b85e4',
        'P3': '#2db7f5',
        'P4': '#c5c8ce'
      },
      currentLevel: '',
      selectedRow: ''
    }
  },
  methods: {
    handleShowUpdateLevel(row) {
      if (this.beforeClick) {
        this.beforeClick(this.row);
      }
      this.showUpdateLevel = true;
      this.selectedRow = row;
      this.currentLevel = row.jobLevel;
    },
    updateJobLevel() {
      updateJobLevel({
        jobId: this.selectedRow.dataJobId,
        jobLevel: this.currentLevel
      }).then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('xiu-gai-cheng-gong'));
          this.nextStep();
        }
        this.showUpdateLevel = false;
      })
    },
    handleCancel() {
      this.showUpdateLevel = false;
    }
  }
}
</script>
<style lang="less" scoped>
.level-block {
  display: inline-block;
  padding: 0 2px;
//vertical-align: middle;
//background: red;
  color: #ffffff;
  border-radius: 2px;
  margin-right: 4px;
  cursor: pointer;
}
</style>
