<template>
  <div class="sql-viewer">
    <operators :onRun="onRun" :tab="tab" :stopping="stopping" :handle-rollback="handleRollback"
               :handle-commit="handleCommit" :handle-set-isolation="handleSetIsolation"
               :handle-set-tx="handleSetTx" :handle-stop="handleStop"
               :handle-read-only="handleReadOnly"
               :store-query-tabs="storeQueryTabs" :format-sql="formatSql"
    />
    <Editor :set-editor-instance="setEditorInstance" :current-tab="tab" ref="editor"
            style="border-bottom: 1px solid #ccc" :completion-data="completionData"
            :store-query-tabs="storeQueryTabs"
            :rdb-table-detail="rdbTableDetail" :on-run="onRun"/>
    <div class="editor-resize"/>
    <div :class="`message ${tab.message.type}`" v-if="tab.message.text && tab.message.show && tab.connected">
      {{ tab.message.text }}
      <a-icon type="close" @click="handleCloseError"/>
    </div>
    <div class="message Error" v-if="!tab.connected && tab.msg">
     {{ tab.msg }}
      <Button type="text" size="small" @click="handleClickDsStatusIcon">{{ $t('zhong-xin-lian-jie') }}</Button>
    </div>
    <Modal v-model="showNoPassedRuleModal" :title="$t('gui-ze-xiao-yan-shi-bai')" :width="800">
      <div style="margin-bottom: 10px;">
        {{ $t('gui-ze-xiao-yan-shi-bai-qing-jian-cha-sql-yu-ju-hou-zai-ci-ti-jiao') }}
      </div>
      <Table :columns="noPassedRuleColumns" :data="noPassedRuleList" border>
        <template slot-scope="{ row }" slot="warnLevel">
          <Tag :color="row.level === 'SUGGEST' ? 'warning' : 'error'" >
            {{RULE_WARN_LEVEL[row.level]}}
          </Tag>
        </template>
      </Table>
      <div slot="footer">
        <Button @click="handleRun(currentSql, true)" type="primary"
                v-if="currentWarnLevel === 'SUGGEST'">{{ $t('zai-ci-zhi-hang') }}
        </Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import * as monaco from 'monaco-editor';
import { Modal } from 'ant-design-vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import Operators from '@/views/sql/components/Operators';
import { MYSQL_DATA_TYPE } from '@/const';
import { chunk } from 'xe-utils';
import Editor from '@/components/editor';
import browseMixin from '@/mixins/browseMixin';
import { sendWebSocket } from '@/services/socket';
import sqlMixin from '@/mixins/sqlMixin';
import { RULE_WARN_LEVEL, WS_REQ_QUERY_TYPE, WS_TYPE } from '@/utils';
import { UPDATE_SOCKET_STATUS } from '@/store/mutationTypes';

export default {
  name: 'SqlViewer',
  components: {
    Editor,
    Operators
  },
  props: {
    handleClickDsStatusIcon: Function,
    handleGetDsSetting: Function,
    tabs: {
      type: Array,
      default: () => []
    },
    rdbTableDetail: Function,
    completionData: Object,
    handleRunAsync: Function,
    tab: Object,
    storeQueryTabs: Function,
    createSession: Function
  },
  computed: {
    ...mapGetters([
      'isDesktop'
    ]),
    ...mapState(['productClusterList'])
  },
  mixins: [sqlMixin, browseMixin],
  data() {
    return {
      RULE_WARN_LEVEL,
      currentSql: '',
      currentWarnLevel: '',
      noPassedRuleList: [],
      noPassedRuleColumns: [
        {
          title: this.$t('deng-ji'),
          slot: 'warnLevel',
          width: 90
        },
        {
          title: this.$t('ming-cheng'),
          key: 'ruleName',
          width: 200
        },
        {
          title: this.$t('miao-shu'),
          key: 'ruleDesc'
        }
      ],
      showNoPassedRuleModal: false,
      stopping: false,
      canRun: true,
      monacoEditor: null
    };
  },
  mounted() {
    window.$bus.on('showNoPassedRuleListModal', (index) => this.handleShowUnPassedRuleListModal(index));
  },
  beforeDestroy() {
    window.$bus.off('showNoPassedRuleListModal');
  },
  methods: {
    ...mapMutations([UPDATE_SOCKET_STATUS]),
    handleCloseModal() {
      this.currentSql = '';
      this.currentWarnLevel = '';
      this.showNoPassedRuleModal = false;
    },
    handleShowUnPassedRuleListModal(index) {
      this.noPassedRuleList = this.tab.executeInfo[index].ruleList;
      this.showNoPassedRuleModal = true;
    },
    handleReadOnly(e) {
      sendWebSocket({
        type: WS_TYPE.WS_REQ_QUERY,
        object: {
          sessionId: this.tab.sessionId,
          queryType: WS_REQ_QUERY_TYPE.TX_STATUS,
          levels: this.browseGenLevelsData(this.tab.node),
          rdbAutoCommit: this.tab.autoCommit === 'true',
          rdbReadOnly: e,
          rdbIsolation: this.tab.isolation
        }
      }, { message: this.onmessage });
    },
    handleStop() {
      // this.tab.stopping = true;
      this.tab.running = false;
      sendWebSocket({
        type: WS_TYPE.WS_REQ_QUERY,
        object: {
          sessionId: this.tab.sessionId,
          queryType: WS_REQ_QUERY_TYPE.CANCEL_QUERY
        }
      }, { message: this.onmessage });
    },
    handleRollback() {
      sendWebSocket({
        type: WS_TYPE.WS_REQ_QUERY,
        object: {
          sessionId: this.tab.sessionId,
          queryType: WS_REQ_QUERY_TYPE.TX_ROLLBACK
        }
      }, { message: this.onmessage });
    },
    handleCommit() {
      sendWebSocket({
        type: WS_TYPE.WS_REQ_QUERY,
        object: {
          sessionId: this.tab.sessionId,
          queryType: WS_REQ_QUERY_TYPE.TX_COMMIT
        }
      }, { message: this.onmessage });
    },
    handleSetIsolation(isolation) {
      sendWebSocket({
        type: WS_TYPE.WS_REQ_QUERY,
        object: {
          sessionId: this.tab.sessionId,
          queryType: WS_REQ_QUERY_TYPE.TX_STATUS,
          levels: this.browseGenLevelsData(this.tab.node),
          rdbAutoCommit: this.tab.autoCommit === 'true',
          rdbReadOnly: this.tab.readOnly,
          rdbIsolation: isolation
        }
      }, { message: this.onmessage });
    },
    handleSetTx(autoCommit) {
      sendWebSocket({
        type: WS_TYPE.WS_REQ_QUERY,
        object: {
          sessionId: this.tab.sessionId,
          queryType: WS_REQ_QUERY_TYPE.TX_STATUS,
          levels: this.browseGenLevelsData(this.tab.node),
          rdbAutoCommit: autoCommit,
          rdbReadOnly: this.tab.readOnly,
          rdbIsolation: this.tab.isolation
        }
      }, { message: this.onmessage });
    },
    formatSql() {
      this.$refs.editor.formatSql();
    },
    setEditorInstance(editor) {
      this.monacoEditor = editor;
    },
    setSql(sql) {
      const position = this.monacoEditor.getPosition();
      let text = sql;
      if (position.column !== 1) {
        text = `\n${sql}`;
      } else {
        text = `${sql}\n`;
      }

      const range = new monaco.Range(position.lineNumber,
        position.column,
        position.lineNumber,
        position.column);
      this.monacoEditor.executeEdits('', [
        {
          range,
          text
        }
      ]);
      this.monacoEditor.focus();

      let selectRange;
      if (position.column !== 1) {
        selectRange = new monaco.Range(position.lineNumber + 1,
          position.column,
          position.lineNumber + 1,
          position.column + sql.length);
      } else {
        selectRange = new monaco.Range(position.lineNumber,
          position.column,
          position.lineNumber,
          position.column + sql.length);
      }

      this.monacoEditor.setSelection(selectRange);
    },
    getColumnsList(res) {
      // const list = [];
      const list = [{
        type: 'seq',
        width: 50,
        title: this.$t('xu-hao'),
        fixed: 'left',
        className: 'seq-content',
        headerClassName: 'seq-header'
      }];
      // 这边根据列和值来算width，值取一批的最长值，另需要设置最小宽度
      if (res.columnList) {
        res.columnList.forEach((item, index) => {
          const minWidth = 100;
          const maxWidth = 200;
          let width = 0;
          if (res.resultSet && res.resultSet[0]) {
            const value = res.resultSet[0][index].value;
            if (value && value.length > item.length) {
              width = value.length * 5 + 80;
            }
          }
          if (width === 0) {
            width = item.length * 5 + 80;
          }

          if (width < minWidth) {
            width = minWidth;
          }

          if (width > maxWidth) {
            width = maxWidth;
          }

          let cellRenderName = 'input';
          if (MYSQL_DATA_TYPE.TIME.includes(item)) {
            cellRenderName = 'time';
          }
          list.push({
            field: item,
            title: item,
            width,
            cellRender: { name: `vxe-${cellRenderName}-tpl` }
          });
        });
      }
      return list;
    },
    onclose() {
    },
    onmessage(data) {
      try {
        const queryData = JSON.parse(data);
        let currentTab = this.tab;
        if (this.tab.sessionId !== queryData.object.sessionId) {
          currentTab = null;
          this.tabs.forEach((tab) => {
            if (tab.sessionId === queryData.object.sessionId) {
              currentTab = tab;
            }
          });
        }

        if (!currentTab) {
          return;
        }
        if (queryData.object.resultType === 'ResultSet') {
          const {
            rowSet,
            columnList
          } = queryData.object;
          queryData.object.columnListSeq = this.getColumnsList(queryData.object);
          const list = [];
          if (rowSet) {
            rowSet.forEach((item) => {
              const currentRow = {};
              for (let i = 0; i < columnList.length; i++) {
                currentRow[columnList[i]] = item.row[i].value;
              }
              list.push(currentRow);
            });
          }

          const dataArr = chunk(list, 50);
          Object.assign(queryData.object, {
            page: 1,
            size: 50,
            total: rowSet ? rowSet.length : 0,
            data: rowSet,
            dataArr,
            showData: rowSet ? dataArr[0] : []
          });
          const len = currentTab.result.list.length;
          queryData.object.showIndex = len ? currentTab.result.list[len - 1].showIndex + 1 : 1;
          currentTab.result.list.push(queryData.object);
          currentTab.result.active = queryData.object.resultId;
        }

        if (queryData.object.resultType === 'Done') {
          currentTab.running = false;
          currentTab.stopping = false;
        }

        if (queryData.object.resultType === 'QueryScript') {
          currentTab.executeInfo.push({
            time: queryData.time,
            ...queryData.object
          });

          if (currentTab.result.active === 'message') {
            setTimeout(() => {
              const ele = document.getElementById('result-info-container');
              if (ele) {
                ele.scrollTop = ele.scrollHeight;
              }
            }, 0);
          }
        }

        if (queryData.object.resultType === 'Message') {
          if (queryData.object.entities && queryData.object.entities.length) {
            if (queryData.object.entities[0].mode === 'Hint') {
              currentTab.message.show = true;
              currentTab.message.text = queryData.object.entities[0].message;
              currentTab.message.type = queryData.object.entities[0].level;
            } else {
              currentTab.executeInfo.push({
                time: queryData.time,
                ...queryData.object.entities[0]
              });
              if (queryData.object.entities[0].level === 'Error') {
                currentTab.result.active = 'message';
              }
              if (currentTab.result.active === 'message') {
                setTimeout(() => {
                  const ele = document.getElementById('result-info-container');
                  if (ele) {
                    ele.scrollTop = ele.scrollHeight;
                  }
                }, 0);
              }
            }
          }
        }

        if (queryData.object.resultType === 'RuleCheck') {
          if (!currentTab.executeInfo) {
            currentTab.executeInfo = [];
          }
          currentTab.executeInfo.push({
            time: queryData.time,
            level: 'Error',
            warnLevel: queryData.object.warnLevel,
            message: '规则校验失败',
            queryBody: queryData.object.queryBody,
            ruleList: queryData.object.message
          });

          currentTab.cost.popIndex = -1;
          currentTab.cost.popList = [];
          this.currentSql = queryData.object.queryBody;
          this.currentWarnLevel = queryData.object.warnLevel;
          if (currentTab.sessionId === this.tab.sessionId) {
            this.handleShowUnPassedRuleListModal(currentTab.executeInfo.length - 1);
          }

          if (currentTab.result.active === 'message') {
            setTimeout(() => {
              const ele = document.getElementById('result-info-container');
              if (ele) {
                ele.scrollTop = ele.scrollHeight;
              }
            }, 0);
          }
        }

        if (queryData.object.resultType === 'Cost') {
          currentTab.cost = queryData.object;
          let popIndex = -1;
          let popList = [];
          switch (queryData.object.step) {
            case 'Prepare':
              popList = [
                {
                  icon: 'loading',
                  theme: 'outlined',
                  text: '准备查询，执行中...',
                  color: 'gray'
                },
                {
                  icon: 'clock-circle',
                  theme: 'filled',
                  text: '执行查询，等待...',
                  color: 'gray'
                },
                {
                  icon: 'clock-circle',
                  theme: 'filled',
                  text: '接收结果，等待...',
                  color: 'gray'
                }
              ];
              popIndex = 0;
              break;
            case 'Query':
              popList = [
                {
                  icon: 'check-circle',
                  theme: 'filled',
                  text: `准备查询，耗时${queryData.object.preCost}毫秒`,
                  color: 'green'
                },
                {
                  icon: 'loading',
                  theme: 'outlined',
                  text: '执行查询，等待...',
                  color: 'gray'
                },
                {
                  icon: 'clock-circle',
                  theme: 'filled',
                  text: '接收结果，等待...',
                  color: 'gray'
                }
              ];
              popIndex = 1;
              break;
            case 'Receive':
              popList = [
                {
                  icon: 'check-circle',
                  theme: 'filled',
                  text: queryData.object.preCost === -1 ? '准备查询' : `准备查询，耗时${queryData.object.preCost}毫秒`,
                  color: 'green'
                },
                {
                  icon: 'check-circle',
                  theme: 'filled',
                  text: queryData.object.queryCost === -1 ? '执行查询' : `执行查询，耗时${queryData.object.queryCost}毫秒`,
                  color: 'green'
                },
                {
                  icon: 'clock-circle',
                  theme: 'filled',
                  text: '接收结果，执行中...'
                }
              ];
              popIndex = 2;
              break;
            case 'Finish':
              popList = [
                {
                  icon: 'check-circle',
                  theme: 'filled',
                  text: queryData.object.preCost === -1 ? '准备查询' : `准备查询，耗时${queryData.object.preCost}毫秒`,
                  color: 'green'
                },
                {
                  icon: 'check-circle',
                  theme: 'filled',
                  text: queryData.object.queryCost === -1 ? '执行查询' : `执行查询，耗时${queryData.object.queryCost}毫秒`,
                  color: 'green'
                },
                {
                  icon: 'check-circle',
                  theme: 'filled',
                  text: queryData.object.rcvCost === -1 ? '接收结果' : `接收结果，耗时${queryData.object.rcvCost}毫秒`,
                  color: 'green'
                }
              ];
              popIndex = 2;
              break;
            default:
              break;
          }
          currentTab.cost.popIndex = popIndex;
          currentTab.cost.popList = popList;
        }

        if (queryData.object.resultType === 'ClearHintMessage') {
          currentTab.message.show = false;
          currentTab.message.text = '';
        }

        if (queryData.object.resultType === 'Status') {
          currentTab.autoCommit = queryData.object.rdbAutoCommit ? 'true' : 'false';
          currentTab.readOnly = queryData.object.rdbReadOnly;
          currentTab.isolation = queryData.object.rdbTxIsolation;
        }

        if (queryData.type === 'WS_SYS_STATUS') {
          if (queryData.object.serviceReady) {
            this.handleGetDsSetting();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    onerror() {

    },
    onopen() {

    },
    async handleRun(selectedSql, force = false) {
      this.tab.running = true;
      this.tab.result.active = 'message';
      const wsData = {
        type: WS_TYPE.WS_REQ_QUERY,
        object: {
          force,
          sessionId: this.tab.sessionId,
          queryType: WS_REQ_QUERY_TYPE.REQUEST_QUERY,
          levels: this.browseGenLevelsData(this.tab.node),
          queryString: selectedSql,
          queryArgs: [],
          rdbAutoCommit: this.tab.autoCommit === 'true'
        }
      };

      this.handleCloseModal();

      this.tab.cost.popList = [
        {
          icon: 'clock-circle',
          text: '准备查询，等待...'
        },
        {
          icon: 'clock-circle',
          text: '执行查询，等待...'
        },
        {
          icon: 'clock-circle',
          text: '接收结果，等待...'
        }
      ];
      this.tab.cost.popIndex = 0;
      sendWebSocket(wsData, {
        open: this.onopen, message: this.onmessage, error: this.onerror, close: this.onclose
      });
    },
    handleRunTicket(selectedSql) {
      this.goTicketPage(selectedSql);
    },
    async onRun(type = 'run', asyncForm) {
      this.storeQueryTabs();
      if (window._hmt && this.isDesktop) {
        window._hmt.push(['_trackEvent', 'execute sql', 'uid', 'personal']);
      }
      let selectedSql = '';
      if (type !== 'asyncList') {
        selectedSql = this.monacoEditor.getModel().getValueInRange(this.monacoEditor.getSelection());
        if (!selectedSql) {
          selectedSql = this.$refs.editor.getCurrentSql();
        }
        if (!selectedSql) {
          Modal.warning({
            title: this.$t('zhi-hang-yi-chang-ti-shi'),
            content: this.$t('nin-dang-qian-mei-you-xuan-zhong-ren-he-sql-yu-ju-qing-xuan-zhong-hou-zai-zhi-hang'),
            okText: this.$t('zhi-dao-le')
          });
          return;
        }
      }

      switch (type) {
        case 'run':
          await this.handleRun(selectedSql);
          break;
        case 'runAsync':
          this.tab.showAsyncListTab = false;
          await this.handleRunAsync(selectedSql, asyncForm);
          await this.onRun('asyncList');
          break;
        case 'ticket':
          this.handleRunTicket(selectedSql);
          break;
        case 'asyncList':
          this.tab.showAsyncListTab = false;
          if (!this.tab.showAsyncListTab) {
            this.tab.showAsyncListTab = true;
          } else {
            window.$bus.emit('asyncJobListRefresh');
            window.$bus.emit('asyncTaskListBack');
          }
          break;
        default:
          break;
      }
      // this.analysisSplit(selectedSql);
    },
    handleCloseError() {
      this.tab.message.show = false;
    }
  }
};
</script>
<style lang="less">
.sql-viewer {
  position: relative;

  .editor-resize {
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: -3px;
    width: 100%;
    background: #fff;
    cursor: row-resize;
    //border-bottom: 1px solid #ddd;
    height: 6px;
    background: rgba(0, 0, 0, 0);
  }

  .message {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 12px;
    z-index: 4;
    max-height: 200px;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.Error {
      background: #FFDCDC;
    }

    &.Warn {
      background: #fffddc;
    }

    &.Info {
      background: #dedede;
    }
  }
}
</style>
