<template>
  <div class="page-header-container">
    <Form ref="formInline" :model="searchData" inline label-position="right"
          style="padding-right: 300px">
      <FormItem>
        <Select v-model="searchType" style="width:120px" @on-change="handleChangeSearchType">
          <Option value="dataJobDesc" :label="$t('ren-wu-miao-shu')">
            <span>{{ $t('ren-wu-miao-shu') }}</span>
          </Option>
          <Option value="instanceId" :label="$t('ren-wu-ming-cheng')">
            <span>{{ $t('ren-wu-ming-cheng') }}</span>
          </Option>
          <Option value="dataJobType" :label="$t('ren-wu-lei-xing')">
            <span>{{ $t('ren-wu-lei-xing') }}</span>
          </Option>
          <Option value="sourceInstance" :label="$t('yuan-shu-ju-yuan-id')">
            <span>{{ $t('yuan-shu-ju-yuan-id') }}</span>
          </Option>
          <Option value="sinkInstance" :label="$t('mu-biao-shu-ju-yuan-id')">
            <span>{{ $t('mu-biao-shu-ju-yuan-id') }}</span>
          </Option>
          <Option value="workerIp" :label="$t('ji-qi-ip')">
             <span>{{ $t('ji-qi-ip') }}</span>
          </Option>
          <Option value="transferObjName" :label="$t('yuan-duan-biao-ming')">
            <span>{{ $t('yuan-duan-biao-ming') }}</span>
          </Option>
          <Option value="dataJobId" :label="$t('ren-wu-shu-zi-id-0')">
                      <span>{{ $t('ren-wu-shu-zi-id-0') }}</span>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='instanceId'">
        <Input v-model="searchData.dataJobName" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem v-if="searchType==='transferObjName'">
        <Input v-model="searchData.transferObjName" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem v-if="searchType==='dataJobId'">
        <Input v-model="searchData.dataJobId" style="width: 280px" @on-enter="_handleSearch" type="number"/>
      </FormItem>
      <FormItem v-if="searchType==='dataJobType'">
        <Select class="data-job-type-select" v-model="searchData.dataJobType" style="width:250px">
          <Option value="MIGRATION" :label="$t('shu-ju-qian-yi')">
            <span>{{ $t('shu-ju-qian-yi') }}</span>
            <i :class="`iconfont ${Mapping.jobTypeIcon.MIGRATION}`"></i>
          </Option>
          <Option value="SYNC" :label="$t('shu-ju-tong-bu')">
            <span>{{ $t('shu-ju-tong-bu') }}</span>
            <i :class="`iconfont ${Mapping.jobTypeIcon.SYNC}`"></i>
          </Option>
          <Option value="CHECK" :label="$t('shu-ju-xiao-yan')">
            <span>{{ $t('shu-ju-xiao-yan') }}</span>
            <i :class="`iconfont ${Mapping.jobTypeIcon.CHECK}`"></i>
          </Option>
          <Option value="STRUCT_MIGRATION" :label="$t('jie-gou-qian-yi')">
            <span>{{ $t('jie-gou-qian-yi') }}</span>
            <i :class="`iconfont ${Mapping.jobTypeIcon.STRUCT_MIGRATION}`"></i>
          </Option>
          <!--                            <Option value="SUBSCRIBE">数据订阅</Option>-->
          <Option value="all" :label="$t('quan-bu')">{{ $t('quan-bu') }}</Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='dataJobDesc'">
        <Input v-model="searchData.desc" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem v-if="searchType==='sourceInstance'">
        <Select filterable style="width: 250px" v-model="searchData.sourceInstanceId">
          <Option v-for="(instance) in instanceList"
                  :value="instance.id"
                  :key="instance.instanceId"
                  :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
            <p>{{ instance.instanceId }}</p>
            <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='sinkInstance'">
        <Select filterable style="width: 250px" v-model="searchData.targetInstanceId">
          <Option v-for="(instance) in instanceList"
                  :value="instance.id"
                  :key="instance.instanceId"
                  :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
            <p>{{ instance.instanceId }}</p>
            <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
          </Option>
        </Select>
      </FormItem>
      <FormItem v-if="searchType==='workerIp'">
         <Input v-model="searchData.workerIp" style="width: 280px" @on-enter="_handleSearch"/>
      </FormItem>
      <FormItem>
        <pd-button type="primary" @click="_handleSearch" :loading="loading">
          {{ $t('cha-xun') }}
        </pd-button>
<!--        <Checkbox style="margin-left: 20px" v-model="ifClassfy" @on-change="handleChooseClassify">-->
<!--          {{ $t('fen-lei') }}-->
<!--        </Checkbox>-->
<!--        <Select v-model="classfyType" style="width:98px" :disabled="!ifClassfy"-->
<!--                @on-change="handleClassify">-->
<!--          <Option value="status">{{ $t('ren-wu-zhuang-tai') }}</Option>-->
<!--          <Option value="state">{{ $t('ren-wu-jie-duan') }}</Option>-->
<!--          <Option value="type">{{ $t('ren-wu-zhong-lei') }}</Option>-->
<!--        </Select>-->
      </FormItem>
    </Form>
    <div class="page-header-function">
      <!--            <a href="/home/document" target="_blank">查看任务创建流程</a>-->
      <pd-button type="primary" ghost @click="handleCreateJob" v-if="myAuth.includes('CC_DATAJOB_MANAGE')">
        <Icon type="md-add"/>
        {{ $t('chuang-jian-ren-wu') }}
      </pd-button>
      <pd-button v-if="myAuth.includes('CC_DATAJOB_MANAGE') && mode==='table'" @click="updateBatchInTable">
        {{ batchEditInTable ? $t('qu-xiao-pi-liang-chu-li-ren-wu') : $t('pi-liang-chu-li-ren-wu') }}
      </pd-button>
      <pd-button @click="handleGoMetaCenter">
        {{ $t('yuan-shu-ju-jian-suo') }}
      </pd-button>
      <pd-button @click="handleTransferJob">
        {{ $t('dao-ru-ren-wu') }}
      </pd-button>
<!--      <pd-button @click="handleTransferDs">-->
<!--        {{ $t('dao-ru-shu-ju-yuan') }}-->
<!--      </pd-button>-->
<!--      <Dropdown>-->
<!--        <pd-button>-->
<!--          {{$t('xi-tong-qian-yi')}}-->
<!--          <Icon type="ios-arrow-down"></Icon>-->
<!--        </pd-button>-->
<!--        <template #list>-->
<!--          <DropdownMenu>-->
<!--            <DropdownItem>-->
<!--              <span @click="handleTransferJob">{{ $t('dao-ru-ren-wu') }}</span>-->
<!--            </DropdownItem>-->
<!--            <DropdownItem><span @click="handleTransferDs">{{ $t('dao-ru-shu-ju-yuan') }}</span></DropdownItem>-->
<!--          </DropdownMenu>-->
<!--        </template>-->
<!--      </Dropdown>-->
<!--      <Tooltip transfer placement="bottom-end" :content="`点击切换${mode==='table'?'卡片':'列表'}模式`">-->
<!--                <span class="data-job-mode-switch"-->
<!--                      @click="handleChangeMode(mode==='table'?'card':'table')">-->
<!--                <svg class="icon" aria-hidden="true" v-if="mode==='table'">-->
<!--                    <use xlink:href="#icon-card"></use>-->
<!--                </svg>-->
<!--                <svg class="icon" aria-hidden="true" v-if="mode==='card'">-->
<!--                    <use xlink:href="#icon-list"></use>-->
<!--                </svg>-->
<!--            </span>-->
<!--      </Tooltip>-->
      <Checkbox style="margin-left: 8px" v-model="hasIntervalNow" @on-change="handleInterval">
        {{ $t('20-miao-ding-shi-shua-xin') }}
      </Checkbox>
      <pd-button style="margin-left: 0" type="default" :loading="loading"
                 @click="_handleSearch">{{ $t('shua-xin') }}
      </pd-button>
    </div>
    <Modal
      v-model="showTransferDs"
      :title="$t('dao-ru-shu-ju-yuan')"
      footer-hide
    >
      <div>
        <Form label-position="right" :label-width="130" :rules="validateDs" :model="transferDsInfo">
          <FormItem :label="$t('shu-ju-yuan-shu-zi-id')" prop="dsId">
            <Input style="width: 280px" v-model="transferDsInfo.dsId"/>
          </FormItem>
          <FormItem :label="$t('huan-jing')" prop="dsEnvId">
            <Select v-model="transferDsInfo.dsEnvId" style="width:280px"
                    filterable>
              <Option v-for="(env) in envList" :value="env.id" :key="env.id"
                      :label="env.envName">
                <p>{{ env.envName }}</p>
              </Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('lao-ji-qun-di-zhi')" prop="host">
            <Input style="width: 280px" v-model="transferDsInfo.host"/>
          </FormItem>
          <FormItem :label="$t('lao-ji-qun-accesskey')" prop="accessKey">
            <Input style="width: 280px" v-model="transferDsInfo.accessKey"/>
          </FormItem>
          <FormItem :label="$t('lao-ji-qun-secretkey')" prop="secretKey">
            <Input style="width: 280px" v-model="transferDsInfo.secretKey"/>
          </FormItem>
          <FormItem :label="$t('zhang-hao')" prop="account">
            <Input style="width: 280px" v-model="transferDsInfo.account"/>
          </FormItem>
          <FormItem :label="$t('mi-ma')" prop="password">
            <Input type="password" password style="width: 280px" v-model="transferDsInfo.password"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmTransferDs">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showTransferJob"
      :title="$t('dao-ru-ren-wu')"
      width="640"
      footer-hide
    >
      <div>
        <Alert type="warning">
          <p>{{$t('jin-zhi-chi-dao-ru')}}<b>{{$t('zeng-liang-tong-bu')}}</b>{{$t('ren-wu')}}。{{$t('dao-ru-de-ren-wu-mo-ren-bu-qi-dong')}}</p>
        </Alert>
        <div style="max-height: 500px;overflow: auto">
          <Form label-position="right" :label-width="150" :rules="validate" :model="transferJobInfo">
            <FormItem :label="$t('ren-wu-shu-zi-id-0')" prop="dataJobNumId">
              <Input style="width: 280px" v-model="transferJobInfo.dataJobNumId"/>
              <Checkbox style="display: inline;margin-left: 10px" v-model="transferJobInfo.renameJobInstanceId">{{$t('chong-xin-sheng-cheng')}}</Checkbox>
            </FormItem>
<!--            <FormItem :label="$t('ren-wu-shi-li-id')">-->
<!--              <Checkbox v-model="transferJobInfo.renameJobInstanceId">{{$t('chong-xin-sheng-cheng')}}</Checkbox>-->
<!--            </FormItem>-->
            <FormItem :label="$t('bang-ding-ji-qun')" prop="clusterIdInCurrSys">
              <Select v-model="transferJobInfo.clusterIdInCurrSys" style="width:280px" transfer
                      filterable>
                <Option v-for="(cluster) in clusterList" :value="cluster.id" :key="cluster.id"
                        :label="cluster.clusterDesc?cluster.clusterDesc:cluster.clusterName" :style="`${cluster.runningCount ? '' : 'cursor: not-allowed'}`">
                  <p>{{ cluster.clusterName }}</p>
                  <p style="color:#ccc;margin: 5px 0">
                    {{ cluster.clusterDesc }}
                    <span style="margin-left: 8px">{{ cluster.runningCount }}/{{
                        cluster.workerCount
                      }}</span>
                  </p>
                </Option>
              </Select>
            </FormItem>
            <FormItem :label="$t('yuan-shu-ju-yuan-id')" prop="srcDsIdInCurrSys">
              <Select v-model="transferJobInfo.srcDsIdInCurrSys" style="width:280px" transfer
                      filterable>
                <Option v-for="(instance) in instanceList"
                        :value="instance.id"
                        :key="instance.instanceId"
                        :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
                  <p>{{ instance.instanceId }}</p>
                  <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
                </Option>
              </Select>
              <RadioGroup style="margin-left: 10px" v-model="transferJobInfo.srcDsHostType">
                <Radio label="PRIVATE">
                  {{ $t('nei-wang') }}
                </Radio>
                <Radio label="PUBLIC">
                  {{ $t('wai-wang') }}
                </Radio>
              </RadioGroup>
            </FormItem>
<!--            <FormItem :label="$t('wang-luo-lei-xing')" prop="srcDsHostType">-->
<!--              <RadioGroup v-model="transferJobInfo.srcDsHostType">-->
<!--                <Radio label="PRIVATE">-->
<!--                  {{ $t('nei-wang') }}-->
<!--                </Radio>-->
<!--                <Radio label="PUBLIC">-->
<!--                  {{ $t('wai-wang') }}-->
<!--                </Radio>-->
<!--              </RadioGroup>-->
<!--            </FormItem>-->
            <FormItem :label="$t('mu-biao-shu-ju-yuan-id')" prop="srcDsIdInCurrSys">
              <Select v-model="transferJobInfo.dstDsIdInCurrSys" style="width:280px" transfer
                      filterable>
                <Option v-for="(instance) in instanceList"
                        :value="instance.id"
                        :key="instance.instanceId"
                        :label="instance.instanceDesc?`${instance.instanceId} (${instance.instanceDesc})`:`${instance.instanceId}`">
                  <p>{{ instance.instanceId }}</p>
                  <p style="margin-top:5px;color:#ccc">{{ instance.instanceDesc }}</p>
                </Option>
              </Select>
              <RadioGroup style="margin-left: 10px"  v-model="transferJobInfo.dstDsHostType">
                <Radio label="PRIVATE">
                  {{ $t('nei-wang') }}
                </Radio>
                <Radio label="PUBLIC">
                  {{ $t('wai-wang') }}
                </Radio>
              </RadioGroup>
            </FormItem>
<!--            <FormItem :label="$t('wang-luo-lei-xing')" prop="dstDsHostType">-->
<!--              <RadioGroup v-model="transferJobInfo.dstDsHostType">-->
<!--                <Radio label="PRIVATE">-->
<!--                  {{ $t('nei-wang') }}-->
<!--                </Radio>-->
<!--                <Radio label="PUBLIC">-->
<!--                  {{ $t('wai-wang') }}-->
<!--                </Radio>-->
<!--              </RadioGroup>-->
<!--            </FormItem>-->
            <FormItem :label="$t('lao-ji-qun-di-zhi')" prop="ip">
              <Input :placeholder="$t('ip-huo-yu-ming')" style="width: 190px" v-model="transferJobInfo.ip"/>
              :
              <Input style="width: 80px" v-model="transferJobInfo.port"/>
            </FormItem>
            <FormItem :label="$t('lao-ji-qun-accesskey')" prop="accessKey">
              <Input style="width: 280px" v-model="transferJobInfo.accessKey"/>
            </FormItem>
            <FormItem :label="$t('lao-ji-qun-secretkey')" prop="secretKey">
              <Input style="width: 280px" v-model="transferJobInfo.secretKey"/>
            </FormItem>
            <FormItem :label="$t('zi-ding-yi-dai-ma')">
              <Button @click="handleShowUploadPkg" v-if="!customPkgFile">{{ $t('shang-chuan-zi-ding-yi-dai-ma') }}</Button>
              <Button type="success" ghost v-if="customPkgFile"
                      @click="handleShowUploadPkg">{{ $t('zi-ding-yi-dai-ma-cha-jian-yi-pei-zhi') }}</Button>
            </FormItem>
          </Form>
        </div>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button :loading="importLoading" type="primary" @click="handleConfirmTransferJob">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancel">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="showUploadPkg"
      :title="$t('zi-ding-yi-dai-ma-chu-li-cha-jian')"
      footer-hide
    >
      <div>
        <Alert style="margin-bottom: 20px;font-size: 12px">{{ $t('zi-ding-yi-dai-ma-shi-shi-jia-gong-yun-xu-yong-hu-shi-yong-java-yu-yan-bian-xie-zi-ding-yi-de-shu-ju-hang-chu-li-luo-ji-shang-chuan-cloudcanal-ping-tai-hou-shu-ju-tong-bu-ren-wu-zhi-hang-quan-liang-zeng-liang-shi-hui-zi-dong-ying-yong-yong-hu-de-zi-ding-yi-chu-li-luo-ji-ran-hou-zai-xia-ru-dui-duan-shu-ju-yuan') }}<br><br>
          {{ $t('shi-li') }}<a target="_blank" :href="pkgUrl">{{pkgUrl}}</a>
        </Alert>
        <Form label-position="right" :label-width="80">
          <FormItem :label="$t('shang-chuan-cha-jian')">
        <span class="upload-btn" v-if="!customPkgFile">
                  <input style="margin-left: 10px" @change="handleFileChange" type = "file" name = "uploadfile" id = "uploadfile" />
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-LocalUpload"></use>
                  </svg>
                  {{ $t('dian-ji-zhe-li-shang-chuan-wen-jian') }}
          </span>
            <span v-if="customPkgFile" class="file-name-span">
            {{customPkgFile.name}}
            ({{(customPkgFile.size/(1024)).toFixed(1)}}KB)
            <Icon class="pkg-close" type="md-close" @click="handleRemovePkg"/>
              <!--              <Icon class="pkg-upload-success" style="color: #00bc4a" type="md-checkmark" />-->
          </span>
          </FormItem>
          <FormItem :label="$t('miao-shu')">
            <Input style="width: 280px" v-model="transferJobInfo.pkgDescription"/>
          </FormItem>
        </Form>
        <div slot="footer" class="modal-footer" style="margin-top: 20px">
          <pd-button type="primary" @click="handleConfirmUpload">{{ $t('que-ding') }}</pd-button>
          <pd-button @click="handleCancelCustomPkg">{{ $t('qu-xiao') }}</pd-button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { preFirstCheck } from '@/services/cc/api/job';
import Mapping from '@/views/util';
import { mapState } from 'vuex';
import { transferDataJobIn } from '@/services/cc/api/upload';
import { transferDsIn } from '@/services/cc/api/transfer';
import { listClusterByCondition } from '@/services/cc/api/cluster';

export default {
  props: ['handleSearch', 'handleChangeMode', 'mode', 'batchEditInTable', 'updateBatchInTable', 'handleClassify', 'loading', 'handleInterval', 'hasInterval', 'instanceList', 'handleRefresh'],
  data() {
    return {
      ifClassfy: false,
      classfyType: 'status',
      hasIntervalNow: this.hasInterval,
      searchType: 'dataJobDesc',
      Mapping,
      searchData: {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        transferObjName: '',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        dataJobId: null,
        sourceInstanceId: 0,
        targetInstanceId: 0
      },
      importLoading: false,
      showTransferJob: false,
      showTransferDs: false,
      transferJobInfo: {
        port: '8111',
        dstDsHostType: 'PRIVATE',
        srcDsHostType: 'PRIVATE'
      },
      transferDsInfo: {},
      customPkgFile: null,
      pkgUrl: 'https://github.com/ClouGence/cloudcanal-data-process',
      showUploadPkg: false,
      clusterList: [],
      validate: {
        dataJobNumId: [
          {
            required: true,
            message: this.$t('ren-wu-id-bu-neng-wei-kong')
          }
        ],
        clusterIdInCurrSys: [
          {
            required: true,
            message: this.$t('bang-ding-ji-qun-bu-neng-wei-kong')
          }
        ],
        srcDsIdInCurrSys: [
          {
            required: true,
            message: this.$t('yuan-shu-ju-yuan-bu-neng-wei-kong')
          }
        ],
        dstDsIdInCurrSys: [
          {
            required: true,
            message: this.$t('mu-biao-shu-ju-yuan-bu-neng-wei-kong')
          }
        ],
        ip: [
          {
            required: true,
            message: this.$t('lao-host-bu-neng-wei-kong')
          }
        ],
        accessKey: [
          {
            required: true,
            message: this.$t('lao-accesskey-bu-neng-wei-kong')
          }
        ],
        secretKey: [
          {
            required: true,
            message: this.$t('lao-secretkey-bu-neng-wei-kong')
          }
        ]
      },
      validateDs: {
        dsId: [
          {
            required: true,
            message: this.$t('shi-ju-yuan-bu-neng-wei-kong')
          }
        ],
        dsEnvId: [
          {
            required: true,
            message: this.$t('huan-jing-bu-neng-wei-kong')
          }
        ],
        host: [
          {
            required: true,
            message: this.$t('lao-host-bu-neng-wei-kong')
          }
        ],
        accessKey: [
          {
            required: true,
            message: this.$t('lao-accesskey-bu-neng-wei-kong')
          }
        ],
        secretKey: [
          {
            required: true,
            message: this.$t('lao-secretkey-bu-neng-wei-kong')
          }
        ]
      },
      envList: []
    };
  },
  created() {
    const params = JSON.parse(sessionStorage.getItem('job_search_params'));
    if (params) {
      this.searchData = params;
      this.searchType = params.searchType;
      this.ifClassfy = params.ifClassfy;
      this.classfyType = params.classfyType;
      this.handleSearch(params);
    } else {
      this.handleSearch(this.searchData);
    }
  },
  computed: {
    ...mapState(['myAuth'])
  },
  methods: {
    _handleSearch() {
      const {
        ifClassfy, classfyType, searchType, searchData
      } = this;
      sessionStorage.setItem('job_search_params', JSON.stringify({
        searchType, ifClassfy, classfyType, ...searchData
      }));
      this.handleSearch(this.searchData);
    },
    handleCreateJob() {
      preFirstCheck()
        .then((res2) => {
          if (res2.data.code === '1') {
            this.$router.push({ path: '/data/job/create/process' });
          }
        });
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchData = {
        timeRange: [],
        dataJobName: '',
        dataJobType: 'all',
        desc: '',
        status: '',
        type: '',
        sourceType: '',
        sinkType: '',
        sourceName: '',
        sinkName: '',
        sourceInstanceId: 0,
        targetInstanceId: 0,
        transferObjName: '',
        dataJobId: null
      };
    },
    handleChooseClassify(data) {
      this.handleChangeMode('card');
      if (!data) {
        this.classfyType = 'status';
        this.handleClassify('');
      } else {
        this.classfyType = 'status';
        this.handleClassify('status');
      }
    },
    handleGoMetaCenter() {
      this.$router.push({
        path: '/data/job/meta/center'
      });
    },
    handleTransferJob() {
      this.getCluster();
      this.showTransferJob = true;
    },
    handleTransferDs() {
      this.listEnv();
      this.showTransferDs = true;
    },
    handleConfirmTransferJob() {
      this.importLoading = true;
      this.transferJobInfo.host = `${this.transferJobInfo.ip}:${this.transferJobInfo.port}`;
      // Object.keys(this.transferJobInfo).forEach((key) => {
      //   if (this.transferJobInfo[key]) {
      //     console.log('this.transferJobInfo[key]', this.transferJobInfo[key]);
      //     this.transferJobInfo[key] = this.transferJobInfo[key].trim();
      //   }
      // })
      if (this.transferJobInfo.accessKey) {
        this.transferJobInfo.accessKey = this.transferJobInfo.accessKey.trim();
      }
      if (this.transferJobInfo.secretKey) {
        this.transferJobInfo.secretKey = this.transferJobInfo.secretKey.trim();
      }
      const stringData = JSON.stringify(this.transferJobInfo);
      const formData = new FormData();

      if (this.customPkgFile) {
        formData.append('customPkgFile', this.customPkgFile);
      }
      formData.append('dataJobInFO', new Blob([stringData], { type: 'application/json' }));
      transferDataJobIn(formData).then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('dao-ru-cheng-gong'));
          this.customPkgFile = null;
          this.showTransferJob = false;
          this.handleRefresh();
          this.transferJobInfo = {
            port: '8111',
              dstDsHostType: 'PRIVATE',
              srcDsHostType: 'PRIVATE'
          };
        }
        this.importLoading = false;
      })
    },
    handleCancel() {
      this.showTransferJob = false;
      this.showTransferDs = false;
    },
    handleCancelCustomPkg() {
      this.showUploadPkg = false;
    },
    handleShowUploadPkg() {
      this.showUploadPkg = true;
    },
    handleFileChange(e) {
      const files = e.target.files;

      if (files && files[0]) {
        const file = files[0];

        if (file.size > 1024 * 1024 * 500) {
          this.$Modal.error({
            title: this.$t('wen-jian-guo-da'),
            content: this.$t('wen-jian-da-xiao-ying-bu-chao-guo-500m')
          });

          return false;
        }
        this.customPkgFile = file;
      }
    },
    handleRemovePkg() {
      this.customPkgFile = null;
    },
    handleConfirmUpload() {
      this.transferJobInfo.customPkgFile = this.customPkgFile;
      this.showUploadPkg = false;
    },
    handleConfirmTransferDs() {
      transferDsIn(this.transferDsInfo).then((res) => {
        if (res.data.success) {
          this.$Message.success(this.$t('dao-ru-cheng-gong'));
          this.showTransferDs = false;
          this.transferDsInfo.dsId = '';
          this.transferDsInfo.account = '';
          this.transferDsInfo.password = '';
        }
      })
    },
    getCluster() {
      listClusterByCondition({})
        .then((res) => {
          this.clusterList = [];
          if (res.data.code === '1') {
            this.clusterList = res.data.data;
            for (let i = 0; i < this.clusterList.length; i++) {
              if (this.clusterList[i].runningCount > 0) {
                this.transferJobInfo.clusterId = this.clusterList[i].id;
                return;
              }
            }
          }
        });
    },
    async listEnv() {
      const data = {
        envName: ''
      };
      const res = await this.$services.listDsEnv({ data });
      if (res.success) {
        this.envList = res.data;
      }
    },
  }
};
</script>
<style lang="less" scoped>
.page-header-container {
  background: #ffffff;
  border: 1px solid #EDEDED;
  height: 60px;
  line-height: 54px;
  padding: 0 20px;
  position: relative;

  .ivu-form-inline .ivu-form-item {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .ivu-form-item {
    margin-bottom: 0;
  }

  .page-header-function {
    position: absolute;
    right: 20px;
    top: 2px;

    a {
      color: #333;
      margin-right: 10px;
    }

    button {
      margin-left: 8px;
    }

    .ivu-tooltip {
      margin-left: 8px;
    }
  }
}

.data-job-mode-switch {
  width: 32px;
  height: 32px;
  border: 1px solid #BABDC5;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}
.data-job-type-select i {
  margin-left: 6px;
}

.ivu-alert-warning {
  padding-right: 16px!important;
}
</style>
