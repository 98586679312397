<template>
  <Modal
    v-model="visible"
    :title="$t('ren-wu-gao-jing-pei-zhi')"
    width="600"
    footer-hide
    @on-cancel="handleCloseModal"
  >
    <div style="font-size: 14px;">
      {{ $t('gao-jing-ren-yuan') }}
      <div style="width: 100%;border: 1px solid #ccc;border-radius: 3px;padding: 5px;margin-top: 5px;background: #eee">
        {{ Object.values(alertReceivers).length ? Object.values(alertReceivers).join(',') : $t('wu-0') }}
      </div>
    </div>
    <div>
      <p style="font-size: 16px;margin-top: 10px;font-weight:600">{{$t('ji-chu-pei-zhi')}}</p>
      <div v-for="(exception, i) in exceptionList"  :key="exception.id">
        <div v-if="i === 0">
          <div style="font-size: 14px;margin-top: 10px">
            {{ exception.eventTypeVO.i18nName }}
            <Tooltip placement="right-start">
              <Icon type="ios-help-circle-outline" style="font-size: 14px"/>
              <div slot="content">
                <p>{{ exception.eventDetailVO.i18nName }}</p>
              </div>
            </Tooltip>
          </div>
          <Form label-position="right" :label-width="100">
            <FormItem :label="$t('zhi-biao')" v-if="exception.indicators&&exception.showExpression">
            <span style="width: 240px;margin-right: 5px">
              {{ exception.expressionVO.i18nName }}</span>
              <span style="width: 70px;margin-right: 5px">{{ exception.indicators.symbol }}</span>
              <Input v-model="exception.indicators.value" style="width: 100px;margin-right: 5px" size="small"/>
              <span>{{ $t('dan-wei-fen-zhong') }}</span>
              <Tooltip placement="right-start">
                <Icon type="ios-help-circle-outline" style="font-size: 14px"/>
                <div slot="content">
                  <P>{{ $t('zui-xiao-1-fen-zhong') }}</P>
                </div>
              </Tooltip>
            </FormItem>
            <div style="display: flex;">
              <FormItem :label="$t('gao-jing-fang-shi')">
                <CheckboxGroup v-model="exception.methods">
                  <Checkbox label="dingding">IM</Checkbox>
                  <Checkbox label="email">{{ $t('you-xiang') }}</Checkbox>
                  <Checkbox label="sms">{{ $t('duan-xin') }}</Checkbox>
                  <Checkbox v-if="exception.eventTypeVO.eventType === 'TASK_POSITION_DELAY_EVENT'" label="phone">{{ $t('dian-hua') }}</Checkbox>
                </CheckboxGroup>
              </FormItem>
              <FormItem :label="$t('gao-jing-deng-ji')">
                <Select v-model="exception.alarmLevel" style="width: 100px;" size="small">
                  <Option v-for="level in alarmLevels" :value="level.alarmLevel" :key="level.alarmLevel">{{level.i18nName}}</Option>
                </Select>
              </FormItem>
            </div>
            <!--          <FormItem :label="$t('te-shu-pei-zhi')">-->
            <!--            <Checkbox label="phone" v-model="exception.sendAdmin">{{ $t('fa-song-gei-guan-li-yuan') }}</Checkbox>-->
            <!--            <Tooltip placement="right-start">-->
            <!--              <Icon type="ios-help-circle-outline" style="font-size: 14px"/>-->
            <!--              <div slot="content">-->
            <!--                <P>{{ $t('gou-xuan-ci-pei-zhi-dang-yong-hu-shou-dao-bao-jing-shi-gai-bao-jing-hui-yi-tong-yang-de-fang-shi-fa-song-gei-suo-you-guan-li-yuan') }}</P>-->
            <!--              </div>-->
            <!--            </Tooltip>-->
            <!--          </FormItem>-->
          </Form>
        </div>
      </div>
      <div style="margin-bottom: 10px">
        <Collapse style="background: #fff;border:none">
          <Panel name="1">
            <span>{{$t('geng-duo')}}</span>
            <template #content>
              <div style="padding: 0 16px 10px 16px">
                <div v-for="(exception, i) in exceptionList"  :key="exception.id">
                  <div v-if="i > 0">
                    <div style="font-size: 14px;margin-top: 10px">
                      {{ exception.eventTypeVO.i18nName }}
                      <Tooltip placement="right-start">
                        <Icon type="ios-help-circle-outline" style="font-size: 14px"/>
                        <div slot="content">
                          <p>{{ exception.eventDetailVO.i18nName }}</p>
                        </div>
                      </Tooltip>
                    </div>
                    <Form label-position="right" :label-width="100">
                      <FormItem :label="$t('zhi-biao')" v-if="exception.indicators&&exception.showExpression">
            <span style="width: 240px;margin-right: 5px">
              {{ exception.expressionVO.i18nName }}</span>
                        <span style="width: 70px;margin-right: 5px">{{ exception.indicators.symbol }}</span>
                        <Input v-model="exception.indicators.value" style="width: 100px;margin-right: 5px" size="small"/>
                        <span>{{ $t('dan-wei-fen-zhong') }}</span>
                        <Tooltip placement="right-start">
                          <Icon type="ios-help-circle-outline" style="font-size: 14px"/>
                          <div slot="content">
                            <P>{{ $t('zui-xiao-1-fen-zhong') }}</P>
                          </div>
                        </Tooltip>
                      </FormItem>
                      <div style="display: flex;">
                        <FormItem :label="$t('gao-jing-fang-shi')">
                          <CheckboxGroup v-model="exception.methods">
                            <Checkbox label="dingding">IM</Checkbox>
                            <Checkbox label="email">{{ $t('you-xiang') }}</Checkbox>
                            <Checkbox label="sms">{{ $t('duan-xin') }}</Checkbox>
                            <Checkbox v-if="exception.eventTypeVO.eventType === 'TASK_POSITION_DELAY_EVENT'" label="phone">{{ $t('dian-hua') }}</Checkbox>
                          </CheckboxGroup>
                        </FormItem>
                        <FormItem :label="$t('gao-jing-deng-ji')">
                          <Select v-model="exception.alarmLevel" style="width: 100px;" size="small">
                            <Option v-for="level in alarmLevels" :value="level.alarmLevel" :key="level.alarmLevel">{{level.i18nName}}</Option>
                          </Select>
                        </FormItem>
                      </div>
                      <!--          <FormItem :label="$t('te-shu-pei-zhi')">-->
                      <!--            <Checkbox label="phone" v-model="exception.sendAdmin">{{ $t('fa-song-gei-guan-li-yuan') }}</Checkbox>-->
                      <!--            <Tooltip placement="right-start">-->
                      <!--              <Icon type="ios-help-circle-outline" style="font-size: 14px"/>-->
                      <!--              <div slot="content">-->
                      <!--                <P>{{ $t('gou-xuan-ci-pei-zhi-dang-yong-hu-shou-dao-bao-jing-shi-gai-bao-jing-hui-yi-tong-yang-de-fang-shi-fa-song-gei-suo-you-guan-li-yuan') }}</P>-->
                      <!--              </div>-->
                      <!--            </Tooltip>-->
                      <!--          </FormItem>-->
                    </Form>
                  </div>
                </div>
              </div>
            </template>
          </Panel>
        </Collapse>
      </div>
      <div>
        <p style="font-size: 16px;font-weight: 600">{{ $t('gao-ji-gong-neng') }}</p>
        <div>
          <Form label-position="right" :label-width="100">
            <FormItem :label="$t('zan-shi-guan-bi-gao-jing')">
              <Checkbox v-model="enableSuspend" @on-change="handleSwitchEnable">{{ $t('guan-bi') }}</Checkbox>
              <DatePicker v-if="enableSuspend" type="datetimerange" v-model="suspendTimeRange" :options="suspendTimeOptions" placement="bottom-end" :placeholder="$t('xuan-ze-ri-qi')" style="width: 300px" />
            </FormItem>
          </Form>
        </div>
      </div>
      <div slot="footer" class="modal-footer" style="margin-top: 20px">
<!--        <pd-button v-if="alertReceivers[uid]" @click="handleAddAlarmReceiver('REMOVE')">{{ $t('qu-xiao-guan-zhu') }}</pd-button>-->
<!--        <pd-button v-else @click="handleAddAlarmReceiver('ADD')">{{ $t('guan-zhu-gao-jing') }}</pd-button>-->
        <pd-button type="primary" @click="handleEditRule">{{ $t('que-ren') }}</pd-button>
        <pd-button @click="handleCloseModal">{{ $t('qu-xiao') }}</pd-button>
      </div>
    </div>
  </Modal>
</template>

<script>
import {
  listAlertAlarmLevel,
  listAlertConfig,
  listAlertIndicators, modifyJobAlertReceiver,
  updateAlertConfigs
} from '@/services/cc/api/alert';

export default {
  name: 'AlarmRuleModal',
  props: {
    visible: Boolean,
    handleCloseModal: Function
  },
  data() {
    return {
      uid: '',
      jobId: 0,
      exceptionList: [],
      alertReceivers: {},
      alarmLevels: [],
      indicators: [],
      enableSuspend: false,
      suspendTimeRange: [],
      suspendTimeOptions: {
        shortcuts: [
          {
            text: this.$t('3-xiao-shi'),
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 3);
              return [start, end];
            }
          },
          {
            text: this.$t('12-xiao-shi'),
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 12);
              return [start, end];
            }
          },
          {
            text: this.$t('1-tian'),
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24);
              return [start, end];
            }
          },
          {
            text: this.$t('7-tian'),
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: this.$t('1-ge-yue'),
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          }
        ]
      }
    };
  },
  created() {
    this.jobId = Number(this.$route.params.id);
    this.handleAlarmConfig();
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      if (localStorage.getItem('userInfo')) {
        this.uid = JSON.parse(localStorage.getItem('userInfo')).uid;
      }
    },
    verifyExpression(expression) {
      const expressionForm = [];

      if (expression.indexOf('>=') > -1) {
        expressionForm[0] = expression.split('>=')[0];
        expressionForm[1] = '>=';
        expressionForm[2] = expression.split('>=')[1];
      } else if (expression.indexOf('<=') > -1) {
        expressionForm[0] = expression.split('<=')[0];
        expressionForm[1] = '<=';
        expressionForm[2] = expression.split('<=')[1];
      } else if (expression.indexOf('!=') > -1) {
        expressionForm[0] = expression.split('!=')[0];
        expressionForm[1] = '!=';
        expressionForm[2] = expression.split('!=')[1];
      } else if (expression.indexOf('=') > -1) {
        expressionForm[0] = expression.split('=')[0];
        expressionForm[1] = '=';
        expressionForm[2] = expression.split('=')[1];
      } else if (expression.indexOf('>') > -1) {
        expressionForm[0] = expression.split('>')[0];
        expressionForm[1] = '>';
        expressionForm[2] = expression.split('>')[1];
      } else if (expression.indexOf('<') > -1) {
        expressionForm[0] = expression.split('<')[0];
        expressionForm[1] = '<';
        expressionForm[2] = expression.split('<')[1];
      }

      return expressionForm;
    },
    handleAlarmConfig() {
      listAlertAlarmLevel().then((res) => {
        if (res.data.success) {
          this.alarmLevels = res.data.data;
        }
      });
      listAlertIndicators().then((res) => {
        if (res.data.code === '1') {
          this.indicators = res.data.data;
        }
      });
      listAlertConfig({ dataJobId: this.jobId }).then((res) => {
        if (res.data.success) {
          res.data.data.forEach((config) => {
            if (config.id > -1) {
              this.alertReceivers = config.alertReceivers;
              this.enableSuspend = config.suspendAlarmConfigVO.enableSuspend;
              if (config.suspendAlarmConfigVO.suspendStartTimestamp) {
                this.suspendTimeRange = [new Date(JSON.parse(config.suspendAlarmConfigVO.suspendStartTimestamp)), new Date(JSON.parse(config.suspendAlarmConfigVO.suspendEndTimestamp))];
              }
            }
            if (config.expressionVO && config.expressionVO.expression) {
              const expressionForm = this.verifyExpression(config.expressionVO.expression);
              config.indicators = {
                indicator: expressionForm[0],
                value: expressionForm[2].split('*')[0],
                symbol: expressionForm[1]
              };
            }

            config.methods = [];
            if (config.dingding) {
              config.methods.push('dingding');
            }
            if (config.phone) {
              config.methods.push('phone');
            }
            if (config.sms) {
              config.methods.push('sms');
            }
            if (config.email) {
              config.methods.push('email');
            }
          });

          this.exceptionList = res.data.data;
        }
      });
    },
    handleSwitchEnable(data) {
      console.log('data', data);
      if (!data) {
        this.suspendTimeRange = [];
      }
    },
    handleEditRule() {
      this.showEditAlarmRule = false;
      const alertConfigVOList = [];
      this.exceptionList.forEach((exception) => {
        const {
          id, alarmLevel, sendAdmin, methods, indicators, eventTypeVO
        } = exception;
        alertConfigVOList.push({
          id,
          dataJobId: this.jobId,
          eventType: eventTypeVO.eventType,
          alarmLevel,
          sendAdmin,
          phone: methods.includes('phone'),
          dingding: methods.includes('dingding'),
          email: methods.includes('email'),
          sms: methods.includes('sms'),
          expression: indicators ? indicators.indicator + indicators.symbol + indicators.value : null,
          enableSuspend: this.enableSuspend,
          suspendStartTimestamp: this.suspendTimeRange[0] ? this.suspendTimeRange[0].getTime() : null,
          suspendEndTimestamp: this.suspendTimeRange[1] ? this.suspendTimeRange[1].getTime() : null
        });
      });

      updateAlertConfigs(alertConfigVOList)
        .then((res) => {
          if (res.data.success) {
            this.$Message.success(this.$t('xiu-gai-gao-jing-pei-zhi-cheng-gong'));
            this.handleAlarmConfig();
            this.handleCloseModal();
          }
        });
    },
    async handleAddAlarmReceiver(type) {
      const res = await modifyJobAlertReceiver({ dataJobId: this.jobId, operation: type });
      if (res.data.success) {
        this.$Message.success(type === 'REMOVE' ? this.$t('qu-xiao-guan-zhu-cheng-gong') : this.$t('guan-zhu-cheng-gong'));
        this.handleAlarmConfig();
      }
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .ivu-form-item {
  margin-bottom: 0;
}
</style>
